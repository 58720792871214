import React, { Component } from 'react';
import './style.css'
import { connect } from 'react-redux';
import YearPicker from "react-year-picker";
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import CageQuestion from './cageQuestion';
import StandardDrinks from './standardDrinks';
import AuditC from './auditC';
import {api_alocohol_history,api_get_alcohol_details} from '../../../DS/DS.FamilyHistory';
import TobaccoHistory from './tobaccoHistory';
import Showhelp from './../../showHelp';

class alcoholhistory extends Component {
    constructor(props)
    {
        super(props);
        this.handleEndChange = this.handleEndChange.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.state={
            disable:false,
            key:0,
            past_drinking_type:0,
            disable1:false,
            alcohol_comments:'',
            per_week:'',
            per_day:'',
            cageQuestions:false,
            stdDrinks:false,
            auditc:false,
            years:''
        }
    }
    componentWillMount=()=>
    {
        this.state.styles = {
            changeto: {
                marginTop:'5px'
            }
        }
        const val={id:this.props.redux_patientId}
        this.props.apigetalcoholdetails(val).then(
            req=>{
                if(req.data.content!='')
                {
                    this.setState({ 
                        per_week:req.data.content[0].per_week,
                        per_day:req.data.content[0].per_day,
                        description:req.data.content[0].description,
                        past_drinking_type:req.data.content[0].past_drinking_type,
                        started_date:req.data.content[0].started_date,
                        end_date:req.data.content[0].end_date,
                        alcohol_comments:req.data.content[0].alcohol_comments,
                        drinking_type:req.data.content[0].drinking_type,
                        })
                if(req.data.content[0].drinking_type==1)
                {
                    this.setState({disable:true,disable1:true})
                }
            }
            }
        )
        const year =(new Date()).getFullYear() - 1925;
    
        this.state.years = Array.from(new Array(year+1),(val, index) => index + 1925);
    }
    nonDrinkerHandler=(e)=>
    {
        if(e.target.checked)
        {
            this.setState({disable:true})
            this.setState({drinking_type:1})
        }
        else
        {
            this.setState({disable:false})
            this.setState({drinking_type:0})
        }
    }
    handleAlcoholRadio=(val,e)=>
    {
       // alert("IN"+val)
        this.setState({past_drinking_type:e.target.value})
        if(val==1)
        {
            this.setState({disable1:true})
            this.state.styles = {
                changeto: {
                    marginTop:'5px',
                    pointerEvents:'none'
                }
            }
            this.setState({key:Math.random()})
        }
        else
        {
            this.setState({disable1:false})
            this.state.styles = {
                changeto: {
                    marginTop:'5px',
                }
            }
            this.setState({key:Math.random()})
        }
      
    }
    handleStartChange(date) {
        this.setState({started_date:date})
      }
      handleEndChange(date)
      {
        this.setState({end_date:date})
      }
      reloadPage=()=>
      {
          this.setState({cageQuestions:false,stdDrinks:false,auditc:false})
          this.setState({key:Math.random()})
      }
    saveHandler=()=>
    {
        const values=
        {
            per_week:this.state.per_week,
            per_day:this.state.per_day,
            description:this.state.description,
            past_drinking_type:this.state.past_drinking_type,
            started_date:this.state.started_date,
            end_date:this.state.end_date,
            alcohol_comments:this.state.alcohol_comments,
            drinking_type:this.state.drinking_type,
            patient_id:this.props.redux_patientId,
            doc_id:this.props.redux_docId

        }
        this.props.apiAlocoholHistory({values}).then(
            req=>
              NotificationManager.success('Alocohol details saved successfully')
            )
    }
    render() { 
        return ( 
            <div className="col-sm-12 mb-1 mt-1">
                <div className="col-md-6">
                <div className="col-sm-12 pl-0 pr-0">
                            <h5 class="sheadtitle">Alcohol History <Showhelp gotohelp="alocohol_history_help"/></h5>
                    </div>
                <hr/>
                    <div style={{padding:'10px 10px 10px 0', overflowY: 'auto'}}>
                        <div className="col-sm-12">
                            <div className="col-sm-5 " style={{textAlign:'left'}}>
                                <label style={{marginTop:'5px'}}>Current alcohol intake</label>
                            </div>
                            <div className="col-sm-4" style={{marginTop:''}}>
                                <label>  
                                    <input type="checkbox"  style={{marginTop: ''}}
                                      checked={this.state.drinking_type==1?'checked':''} onChange={(e)=>this.nonDrinkerHandler(e)}/> Non drinker   
                                </label>            
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-3 " style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>Day per week:</label>
                            </div>
                            <div className="col-sm-3 " style={{textAlign:'left'}}>
                                <input type="text" class="form-control" disabled={this.state.disable}
                                onChange={(e)=>this.setState({per_week:e.target.value})} value={this.state.per_week}/>
                            </div>
                            <div className="col-sm-3 " style={{textAlign:'left', paddingRight:'0px'}}>
                                <label style={{marginTop:'20px'}}>Standard drink per day :</label>
                            </div>
                            <div className="col-sm-3" style={{textAlign:'left'}}>
                                <input type="text" class="form-control" disabled={this.state.disable}
                                 onChange={(e)=>this.setState({per_day:e.target.value})} value={this.state.per_day}/>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-3" style={{float:'left'}}>
                                <label style={{marginTop: '15px'}}> Description:</label>
                            </div>
                            <div className="col-sm-9">
                                <textarea rows="3" cols="" style={{padding:'15px',border:'1px solid rgb(221, 221, 221)',marginTop: '10px',width:'100%'}} 
                                disabled={this.state.disable} value={this.state.description} onChange={(e)=>this.setState({description:e.target.value})}></textarea>
                            </div>
                        </div>
                        <div className="col-sm-4 " style={{textAlign:'left'}}>
                            <label style={{margin:'8px 0 8px 0'}}>Past alcohol intake</label>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-3" >
                                <label onChange={(e)=>{this.handleAlcoholRadio(1,e)}}>
                                    <input type="radio" name="esmoking" value="1" checked={this.state.past_drinking_type==1 ?'checked':''}/> Nil 
                                </label>
                            </div>
                            <div className="col-sm-3">
                                <label onChange={(e)=>{this.handleAlcoholRadio(2,e)}}>
                                    <input type="radio" name="esmoking" value="2" checked={this.state.past_drinking_type==2 ?'checked':''}/> Occasional 
                                </label>
                            </div>
                            <div className="col-sm-4 ">
                                <label onClick={(e)=>{this.handleAlcoholRadio(3,e)}}> 
                                    <input type="radio" name="esmoking" value="3" checked={this.state.past_drinking_type==3 ?'checked':''}/> Moderate 
                                </label>
                            </div>
                            <div className="col-sm-2 " >
                                <label onClick={(e)=>{this.handleAlcoholRadio(4,e)}}> 
                                    <input type="radio" name="esmoking" value="4" checked={this.state.past_drinking_type==4 ?'checked':''}/> Heavy 
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-3" style={{marginTop:'20px'}}>
                                <label style={{marginTop: ''}}>year started</label>
                            </div>
                            <div className="col-sm-3" style={this.state.styles.changeto}>
                               {/* <YearPicker onChange={this.handleStartChange}/>*/}
                               <select class="form-control" onChange={(e)=>this.setState({started_date:e.target.value})} value={this.state.started_date}>
                                    {
                                    this.state.years.map((year, index) => {
                                        return <option key={`year${index}`} value={year}>{year}</option>
                                    })
                                    }
                                    </select>
                            </div>
                           
                            <div className="col-sm-3" style={{marginTop:'20px'}}>
                                <label style={{marginTop: ''}}>year stop</label>
                            </div>
                            <div className="col-sm-3" style={this.state.styles.changeto} >
                                {/*<YearPicker onChange={this.handleEndChange}/>*/}
                               <select class="form-control" onChange={(e)=>this.setState({end_date:e.target.value})} value={this.state.end_date}>
                                    {
                                    this.state.years.map((year, index) => {
                                        return <option key={`year${index}`} value={year}>{year}</option>
                                    })
                                    }
                                    </select>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <div className="col-sm-3" style={{float:'left'}}>
                                <label style={{marginTop: '15px'}}> Comments:</label>
                            </div>
                            <div className="col-sm-9">
                                <textarea rows="3" cols="" style={{padding:'15px',border:'1px solid rgb(221, 221, 221)',marginTop: '10px',width:'100%'}}
                                onChange={(e)=>this.setState({alcohol_comments:e.target.value})} value={this.state.alcohol_comments}></textarea>
                            </div>
                        </div>
                        <div className="col-sm-12" style={{marginTop: '7px'}}>
                            <div className="" style={{float:'left'}}></div>
                            <div className="col-sm-4" style={{float:''}}>
                                <button className="btn btn-primary btn-xs"
                                onClick={()=>this.setState({cageQuestions:true})}>CAGE Questions</button>
                            </div>
                            <div className="col-sm-4" align="">
                                <button className="btn btn-primary btn-xs"
                                onClick={()=>this.setState({stdDrinks:true})}>Standards Drinks</button>
                            </div>
                            <div className="col-sm-4" style={{ float:''}}>
                                <button className="btn btn-primary btn-xs" 
                                onClick={(e)=>this.setState({auditc:true})}>Audit-c</button>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-6" style={{float:'left'}}></div>
                            <div className="col-sm-6" align="right" style={{float:'right', marginTop:'10px',marginBottom:'15px'}}>
                                <button className="btn btn-primary btn-xs" onClick={()=>this.saveHandler()}>Save</button>
                                <button className="btn btn-primary btn-xs" onClick={()=>this.props.reloadPage()}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    {(this.state.cageQuestions) ?
                        <CageQuestion showmodal={this.state.cageQuestions}  changeKey={this.reloadPage}/> : ''}
                   
                    {(this.state.stdDrinks) ? 
                    <StandardDrinks showmodal={this.state.stdDrinks} changeKey={this.reloadPage}/> : '' }    

                    {(this.state.auditc) ? 
                    <AuditC showmodal={this.state.auditc} changeKey={this.reloadPage}/> : ''}
                </div>
                 <div className="col-md-6">  
                    <div className="col-sm-12 pl-0 pr-0">
                            <h5 class="sheadtitle">Tobacco History</h5>
                    </div>
                        <div>
                            <TobaccoHistory/>
                        </div>
                   </div>     
                </div>
            );
        }
    }
    const mapDispatchToProps = dispatch => {
        return {
            apiAlocoholHistory: (data) => dispatch(api_alocohol_history(data)),
            apigetalcoholdetails :(data) =>dispatch(api_get_alcohol_details(data))
                };
      };
      const mapStateToProps = state => {
       console.log(">>> doc data",state.getDrAppointments.logged_info)
         let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
         let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
     
       return {
         redux_patientId,redux_docId
       };
     };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  ) (alcoholhistory);
