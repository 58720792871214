import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../showHelp';

class AuditCExplanatory extends Component {
    constructor(props){
        super(props);
        this.state = {
            open:this.props.showmodal,
        }
    }
    render() {
        return ( 
        <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
         {close => (
             <div>
                <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding:'5px 15px'}}>
                    <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px',
                     fontSize:'16px', marginBottom:'0px',color:'black'}}> Audit-C Explanatory notes <Showhelp gotohelp="audit_explanatory_help"/></h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}
                    style={{marginTop:'10px',color:'black'}}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
				            <p style={{fontSize:'20px'}}>  <b> How is Audit-C scored? </b> </p>
				   	        <div className="row row-distance"></div>
                            <p> A total of 5+ indicates increasing or higher risk drinking. </p>
                            <p> An overall total Score OF 5 or above IS AUDIT-C positive </p>
                            <div className="row row-distance3"></div>
                            <p> This is one unit of alcohol_ </p>
                            <p> • Half pint of regular beer, larger or cider </p>
                            <p> • 1 small glass of wine </p>
                            <p> • 1 single measure of spitits </p>
                            <p> • 1 small glass of sherry </p>
                            <p> • 1 single measure of aperitifs </p>
			            </div>		
			        </div>
                    <div className="row row-distance3"></div>
                    <div className="row row-distance">
                        <div className="col-sm-12">
                            <p> _and each of these is more than one unit </p>
                            <p> • 2 = Pint of Regular Beer/Larger/Cider </p>
                            <p> • 3 = Pint of Ptemium Beer/Larger/Cider </p>
                            <p> • 1.5 = Alcopop or can/bottle of Regular Larger </p>
                            <p> • 2 = 440ml Can of Premium Larger or Strong Beer </p>
                            <p> • 4 = 440mml Can of Super Strength Larger </p>
                            <p> • 2 = Glass of Wine (175ml) </p>
                            <p> • 9 = Bottle of Wine </p>
			            </div>		
			        </div>
                </div>
                </div>
		        <div className="modal-footer">
				    <button type="button" className="btn btn-primary btn-xs" onClick={()=>this.props.changeKey()}>Ok</button>
                </div>
            </div>
        )}
    </Popup>
        )
    }
}     
export default AuditCExplanatory;