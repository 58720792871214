import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../../showHelp';


class ent extends Component {
    state = {  }
    render() {
        return (
            <Popup trigger={<a  title="ENT" className="collapsed" aria-expanded="false"><i className="fa fa-plus-square" style={{'fontSize':'20px' ,padding:'10px'}} aria-hidden="true"></i></a>} position="right center"   modal
            closeOnDocumentClick={false}
          >
             {close => (
          <div className="entlay">

            <div className="modal-header"
            //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
             >

                      <button type="button" className="c
                      lose" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> ENT <Showhelp gotohelp="ent_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>ENT</h4> */}
                    </div>
                    <div className="modal-body"   style={{overflowY: "scroll", height: "450px" }}>
                        <h4 style={{}}>History:</h4>

                        <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Earache</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.Earache === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Earache")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.Earache == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Earache")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.Earache === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Earache")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Sore throat</label>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.SoreThroat === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "SoreThroat")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.SoreThroat === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "SoreThroat")}
                    /> No

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Ear discharge</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.EarDischarge === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "EarDischarge")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.EarDischarge === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "EarDischarge")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.EarDischarge === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "EarDischarge")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Hoarse voice</label>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.HoarseVoice === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "HoarseVoice")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.HoarseVoice === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "HoarseVoice")}
                    /> No

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Deafness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.Deafness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Deafness")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.Deafness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Deafness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.Deafness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Deafness")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Lost voice</label>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.LostVoice === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "LostVoice")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.LostVoice === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "LostVoice")}
                    /> No

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Rhinorrhoea</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Rhinorrhoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Rhinorrhoea")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Rhinorrhoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Rhinorrhoea")}
                    /> No
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Facial pain</label>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.FacialPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "FacialPain")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.FacialPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "FacialPain")}
                    /> No

                        </div>
                </div>
                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Epistaxis</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Epistaxis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Epistaxis")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Epistaxis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "Epistaxis")}
                    /> No
                        </div>

                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>


                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Nasal congestion</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.NasalCongestion === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "NasalCongestion")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.NasalCongestion === "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "NasalCongestion")}
                    /> No
                        </div>

                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>


                        </div>
                </div>
            &nbsp;
              <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                <h4 style={{}}>Examination:</h4>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Drum red</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.DrumRed == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "DrumRed")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.DrumRed == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "DrumRed")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.DrumRed == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "DrumRed")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Red throat</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.RedThroat == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "RedThroat")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RedThroat == "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "RedThroat")}
                    /> No

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Discharge </label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Discharge == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Discharge")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Discharge == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Discharge")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Discharge == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Discharge")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Pus on tonsils</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.PusOnTonsils == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "PusOnTonsils")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.PusOnTonsils == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "PusOnTonsils")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.PusOnTonsils == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "PusOnTonsils")}
                    /> Neither
                           

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Perforation</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Perforation == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Perforation")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Perforation == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Perforation")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Perforation == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Perforation")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Enlarged tonsils</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.EnlargedTonsils == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "EnlargedTonsils")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.EnlargedTonsils == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "EnlargedTonsils")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.EnlargedTonsils == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "EnlargedTonsils")}
                    /> Neither
                           
                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Glue </label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Glue == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Glue")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Glue == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Glue")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Glue == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Glue")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Nose discharge</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.NoseDischarge == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseDischarge")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.NoseDischarge == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseDischarge")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.NoseDischarge == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseDischarge")}
                    /> Neither
                           

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Canal red</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.CanalRed == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "CanalRed")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.CanalRed == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "CanalRed")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.CanalRed == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent", "CanalRed")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Nasal obstruction</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.NasalObstruction == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalObstruction")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.NasalObstruction == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalObstruction")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.NasalObstruction == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalObstruction")}
                    /> Neither
                           
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Wax </label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Wax == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Wax")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Wax == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Wax")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Wax == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Wax")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Nose redness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.NoseRedness == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseRedness")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.NoseRedness == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseRedness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.NoseRedness == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NoseRedness")}
                    /> Neither
                           
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>FB</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Fb == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Fb")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Fb == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Fb")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Fb == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "Fb")}
                    /> Neither
                           
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Nasal FB</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.NasalFB == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalFB")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.NasalFB == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalFB")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.NasalFB == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "NasalFB")}
                    /> Neither
                           
                        </div>
                </div>

                &nbsp;


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Mouth lesion</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.MouthLesion == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "MouthLesion")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.MouthLesion == "No"}
                      onChange={e => this.props.handleOptionChange(e, "ent_examination", "MouthLesion")}
                    /> No

                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Description:</label>
                        </div>
                        <div className="col-sm-6" style={{float: 'left',marginTop: '15px'}}>
                        <input type="text" style={{width: '250px'}} 
                        value={this.props.vals_examination.Description}
                        onChange={e => this.props.handleOptionChange(e, "ent_examination", "Description")}
                        />

                        </div>
                </div>

            </div>

                <div className="modal-footer"  >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                      
                      onClick={e => 
                        {this.props.handleSaveOptions(e, "ent");
                        close();}
                        }
                        >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>


 </div>


)}
</Popup>
         );
    }
}

export default ent;
