import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import {
  acute,
  ChangeDragData,
  api_searchConditions,
  api_addConditions,
  deleteData,
  addPaitentData,
  //deletePatientId,
  editPaitentData,
  api_getPatientDetails
} from "./../../../DS/DS.Appointments";
import {
  patientConditions,
  patientConditions2,
  drConditions,
  drConditions2,
  deletePatientId,
  deletePatientFavId,
  deleteDoctorFavId, 
 
} from "./../../../DS/DS.VisitnotesDiagnosis";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "./diagonsis.css";
import Showhelp from './../../showHelp';
import { setState } from "react-jsonschema-form/lib/utils";
import { Pointer } from "highcharts";
class Diagonsis extends Component {
  state = {
    masterConditions: {},
    saveDisable: false,
    showEditMode: '',
    showEditModeValue: '',
    canclose: true,
    patientId: this.props.redux_patientId,
    drId: this.props.logged_info.content.details[0].doctor_id,
    logged_info: [],
    acuteConditions: {},
    chronicConditions: {},
    patientFavouritesConditions: {},
    drFavouritesCondtions: {},
    drFavouritesCondtions2: {},
    general_comments: this.props.pastHistoryComments,
    acuteSearchText: "",
    chronicSearchText: "",
    patientFavouritesSearchText: "",
    drFavouritesSearchText: "",
    drFavouritesSearchText2: "",

    conditionsIdCheckd: {},
    conditionsChecked: {},

    acuteCheckedConditions: {},
    acuteCheckedConditionsId: {},

    chronicCheckedConditions: {},
    chronicCheckedConditionsId: {},

    patientFavCheckedConditions: {},
    patientFavCheckedConditionsId: {},

    drFavCheckedConditions: {},
    drFavCheckedConditionsId: {},

    drFavCheckedConditions2: {},
    drFavCheckedConditionsId2: {},

    add_acute_fav: false,
    add_chronic_fav: false,

    acuteSelectedOption: "Mild",
    chronicSelectedOption: "Mild",
    patientFavSelectedOption: "Mild",
    drFavSelectedOption: "Mild",
    drFavSelectedOption2: "Mild",
    confidential: 0,
    acuteData: []
  };

  handleConfidential = (e) => {
if(e.target.checked){
  this.setState({confidential: 1});
} else {
  this.setState({confidential: 0});
}
  }
  componentWillMount(){
    this.props.drFavConditionsLocal({ drId: this.state.drId });
    this.props.drFavConditionsLocal_chronic({ drId: this.state.drId });
  }
  componentDidMount() {
    this.props.patientConditionsLocal({
      searchTxt: "",
      patientId: this.state.patientId
    });
    this.props.searchConditions({ searchText: '' }).then(list => {
      this.setState({ acuteConditions: list.data.content });
      this.setState({ chronicConditions: list.data.content });
      this.setState({ masterConditions: list.data.content });
    });
    this.props.drFavConditionsLocal({ drId: this.state.drId });
    this.props.drFavConditionsLocal_chronic({ drId: this.state.drId });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });
      this.setState({ patientId: nextProps.general_comments });
     
    }
    if (nextProps.pastHistoryComments !== this.props.pastHistoryComments) {
      this.setState({general_comments: nextProps.pastHistoryComments});
    }
    if(nextProps.logged_info.content.details[0].doctor_id!=this.state.drId){
      this.setState({ logged_info: nextProps.logged_info });
      alert( nextProps.logged_info.content.details[0].doctor_id);
      this.setState({ drId: nextProps.logged_info.content.details[0].doctor_id });
    }
  }
  handleGeneralComments = (e) => {
    this.setState({general_comments: e.target.value});
  }
  handleOptionChange = (changeEvent, type) => {
    if (type === "acute") {
      this.setState({
        acuteSelectedOption: changeEvent.target.value
      });
    } else if (type === "chronic") {
      this.setState({
        chronicSelectedOption: changeEvent.target.value
      });
    }

    if (type === "patientFavourites") {
      this.setState({
        patientFavSelectedOption: changeEvent.target.value
      });
    }
    if (type === "drFavourites") {
      console.log("drFavourites");
      this.setState({
        drFavSelectedOption: changeEvent.target.value
      });
    }
    if (type === "drFavourites2") {
      this.setState({
        drFavSelectedOption2: changeEvent.target.value
      });
    }
  };

  handleChangeText = (e, type) => {
    if (type === "acute") {
      this.setState({ acuteSearchText: e.target.value });
      this.props.searchConditions({ searchText: e.target.value }).then(list => {
        this.setState({ acuteConditions: list.data.content });
      });
    } else if (type === "chronic") {
      this.setState({ chronicSearchText: e.target.value });
      this.props.searchConditions({ searchText: e.target.value }).then(list => {
        this.setState({ chronicConditions: list.data.content });
      });
    } else if (type === "patientFavourites") {
      console.log(e.target.value);
      this.props.patientConditionsLocal({
        searchTxt: e.target.value,
        patientId: this.state.patientId
      });
    } else if (type === "drFavourites") {
      this.props.drFavConditionsLocal({
        searchTxt: e.target.value,
        drId: this.state.drId
      });
    } else if (type === "drFavourites2") {
      this.props.drFavConditionsLocal_chronic({
        searchTxt: e.target.value,
        drId: this.state.drId
      });
    }
    //this.searchCondition(type);
    console.log(this.state);
  };

  searchCondition = type => {
    switch (type) {
      case "acute": {
        // var searchParam = { searchText: this.state.acuteSearchText };
        // this.props.searchConditions(searchParam).then(list => {
        //   this.setState({ acuteConditions: list.data.content });
        // });
        break;
      }
      case "chronic": {
        // var searchParam = { searchText: this.state.chronicSearchText };
        // this.props.searchConditions(searchParam).then(list => {
        //   this.setState({ chronicConditions: list.data.content });
        // });
        break;
      }
      case "patientFavourites": {
        //this.props.patientConditionsLocal(searchParam);
        break;
      }
      case "drFavourites": {
        // var searchParam = { searchText: this.state.drFavouritesSearchText };
        // this.props.drFavConditionsLocal(searchParam).then(list => {
        //   this.setState({ drFavouritesCondtions: list.data.content });
        // });
        break;
      }
      case "drFavourites2": {
        // var searchParam = { searchText: this.state.drFavouritesSearchText };
        // this.props.drFavConditionsLocal(searchParam).then(list => {
        //   this.setState({ drFavouritesCondtions: list.data.content });
        // });
        break;
      }
      default:
        alert("Something went wrong!");
    }
  };

  handleCheckbox = (e, data, id, type) => {


  //  console.log("diagnosis state, length", this.state, Object.keys(this.state.acuteCheckedConditions).length);
   
     // this.setState({saveDisable: false});
     // alert(1);
     // } else  {
      // console.log("diagnosis state", this.state);
        
     //   this.setState({saveDisable: true});
    //  }



    if (type === "acute") {
      let itemChecked = this.state.acuteCheckedConditions;
      let itemCheckedId = this.state.acuteCheckedConditionsId;
      if (!e.target.checked) {
        delete itemChecked[data];
        delete itemCheckedId[id];
      } else {
        itemChecked[data] = e.target.checked;
        itemCheckedId[id] = e.target.checked;
      }
      this.setState({
        acuteCheckedConditions: itemChecked,
        acuteCheckedConditionsId: itemCheckedId
      });
    }
    if (type === "chronic") {
      let itemChecked = this.state.chronicCheckedConditions;
      let itemCheckedId = this.state.chronicCheckedConditionsId;
      if (!e.target.checked) {
        delete itemChecked[data];
        delete itemCheckedId[id];
      } else {
        itemChecked[data] = e.target.checked;
        itemCheckedId[id] = e.target.checked;
      }
      this.setState({
        chronicCheckedConditions: itemChecked,
        chronicCheckedConditionsId: itemCheckedId
      });
    }
    if (type === "patientsFavourites") {
      let itemChecked = this.state.patientFavCheckedConditions;
      let itemCheckedId = this.state.patientFavCheckedConditionsId;
      if (!e.target.checked) {
        delete itemChecked[data];
        delete itemCheckedId[id];
      } else {
        itemChecked[data] = e.target.checked;
        itemCheckedId[id] = e.target.checked;
      }
      this.setState({
        patientFavCheckedConditions: itemChecked,
        patientFavCheckedConditionsId: itemCheckedId
      });
    }
    if (type === "drFavourites") {
      let itemChecked = this.state.drFavCheckedConditions;
      let itemCheckedId = this.state.drFavCheckedConditionsId;
      if (!e.target.checked) {
        delete itemChecked[data];
        delete itemCheckedId[id];
      } else {
        itemChecked[data] = e.target.checked;
        itemCheckedId[id] = e.target.checked;
      }
      this.setState({
        drFavCheckedConditions: itemChecked,
        drFavCheckedConditionsId: itemCheckedId
      });
    }
    if (type === "drFavourites2") {
      let itemChecked = this.state.drFavCheckedConditions2;
      let itemCheckedId = this.state.drFavCheckedConditionsId2;
      if (!e.target.checked) {
        delete itemChecked[data];
        delete itemCheckedId[id];
      } else {
        itemChecked[data] = e.target.checked;
        itemCheckedId[id] = e.target.checked;
      }
      this.setState({
        drFavCheckedConditions2: itemChecked,
        drFavCheckedConditionsId2: itemCheckedId
      });
    }
  };

  handleAddText = e => {
    this.setState({
      AddConditionText: e.target.value
    });
  };

  acuteAddCondition = data => {
    if(this.state.acuteSearchText!=''){


      let duplicate = false;
      if(this.state.masterConditions.length>0){
        let co = this.state.masterConditions.length;
        for(let i = 0; i<co; i++){
          if(this.state.masterConditions[i].name.toUpperCase() == this.state.acuteSearchText.toUpperCase()){
              duplicate = true;
              break;
          }
        }
      }
      if(duplicate==false){
    var AddParam = {
      AddConditionText: this.state.acuteSearchText,
      doctor_id: this.state.drId
    };
    this.props.apiAddConditions(AddParam).then(res => {
      this.setState({ acuteSearchText: '' });
      NotificationManager.success("Condition Added Successfully");
      this.props.searchConditions({ searchText: '' }).then(list => {
        this.setState({ acuteConditions: list.data.content });
        this.setState({ chronicConditions: list.data.content });
        this.setState({ masterConditions: list.data.content });
      });
    });
  } else {
    alert("Cannot add Condition, Condition already found!");
  }
  } else {
    alert("Cannot add Empty Conditon, Please write something ");
  }
    
  };

  chronicAddCondition = data => {
 
    if(this.state.chronicSearchText!=''){
     


    let duplicate = false;
    if(this.state.masterConditions.length>0){
      let co = this.state.masterConditions.length;
      for(let i = 0; i<co; i++){
        if(this.state.masterConditions[i].name.toUpperCase() == this.state.chronicSearchText.toUpperCase()){
            duplicate = true;
            break;
        }
      }
    }
    if(duplicate==false){
      var AddParam = {
        AddConditionText: this.state.chronicSearchText,
        doctor_id: this.state.drId
      };
      this.props.apiAddConditions(AddParam).then(res => {
        this.setState({ chronicSearchText: '' });
        NotificationManager.success("Condition Added Successfully");
        this.props.searchConditions({ searchText: '' }).then(list => {
          this.setState({ acuteConditions: list.data.content });
          this.setState({ chronicConditions: list.data.content });
          this.setState({ masterConditions: list.data.content });
        });
      });
    } else {
      alert("Cannot add Condition, Condition already found!");
    }

  } else {
    alert("Cannot add Empty Conditon, Please write something ");
  }
    
    // this.searchCondition();
  };

  handleSave = e => {

console.log("length", Object.keys(this.state.acuteCheckedConditions).length);
if(Object.keys(this.state.acuteCheckedConditions).length>0 || 
Object.keys(this.state.chronicCheckedConditions).length>0 ||
Object.keys(this.state.drFavCheckedConditions).length>0 || 
Object.keys(this.state.drFavCheckedConditions2).length>0 ||
Object.keys(this.state.patientFavCheckedConditions).length>0

){
 
    this.props.diagonsisSave(this.state,this.props.showfor);
    
    this.setState({
      acuteCheckedConditionsId:{},
      acuteCheckedConditions:{},
      chronicCheckedConditionsId: {},
      chronicCheckedConditions:{}, 
      drFavCheckedConditions:{},
      drFavCheckedConditionsId:{},
      drFavCheckedConditions2:{},
      drFavCheckedConditionsId2:{},
    });

    this.props.drFavConditionsLocal({ drId: this.state.drId });
    this.props.drFavConditionsLocal_chronic({ drId: this.state.drId });

    this.setState({canclose: true});
    if(this.props.key=='')
    {
    NotificationManager.success("Past History Record(s) Added Successfully! ");
    }

    if(this.props.key!='')
    {
      this.props.closeDiagonsis()
    }

    // this.props.patientConditionsLocal({
    //   searchTxt: "",
    //   patientId: this.state.patientId
    // });
    // this.props.searchConditions({ searchText: '' }).then(list => {
    //   this.setState({ acuteConditions: list.data.content });
    //   this.setState({ chronicConditions: list.data.content });
    //   this.setState({ masterConditions: list.data.content });
    // });
    // this.props.drFavConditionsLocal({ drId: this.state.drId });
    // this.props.drFavConditionsLocal_chronic({ drId: this.state.drId });

    //then(res => this.props.changeKey());
  } else {
    this.setState({canclose: true});
    alert("Please select any one condition.");
  } 
  };

  handleDelete = (e, data) => {
    e.preventDefault();
    if (data !== undefined && data !== null) {
     
      confirmAlert({
        title: "",
        message: "You are about to delete a condition. Do you want to proceed?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.deletePatientId({
                condition_id: data
              });
              this.props.searchConditions({ searchText: '' }).then(list => {
                this.setState({ acuteConditions: list.data.content });
                this.setState({ chronicConditions: list.data.content });
              });

            }
          },
          {
            label: "No",
            onClick: () => {
              
            }
          }
        ]
      });
     
    
      // .then(res => {
      //   resetForm()
      // })
    }
  };

  handlePatientDelete = data => {
    if (data !== undefined && data !== null) {
      this.props.deletePatientFavId({
        condition_id: data
      }).then(res =>   this.props.patientConditionsLocal({
        searchTxt: "",
        patientId: this.state.patientId
      }));
    }
  };

  handleDoctorDelete = data => {
    if (data !== undefined && data !== null) {
      this.props
        .deleteDoctorFavId({
          condition_id: data
        })
        .then(res => {
          this.props.drFavConditionsLocal({ drId: this.state.drId });
          NotificationManager.success("Doctor Favourite Record Deleted");
        });
    }
  };

  handleDoctorDelete2 = data => {
    if (data !== undefined && data !== null) {
      this.props.deleteDoctorFavId({
        condition_id: data
      }).then(res =>   {this.props.drFavConditionsLocal_chronic({ drId: this.state.drId });
      NotificationManager.success("Doctor Favourite Record Deleted");
    });
    }
  };

  editMaster = (e, editId, name) => {
    e.preventDefault();
    this.setState({showEditMode: editId});
    this.setState({showEditModeValue: name});


  }

  changeEditName = (e) => {
    this.setState({showEditModeValue: e.target.value});
  }

  editSave = (id, value) => {
    var AddParam = {
      id: id,
      AddConditionText: value
    };
  this.props.apiAddConditions(AddParam).then(res => {
    this.setState({showEditMode: ''});
    this.setState({showEditModeValue: ''});
    NotificationManager.success("Condition Added Successfully");
      this.props.searchConditions({ searchText: '' }).then(list => {
        this.setState({ acuteConditions: list.data.content });
        this.setState({ chronicConditions: list.data.content });
      });
  })
  
  
  }

  render() {
    return (
      <Popup
      open={this.props.openDiagonsis}
      close={this.props.closeDiagonsis}
        // trigger={

          
        //   <button
        //     type="button"
        //     className="btn btn-default"
        //     style={{
        //       marginTop: "20px",
        //       padding: "5px 10px",
        //       marginRight: "6px"
        //     }}
        //   >
        //     {this.props.popupName}
        //   </button>
        // }
        modal
        closeOnDocumentClick={false}
      >
        <div>
          <div className="modal-header">
            <div class="popup-title">
              <span>{this.props.popupName}</span>
              <Showhelp gotohelp="diagnosis_popup"/>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={e => this.props.closeDiagonsis()}>
                  ×
                </span>
              </button>
            </div>
            </div>
            <div className="modal-body visit-notes-wrapper" style={{overflowY:'scroll', maxHeight: '670px'}}> 
              <NotificationContainer />
              <div className="col-sm-6 mt-3">
                <div className="section-title">Acute:</div>
                <div className="row add-condition">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control form-control-sm add-condition-input" 
                      onChange={e => this.handleChangeText(e, "acute")}
                    />
                  </div>
                  <div className="col-sm-1 add-condition-button">
                    <a
                      title="Condition"
                      aria-expanded="false"
                      onClick={() =>
                        this.acuteAddCondition(this.state.acuteSearchText)
                      }
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                    </a>
                  </div>
                </div>
                <div className="condition-list">
                  <ul className="navpat nav-list nav-menu-list-style overflow">
                    {this.state.acuteConditions != undefined &&
                    this.state.acuteConditions.length > 0 ? (
                      this.state.acuteConditions.map(condition => (
                        <li>
                          <label
                            className="tree-toggle nav-header"
                            style={{ paddingLeft: 0 }}
                          >
                            <div className="newdesign5">
                              <input
                                 
                                type="checkbox"
                                onChange={e =>
                                  this.handleCheckbox(
                                    e,
                                    condition.name,
                                    condition.condition_id,
                                    "acute"
                                  )
                                }
                              />
                              {this.state.showEditMode != 'acute'+condition.condition_id && " "+condition.name}


                              {this.state.showEditMode == 'acute'+condition.condition_id && 
                              <span>
                                {" "}
                              <input 
                              style={{width: '85%', border: '0px', borderBottom:'2px solid #eee' }} 
                              value={this.state.showEditModeValue} 
                              onChange={e => this.changeEditName(e)} 
                              /> 
                              <span 
                              onClick={e=> this.editSave(condition.condition_id, this.state.showEditModeValue)}>
                                <i className="fa fa-save" aria-hidden="true" />
                              </span>
                              {" "}
                              <span onClick={e=> {this.setState({showEditMode: ''})}}>
                                <i className="fa fa-close" aria-hidden="true" />
                              </span>

                              </span>
                              }
                             
                      
                          
                             {this.state.showEditMode != 'acute'+condition.condition_id &&
                               <div
                                style={{
                                  width: "auto",
                                  height: 20,
                                  marginTop: 0,
                                  float: "right"
                                }}
                              >


                               {this.state.drId == condition.owner &&    
                                <span className="makescreen">
                                  <a
                                    href="#"
                                    title="Edit"
                                    style={{ padding: 0 }}
                                    className="collapsed"
                                    aria-expanded="false"
                                  >
                                    
                                    
                                    <i
                                      className="fas fa-pencil-alt"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: 12,
                                        marginRight: 5
                                      }}
                                      onClick={e =>
                                        {this.editMaster(e, 'acute'+condition.condition_id, condition.name )
                                       
                                        }
                                      }
                                    />
 
                                  </a>{" "}
                                </span>}
                                {this.state.drId == condition.owner &&   
                                <i
                                  className="fas fa-trash-alt"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 12,
                                    marginRight: 5
                                  }}
                                  onClick={e =>
                                    this.handleDelete(e, condition.condition_id)
                                  }
                                />
                                }


                              </div>}
                            </div> 
                          </label>
                        </li>
                      ))
                    ) : (
                      <li>
                        <label className="tree-toggle nav-header">
                          {" "}
                          No Conditions Found
                        </label>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="condition-type" style={{marginTop: "30px"}}>
                  <div className="condition-type-item">
                  <label for="acute-condition-type-mild" style={{ marginLeft: '0px'}}>
                    <input
                      type="radio"
                      value="Mild"
                      id="acute-condition-type-mild"
                      checked={this.state.acuteSelectedOption === "Mild"}
                      onChange={e => this.handleOptionChange(e, "acute")}
                    />
&nbsp;
                    Mild</label>
                  </div>
                  <div className="condition-type-item">
                  <label for="acute-condition-type-moderate">
                    <input
                      type="radio"
                      value="Moderate"
                      id="acute-condition-type-moderate"
                      checked={this.state.acuteSelectedOption === "Moderate"}
                      onChange={e => this.handleOptionChange(e, "acute")}
                    />&nbsp;
                    Moderate</label>
                  </div>
                  <div className="condition-type-item">
                  <label for="acute-condition-type-severe">
                    <input
                      type="radio"
                      value="Severe"
                      id="acute-condition-type-severe"
                      checked={this.state.acuteSelectedOption === "Severe"}
                      onChange={e => this.handleOptionChange(e, "acute")}
                    />&nbsp;
                    Severe</label>
                  </div>
                </div>
                <div className="add-to-my-favourites">
                  <input
                    type="checkbox"
                    name="acute-add-to-my-favourites"
                    id="acute-add-to-my-favourites"
                    onChange={e =>
                      this.setState({
                        add_acute_fav: !this.state.add_acute_fav
                      })
                    }
                  />
                  <label for="acute-add-to-my-favourites">
                    Add to my favourites
                  </label>
                  
                </div>
              </div>
              <div className="col-sm-6 mt-3">
                <div className="section-title">Chronic:</div>
                <div className="row add-condition">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control form-control-sm add-condition-input"
                      onChange={e => this.handleChangeText(e, "chronic")}
                    />
                  </div>
                  <div className="col-sm-1 add-condition-button">
                    <a
                      title="Condition"
                      aria-expanded="false"
                      onClick={() =>
                        this.chronicAddCondition(this.state.chronicSearchText)
                      }
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                    </a>
                  </div>
                </div>
                <div className="condition-list" >
                  <ul className="navpat nav-list nav-menu-list-style overflow">
                    {this.state.chronicConditions != undefined &&
                    this.state.chronicConditions.length > 0 ? (
                      this.state.chronicConditions.map(condition => (
                        <li>
                          <label
                            className="tree-toggle nav-header"
                            style={{ paddingLeft: 0 }}
                          >
                            <div className="newdesign5">
                              <input
                                 
                                type="checkbox"
                                onChange={e =>
                                  this.handleCheckbox(
                                    e,
                                    condition.name,
                                    condition.condition_id,
                                    "chronic"
                                  )
                                }
                              />
                               {this.state.showEditMode != 'acute'+condition.condition_id && " "+condition.name}
                               {this.state.showEditMode == 'acute'+condition.condition_id && 
                              <span>
                                {" "}
                              <input 
                              style={{width: '85%', border: '0px', borderBottom:'2px solid #eee' }} 
                              value={this.state.showEditModeValue} 
                              onChange={e => this.changeEditName(e)} 
                              /> 
                              <span 
                              onClick={e=> this.editSave(condition.condition_id, this.state.showEditModeValue)}>
                                <i className="fa fa-save" aria-hidden="true" />
                              </span>
                              {" "}
                              <span onClick={e=> {this.setState({showEditMode: ''})}}>
                                <i className="fa fa-close" aria-hidden="true" />
                              </span>

                              </span>
                              }
                               {this.state.showEditMode != 'acute'+condition.condition_id &&
                              <div
                                style={{
                                  width: "auto",
                                  height: 20,
                                  marginTop: 0,
                                  float: "right"
                                }}
                              >
                                {this.state.drId == condition.owner &&   
                                <span className="makescreen">
                                  <a
                                    href="#"
                                    title="Edit"
                                    style={{ padding: 0 }}
                                    className="collapsed"
                                    aria-expanded="false"
                                  >
                                     <i
                                      className="fas fa-pencil-alt"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: 12,
                                        marginRight: 5
                                      }}
                                      onClick={e =>
                                        {this.editMaster(e, 'acute'+condition.condition_id, condition.name )
                                       
                                        }
                                      }
                                    />
                                  </a>{" "}
                                </span>}
                                {this.state.drId == condition.owner &&   
                                <i
                                  className="fas fa-trash-alt"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 12,
                                    marginRight: 5
                                  }}
                                  onClick={e =>
                                    this.handleDelete(e, condition.condition_id)
                                  }
                                />}
                              </div>}
                            </div>
                          </label>
                        </li>
                      ))
                    ) : (
                      <li>
                        <label className="tree-toggle nav-header">
                          {" "}
                          No Conditions Found
                        </label>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="condition-type" style={{marginTop: "30px"}}>
                  <div className="condition-type-item">
                  <label for="chronic-condition-type-mild" style={{ marginLeft: '0px'}}>
                    <input
                      type="radio"
                      value="Mild"
                      id="chronic-condition-type-mild"
                      checked={this.state.chronicSelectedOption === "Mild"}
                      onChange={e => this.handleOptionChange(e, "chronic")}
                    />&nbsp;
                    Mild</label>
                  </div>
                  <div className="condition-type-item">
                  <label for="chronic-condition-type-moderate">
                    <input
                      type="radio"
                      value="Moderate"
                      id="chronic-condition-type-moderate"
                      checked={this.state.chronicSelectedOption === "Moderate"}
                      onChange={e => this.handleOptionChange(e, "chronic")}
                    />
                    &nbsp;
                      Moderate
                    </label>
                  </div>
                  <div className="condition-type-item">
                  <label for="chronic-condition-type-severe">
                    <input
                      type="radio"
                      value="Severe"
                      id="chronic-condition-type-severe"
                      checked={this.state.chronicSelectedOption === "Severe"}
                      onChange={e => this.handleOptionChange(e, "chronic")}
                    />&nbsp;
                   Severe</label>
                  </div>
                </div>
                <div className="add-to-my-favourites">
                  <input
                    type="checkbox"
                    name="chronic-add-to-my-favourites"
                    id="chronic-add-to-my-favourites"
                    onChange={e =>
                      this.setState({
                        add_chronic_fav: !this.state.add_chronic_fav
                      })
                    }
                  />
                  <label for="chronic-add-to-my-favourites">
                    Add to my favourites
                  </label>
                
                </div>
              </div>
              <div className="col-sm-12 gcomment">
                  <label for="gc">General Comments</label>
                  <textarea  rows="2" id="gc" value={this.state.general_comments} onChange={e=>this.handleGeneralComments(e)}> </textarea>
                  </div>

              <div className="col-sm-6">
                <div className="section-title">My Favourites - Acute:</div>
                <div className="row add-condition">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control form-control-sm add-condition-input"
                      onChange={e => this.handleChangeText(e, "drFavourites")}
                    />
                  </div>
                  {/*<div className="col-sm-1 add-condition-button">
                    <a title="Condition" aria-expanded="false">
                      <i className="fa fa-plus" aria-hidden="true" />
                    </a>
                    </div>*/}
                </div>
                <div className="condition-list">
                  <ul className="navpat nav-list nav-menu-list-style overflow">
                    {this.props.drFavData != undefined &&
                    this.props.drFavData.length > 0 ? (
                      this.props.drFavData
                        .filter(item => item.acute_chronic == "acute")
                        .map(condition => (
                          <li>
                            <label
                              className="tree-toggle nav-header"
                              style={{ paddingLeft: 0 }}
                            >
                              <div className="newdesign5">
                                <input
                              
                                  type="checkbox"
                                  onChange={e =>
                                    this.handleCheckbox(
                                      e,
                                      condition.name,
                                      condition.condition_id,
                                      "drFavourites"
                                    )
                                  }
                                />
                                &nbsp;{condition.name}
                                {/* ({condition.acute_chronic}) */}
                                
                              </div>
                            </label>
                            <div
                                  style={{
                                    width: "auto",
                                    height: 20,
                                    marginTop: 0,
                                    float: "right",
                                    marginLeft: '10px',
                                    float: 'right',
                                    marginBottom: '10px',
                                    position: 'relative',
                                    top: '-23px'
                                  }}
                                >
                                  <i
                                    className="fas fa-trash-alt"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: 12,
                                      marginRight: 5,
                                      cursor: Pointer,
                                    }}
                                    title="Delete"
                                    onClick={() =>
                                      this.handleDoctorDelete(
                                        condition.dr_conditions_id
                                      )
                                    }
                                  />
                                </div>
                          </li>
                        ))
                    ) : (
                      <li>
                        <label className="tree-toggle nav-header">
                          {" "}
                          No Conditions Found
                        </label>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="condition-type">
                  <div className="condition-type-item">
                  <label for="my-favt-condition-type-mild">
                    <input
                      type="radio"
                      value="Mild"
                      checked={this.state.drFavSelectedOption === "Mild"}
                      onChange={e => this.handleOptionChange(e, "drFavourites")}
                    />
                          &nbsp; Mild</label>
                  </div>
                  <div className="condition-type-item">
                  <label for="my-favt-condition-type-moderate">
                    <input
                      type="radio"
                      value="Moderate"
                      checked={this.state.drFavSelectedOption === "Moderate"}
                      onChange={e => this.handleOptionChange(e, "drFavourites")}
                    />
                   
                      Moderate
                    </label>
                  </div>
                  <div className="condition-type-item">
                  <label for="my-favt-condition-type-severe">
                    <input
                      type="radio"
                      value="Severe"
                      checked={this.state.drFavSelectedOption === "Severe"}
                      onChange={e => this.handleOptionChange(e, "drFavourites")}
                    />
                   Severe</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="section-title">My Favourites - Chronic:</div>
                <div className="row add-condition">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control form-control-sm add-condition-input"
                      onChange={e => this.handleChangeText(e, "drFavourites2")}
                    />
                  </div>
                  {/*<div className="col-sm-1 add-condition-button">
                    <a title="Condition" aria-expanded="false">
                      <i className="fa fa-plus" aria-hidden="true" />
                    </a>
                    </div>*/}
                </div>
                <div className="condition-list">
                  <ul className="navpat nav-list nav-menu-list-style overflow">
                    {this.props.drFavData2 != undefined &&
                    this.props.drFavData2.length > 0 ? (
                      this.props.drFavData2
                        .filter(item => item.acute_chronic == "chronic")
                        .map(condition => (
                          <li>
                            <label
                              className="tree-toggle nav-header"
                              style={{ paddingLeft: 0 }}
                            >
                              <div className="newdesign5">
                                <input
                              
                                  type="checkbox"
                                  onChange={e =>
                                    this.handleCheckbox(
                                      e,
                                      condition.name,
                                      condition.condition_id,
                                      "drFavourites2"
                                    )
                                  }
                                />
                                &nbsp;{condition.name}
                                {/* ({condition.acute_chronic}) */}
                              
                              </div>
                            </label>
                            <div
                                  style={{
                                    width: "auto",
                                    height: 20,
                                    marginTop: 0,
                                    float: "right",
                                    marginLeft: '10px',
                                    float: 'right',
                                    marginBottom: '10px',
                                    position: 'relative',
                                    top: '-23px'
                                  }}
                                >
                                  <i
                                    className="fas fa-trash-alt"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: 12,
                                      marginRight: 5,
                                      cursor: Pointer,
                                    }}
                                    title="Delete"
                                    onClick={() =>
                                      this.handleDoctorDelete2(
                                        condition.dr_conditions_id
                                      )
                                    }
                                  />
                                </div>
                          </li>
                        ))
                    ) : (
                      <li>
                        <label className="tree-toggle nav-header">
                          {" "}
                          No Conditions Found
                        </label>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="condition-type">
                  <div className="condition-type-item">
                  <label for="my-favt-condition-type-mild"> <input
                      type="radio"
                      value="Mild"
                      checked={this.state.drFavSelectedOption2 === "Mild"}
                      onChange={e =>
                        this.handleOptionChange(e, "drFavourites2")
                      }
                    />
                    Mild</label>
                  </div>
                  <div className="condition-type-item">
                  <label for="my-favt-condition-type-moderate"><input
                      type="radio"
                      value="Moderate"
                      checked={this.state.drFavSelectedOption2 === "Moderate"}
                      onChange={e =>
                        this.handleOptionChange(e, "drFavourites2")
                      }
                    />
                   
                      Moderate
                    </label>
                  </div>
                  <div className="condition-type-item">
                  <label for="my-favt-condition-type-severe">   <input
                      type="radio"
                      value="Severe"
                      checked={this.state.drFavSelectedOption2 === "Severe"}
                      onChange={e =>
                        this.handleOptionChange(e, "drFavourites2")
                      }
                    />
                   Severe</label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="section-title">Patient Favourites:</div>
                <div className="row add-condition">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control form-control-sm add-condition-input"
                      onChange={e =>
                        this.handleChangeText(e, "patientFavourites")
                      }
                    />
                  </div>
                </div>
                <div className="condition-list">
                  <ul className="navpat nav-list nav-menu-list-style overflow">
                    {this.props.patient_fav_localdata.length > 0 ? (
                      this.props.patient_fav_localdata.map(condition => (
                        <li>
                          <label
                            className="tree-toggle nav-header"
                            style={{ paddingLeft: 0 }}
                          >
                            <div className="newdesign5">
                              <input
                             
                                type="checkbox"
                                onChange={e =>
                                  this.handleCheckbox(
                                    e,
                                    condition.name,
                                    condition.condition_id,
                                    "patientsFavourites"
                                  )
                                }
                              />
                              &nbsp;{condition.name}
                              
                            </div>
                          </label>

                          <div
                                style={{
                                  width: "auto",
                                  height: 20,
                                  marginTop: 0,
                                  float: "right",
                                  marginLeft: '10px',
                                  float: 'right',
                                  marginBottom: '10px',
                                  position: 'relative',
                                  top: '-23px'
                                }}
                              >
                                <span className="makescreen"></span>
                                <i
                                  className="fas fa-trash-alt"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 12,
                                    marginRight: 5,
                                    cursor: Pointer,
                                  }}
                                  title="Delete"
                                  onClick={() =>
                                    this.handlePatientDelete(
                                      condition.patients_conditions_id
                                    )
                                  }
                                />
                              </div>
                        </li>
                      ))
                    ) : (
                      <li>
                        <label className="tree-toggle nav-header">
                          {" "}
                          No Conditions Found
                        </label>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="condition-type">
                  <div className="condition-type-item">
                  <label for="patient-favt-condition-type-mild">  <input
                      type="radio"
                      value="Mild"
                      checked={this.state.patientFavSelectedOption === "Mild"}
                      onChange={e =>
                        this.handleOptionChange(e, "patientFavourites")
                      }
                    />
                    Mild</label>
                  </div>
                  <div className="condition-type-item">
                  <label for="patient-favt-condition-type-moderate"> <input
                      type="radio"
                      value="Moderate"
                      checked={
                        this.state.patientFavSelectedOption === "Moderate"
                      }
                      onChange={e =>
                        this.handleOptionChange(e, "patientFavourites")
                      }
                    />
                    
                      Moderate
                    </label>
                  </div>
                  <div className="condition-type-item">
                  <label for="patient-favt-condition-type-severe"> <input
                      type="radio"
                      value="Severe"
                      checked={this.state.patientFavSelectedOption === "Severe"}
                      onChange={e =>
                        this.handleOptionChange(e, "patientFavourites")
                      }
                    />
                   
                      Severe
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                {this.props.popupName != "Diagnosis" ? (
                  <div className="col-sm-6">
                  <div className="col-sm-6 add-to-diagnosis-checkbox">
                    <input
                      type="checkbox"
                      name="add-to-diagnosis"
                      id="add-to-diagnosis"
                    />
                    <label for="add-to-diagnosis">Add to diagnosis</label>
                  </div>
                  <div className="col-sm-6 confidtext">
<input id = "gconfindential" type="checkbox" 
checked={this.state.confidential == '1' ? true : false}
onChange={e => this.handleConfidential(e)}
/>
<label for="gconfindential"> Confidential </label>
</div>
</div>

                ) : (
                  <div className="col-sm-6 add-to-todays-reason-checkbox">
                    <input
                      type="checkbox"
                      name="add-to-todays-reason"
                      id="add-to-todays-reason"
                    />
                    <label for="add-to-todays-reason">
                      Add to Reason for Today's visit
                    </label>
                  </div>
                )}


                <div className="col-sm-6">
                  <button
                    type="button"
                    className="btn btn-primary btn-xs"
                    disabled={this.state.saveDisable}
                    onClick={() => {
                      this.handleSave();
                      
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-xs"
                    data-dismiss="modal"
                    onClick={e => this.props.closeDiagonsis()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        
      </Popup>
    );
  }
}

//export default Diagonsis;
const mapDispatchToProps = dispatch => {
  return {
    patientConditionsLocal: data => dispatch(patientConditions2(data)),
    drFavConditionsLocal: data => dispatch(drConditions(data)),
    drFavConditionsLocal_chronic: data => dispatch(drConditions2(data)),
    //  ChangeDragData: data => dispatch(ChangeDragData(data)),
    //  deleteData: data => dispatch(deleteData(data)),
    searchConditions: searchParam =>
      dispatch(api_searchConditions(searchParam)),
    apiAddConditions: data => dispatch(api_addConditions(data)),
    // addPaitentData: data => dispatch(addPaitentData(data)),
    // editPaitentData: data => dispatch(editPaitentData(data)),
    deletePatientId: data => dispatch(deletePatientId(data)),
    deletePatientFavId: data => dispatch(deletePatientFavId(data)),
    deleteDoctorFavId: data => dispatch(deleteDoctorFavId(data)),
    getPatientDetails: patient_id => dispatch(api_getPatientDetails(patient_id))
     
  };
};

const mapStateToProps = (state,ownProps) => {
  let patient_fav_localdata = state.getDrAppointments.patient_fav_data
    ? state.getDrAppointments.patient_fav_data
    : [];
  let drFavData = state.getDrAppointments.dr_fav_data
    ? state.getDrAppointments.dr_fav_data
    : [];
  let drFavData2 = state.getDrAppointments.dr_fav_data2
    ? state.getDrAppointments.dr_fav_data2
    : [];
    let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";
      if(ownProps.patient!='')
      {
      /*let pastHistoryComments = 
      state.getDrAppointments.getPatientDetails != undefined
        ? state.getDrAppointments.getPatientDetails[0].pastHistoryComments
        : "";*/
        let redux_patientId  = ownProps.patient//state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     
        return {
          patient_fav_localdata,
          drFavData,
          drFavData2,
          redux_patientId,logged_info
        };
      }
      else
      {
        let pastHistoryComments = 
      state.getDrAppointments.getPatientDetails != undefined
        ? state.getDrAppointments.getPatientDetails[0].pastHistoryComments
        : "";
        let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     
        return {
          patient_fav_localdata,
          drFavData,
          drFavData2,
          redux_patientId,logged_info, pastHistoryComments
        };
      }
     
 
};

export default connect(mapStateToProps, mapDispatchToProps)(Diagonsis);
