import React, { Component } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Grid from "../../crudcomponent/combinedcrud";
import { dropDown, dropdown1 } from "../../../DS/services";
import Leftsidemenu from "../../leftmenu";
import AdminHeader from "../../adminheader";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import {api_get_settings} from '../../../DS/DS.Reminders';
import {update_setting} from '../../../DS/DS.AppointmentReminder';
import {api_update_general_settings,api_getgeneral_settings} from '../../../DS/DS.General';
class AddGeneral extends Component {
    constructor(props)
    {
        super(props)
        this.state={
          hl7Export:0,
          argus_format:false,
          obx:false,
          reactivate:false,
          prompt:false,
          system_id:false,
          travel_link:'',
          IHI:'',
          directory:0
    
        } 
    }
    componentWillMount=()=>
    {
      const settname={setting_name:'hl7_export'}
      this.props.apigetsettings(settname).then(res=>{
           this.setState({hl7Export:res.data.content[0].setting_value})
      })
  
      const settname1={setting_name:'argus_format'}
      this.props.apigetsettings(settname1).then(res=>{
       this.setState({argus_format:res.data.content[0].setting_value})
      })

      const settname2={setting_name:'include_obx'}
      this.props.apigetsettings(settname2).then(res=>{
            this.setState({obx:res.data.content[0].setting_value})
         
      })
      const settname3={setting_name:'reactivate_patient'}
      this.props.apigetsettings(settname3).then(res=>{
            this.setState({reactivate:res.data.content[0].setting_value})
      })
      const settname4={setting_name:'free_vaccination'}
      this.props.apigetsettings(settname4).then(res=>{
            this.setState({prompt:res.data.content[0].setting_value})
      })
      const settname5={setting_name:'system_id'}
      this.props.apigetsettings(settname5).then(res=>{
       this.setState({system_id:res.data.content[0].setting_value})
      })

      const settname6={setting_name:'tvl_medicine'}
      this.props.apigetgeneralsettings(settname6).then(res=>{
        this.setState({travel_link:res.data.content[0].general_value})
       })

       const settname7={setting_name:'validate_ihi'}
       this.props.apigetgeneralsettings(settname7).then(res=>{
        this.setState({IHI:res.data.content[0].general_value})
       })
       const settname8={setting_name:'onl_directory'}
       this.props.apigetgeneralsettings(settname8).then(res=>{
        this.setState({directory:res.data.content[0].general_value})
       })
    }
    render() {
        return (
            <div>
               <AdminHeader /><Leftsidemenu />
               <NotificationContainer />
                 <section className="content-header" style={{marginLeft: '240px'}}>
                     <h1>
                         General
                     </h1>
                     <ol className="breadcrumb">
                         <li><a href="#"><i className="fa fa-dashboard"></i> General</a></li>
                         <li className="active">Messages</li>
                     </ol>
                 </section>
                 {/* main section start */}
                <section class="content" style={{marginLeft: '240px'}}>
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="box">
		                        <div class="col-xs-12" style={{margin:'0px auto', float:"none"}}>
                                    <div class="box-body">
					                    <div class="col-sm-12 col-md-10 col-xl-10">		
							                <div id="pageContent">
                                                <div class="form-group row m-b-10">
                                                    <div class="col-sm-2">
							                            <label class="col-form-label text-left">Default HL7 export format</label>
                                                    </div>    
                                                    <div class="col-sm-2">
                                                        <select name="select" class="form-control" style={{padding:'.0rem .5rem', height:'30px', fontSize:'14px'}}
                                                        value={this.state.hl7Export} onChange={(e)=>this.setState({hl7Export:e.target.value})}>
                                                            <option value="opt1">REF</option>
                                                            <option value="opt1">ORU</option>
                                                        </select>
							                        </div>
							                        <div class="border-checkbox-section">
                                                        <div class="border-checkbox-group border-checkbox-group-default">
                                                        <label class="border-checkbox-label" for="checkbox0"> 
                                                            <input class="border-checkbox" type="checkbox" id="checkbox0" value={this.state.argus_format} 
                                                            checked={this.state.argus_format==1 ? "checked" :''}  onChange={(e)=>this.setState({argus_format:e.target.checked})}/>
                                                             &nbsp; Use Argus format</label>
                                                        </div>
							                        </div>
						                        </div>
                                                <div class="form-group row m-b-0">
                                                    <div class="border-checkbox-section">
                                                        <div class="border-checkbox-group border-checkbox-group-default">
                                                        <label class="border-checkbox-label" for="checkbox1"> 
                                                            <input class="border-checkbox" type="checkbox" id="checkbox1" value={this.state.obx}
                                                            checked={this.state.obx==1 ? "checked" :''}  onChange={(e)=>this.setState({obx:e.target.checked})}/>
                                                             &nbsp; Include a formatted text OBX in REF messages</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row row-distance3"> </div>
                                                <div class="form-group row m-b-10">					
                                                    <button id="session-timeout-dialog-keepalive clear-safety-net-numbers" type="button" class="btn btn-default" onclick="myFunction()">
                                                    Clear Safety Net numbers</button>
                                                </div>
					                        <div class="form-group row m-b-0">
							                    <div class="border-checkbox-section">
							                        <div class="border-checkbox-group border-checkbox-group-default">
                                                    <label class="border-checkbox-label" for="checkbox2">  
                                                        <input class="border-checkbox" type="checkbox" id="checkbox2" value={this.state.reactivate}
                                                        checked={this.state.reactivate==1 ? "checked" :''}  onChange={(e)=>this.setState({reactivate:e.target.checked})}/>
							                            &nbsp; Reactivate inactive patient if they have notes recorded</label>
							                        </div>
							                    </div>
						                    </div>
					                        <div class="form-group row m-b-0">
							                    <div class="border-checkbox-section">
							                        <div class="border-checkbox-group border-checkbox-group-default">
                                                    <label class="border-checkbox-label" for="checkbox3">
                                                        <input class="border-checkbox" type="checkbox" id="checkbox3" value={this.state.prompt}
                                                        checked={this.state.prompt==1 ? "checked" :''}  onChange={(e)=>this.setState({prompt:e.target.checked})}/>
							                             &nbsp; Prompt for free vaccination eligibility after recording influenza vaccinations</label>
							                        </div>
							                    </div>
						                    </div>
					                        <div class="form-group row m-b-0">
							                    <div class="border-checkbox-section">
							                        <div class="border-checkbox-group border-checkbox-group-default">
                                                    <label class="border-checkbox-label" for="checkbox4"> 
                                                        <input class="border-checkbox" type="checkbox" id="checkbox4" value={this.state.system_id}
                                                        checked={this.state.system_id==1 ? "checked" :''}  onChange={(e)=>this.setState({system_id:e.target.checked})}/>
							                             &nbsp;Use the system generated ID as the  'Recored Numbers'&nbsp;  &nbsp;</label>
							                            <button id="session-timeout-dialog-keepalive update-record-numbers" type="button" class="btn btn-default" onclick="myFunction()"> Update record numbers</button>
							                        </div>
							                    </div>
						                    </div>
					                        <div class="form-group row">
					                            <label class="col-form-label text-left">Travel medicine link</label>
					                            <div class="col-sm-4">
					                                <input type="text" class="form-control" style={{padding:'.2rem .75rem'}} value={this.state.travel_link} onChange={(e)=>this.setState({travel_link:e.target.value})}/>
					                            </div>
					                        </div>
				                            <div class="form-group row m-b-0">
							                    <div class="border-checkbox-section">
                                                    <div class="border-checkbox-group border-checkbox-group-default">
								                        <label class="col-sm-3"> &nbsp; Validate IHI after  &nbsp; &nbsp;</label>
								                        <span class="col-sm-2">
									                        <input type="text" class="form-control days-input" value={this.state.IHI} onChange={(e)=>this.setState({IHI:e.target.value})}/> 
								                        </span>
								                        <label  class="col-sm-4"> &nbsp; &nbsp;   days for My Health Record access </label> 
								                    </div>
							                    </div>			
					                        </div>
                                            <div class="form-group row m-b-10">					
						                        <button id="session-timeout-dialog-keepalive" type="button" class="btn btn-default"> Import Certificates </button>
						                        <label class="col-form-label text-left">&nbsp; *NOTE : A windows aAdminitrator is required to import Certificates.</label>
						                    </div>
                                            <div class="col-sm-12 col-md-10 col-xl-10">		
							                    <div id="pageContent"><div class="form-group row m-b-10">
							                        <label class="col-form-label text-left"> Online Directory for Contacts</label>
							                        <div class="col-sm-2">
								                        <select name="select" class="form-control" style={{padding:'.0rem .5rem', height:'30px', fontSize:'14px'}} value={this.state.directory}
                                                        onChange={(e)=>this.setState({directory:e.target.value})}>
                                                            <option value="opt1"></option>
                                                            <option value="opt1"> Healthlink </option>
                                                        </select>
                                                    </div>
                                                </div>	
				                            </div>
			                            </div> 
	                                </div>
			                    </div>
		                    </div>
		                    <div class="modal-footer">
				                <div class="row">
                                    <div class="col-sm-10"> </div>
                                    <div class="col-sm-2">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Save</button>
                                        <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">Cancel</button> 
                                    </div>
                                </div>
				            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
    )}
}


const mapDispatchToProps = dispatch => {
    return {
        updatesetting:data=>dispatch(update_setting(data)),
        apigetsettings:(data)=>dispatch(api_get_settings(data)),
        apigeneralsettings:(data)=>dispatch(api_update_general_settings(data)),
        apigetgeneralsettings:(data)=>dispatch(api_getgeneral_settings(data)),

            };
  };    
  const mapStateToProps = state => {
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
 
   return {
   redux_docId
   };
 };
 export default connect(mapStateToProps,mapDispatchToProps) (AddGeneral);