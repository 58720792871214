import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_visitnotes_list_options} from './../../../../DS/DS.VisitsNotes';
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class genitourinary extends Component {
  state = { 
    list_options: {}
  }
  componentDidMount() {
    this.props.visitnotes_list_options().then(res => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }


    render() {
        return (
            <Popup 
            style={{ minHeight: "500px" }}
            trigger={
            <a  title="Genito-urinary" className="collapsed" aria-expanded="false">
              <i className="fa fa-medkit" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>} 
              position="right center"  
               modal
            closeOnDocumentClick={false}
          
            >
             {close => (

                 <div className="ganito">

               <div className="modal-header" 
              //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
               >

                      <button type="button" className="c
                      lose" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span>Genito-Urinary <Showhelp gotohelp="genito_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Genito-urinary</h4> */}
                    </div>



         <div className="modal-body"   style={{overflowY: "scroll", height: "450px" }}>
            <h4 style={{}}>History:</h4>


    <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Dysuria</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Dysuria == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Dysuria")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Dysuria == "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Dysuria")}
                    /> No
                        </div>

                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Frequency</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Frequency === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Frequency")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Frequency === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Frequency")}
                    /> No

                        </div>
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Nocturia</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Noctoria === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Noctoria")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Noctoria === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Noctoria")}
                    /> No

                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Right loin pain</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.RightLoinPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "RightLoinPain")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.RightLoinPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "RightLoinPain")}
                    /> No

                        </div>

                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Left loin pain</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.LeftLoinPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "LeftLoinPain")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.LeftLoinPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "LeftLoinPain")}
                    /> No
                        </div>
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Suprapubic pain</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.SubrapubicPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "SubrapubicPain")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.SubrapubicPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "SubrapubicPain")}
                    /> No

                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Haematuria</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Haematuria === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Haematuria")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Haematuria === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Haematuria")}
                    /> No

                        </div>

                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Urgency</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Urgency === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Urgency")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Urgency === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Urgency")}
                    /> No
                        </div>
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Stress incontinence</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.StressIncontinence === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "StressIncontinence")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.StressIncontinence === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "StressIncontinence")}
                    /> No
                        </div>

                 </div>



                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Testicular pain</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.TesticularPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "TesticularPain")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.TesticularPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "TesticularPain")}
                    /> No

                        </div>

                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Testicular lump</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.TesticularLamp === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "TesticularLamp")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.TesticularLamp === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "TesticularLamp")}
                    /> No
                        </div>
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Impotence</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Impotence === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Impotence")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Impotence === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Impotence")}
                    /> No
                        </div>

                 </div>




                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Difficulty voiding</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.DifficultyVoiding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "DifficultyVoiding")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.DifficultyVoiding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "DifficultyVoiding")}
                    /> No
                        </div>

                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Termianl dribbling</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.TermianlDribbling === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "TermianlDribbling")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.TermianlDribbling === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "TermianlDribbling")}
                    /> No

                        </div>
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Incomplete emptying </label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.InCompleteEmptying === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "InCompleteEmptying")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.InCompleteEmptying === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "InCompleteEmptying")}
                    /> No

                        </div>

                 </div>



                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Penile discharge</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PenileDischarge === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "PenileDischarge")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PenileDischarge === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "PenileDischarge")}
                    /> No

                        </div>

                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Penile lesion</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PenileLesin === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "PenileLesin")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PenileLesin === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "PenileLesin")}
                    /> No

                        </div>
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Haematospermia</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Haematospermia === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Haematospermia")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Haematospermia === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary", "Haematospermia")}
                    /> No

                        </div>

                 </div>

                 &nbsp;
                 <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                 <h4 style={{}}>Examination:</h4>
                 

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Loin tenderness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.LointenTerness == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "LointenTerness")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.LointenTerness == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "LointenTerness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.LointenTerness == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "LointenTerness")}
                    /> Neither

                        </div>
                        


                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Urethral discharge</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.UrethralDischarge == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "UrethralDischarge")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.UrethralDischarge == "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "UrethralDischarge")}
                    /> No


                        </div>

                 </div>
                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Scrotal swelling</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.ScrotalSwelling == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "ScrotalSwelling")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.ScrotalSwelling == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "ScrotalSwelling")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.ScrotalSwelling == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "ScrotalSwelling")}
                    /> Neither


                        </div>


                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Scrotal redness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.ScrotalRedness == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "ScrotalRedness")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.ScrotalRedness == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "ScrotalRedness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.ScrotalRedness == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "ScrotalRedness")}
                    /> Neither
                           

                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Testicular swelling</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.TesticularSwelling == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "TesticularSwelling")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.TesticularSwelling == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "TesticularSwelling")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.TesticularSwelling == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "TesticularSwelling")}
                    /> Neither
                           
                           
                           

                        </div>


                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Testicular tenderness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.TesticularTenderness == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "TesticularTenderness")}
                    /> Right 
                    &nbsp; &nbsp;
                        <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.TesticularTenderness == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "TesticularTenderness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.TesticularTenderness == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "TesticularTenderness")}
                    /> Neither
                          

                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Undescended testis</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.UndescendedTesties == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "UndescendedTesties")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.UndescendedTesties == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "UndescendedTesties")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.UndescendedTesties == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "UndescendedTesties")}
                    /> Neither
                           
                          

                        </div>


                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Hydrocoele</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Hydrocoele == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Hydrocoele")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Hydrocoele == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Hydrocoele")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Hydrocoele == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Hydrocoele")}
                    /> Neither
                          
                          
                          

                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Varicocoele</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Varicocoele == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Varicocoele")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Varicocoele == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Varicocoele")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Varicocoele == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Varicocoele")}
                    /> Neither
                           
                           

                        </div>


                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Penile warts</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PenileWarts == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "PenileWarts")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PenileWarts == "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "PenileWarts")}
                    /> No

                        </div>

                 </div>
                 &nbsp;

                 <div className="col-sm-12">

                    <div className="col-sm-4" style={{float: 'left',marginTop: '5px'}}>
                    <h4 style={{}}>PR Exam:</h4>

                    </div>
                    <div className="col-sm-3" style={{float: 'left',marginTop: '5px'}}>
                    <h4 style={{}}>Urinanalysis:</h4>

                    </div>

                  </div>


                  <div className="col-sm-12">

                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>PR blood</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PrBlood == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "PrBlood")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PrBlood == "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "PrBlood")}
                    /> No

                        </div>

                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Blood:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}

                            value={this.props.vals_examination.Blood}
                            onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Blood")}
                            >
                            {this.state.list_options != undefined &&
					  this.state.list_options.urinanalysis_blood != null &&
					  this.state.list_options.urinanalysis_blood.map(
						(item, index) => (

							<option value={item.value} > {item.label} </option>
						
))}
                                
                                
                            </select>
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Protein:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Protein}
                            onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Protein")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.urinanalysis_blood != null &&
                          this.state.list_options.urinanalysis_blood.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>


                      </div>

                      <div className="col-sm-12">

                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>PR Mass</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PrMass == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "PrMass")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PrMass == "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "PrMass")}
                    /> No
                        </div>

                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Glucose:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Glucose}
                            onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Glucose")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.urinanalysis_blood != null &&
                          this.state.list_options.urinanalysis_blood.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Bilirubin:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Bilirubin}
                            onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Bilirubin")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.urinanalysis_blood != null &&
                          this.state.list_options.urinanalysis_blood.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>


                      </div>

                      <div className="col-sm-12">

                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>PR tenderness</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PrTenderness === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "PrTenderness")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PrTenderness === "No"}
                      onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "PrTenderness")}
                    /> No

                        </div>

                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Leucocytes:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Leucocytes}
                            onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Leucocytes")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.urinanalysis_blood != null &&
                          this.state.list_options.urinanalysis_blood.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Nitrites:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Nitrites}
                            onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Nitrites")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Nitrites_option != null &&
                          this.state.list_options.Nitrites_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>


                      </div>


                      <div className="col-sm-12">

                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Prostatomegaly:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Prostatomegaly}
                            onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Prostatomegaly")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Prostatomegaly != null &&
                          this.state.list_options.Prostatomegaly.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>pH:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input type="text" style={{width: '120px'}}
                        value={this.props.vals_examination.pH}
                        onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "pH")}
                        />
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Ketones:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Ketones}
                            onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Ketones")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Ketones_option != null &&
                          this.state.list_options.Ketones_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>


                      </div>


                      <div className="col-sm-12">

                  <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>


                        </div>

                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Sp.gravity:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input type="text" style={{width: '120px'}} 
                        value={this.props.vals_examination.Spgravity}
                        onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Spgravity")}
                        
                        />
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Urobilinogen:</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Urobilinogen}
                            onChange={e => this.props.handleOptionChange(e, "genitourinary_examination", "Urobilinogen")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Urobilinogen != null &&
                          this.state.list_options.Urobilinogen.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>


                      </div>











             </div>


             <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                        onClick={e => 
                          {this.props.handleSaveOptions(e, "genitourinary");
                          close();}
                          }
                      >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>

           </div>








)}
</Popup>
         );
    }
}

 
const mapDispatchToProps = dispatch => {
  return {
    visitnotes_list_options: data => dispatch(api_visitnotes_list_options(data)),
    
  };
};

export default connect(null, mapDispatchToProps)(genitourinary);