import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import {api_cage_question,api_cage_details_master} from '../../../DS/DS.FamilyHistory';
import { NotificationManager } from 'react-notifications';
import Showhelp from './../../showHelp';

class CageQuestion extends Component {
    constructor(props){
        super(props);
        this.state = {
            open:this.props.showmodal,
            cut_down:'',
            annoyed:'',
            guilt:'',
            eye_opener:''
        }
    }
    componentWillMount=()=>
    {
        const patient={
            patient_id:this.props.redux_patientId
        }
        this.props.apigetcage(patient).then(
            req=>
            {
                this.setState({
                    cut_down:req.data.content[0].cut_down,
                    annoyed:req.data.content[0].annoyed,
                    guilt:req.data.content[0].guilt,
                    eye_opener:req.data.content[0].eye_opener
                })
            }
        )
    }

    cutDownHandler=(e)=>
    {
        this.setState({cut_down:e.target.value})
    }
    
    annoyedHandler=(e)=>
    {
        this.setState({annoyed:e.target.value})
    }
    
    guiltHandler=(e)=>
    {
        this.setState({guilt:e.target.value})
    }
    
    eyeOpenerHandler=(e)=>
    {
        this.setState({eye_opener:e.target.value})
    }
    SaveHandler=()=>
    {
        const cageQuestion=
        {
            cut_down:this.state.cut_down,
            annoyed:this.state.annoyed,
            guilt:this.state.guilt,
            eye_opener:this.state.eye_opener,
            patient_id:this.props.redux_patientId,
            doc_id:this.props.redux_docId
        }
        this.props.apicagequestion({cageQuestion}).then(
            req=>
            {
                this.props.changeKey()
                NotificationManager.success('Record saved successfully')
            }
        )
    }
    render() {
        return ( 
        <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
         {close => (
             <div>
                <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding:'5px 15px'}}>
                    <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px',
                     fontSize:'16px', marginBottom:'0px',color:'black'}}> CAGE Questions <Showhelp gotohelp="cage_question_help"/></h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}
                    style={{marginTop:'10px',color:'black'}}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="container" style={{width:'auto'}}>
                    <div className="row row-distance">
                        <div className="col-sm-3">
                            <label className="col-form-label text-left"> <b> Cut down </b> </label>
                        </div>		
                        <div className="col-sm-9"></div>	  	
			        </div>
			        <div className="row">
                        <div className="col-sm-5">
                            <label className="col-form-label text-left"> Have you ever felt you ought to cut down on your drinking? </label>
			            </div>	
			            <div className="col-sm-3"></div>
			                <div className="col-sm-2">
                                <label onClick={(e)=>this.cutDownHandler(e)}>
                                    <input type="radio" value="1" name="cutdown" 
                                    checked={this.state.cut_down=='1' ? 'checked' : ''} style={{marginTop: '5px'}}/> Yes
                                </label>
			                </div> 
                            <div className="col-sm-2">
                                <label onClick={(e)=>this.cutDownHandler(e)}>
				                    <input type="radio" value="2" name="cutdown" checked={this.state.cut_down=='2' ? 'checked' : ''}  style={{marginTop: '5px'}}/> No
                                </label> 
			                </div> 
			            </div>
			            <div className="row row-distance">
                            <div className="col-sm-3">
                                <label className="col-form-label text-left"> <b> Annoyed </b> </label>
                            </div>		
                            <div className="col-sm-9"></div>	  	
			            </div>
			            <div className="row">
                            <div className="col-sm-5">
                                <label className="col-form-label text-left"> Have people annoyed you by criticizing your drinking? </label>
			                </div>	
			                <div className="col-sm-3"></div>
			                <div className="col-sm-2">
                                <label onClick={(e)=>this.annoyedHandler(e)}>
				                   <input type="radio" value="1" name="annoyed" checked={this.state.annoyed=='1' ? 'checked' : ''}  style={{marginTop:'5px'}}/> Yes
                                </label>
                            </div> 
                            <div className="col-sm-2">
                                <label onClick={(e)=>this.annoyedHandler(e)}>
                                    <input type="radio" value="2" name="annoyed" checked={this.state.annoyed=='2' ? 'checked' : ''} style={{marginTop: '5px'}}/> No 
                                </label>    
			                </div> 
			            </div>
			            <div className="row row-distance">
                            <div className="col-sm-3">
                                <label className="col-form-label text-left"> <b> Guilt </b> </label>
                            </div>		
                            <div className="col-sm-9"></div>	  	
			            </div>
			            <div className="row">
                            <div className="col-sm-5">
                                <label className="col-form-label text-left"> Have you ever felt bad or guilty about your drinking? </label>
			                </div>	
			                <div className="col-sm-3"></div>
			                <div className="col-sm-2">
                                <label onClick={(e)=>this.guiltHandler(e)}> 
				                    <input type="radio" value="1" name="guilt" checked={this.state.guilt=='1' ? 'checked' : ''} style={{marginTop: '5px'}}/> Yes
                                </label>
                            </div> 
                            <div className="col-sm-2">
                                <label onClick={(e)=>this.guiltHandler(e)}>
				                    <input type="radio" value="2" name="guilt" checked={this.state.guilt=='2' ? 'checked' : ''} style={{marginTop: '5px'}}/> No 
                                </label>    
                            </div> 
			            </div>
			            <div className="row row-distance">
                            <div className="col-sm-3">
                                <label className="col-form-label text-left"> <b> Eye Opener </b> </label>
                            </div>		
                            <div className="col-sm-9"></div>	  	
			            </div>
			            <div className="row">
                            <div className="col-sm-5">
                                <label className="col-form-label text-left"> Have you ever had an eye-opener to steady nerves in the AM? </label>
			                </div>	
			                <div className="col-sm-3"></div>
			                <div className="col-sm-2">
                                <label onClick={(e)=>this.eyeOpenerHandler(e)}>
				                    <input type="radio" value="1" name="eyeopener" checked={this.state.eye_opener=='1' ? 'checked' : ''} style={{marginTop: '5px'}}/> Yes       
                                </label>
                            </div> 
                        <div className="col-sm-2">
                            <label onClick={(e)=>this.eyeOpenerHandler(e)}>
				                <input type="radio" value="2" name="eyeopener"  checked={this.state.eye_opener=='2' ? 'checked' : ''} style={{marginTop: '5px'}}/> No 
                            </label>
                        </div> 
                    </div>
                </div>
                </div>
		        <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>this.SaveHandler()}>Save</button>
                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>this.props.changeKey()}>Cancel</button>
                </div>
            </div>
         )}
        </Popup>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
             apicagequestion: (data) => dispatch(api_cage_question(data)),
             apigetcage :(data)=>dispatch(api_cage_details_master(data))    
            };
  };
  const mapStateToProps = state => {
   console.log(">>> doc data",state.getDrAppointments.logged_info)
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };
 export default connect(
    mapStateToProps,
  mapDispatchToProps
  ) 
 (CageQuestion);