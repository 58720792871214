import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddObstetricHistory from './addObstetricHistory';
import axios from 'axios';
import Popup from "reactjs-popup";
import './obstetric.css';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import PregnancyOutcome from './pregnancyOutcome';
import {api_get_pregnancy_data,api_get_pregnancy,api_delete_pregnancy} from '../../../DS/DS.ObstetricHistory'
import Antenatal from './antenatal';
import AntenatalResult from './antenantalResul';
import Showhelp from './../../showHelp';

class ObstetricHistory extends Component {

    constructor (props) {
        super(props);
        this.deleteRecord=this.deleteRecord.bind(this);
      this.state={
        pregnancyHistory: {
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: true,
              isSelection:true,
              deleteFunction:this.deleteRecord,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'',
              cellClicked:this.addInformation,
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "patients_women_obstetric_pregnancies",
                cols: [
                  "patients_women_obstetric_pregnancies_id",
                  "DATE_FORMAT(createdAt,'%d-%m-%Y') as date_added",
                  "number",
                  "DATE_FORMAT(ActualLMP,'%d-%m-%Y') as ActualLMP",
                  "scanweeks",
                  "DATE_FORMAT(EDCByDate,'%d-%m-%Y') as EDCByDate",
                  "ended",
                  "outcome",
                  "delivery",
                  "GestationLength"
                ],
               where: [
                  {
                    type: "and",
                    key: "patients_id",
                    operator: "=",
                    value: this.props.redux_patientId
               },
               {
                type: "and",
                key: "patients_women_obstetric_pregnancies.isDeleted",
                operator: "=",
                value: 0
              }
             ],
              
                primary_key:"patients_women_obstetric_pregnancies_id",
              },
              viewtabletitle: "",
              columns: [
                { name: "number", title: "No" },
                { name: "ActualLMP"  , title: "LMP" },
                { name: "ended" , title: "Ended" },
                { name: "GestationLength" , title: "Weeks" },
                { name: "outcome" , title: "Outcome" },
                { name: "delivery" , title: "Delivery" },
              ],
              rows: [],
              sortinit: [{ columnName: "date_added", direction: "desc" }],
              pagesize: 10
            }
    
          },
          antenatalHistory: {
            jsonGridVersion: "v2.0",
            grid: true,
            viewgrid: {
              isVisible:true,
              isPagination: true,
              isFiltering: true,
              isSelection:true,
              deleteFunction:this.deleteRecord,
              cellClicked:this.updateInfo,
              isDateGroup:false,
              isYearSeparator:false,
              dateCol:'',
              cellClicked:'',
              baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
              query: {
                tb: "patients_women_obstetric_antenatal",
                cols: [
                  "patients_women_obstetric_antenatal_id",
                  "DATE_FORMAT(date,'%d-%m-%Y') as date_added",
                  "weight",
                  "urine_other",
                  "ankle_oedema",
                  "clinical_size",
                  "fundus_height",
                  "foetal_heart_rate",
                  "comment",
                  "size_by_dates",
                  "foetal_movements",
                  "position",
                  "engagement"
                
                ],
               where: [
                  {
                    type: "and",
                    key: "patient_id",
                    operator: "=",
                    value: this.props.redux_patientId
               },
               {
                type: "and",
                key: "patients_women_obstetric_antenatal.isDeleted",
                operator: "=",
                value: 0
              }
             ],
             customCols: [  
             "CONCAT(systolic,'/',diastolic) AS BP"
              
            ],
              
                primary_key:"patients_women_obstetric_pregnancies_id",
              },
              viewtabletitle: "",
              columns: [
                { name: "date_added", title: "Date" },
                { name: "weight"  , title: "Weight" },
                { name: "BP" , title: "BP" },
                { name: "urine_other" , title: "Urine" },
                { name: "ankle_oedema" , title: "Oedema" },
                { name: "size_by_dates" , title: "Calc.size" },
                { name: "clinical_size" , title: "Clin.size" },
                { name: "fundus_height" , title: "Fundus" },
                { name: "foetal_heart_rate" , title: "FH" },
                { name: "comment" , title: "Notes" },
                { name: "foetal_movements" , title: "Movements" },
                { name: "presentation" , title: "Presentation" },
                { name: "position" , title: "Position" },
                { name: "engagement" , title: "Engagement" },
                
              ],
              rows: [],
              sortinit: [{ columnName: "date_added", direction: "desc" }],
              pagesize: 10
            }
    
          },
          key:0,
          addObsHistory:false,
          pregOutcome:false,
          editFor:'',
          showante:false,
          addAnte:false,
          pregnancy_for:'',
          selectedRecLength:0,
          selectedRec:'',
          antresult:false,
          pregDetails:'',
          addPast:false


      }
    }

    deleteRecord=(selectedId,action,checklength)=>
    {
      if(action=='delete')
      {
          if (this.state.selectedRecLength> 0) {
                const clinicalData={clinical_id:this.state.selectedRec}
                confirmAlert({
                  title: '',
                  message: 'You are about to delete the pregnancy record. Do you want to proceed?',
                  buttons: [
                  {
                      label: 'Yes',
                      onClick: () => {
                      this.props.apiDeletePregnancy(clinicalData).then(
                      res => this.changeKey() ,
                      NotificationManager.success(
                      'Pregnancy record(s) deleted successfully',
                      //this.changeKey()
                      )
                    );
                  },
              },
              {
                label: 'No',
                onClick: () => { this.changeKey()},
              },
            ],
          });
        } 
        else 
        {
          NotificationManager.error('Please select a record(s) to delete');
        }
      }
      if(action=='selection')
      {
//        console.log("****",selectionindex)
        this.state.selectedRec=selectedId;
        this.state.selectedRecLength=checklength;
      }
    }
    changeKey=()=>
    {
      this.setState({addObsHistory:false,key:Math.random(),pregOutcome:false,addAnte:false,antresult:false,addPast:false})
      this.state.selectedRec='';
       this.state.selectedRecLength=0;
    }
    addInformation=(id,action)=>
    {
        this.setState({pregOutcome:true,editFor:id})
    }
    componentWillMount=()=>
    {
      this.props.getPregnancyData({id:this.props.redux_patientId}).then(res=>{
        if(res.data.content!='')
        {
        if(res.data.content[0].ended==null && res.data.content[0].outcome==null)
        {
          this.setState({showante:true,pregnancy_for:res.data.content[0].patients_women_obstetric_pregnancies_id})
        }
        else
        {
          this.setState({showante:false})
        }
      }
      })
    
    }
    render(){
    return(
        <div>
            <div className="panel panel-default">
                <div className="panel-heading panspace">
                    <div className="col-lg-12 col-xl-12">
                        <h4 class="panel-title"><a href="#" className="collapsed" aria-expanded="false">Pregnancies</a>  <Showhelp gotohelp="pregnancies_help"/></h4>
                            <div style={{width: 'auto', height: 30, position: 'absolute', right: 15, top:'0'}}>
                                    <span className="makescreen">
                                    <a href="#" title="Make This Default Screen" className="collapsed" aria-expanded="false"><i className="fa fa-star panelicon" aria-hidden="true" /></a></span>
                                    <a href="#" title="Email" className="collapsed" aria-expanded="false"><i className="fas fa-envelope panelicon" aria-hidden="true"  /></a>
                                    <a href="#" title="Print" className="collapsed" aria-expanded="false"><i className="fas fa-print panelicon" aria-hidden="true" /></a>
                                    <a title="Export PDF" onclick="recentVisitmini();" className="collapsed" aria-expanded="false"><i className="fa fa-file-pdf-o panelicon" aria-hidden="true" /></a>
                            </div>
                      </div>
                  </div>
              </div>
              <div className="col-lg-12 classic-tabs" style={{float: 'left', width: '100%', marginTop: 5}}>
                <div className="row">
                    <div className="col-xs-12" style={{padding: 10}}>
                        <div className="tab-content px-3 px-sm-0" id="nav-tabContent">
                            <div className="tab-pane fade active in" id="nav-current" role="tabpanel" aria-labelledby="nav-current-tab" style={{marginBottom: 10, marginTop: 10}}>
                                <div className="col-sm-4">
                                    {this.state.showante ? <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={e => { this.setState({addAnte:true})}}>Add</button>: 
                                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={e => { this.setState({addObsHistory:true})}}>Add</button>}
                                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.deleteRecord('','delete','')}>Delete</button>
                                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={e => this.setState({antresult:true})} >Result</button>
                                    <button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs" onClick={e => this.setState({addPast:true})} >Past Pregnancy</button>
                                </div>
                                <div className="col-sm-12" style={{float: 'left'}}>
                                    
                                    
                                    <div className="col-sm-8 no-left-padding" style={{float: 'left'}}>
                                        <div className="record-note-wrapper" style={{float: 'left', width: '100%', border: 'opx'}}>
                                            <h5 className="sheadtitle">Pregnancies</h5>
                                        <div>
                                        <div className="box">

                                          <CommonGrid
                                          crud={this.state.pregnancyHistory}
                                          key={this.state.key}
                                          />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4" style={{paddingLeft: 0, paddingRight: 0, marginTop: 15}}>
                                    <h5 className="sheadtitle">Pregnancy details</h5>
                                    <div style={{width: '100%', height: 367, overflowY: 'auto', marginTop: 0, border: '1px solid rgb(221, 221, 221)', padding: 5}}>
                                        {this.state.pregDetails}
                                    </div>
                                </div>
                                <div className="col-sm-12" style={{paddingRight: 0, paddingLeft: 0, marginTop: 20}}>
                                  <div className="record-note-wrapper" style={{float: 'left', width: '100%'}}>
                                    <h5 className="sheadtitle">Antenatal Visits</h5>
                                  <div>
                                  <div className="box">
                                      <CommonGrid
                                          crud={this.state.antenatalHistory}
                                          key={this.state.key}
                                          />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.addObsHistory ?
                  <AddObstetricHistory showmodal={this.state.addObsHistory} changeKey={this.changeKey} valfor='current'/>:''}
                  {this.state.addPast ?
                  <PregnancyOutcome showmodal={this.state.addPast} changeKey={this.changeKey} valfor='past' id=''/>:''}
                 
                  {this.state.pregOutcome ?
                  <PregnancyOutcome showmodal={this.state.pregOutcome} changeKey={this.changeKey} id={this.state.editFor}/>:''}
                  {this.state.addAnte ? 
                  <Antenatal showmodal={this.state.addAnte} changeKey={this.changeKey} id={this.state.pregnancy_for}/> : ''}
                  {this.state.antresult ?
                   <AntenatalResult showmodal={this.state.antresult} changeKey={this.changeKey} id={this.state.pregnancy_for}/>:''}
                </div>
            </div>
    
)}
}
const mapDispatchToProps = dispatch => {
  return {
    getPregnancyData: (data) => dispatch(api_get_pregnancy(data)),
    apiDeletePregnancy:(data) => dispatch(api_delete_pregnancy(data))
     };
};
const mapStateToProps = state => {
   let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
   let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';

 return {
   redux_patientId,redux_docId
 };
};

export default connect(
mapStateToProps,mapDispatchToProps
) (ObstetricHistory);

