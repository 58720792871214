import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  api_observation_master,
  api_patient_observations,
  api_add_patient_observations
} from "./../../../DS/DS.Observations";
import LineGraph from "./chart2";
import "./observation.css";
import Highchart from "./highChart";

import MyChart from "./chart";
import Showhelp from './../../showHelp';

class Observations extends Component {
  state = {
    drId: 1,
    patientId: this.props.redux_patientId,
    observationAddData: [],
    isTable: true,
    selectedChart: [],
    setDateRange: "showall",
    selectedChartId: 1,
    showEditItem: ""
  };

  constructor(props) {
    super(props);
    this.addObservationData = this.addObservationData.bind(this);
    this.tableGraph = this.tableGraph.bind(this);
    this.loadChart = this.loadChart.bind(this);
  }
  componentDidMount() {
    this.props.patient_observations({
      type: this.state.setDateRange,
      patient_id: this.state.patientId
    });
    this.props.observation_master();
  }
  componentWillReceiveProps(nextProps) {
    //    console.log('---------recieve props changed',nextProps);

    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });

      this.props.patient_observations({
        type: this.state.setDateRange,
        patient_id: nextProps.redux_patientId
      });
      console.log("inside receive props", this.state.patientId);
    }
  }
  toggleEditMode = (value, id) => {
    //alert(id);
    this.setState({ showEditItem: id });
    this.setState({ showEditItemValue: value });
  };
  editObservation = (e, item) => {
    //this.props.editObservation(item.observation_id, e.target.value);
    console.log("editObservation", item.observation_id, e.target.value);
    this.setState({ showEditItem: "" });
  };

  setDateRange(e, type) {
    e.preventDefault();
    this.setState({ setDateRange: type });
    this.props.patient_observations({
      type: type,
      patient_id: this.state.patientId
    });
    this.loadChartDirecly(this.state.selectedChartId);
  }
  tableGraph(show) {
    if (show === "graph") {
      this.setState({ isTable: false });
      this.loadChartDirecly(this.state.selectedChartId);
    } else {
      this.setState({ isTable: true });
    }
  }

  loadChartDirecly(id) {
    let selectedChart = [];
    //this.setState({selectedChart: []});
    if (this.props.patient_observations_data != undefined) {
      for (let i = 0; i < this.props.patient_observations_data.length; i++) {
        let item = this.props.patient_observations_data[i];
        console.log(item.observation_id, id);
        if (item.observation_id == id) {
          let arr = [item.date, item.value];
          selectedChart.push(arr);
          console.log(item, id);
        }
      }
    }
    this.setState({ selectedChart: selectedChart });
    console.log(this.state);
  }

  loadChart(e, id) {
    e.preventDefault();

    let selectedChart = [];
    //this.setState({selectedChart: []});
    if (this.props.patient_observations_data != undefined) {
      for (let i = 0; i < this.props.patient_observations_data.length; i++) {
        let item = this.props.patient_observations_data[i];

        if (item.observation_id === id) {
          let arr = [item.date, item.value];
          selectedChart.push(arr);
        }
      }
    }
    this.setState({ selectedChart: selectedChart });
    this.setState({ selectedChartId: id });
    console.log(this.state);
  }
 
  isLetter = (str) => {
    return str.length === 1 && str.match(/[a-z]/i);
  }
  updateObservationAddData(e, day, observationId) {
   let isValid = false;
   let text = e.target.value;
    var keyCode = (e.which) ? e.which : e.keyCode;
    if ((keyCode >= 48 && keyCode <= 57) || (keyCode == 8))
    isValid = true;
    else if (keyCode == 46) {
        var curVal = document.activeElement.value;
        if (curVal != null && curVal.trim().indexOf('.') == -1)
        isValid = true;
        else
        isValid = false;
    }
    else
    isValid = false;


    if(this.isLetter(text)){
      alert("Please provid valid input!");
      e.preventDefault();
      isValid = false;
    } else {
       isValid = true;
    }




    if(isValid){
      let items = this.state.observationAddData;
      let inputValue = e.target.value;
      this.setState({ showEditItemValue: e.target.value });
  
      let obj = {
        observationId: observationId,
        value: inputValue,
        patientId: this.state.patientId,
        date: day
      };
      let found = 0;
      for (let i = 0; i < items.length; i++) {
        console.log(items[i]);
        console.log(items[i].observationId);
        if (items[i].observationId == observationId) {
          items[i].value = inputValue;
          found = 1;
          break;
        } else {
          found = 0;
        }
      }
      if (found === 0) {
        items.push(obj);
      }
  
      this.setState({
        observationAddData: items
      });
      console.log("on changing values", this.state);
    } else {
      
    }
 

   
  }

  addObservationData() {
    let addarr = {
      observation: this.state.observationAddData,
      patientId: this.state.patientId
    };
    console.log("***********************",addarr);
    this.props.add_patient_observations(addarr);
    this.setState({ showEditItem: "" });
    this.setState({ showEditItemValue: "" });
    this.setState({ observationAddData: [] });
    this.props.patient_observations({
      type: this.state.setDateRange,
      patient_id: this.state.patientId
    });
  }
  renderObservationColumnData(date, observationId, a3, a4) {
    let cellData = "";
    let EditingObservation = "";

    let found = 0;

    for (let i = 0; i < this.props.patient_observations_data.length; i++) {
      let item = this.props.patient_observations_data[i];

      if (item.date === date && item.observation_id === observationId) {
        cellData = item.value;
        EditingObservation = item;
        found = 1;
      //  console.log(item.date, date, item.name);
        break;
      } else {
        found = 0;
      }
    }
    if (found === 0) {
      cellData = <span>&nbsp;</span>;
    }

    return (
      <td
        // onClick={e =>
        //   this.state.showEditItem != date + "_" + observationId &&
        //   this.toggleEditMode(
        //     EditingObservation.value,
        //     date + "_" + observationId
        //   )
        // }
      >
        {this.state.showEditItem == date + "_" + observationId && (
          <input
            value={this.state.showEditItemValue}
            onBlur={this.addObservationData}
            onChange={e =>
              this.updateObservationAddData(e, date, observationId)
            }
            onKeyPress={event => {
              if (event.key === "Enter") {
                this.addObservationData();
              }
            }}
          />
        )}
        {this.state.showEditItem != date + "_" + observationId && cellData}
      </td>
    );
  }

  render() {
    return (
      <div
        id="notesdiv3"
        className="col-md-12"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <div className="panel panel-default">
          <div
            className="panel-heading panspace">
            <div className="col-lg-12 col-xl-12">
              <h4
                className="panel-title link">
                <a
                  onClick={() => this.tableGraph("table")}
                  id="obsactiv" onclick="observationsOpennew();"
                  title="Click to see graph"
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={this.state.isTable ? "boldd" : ""}>
                    Observations   <Showhelp gotohelp="observations_help"/>
                  </span>
                </a>
                <a
                  onClick={() => this.tableGraph("graph")}
                  id="graphactiv"
                  style={{ float: "left", fontSize: 16, paddingLeft: 20 }}
                  onclick="observationsClose();"
                  title="Click to see graph"
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={!this.state.isTable ? "boldd" : ""}>
                    Graphs
                  </span>
                </a>
              </h4>
              <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}} >
                <span className="makescreen">
                  <a
                    href="#"
                    title="Make This Default Screen"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-star panelicon" />
                  </a>
                </span>
                <a
                  href="#"
                  title="Email"
                  onclick="recentObs3();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-envelope panelicon"
                    aria-hidden="true" />
                </a>
                <a
                  href="#"
                  title="Print"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-print panelicon"
                    aria-hidden="true"  />
                </a>
                {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
                <a
                  title="Export PDF"
                  onclick="recentVisitmini();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-file-pdf-o panelicon"
                    aria-hidden="true"  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group row" style={{ marginTop: 10 }}>
            <div className="col-sm-7">
              <div
                className="col-sm-3 pr-1"
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  textAlign: "right",
                  marginLeft: "0px"
                }}
              >
                <label
                  className="col-form-label text-left"
                  style={{ fontSize: "12px", marginTop: "5px" }}
                >
                  View observations from:
                </label>
              </div>
              <div
                className="col-sm-6"
                style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0 }}
              >
                <button
                  onClick={e => this.setDateRange(e, "showall")}
                  id="session-timeout-dialog-keepalive"
                  style={{
                    marginRight: "5px" }}
                  type="button"
                  className={
                    this.state.setDateRange === "showall"
                      ? " selectedBg btn-primary btn-xs"
                      : "  btn-primary btn-xs"
                  }
                >
                  Show All
                </button>
              
              <button
                onClick={e => this.setDateRange(e, "oneyear")}
                id="session-timeout-dialog-keepalive"
                style={{
                  marginRight: "5px" }}
                type="button"
                className={
                  this.state.setDateRange === "oneyear"
                    ? " selectedBg btn btn-primary btn-xs"
                    : " btn btn-primary btn-xs"
                }
              >
                Last 1 Year
              </button>
        
              <button
                onClick={e => this.setDateRange(e, "twoyears")}
                id="session-timeout-dialog-keepalive"
                style={{
                  marginRight: "5px" }}
                type="button"
                className={
                  this.state.setDateRange === "twoyears"
                    ? " selectedBg btn btn-primary btn-xs"
                    : "  btn btn-primary btn-xs"
                }
              >
                Last 2 Years
              </button>
           
            </div>
            </div>
          </div>
        </div>

        {this.state.isTable && (
          <div
            className="col-lg-12 classic-tabs"
            style={{
              float: "left",
              width: "100%",
              paddingLeft: 0,
              paddingRight: 0,
              marginTop: 0
            }}
          >
            <div
              id="observations_details"
              className="col-lg-2 classic-tabs"
              style={{
                float: "left",
                padding: "10px",
                marginTop: 0,
                display: "block"
              }}
            >
              <table
                className="  observation  table-bordered"
                style={{ backgroundColor: "#f4f4f4", marginLeft: "10px" }}
              >
                <tbody>
                  <tr
                    style={{
                      backgroundColor: "#333",
                      padding: "15px",
                      fontSize: "12px",
                      color: "#ffffff"
                    }}
                  >
                    <th className="cellhead">All</th>
                    <th className="cellheadoth">Today</th>
                    {this.props.patient_observations_dates != undefined &&
                    this.props.patient_observations_dates.length > 0
                      ? this.props.patient_observations_dates.map(date => (
                          <th className="cellwidth">
                            {moment(date).format("DD/MM/YYYY")}
                          </th>
                        ))
                      : ""}
                  </tr>

                  {this.props.observation_master_data != undefined &&
                  this.props.observation_master_data.length > 0
                    ? this.props.observation_master_data
                        .filter(observation => observation.isBelow === "0")
                        .map(observation => (
                          <tr style={{ textAlign: "center" }}>
                            <td
                              style={{
                                width: "130px",
                                textAlign: "left",
                                padding: "0px 5px",
                                fontSize: "12px"
                              }}
                            >
                              {" "}
                              {observation.name}{" "}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#ffffff",
                                padding: "0px 5px",
                                fontSize: "12px"
                              }}
                            >
                              <input
                                onBlur={this.addObservationData}
                                type="text" step="any"
                                onChange={e =>
                                  this.updateObservationAddData(
                                    e,
                                    "today",
                                    observation.observations_id
                                  )
                                }
                                 
                                style={{
                                  border: 0,
                                  marginBottom: 0,
                                  width: 40
                                }}
                                id="result"
                                name="result"
                              />
                            </td>
                            {this.props.patient_observations_dates !=
                              undefined &&
                            this.props.patient_observations_dates.length > 0
                              ? this.props.patient_observations_dates.map(
                                  date =>
                                    this.renderObservationColumnData(
                                      date,
                                      observation.observations_id,
                                      this.state.patientId,
                                      this.state.drId
                                    )
                                )
                              : ""}
                          </tr>
                        ))
                    : ""}
                  <tr style={{ textAlign: "center" }}>
                    <th
                      style={{
                        backgroundColor: "rgb(204, 204, 204)",
                        fontSize: "12px"
                      }}
                      colSpan={
                        this.props.patient_observations_dates != undefined &&
                        this.props.patient_observations_dates.length + 2
                      }
                    >
                      Below 18 Yrs{" "}
                    </th>
                  </tr>
                  {this.props.observation_master_data != undefined &&
                  this.props.observation_master_data.length > 0
                    ? this.props.observation_master_data
                        .filter(observation => observation.isBelow === "1")
                        .map(observation => (
                          <tr style={{ textAlign: "center" }}>
                            <td
                              style={{
                                width: "130px",
                                textAlign: "left",
                                padding: "0px 5px",
                                fontWeight: "12px"
                              }}
                            >
                              {" "}
                              {observation.name}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#ffffff",
                                padding: "0px 5px",
                                fontSize: "12px"
                              }}
                            >
                              <input
                                onBlur={this.addObservationData}
                                onChange={e =>
                                  this.updateObservationAddData(
                                    e,
                                    "today",
                                    observation.observations_id
                                  )
                                }
                                type="text" step="any"
                                style={{
                                  border: 0,
                                  marginBottom: 0,
                                  width: 40
                                }}
                                id="result"
                                name="result"
                              />
                            </td>
                            {this.props.patient_observations_dates !=
                              undefined &&
                            this.props.patient_observations_dates.length > 0
                              ? this.props.patient_observations_dates.map(
                                  date =>
                                    this.renderObservationColumnData(
                                      date,
                                      observation.observations_id,
                                      this.state.patientId,
                                      this.state.drId
                                    )
                                )
                              : ""}
                          </tr>
                        ))
                    : ""}
                </tbody>
              </table>
            </div>
            <div className="col-lg-6"> </div>
          </div>
        )}
        {!this.state.isTable && (
          <div className="row">
            <div
              className="col-lg-2 classic-tabs"
              style={{
                float: "left",
                paddingLeft: 0,
                paddingRight: 0,
                marginTop: 0,
                marginLeft: "25px", 
                overflowX:'scroll'
              }}
            >
              <table
                className="     observation  table-bordered"
                style={{ backgroundColor: "#f4f4f4" }}
              >
                <tbody>
                  <tr style={{ backgroundColor: "#cccccc" }}>
                    <th style={{ padding: 5, width: 200 }}>
                      &nbsp;
                      <a
                        onclick="observationsOpen();"
                        className="collapsed"
                        aria-expanded="false"
                      >
                        All
                      </a>
                    </th>
                  </tr>

                  {this.props.observation_master_data != undefined &&
                  this.props.observation_master_data.length > 0
                    ? this.props.observation_master_data
                        .filter(observation => observation.isBelow === "0")
                        .map(observation => (
                          <tr
                            onClick={e =>
                              this.loadChart(e, observation.observations_id)
                            }
                          >
                            <td
                              className={
                                observation.observations_id ==
                                this.state.selectedChartId
                                  ? "selectedTdBg "
                                  : ""
                              }
                              style={{ padding: "0px 5px", fontSize: "12px" }}
                            >
                              <a
                                href="#"
                                title="Click to see graph"
                                className="collapsed"
                                aria-expanded="false"
                                style={{color:"black"}}
                              >
                                {" "}
                                {observation.name}
                              </a>
                            </td>
                          </tr>
                        ))
                    : ""}
                  <tr style={{ textAlign: "center" }}>
                    <th
                      style={{
                        backgroundColor: "rgb(204, 204, 204)",
                        fontSize: "14px",
                        width: "200"
                      }}
                      colSpan={
                        this.props.patient_observations_dates != undefined &&
                        this.props.patient_observations_dates.length + 2
                      }
                    >
                      &nbsp;Below 18 Yrs{" "}
                    </th>
                  </tr>
                  {this.props.observation_master_data != undefined &&
                  this.props.observation_master_data.length > 0
                    ? this.props.observation_master_data
                        .filter(observation => observation.isBelow === "1")
                        .map(observation => (
                          <tr
                            onClick={e =>
                              this.loadChart(e, observation.observations_id)
                            }
                          >
                            <td
                              className={
                                observation.observations_id ==
                                this.state.selectedChartId
                                  ? "selectedTdBg "
                                  : ""
                              }
                              style={{ padding: "5px", fontSize: "14px" }}
                            >
                              <a
                                href="#"
                                title="Click to see graph"
                                className="collapsed"
                                aria-expanded="false"
                                style={{color:"black"}}
                              >
                                {" "}
                                {observation.name}
                              </a>
                            </td>
                          </tr>
                        ))
                    : ""}
                </tbody>
              </table>
            </div>

            <div
              className="col-lg-6"
              style={{ float: "left", marginTop: "50px" }}
            >
              {" "}
              <MyChart
                key={this.state.selectedChart}
                selectedChart={this.state.selectedChart}
              />{" "}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    observation_master: () => dispatch(api_observation_master()),
    patient_observations: data => dispatch(api_patient_observations(data)),
    add_patient_observations: data =>
      dispatch(api_add_patient_observations(data))
  };
};
const mapStateToProps = state => {
  console.log(state);

  let observation_master_data = state.getObservations.observations_master
    ? state.getObservations.observations_master
    : [];
  let patient_observations_data =
    state.getObservations.patient_observations !== undefined
      ? state.getObservations.patient_observations.patient_observations
      : [];
  let patient_observations_dates =
    state.getObservations.patient_observations !== undefined
      ? state.getObservations.patient_observations.dates
      : [];
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";

  return {
    observation_master_data,
    patient_observations_data,
    patient_observations_dates,
    redux_patientId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Observations);
