import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import moment from "moment";
//import "./myList.css";
//import CommonGrid from "../../crudcomponentV2/combinedcrud";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    api_list_options,
    
  } from "./../../DS/DS.Investigation";
import {
    api_getDoctors,
    api_cancellation_list,
    api_modifyAppointments,
    api_waiting_list,
    api_get_recall_list
    
  } from "./../../DS/DS.Appointments";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

import { confirmAlert } from "react-confirm-alert";
import Showhelp from './../showHelp';
import CommonGrid from "../crudcomponentV2/combinedcrud";

 

 class Applist extends Component {
  constructor(props) {
    super(props);
  this.state = {
    showGrid: 0,
    fromdate:new Date(),
    todate: new Date(),
    gridOptions: 'waiting',
    doctors: [],
    waitingListDoctorId : '',
    cancellationList: '',
    cancellationListProvider: '',
    list_options: '',
    open:this.props.open,
    all_appointments1:[],
    key:0,
    recall_list:[],
    crudrecall:"",
    crudwaiting:"",
    crudcancel:"",
    showCancel:false
  }
 
}
  setDoctor = (e) => {
    this.setState({waitingListDoctorId: e.target.value});
    
    if(this.state.gridOptions=='waiting')  {
      this.waitingWithout(e.target.value)
      this.setState({key:Math.random()})
    }
    if(this.state.gridOptions=='recall')
    {
        if(e.target.value=='')
        {
        this.recallCrud();
        this.setState({key:Math.random()})
        }
        else
        {
          this.recallWithout(e.target.value)
          this.setState({key:Math.random()})
        }
    }
    

  }
  handleChange = (e) => {
    this.setState({showCancel:false})
    this.setState({gridOptions: e.target.value});
    if(e.target.value=='recall')
    {
        if(this.state.waitingListDoctorId=='')
        {
        this.recallCrud();
        this.setState({key:Math.random()})
        }
        else
        {
          this.recallWithout(this.state.waitingListDoctorId)
          this.setState({key:Math.random()})
        }
      }
  }
  handlefromDate = date => {
    this.setState({ fromdate: date });
  };
  handletoDate = date => {

    this.setState({ todate: date });
  };
  statuscolor = (status, waitingtime) => {
    let classname = "";
    if(status=="label-danger" || status=="label-primary" || status=="label-success"){
      classname = status;
    }
    if(status=="label-Ontheday"){
      if(waitingtime>15 && waitingtime<30){
        classname = "label-attend";
      } 
      if(waitingtime>30){
        classname = "label-warning";
      } 
    }
    return classname;
  }

  loadCancellationList = () => {
    this.props.loadCancellationList(this.state).then((res) => {
        this.setState({cancellationList: res.data.content});
    })

  }
  timeInterval = (start, end) => {
    let date1 = new Date(start);
    let date2 = new Date(end);



    var diff = Math.abs(date2 - date1);
     let  result = Math.floor((diff/1000)/60);
    return result;
  }

  apptype = (id) => {

let arr = [];
arr[1] = " Standrad Appt.";
arr[2] = " Long Appt.";
arr[3] = " New Patient";
arr[4] = " Excision";
arr[5] = " Procedure";
arr[6] = " Cervical Screening";
arr[7] = " Immunisation";
    return arr[id];
  }
  componentDidMount(){
    this.props.list_options().then(res => {
         
        this.setState({ list_options: res.data.content });
        
      });
    this.props.getDoctors().then(doctors => {
        this.setState({ doctors: doctors.data.content });
      });
  }
  componentWillMount=()=>
  {

    this.waitingWithout(this.state.waitingListDoctorId)
  }

  recallCrud = () => {
    let returnjson = "";
    this.setState({ crudrecall: "" });
    //  alert("in1")
      let crudWithout= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteClick,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appointments_tb",
            cols: [
              "appoinment_id",
              "date",
              "appointments_tb.patient_id",
            ],
           where: [
           {
            type: "and",
            key: "appointments_tb.isDeleted",
            operator: "=",
            value: 0
          },
          {
            type: "and",
            key: "name",
            operator: "=",
            value: 'Recall'
          },
          {
            type:"and",
            key:"date",
            operator:"=",
            value: new Date().getFullYear() + "-"+ parseInt(new Date().getMonth()+1) +"-"+new Date().getDate()
          }],
          joins:[
           {
             joinType: "left",
             joinTable: "doctor_tb",
             joinCondition: "appointments_tb.doctor_id = doctor_tb.doctor_id"
           },{
             joinType: "left",
             joinTable: "appoinments_type",
             joinCondition: "appoinments_type.appoinment_type_id = appointments_tb.appoinment_type"
           },{
            joinType: "left",
            joinTable: "patients",
            joinCondition: "patients.patient_id = appointments_tb.patient_id"
          }
          ],
          customCols: [  
           "CONCAT(doctor_tb.Firstname,' ',doctor_tb.Surname) AS docname",
           "CONCAT(patients.Firstname,' ',patients.Surname) AS patientsname"
           
         ],
            primary_key:"appoinment_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "patientsname", title: "Patient" },
            {name: "docname", title: "Doctor" },
          
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:'',
          highlightprev:false
        }
      }
      returnjson = crudWithout;
      this.setState({ crudrecall: crudWithout });
   return returnjson;
  }
  recallWithout=(docId)=>{
    let returnjson = "";
    this.setState({ crudrecall: "" });
      let crudWith= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteClick,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appointments_tb",
            cols: [
              "appoinment_id",
              "date",
              "appointments_tb.patient_id",
            ],
           where: [
           {
            type: "and",
            key: "appointments_tb.isDeleted",
            operator: "=",
            value: 0
          },
          {
            type: "and",
            key: "name",
            operator: "=",
            value: 'Recall'
          },
          {
            type: "and",
            key: "appointments_tb.doctor_id",
            operator: "=",
            value: docId
          },
          {
            type:"and",
            key:"date",
            operator:"=",
            value: new Date().getFullYear() + "-"+ parseInt(new Date().getMonth()+1) +"-"+new Date().getDate()
          }],
          joins:[
           {
             joinType: "left",
             joinTable: "doctor_tb",
             joinCondition: "appointments_tb.doctor_id = doctor_tb.doctor_id"
           },{
             joinType: "left",
             joinTable: "appoinments_type",
             joinCondition: "appoinments_type.appoinment_type_id = appointments_tb.appoinment_type"
           },{
            joinType: "left",
            joinTable: "patients",
            joinCondition: "patients.patient_id = appointments_tb.patient_id"
          }
          ],
          customCols: [  
           "CONCAT(doctor_tb.Firstname,' ',doctor_tb.Surname) AS docname",
           "CONCAT(patients.Firstname,' ',patients.Surname) AS patientsname"
           
         ],
            primary_key:"appoinment_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "patientsname", title: "Patient" },
            {name: "docname", title: "Doctor" },
          
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:'',
          highlightprev:false
        }
      }
      returnjson = crudWith;
      this.setState({ crudrecall: crudWith });
    return returnjson;
  };

  deleteClick=(id,action,selectedlength)=>
  {}
  waitingWithout=(docId)=>
  {
    let returnjson = "";
    this.setState({ crudwaiting: "" });
    if(docId=='')
    {

      let crudWithout= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteClick,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appointments_tb",
            cols: [
              "appoinment_id",
              "date",
              "appointments_tb.patient_id",
              "start_time"
            ],
           where: [
           {
            type: "and",
            key: "appointments_tb.isDeleted",
            operator: "=",
            value: 0
          },
          {
            type: "and",
            key: "waiting_status",
            operator: "=",
            value: 'waiting'
          },
          {
            type:"and",
            key:"date",
            operator:"=",
            value: new Date().getFullYear() + "-"+ parseInt(new Date().getMonth()+1) +"-"+new Date().getDate()
          }],
          joins:[
           {
             joinType: "left",
             joinTable: "doctor_tb",
             joinCondition: "appointments_tb.doctor_id = doctor_tb.doctor_id"
           },{
            joinType: "left",
            joinTable: "patients",
            joinCondition: "patients.patient_id = appointments_tb.patient_id"
          }
          ],
          customCols: [  
           "CONCAT(doctor_tb.Firstname,' ',doctor_tb.Surname) AS docname",
           "CONCAT(patients.Firstname,' ',patients.Surname) AS patientsname"
           
         ],
            primary_key:"appoinment_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "patientsname", title: "Patient" },
            {name: "docname", title: "Doctor" },
            {name: "start_time", title: "Appointment At" },
          
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:'',
          highlightprev:false
        }
      }
      returnjson = crudWithout;
      this.setState({ crudwaiting: crudWithout });
    }
    else if(docId!='')
    {

      let crudWith= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteClick,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appointments_tb",
            cols: [
              "appoinment_id",
              "date",
              "appointments_tb.patient_id",
              "start_time"
            ],
           where: [
           {
            type: "and",
            key: "appointments_tb.isDeleted",
            operator: "=",
            value: 0
          },
          {
            type: "and",
            key: "waiting_status",
            operator: "=",
            value: 'waiting'
          },
          {
            type: "and",
            key: "appointments_tb.doctor_id",
            operator: "=",
            value: docId
          },
          {
            type:"and",
            key:"date",
            operator:"=",
            value: new Date().getFullYear() + "-"+ parseInt(new Date().getMonth()+1) +"-"+new Date().getDate()
          }],
          joins:[
           {
             joinType: "left",
             joinTable: "doctor_tb",
             joinCondition: "appointments_tb.doctor_id = doctor_tb.doctor_id"
           },{
            joinType: "left",
            joinTable: "patients",
            joinCondition: "patients.patient_id = appointments_tb.patient_id"
          }
          ],
          customCols: [  
           "CONCAT(doctor_tb.Firstname,' ',doctor_tb.Surname) AS docname",
           "CONCAT(patients.Firstname,' ',patients.Surname) AS patientsname"
           
         ],
            primary_key:"appoinment_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "patientsname", title: "Patient" },
            {name: "docname", title: "Doctor" },
            {name: "start_time", title: "Appointment At" },
          
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:'',
          highlightprev:false
        }
      }
      returnjson = crudWith;
      this.setState({ crudwaiting: crudWith });
    }
    return returnjson;

  }

  cancellationList=()=>
  {
    let returnjson = "";
    this.setState({ crudcancel: "" });
    let isequal=1;
    if(this.state.fromdate.getFullYear()+'-'+(this.state.fromdate.getMonth()+1)+'-'+this.state.fromdate.getDate()==this.state.todate.getFullYear()+'-'+(this.state.todate.getMonth()+1)+'-'+this.state.todate.getDate())
    {
      isequal=1;
   //   alert(isequal)
    }
    else
    {
      isequal=0;
     // alert(isequal)
    }
    if(this.state.waitingListDoctorId=='' && isequal==0)
    {

      let crudWithout= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteClick,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appointments_tb",
            cols: [
              "appoinment_id",
              "date",
              "appointments_tb.patient_id",
              "start_time",
              "appoinment_type",
              "cancel_reason",
              "name"
            ],
           where: [
           {
            type: "and",
            key: "appointments_tb.isDeleted",
            operator: "=",
            value: 1
          },
          {
            type: "and",
            key: "waiting_status",
            operator: "=",
            value: 'cancel'
          },
          {
            type: "and",
            key: "date",
            operator: ">=",
            value:this.state.fromdate.getFullYear()+'-'+(this.state.fromdate.getMonth()+1)+'-'+this.state.fromdate.getDate()
          },
          {
            type: "and",
            key: "date",
            operator: "<=",
            value: this.state.todate.getFullYear()+'-'+(this.state.todate.getMonth()+1)+'-'+this.state.todate.getDate()
          }],
          joins:[
           {
             joinType: "left",
             joinTable: "doctor_tb",
             joinCondition: "appointments_tb.doctor_id = doctor_tb.doctor_id"
           },{
            joinType: "left",
            joinTable: "patients",
            joinCondition: "patients.patient_id = appointments_tb.patient_id"
          },
          {
            joinType: "left",
            joinTable: "appoinments_type",
            joinCondition: "appoinments_type.appoinment_type_id = appointments_tb.appoinment_type"
          }
          ],
          customCols: [  
           "CONCAT(doctor_tb.Firstname,' ',doctor_tb.Surname) AS docname",
           "CONCAT(patients.Firstname,' ',patients.Surname) AS patientsname"
           
         ],
            primary_key:"appoinment_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "docname", title: "Doctor" },
            {name: "patientsname", title: "Patient" },
            {name: "start_time", title: "Schedule" },
            {name: "name", title: "Type" },
            {name: "cancel_reason", title: "Reason" },
          
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:'',
          highlightprev:false
        }
      }
      returnjson = crudWithout;
      this.setState({ crudcancel: crudWithout });
    }
    else if(this.state.waitingListDoctorId!='' && isequal==0)
    {

      
      let crudWith= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteClick,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appointments_tb",
            cols: [
              "appoinment_id",
              "date",
              "appointments_tb.patient_id",
              "start_time",
              "appoinment_type",
              "cancel_reason",
              "name"
            ],
           where: [
           {
            type: "and",
            key: "appointments_tb.isDeleted",
            operator: "=",
            value: 1
          },
          {
            type: "and",
            key: "waiting_status",
            operator: "=",
            value: 'cancel'
          },
          {
            type: "and",
            key: "date",
            operator: ">=",
            value: this.state.fromdate.getFullYear()+'-'+(this.state.fromdate.getMonth()+1)+'-'+this.state.fromdate.getDate()
          },
          {
            type: "and",
            key: "date",
            operator: "<=",
            value: this.state.todate.getFullYear()+'-'+(this.state.todate.getMonth()+1)+'-'+this.state.todate.getDate()
          },
          {
            type: "and",
            key: "appointments_tb.doctor_id",
            operator: "=",
            value: this.state.waitingListDoctorId
          }],
          joins:[
           {
             joinType: "left",
             joinTable: "doctor_tb",
             joinCondition: "appointments_tb.doctor_id = doctor_tb.doctor_id"
           },{
            joinType: "left",
            joinTable: "patients",
            joinCondition: "patients.patient_id = appointments_tb.patient_id"
          },
          {
            joinType: "left",
            joinTable: "appoinments_type",
            joinCondition: "appoinments_type.appoinment_type_id = appointments_tb.appoinment_type"
          }
          ],
          customCols: [  
           "CONCAT(doctor_tb.Firstname,' ',doctor_tb.Surname) AS docname",
           "CONCAT(patients.Firstname,' ',patients.Surname) AS patientsname"
           
         ],
            primary_key:"appoinment_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "patientsname", title: "Patient" },
            {name: "start_time", title: "Schedule" },
            {name: "name", title: "Type" },
            {name: "cancel_reason", title: "Reason" },
          
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:'',
          highlightprev:false
        }
      }
      returnjson = crudWith;
      this.setState({ crudcancel: crudWith });
    }
    else if(this.state.waitingListDoctorId=='' && isequal==1)
    {

      let crudWithout= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteClick,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appointments_tb",
            cols: [
              "appoinment_id",
              "date",
              "appointments_tb.patient_id",
              "start_time",
              "appoinment_type",
              "cancel_reason",
              "name"
            ],
           where: [
           {
            type: "and",
            key: "appointments_tb.isDeleted",
            operator: "=",
            value: 1
          },
          {
            type: "and",
            key: "waiting_status",
            operator: "=",
            value: 'cancel'
          },
          {
            type: "and",
            key: "date",
            operator: "=",
            value:this.state.fromdate.getFullYear()+'-'+(this.state.fromdate.getMonth()+1)+'-'+this.state.fromdate.getDate()
          }],
          joins:[
           {
             joinType: "left",
             joinTable: "doctor_tb",
             joinCondition: "appointments_tb.doctor_id = doctor_tb.doctor_id"
           },{
            joinType: "left",
            joinTable: "patients",
            joinCondition: "patients.patient_id = appointments_tb.patient_id"
          },
          {
            joinType: "left",
            joinTable: "appoinments_type",
            joinCondition: "appoinments_type.appoinment_type_id = appointments_tb.appoinment_type"
          }
          ],
          customCols: [  
           "CONCAT(doctor_tb.Firstname,' ',doctor_tb.Surname) AS docname",
           "CONCAT(patients.Firstname,' ',patients.Surname) AS patientsname"
           
         ],
            primary_key:"appoinment_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "docname", title: "Doctor" },
            {name: "patientsname", title: "Patient" },
            {name: "start_time", title: "Schedule" },
            {name: "name", title: "Type" },
            {name: "cancel_reason", title: "Reason" },
          
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:'',
          highlightprev:false
        }
      }
      returnjson = crudWithout;
      this.setState({ crudcancel: crudWithout });
    }
    else if(this.state.waitingListDoctorId!='' && isequal==1)
    {

      
      let crudWith= {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible:true,
          isPagination: true,
          isFiltering: true,
          isSelection:true,
          deleteFunction:this.deleteClick,
          cellClicked:'',
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "appointments_tb",
            cols: [
              "appoinment_id",
              "date",
              "appointments_tb.patient_id",
              "start_time",
              "appoinment_type",
              "cancel_reason",
              "name"
            ],
           where: [
           {
            type: "and",
            key: "appointments_tb.isDeleted",
            operator: "=",
            value: 1
          },
          {
            type: "and",
            key: "waiting_status",
            operator: "=",
            value: 'cancel'
          },
          {
            type: "and",
            key: "date",
            operator: "=",
            value: this.state.fromdate.getFullYear()+'-'+(this.state.fromdate.getMonth()+1)+'-'+this.state.fromdate.getDate()
          },
          {
            type: "and",
            key: "appointments_tb.doctor_id",
            operator: "=",
            value: this.state.waitingListDoctorId
          }],
          joins:[
           {
             joinType: "left",
             joinTable: "doctor_tb",
             joinCondition: "appointments_tb.doctor_id = doctor_tb.doctor_id"
           },{
            joinType: "left",
            joinTable: "patients",
            joinCondition: "patients.patient_id = appointments_tb.patient_id"
          },
          {
            joinType: "left",
            joinTable: "appoinments_type",
            joinCondition: "appoinments_type.appoinment_type_id = appointments_tb.appoinment_type"
          }
          ],
          customCols: [  
           "CONCAT(doctor_tb.Firstname,' ',doctor_tb.Surname) AS docname",
           "CONCAT(patients.Firstname,' ',patients.Surname) AS patientsname"
           
         ],
            primary_key:"appoinment_id",
          },
          viewtabletitle: "",
          columns: [
            {name: "patientsname", title: "Patient" },
            {name: "start_time", title: "Schedule" },
            {name: "name", title: "Type" },
            {name: "cancel_reason", title: "Reason" },
          
          ],
          rows: [],
          sortinit: [{ columnName: "appoinment_id", direction: "desc" }],
          pagesize: 10,
          getAllIds:false,
          getallId:'',
          highlightprev:false
        }
      }
      returnjson = crudWith;
      this.setState({ crudcancel: crudWith });
    }
    return returnjson;

  }

  loadCancelList=()=>
  {
    this.setState({showCancel:true})
    this.cancellationList()
    this.setState({key:Math.random()})
  }
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}> Appoinment List <Showhelp gotohelp="appointment_move_popup"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.close()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                      <div className="col-lg-12" style={{ marginTop: '10',float: "left", width: "100%", height: "450px" }}>
                        <div className="row">
                          <div className="col-lg-12 " style={{marginTop:'20px', marginBottom:'10px'}}>
                            <div className="col-md-1 pr-0"> 
                              <label> List Type </label> 
                            </div>
                            <div className="col-md-4 pl-0">
                              <select name="" className="form-control" onChange={e => this.handleChange(e)} value={this.state.gridOptions}>
                                <option value="none"> Select List </option>
                                <option value="cancellation" > Cancellation List </option>
                                <option value="waiting" > Waiting Room List </option>
                                <option value="recall" > Recall List </option>
                              </select>
                            </div>
                            <div className="col-md-1">  
                              <label style={{float:'left', marginRight:'5px'}}>Doctor: </label>
                            </div>
                            <div className="col-md-3 pl-0"> 
                              <select className="form-control" onChange={e => this.setDoctor(e)} value={this.state.waitingListDoctorId}> 
                                <option value=""> All Doctors </option>
                                {this.state.doctors.map(doctor => (
                                  <option key={doctor.doctor_id} value={doctor.doctor_id}>
                                    {" "} Dr {doctor.Firstname}{" "}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                            {/* CANCELLATION*/ }
                          {this.state.gridOptions == "cancellation" &&
                            <div className="row">
                              <div className="col-md-1">
                                <label style={{float:'left', marginRight:'5px'}}>From</label>
                              </div>
                              <div className="col-md-2">
                                  <DatePicker
                                    //ref={this.textInput}
                                    ref={input => {this.textInput = input;}}
                                    id="date-jumper"
                                    inputRef={ref => { this.textInput = ref;}}
                                    todayButton="Go to Today"
                                    selected={this.state.fromdate}
                                    onChange={this.handlefromDate}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    dateFormat="dd/MM/yyyy"
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                  />
                                </div>
                                <div className="col-md-1">
                                <label style={{float:'left', marginRight:'5px'}}>To</label>
                              </div>
                              <div className="col-md-2">
                                  <DatePicker
                                    ref={input => {this.textInput = input;}}
                                    id="date-jumper"
                                    inputRef={ref => {this.textInput = ref;}}
                                    todayButton="Go to Today"
                                    selected={this.state.todate}
                                    onChange={this.handletoDate}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    dateFormat="dd/MM/yyyy"
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                  />
                                </div>
                               
                                <div className="col-md-3">
                                    <button className="btn btn-primary" onClick={e => this.loadCancelList(e)}>Load List </button>
                                </div>
                                <div className="row">               
                                  <div className="col-md-12" style={{ padding: '0px 30px', marginTop: '10px',float: "left", width: "100%", height: "400px", overflowY: 'auto' }}>               
                                 {this.state.showCancel ?
                                  <CommonGrid
                                    handleButton={this.handleButton}
                                    crud={this.state.crudcancel}
                                    key={this.state.key}/>:''}
                                  {/*}  <table className="  table table-bordered" style={{ backgroundColor: "#ffffff" }}>
                                      <tbody>
                                        <tr style={{ backgroundColor: "#f4f4f4" }}>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Doctor</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Patient</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Scheduled</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Type</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Status</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Details</td>
                                        </tr>
                                        {(this.state.cancellationList!= undefined && this.state.cancellationList.length > 0) && this.state.cancellationList.map(item => (
                                          <tr className={this.statuscolor(item.status, this.timeInterval(moment().format("YYYY/MM/DD HH:mm"), item.arrival_time) )} >
                                            <td>&nbsp;Dr {item.drname}</td>
                                            <td>&nbsp;{item.Firstname}</td>
                                            <td>&nbsp;{moment(item.start_time).format("DD-MM-YYYY HH:mm")}</td>
                                            <td>&nbsp; {this.apptype(item.appoinment_type)}</td>
                                            <td>&nbsp; {this.apptype(item.appoinment_status)}</td>
                                            <td>&nbsp; {item.comments}</td>
                                          </tr>
                                        )) }
                                        </tbody>
                                        </table> */}
                                  </div>
                                </div>
                              </div>}
                              {this.state.gridOptions=='waiting' && 
                              <div className="row">               
                                <div className="col-md-12">  
                                    <CommonGrid
                                    handleButton={this.handleButton}
                                    crud={this.state.crudwaiting}
                                    key={this.state.key}
                                  /> 
                                </div>
                                <div className="col-sm-12 col-md-1 col-xl-1 m-b-0" style={{marginTop: '20px', textAlign: 'right', paddingRight: '0px'}}>
                                  <label className="col-form-label text-right">Legend:</label>
                                </div>
                                <div className="col-sm-12 col-md-11 col-xl-11 m-t-10" style={{textAlign: 'left', paddingLeft: '5px', marginTop:'20px'}}>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-Ontheday">&lt;15mins</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-attend">15-30mins</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-warning">&gt;30mins</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-danger">Urgent</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-primary">with doctor</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-success">At billing</button>
                                </div>
                           </div>
                           }
                              {/*this.state.gridOptions == "waiting" && 
                                <div className="row">               
                                  <div className="col-md-12">               
                                    <table className="  table table-bordered" style={{ backgroundColor: "#ffffff" }}>
                                      <tbody>
                                        <tr style={{ backgroundColor: "#f4f4f4" }}>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Doctor</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Patient</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Scheduled</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Arrived</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Waiting</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Late by</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Type</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Details</td>
                                        </tr>
                                        {this.state.all_appointments1!=undefined && this.state.all_appointments1.map(item => (
                                        <tr className={this.statuscolor(item.status, this.timeInterval(moment().format("YYYY/MM/DD HH:mm"), item.arrival_time) )} >
                                          <td>&nbsp;Dr.. {item.drname}</td>
                                          <td>&nbsp;{item.Firstname}</td>
                                          <td>&nbsp;{moment(item.start_time).format("HH:mm")}</td>
                                          <td>&nbsp; {item.arrival_time!="0000-00-00 00:00:00"?moment(item.arrival_time).format("HH:mm"):''}</td>
                                          <td>&nbsp;{this.timeInterval(moment().format("YYYY/MM/DD HH:mm"), item.arrival_time)}m</td>
                                          <td>&nbsp; {this.timeInterval(item.start_time, item.arrival_time)}m</td>
                                          <td>&nbsp; {this.apptype(item.appoinment_type)}</td>
                                          <td>&nbsp; {item.comments}</td>
                                        </tr>
                                        )) }
                                        {(this.state.waitingListDoctorId=='' && this.props.all_appointments.length > 0) && this.props.all_appointments.filter(app => ((app.status == "label-Ontheday" || app.status == "label-danger" || app.status == "label-primary" || app.status == "label-success"))).map(item => (
                                          <tr className={this.statuscolor(item.status, this.timeInterval(moment().format("YYYY/MM/DD HH:mm"), item.arrival_time) )} >
                                            <td>&nbsp;Dr {item.drname}</td>
                                            <td>&nbsp;{item.Firstname}</td>
                                            <td>&nbsp;{moment(item.start_time).format("HH:mm")}</td>
                                            <td>&nbsp; {item.arrival_time!="0000-00-00 00:00:00"?moment(item.arrival_time).format("HH:mm"):''}</td>
                                            <td>&nbsp;{this.timeInterval(moment().format("YYYY/MM/DD HH:mm"), item.arrival_time)}m</td>
                                            <td>&nbsp; {this.timeInterval(item.start_time, item.arrival_time)}m</td>
                                            <td>&nbsp; {this.apptype(item.appoinment_type)}</td>
                                            <td>&nbsp; {item.comments}</td>
                                          </tr>
                                        )) }
                                    </tbody>
                                  </table> 
                                </div>
                                <div className="row">
                                <div className="col-sm-12 col-md-1 col-xl-1 m-b-0" style={{marginTop: '20px', textAlign: 'right', paddingRight: '0px'}}>
                                  <label className="col-form-label text-right">Legend:</label>
                                </div>
                                <div className="col-sm-12 col-md-11 col-xl-11 m-t-10" style={{textAlign: 'left', paddingLeft: '5px', marginTop:'20px'}}>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-Ontheday">&lt;15mins</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-attend">15-30mins</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-warning">&gt;30mins</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-danger">Urgent</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-primary">with doctor</button>
                                  <button id="session-timeout-dialog-keepalive" type="button" style={{padding: '5px 10px', fontWeight: 'normal', fontSize: '12px'}} className="label label-success">At billing</button>
                                </div>
                              </div>
                            </div>*/}
                           
                            {this.state.gridOptions == "recall" && 
                            <div className="row">               
                              <div className="col-md-12">  
                             <CommonGrid
                             handleButton={this.handleButton}
                             crud={this.state.crudrecall}
                             key={this.state.key}
                           /> 
                           </div>
                           </div>
                           }
{/*<div className="row">               
                                  <div className="col-md-12">               
                                    <table className="  table table-bordered" style={{ backgroundColor: "#ffffff" }}>
                                      <tbody>
                                        <tr style={{ backgroundColor: "#f4f4f4" }}>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Doctor</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Patient</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Scheduled</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Waiting</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Type</td>
                                          <td style={{ padding: "5px 0px" }}>&nbsp;Details</td>
                                        </tr>
                                        {this.state.recall_list.map(item => (
                                        <tr className={this.statuscolor(item.status, this.timeInterval(moment().format("YYYY/MM/DD HH:mm"), item.arrival_time) )} >
                                          <td>&nbsp;Dr.. {item.drname}</td>
                                          <td>&nbsp;{item.Firstname}</td>
                                          <td>&nbsp;{moment(item.start_time).format("HH:mm")}</td>
                                          <td>&nbsp;{this.timeInterval(moment().format("YYYY/MM/DD HH:mm"), item.arrival_time)}m</td>
                                          <td>&nbsp; {this.apptype(item.appoinment_type)}</td>
                                          <td>&nbsp; {item.comments}</td>
                                        </tr>
                                        )) }
                                     </tbody>
                                  </table> 
                                </div>
                                        </div>*/}
                                        
                                
                             
                          </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button"  onClick={() => this.props.close()} className="btn btn-primary btn-xs" data-dismiss="modal">Close</button>
                        </div>   
                      </div>        
                    )}     
                </Popup>
        )}
    } 
    
   
const mapDispatchToProps = dispatch => {
  return {
    getDoctors: () => dispatch(api_getDoctors()),
    modifyAppointments: modifyAppointmentParam =>
      dispatch(api_modifyAppointments(modifyAppointmentParam)),
    list_options: data => dispatch(api_list_options(data)),
    loadCancellationList: data => dispatch(api_cancellation_list(data)),
    loadWaitingList:data=>dispatch(api_waiting_list(data)),
    recallList:data=>dispatch(api_get_recall_list(data))
   
  };
};


const mapStateToProps = state => {
   
  let all_appointments = state.getDrAppointments.getAppointments
    ? state.getDrAppointments.getAppointments
    : [];
 
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";

  return {
    all_appointments,
  
    logged_info
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Applist);



