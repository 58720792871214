import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import {api_search_occupation,api_get_details,api_save_occupation_details,api_get_record,api_update_occupation_details} from '../../../DS/DS.FamilyHistory';
import SearchEmployer from './searchEmployer';
import Showhelp from './../../showHelp';

class NewOccupation extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            key:0,
            occupation:'',
            selected_occupation:'',
            all_occupation:[],
            showsearch:false,
            nameselected:'',
            employer_id:'',
            emp_for:'',
            commenced:'',
            ceased:'',
            asbs:'',
            dust:'',
            rad:'',
            ani:'',
            prime:'',
            comments:'',
           
        }
    }
    componentWillMount=()=>
    {
        if(this.props.sid!='')
        {
            const data={id:this.props.sid}
            this.props.getSingleRecord(data).then(
                req=>
                {
                    this.setState({
                        selected_occupation:req.data.content.occupation_id
                        ,occupation:req.data.content.occupation_name,
                        employer_id:req.data.content.employeer,
                        emp_for:req.data.content.emp_for,
                        commenced:req.data.content.year_commenced,
                        ceased:req.data.content.ceased,
                        asbs:req.data.content.asbestos,
                        dust:req.data.content.dust,
                        rad:req.data.content.radiation,
                        ani:req.data.content.animals,
                        prime:req.data.content.is_primary,
                        comments:req.data.content.details_comment,
                        selectedEmp:req.data.content.employer_name
                    })
                }
            )
        }
    }
    searchOccupation=(e)=>
    {
        this.setState({occupation:e.target.value})
        const searchtxt={searchText:e.target.value}
        this.props.apisearchoccupation(searchtxt).then(
            req=>{
                    this.setState({all_occupation:req.data.content})
            }
        )
    }
    selectClick=(selectedData)=>
    {
        this.setState({selected_occupation:selectedData.occupation_id,occupation:selectedData.occupation_name})
    }
    reloadPage=(data)=>
    {
        this.setState({showsearch:false,key:Math.random()})
        if(data!='')
        {
            this.setState({employer_id:data.ids,emp_for:data.empfor})
            if(data.empfor==1)
            {
                const toSearch={ids:data.ids,opt:data.empfor}
                this.props.getDetails(toSearch).then(
                    req=>{
                        this.setState({selectedEmp:req.data.content[0].Firstname+' '+req.data.content[0].Surname})
                    }
                )
            }
            else if(data.empfor==2)
            {
                const toSearch={ids:data.ids,opt:data.empfor}
                this.props.getDetails(toSearch).then(
                    req=>{
                        this.setState({selectedEmp:req.data.content[0].firstname+' '+req.data.content[0].surname})
                    }
                )
            }
            else if(data.empfor==3)
            {
                const toSearch={ids:data.ids,opt:data.empfor}
                this.props.getDetails(toSearch).then(
                    req=>{
                        this.setState({selectedEmp:req.data.content[0].first_name+' '+req.data.content[0].surname})
                    }
                )
            }
        }
    }
    saveClickHandler=()=>
    {
        const values={
            patient:this.props.redux_patientId,
            doctor:this.props.redux_docId,
            emp_id:this.state.employer_id,
            emp:this.state.emp_for,
            comme:this.state.commenced,
            cease:this.state.ceased,
            asbs:this.state.asbs,
            dust:this.state.dust,
            rad:this.state.rad,
            ani:this.state.ani,
            prime:this.state.prime,
            comments:this.state.comments,
            occ:this.state.selected_occupation,
            empname:this.state.selectedEmp,
            sid:this.props.sid

        }
        if(this.props.sid=='')
        {
            this.props.saveOccupationDetails(values).then(
                req=>{
                    this.props.changeKey();
                }
            )
        }
        else{
            this.props.updateOccupationDetails(values).then(
                req=>{
                    this.props.changeKey();
                }
            )
        }
        
    }
    render() {
        return ( 
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div>        
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}> Occupation details <Showhelp gotohelp="add_occupation_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                        <div className="row row-distance" style={{margin:'10px 0'}}>
                            <div className="col-sm-2">
                                <label className="col-form-label text-left"> Occupation </label>
                            </div>	
                            <div className="col-sm-6">
                                <input type="text" className="form-control form-control-sm" value={this.state.occupation} onChange={(e)=>this.searchOccupation(e)}/>
                                <table>
                                     {this.state.all_occupation != undefined && this.state.all_occupation.length> 0 && this.state.all_occupation.map((allOcc) =>
                                         <tr onClick={(e)=>this.selectClick(allOcc)}>
                                             <td>
                                                {allOcc.occupation_name}
                                             </td>
                                         </tr>
                                     )}
                                     </table>     
                            </div>
                            <div className="col-sm-3">
                            {/*}        <button type="button" className="btn btn-default"> Keyword search</button>*/}
                            </div>
			            </div>
                        <div className="row row-distance" style={{margin:'10px 0'}}> 
                            <div className="col-sm-2">
                                    <label className="col-form-label text-left"> Employer : </label>
                            </div>	
                            <div className="col-sm-6">
                                <input type="text" className="form-control form-control-sm " value={this.state.selectedEmp}/>
                            </div>
                            <div className="col-sm-1">
			                    <button type="button" className="btn btn-primary btn-xs" style={{marginTop:'10px'}} onClick={()=>this.setState({showsearch:true})}>...</button>
			                </div>
			            </div>
                        <div className="row row-distance" style={{margin:'10px 0'}}> 
                            <div className="col-sm-2">
                                    <label className="col-form-label text-left"> Year commenced : </label>
                            </div>	
                            <div className="col-sm-2">
                                <input type="text" className="form-control form-control-sm"  value={this.state.commenced} onChange={(e)=>this.setState({commenced:e.target.value})}/>
                            </div>
    
                            <div className="col-sm-2">
                                    <label className="col-form-label text-left"> Year ceased : </label>
			                </div>	
                            <div className="col-sm-2">
                                <input type="text" className="form-control form-control-sm"  value={this.state.ceased} onChange={(e)=>this.setState({ceased:e.target.value})}/>
                            </div>
			            </div>
                        <div className="row row-distance" style={{margin:'10px 0'}}> 
                            <div className="col-sm-2">
                                    <label className="col-form-label text-left"> Exposure to : </label>
                            </div>	
                            <div className="col-sm-2">
                                    <input type="checkbox" value="" style={{margin: '-1px 5px 3px 5px', float:'left'}}  value={this.state.asbs} onChange={(e)=>this.setState({asbs:e.target.checked})}
                                    checked={this.state.asbs==1?'checked':''}/> Asbestoas
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-2">
                                    <input type="checkbox" value="" style={{margin: '-1px 5px 3px 5px', float:'left'}}  value={this.state.dust} onChange={(e)=>this.setState({dust:e.target.checked})}
                                    checked={this.state.dust==1?'checked':''}/> Dust
                            </div>	
                            <div className="col-sm-3"> </div>
			            </div>
                        <div className="row row-distance" style={{margin:'10px 0'}}> 
                            <div className="col-sm-2">   </div>	
                            <div className="col-sm-2">
                                    <input type="checkbox" value="" style={{margin: '-1px 5px 3px 5px', float:'left'}}  value={this.state.rad} onChange={(e)=>this.setState({rad:e.target.checked})}
                                    checked={this.state.rad==1?'checked':''}/> Radiation
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-2">
                                    <input type="checkbox" value="" style={{margin: '-1px 5px 3px 5px', float:'left'}}  value={this.state.ani} onChange={(e)=>this.setState({ani:e.target.checked})}
                                    checked={this.state.ani==1?'checked':''}/> Animals
                            </div>	
                            <div className="col-sm-3"> </div>
                        </div>
                        <div className="row row-distance" style={{margin:'10px 0'}}> 
                            <div className="col-sm-2">  </div>	
                            <div className="col-sm-3">
                                    <input type="checkbox" value="" style={{margin: '-1px 5px 3px 5px', float:'left'}}  value={this.state.prime} onChange={(e)=>this.setState({prime:e.target.checked})}
                                    checked={this.state.prime==1?'checked':''}/> Primary occupation
                            </div>
                            <div className="col-sm-6">  </div>
                        </div>
                        <div className="row row-distance" style={{margin:'10px 0'}}> 
                            <div className="col-sm-2">
                                    <label className="col-form-label text-left"> Detail : </label>
                            </div>	
                            <div className="col-sm-6">
                                <textarea style={{width:'100%', height:'100px', border:'1px solid #cccccc'}}
                                value={this.state.comments} onChange={(e)=>this.setState({comments:e.target.value})}></textarea>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary btn-xs" onClick={()=>this.saveClickHandler()}> Add </button>
                        <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.props.changeKey()}> Cancel </button>
                    </div>
                    {this.state.showsearch ?
                    <SearchEmployer showmodal={this.state.showsearch} changeKey={this.reloadPage}/> :''}
                </div>
            )}
        </Popup>
        )
    } 
}           
const mapDispatchToProps = dispatch => {
    return {
             apisearchoccupation: (data) => dispatch(api_search_occupation(data)),
             getDetails:(data)=>dispatch(api_get_details(data)) ,
             saveOccupationDetails:(data)=>dispatch(api_save_occupation_details(data)),
             getSingleRecord:(data)=>dispatch(api_get_record(data))  ,
             updateOccupationDetails :(data)=>dispatch(api_update_occupation_details(data)) 
            };
  };    
  const mapStateToProps = state => {
   console.log(">>> doc data",state.getDrAppointments.logged_info)
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };
 export default connect(
    mapStateToProps,
  mapDispatchToProps
  ) 
(NewOccupation);
