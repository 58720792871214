import React, { Component } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
//import CommonGrid from "../../crudcomponentV2/combinedcrud";
import CommonGrid from "./../../crudcomponentV2/combinedcrud";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { confirmAlert } from 'react-confirm-alert';
import AddCareGoals from './../../superadmin/manageCarePlans/addCareGoals';
import { api_list_goals } from "./../../../DS/DS.EPC";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

 
// import Showhelp from './../../showHelp';
// import cicon from "./../../../assets/img/cicon.png";
import Showhelp from './../../showHelp';

class GoalPopup extends Component {
    constructor(props)
    {
    super(props)
    this.state={
    all_goals: [],
    custom_text: "",
    goalSelected:"1,2",
    goalSelectedLength: "2",
    addGoal: false,
    editGoal: false, 
    goalCrud:
    {
      jsonGridVersion: "v2.0",
      grid: true,
      viewgrid: {
        isVisible:true,
        isPagination: true,
        isFiltering: true,
        isSelection:true,
        deleteFunction:this.removeGoals,
        isDateGroup:false,
        isYearSeparator:false,
        dateCol:'',
        cellClicked:'',
        baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
        query: {
          tb: "care_plan_goals_tb",
          cols: [
            "goal_id",
            "goal_name"
          ],
          where: [
                {
                   type: "and",
                   key: "isDeleted",
                   operator: "=",
                   value: 0
                },
       ],
          primary_key:"goal_id",
        },
        viewtabletitle: "",
        columns: [
          { name: "goal_name", title: "Care plan goals" }
        ],
        rows: [],
        sortinit: [{ columnName: "goal_id", direction: "desc" }],
        pagesize: 10,
        getAllIds:false,
        getallId:''
      }

    },
  
  };
}

reloadPage=()=>{
    this.setState({
        addGoal:false, 
        editGoal:false,
        addTask:false,
        editTask:false,
        openTemplate:false,
        addProblem:false,
        editProblem:false
        
       })
    this.setState({
        goalSelected:'',
        goalSelectedLength:-1,
        taskSelected:'',
        taskSelectedLength:-1,
        problemSelected:'',
        problemSelectedLength:-1

       })
    this.setState({reloadKey:Math.random()})


    this.list_goals();
 }
componentDidMount(){
    this.list_goals();
}

    list_goals = () => {
    this.props.list_goals().then((res) => {
        this.setState({all_goals: res.data.content});
    })
    }
  removeGoals=(ids,action,totalLength)=>
   {
   if(action=='selection')
   {
     this.state.goalSelected=ids;
     this.state.goalSelectedLength=totalLength;
   }
   if(action=='delete')
   {
       if (this.state.goalSelectedLength> 0) {
             const deleteData={tb_name:'care_plan_goals_tb',ids:this.state.goalSelected,primary_key:'goal_id'}
             confirmAlert({
               title: '',
               message: 'You are about to delete selected care plan goal(s). Do you want to proceed ?',
               buttons: [
               {
                   label: 'Yes',
                   onClick: () => {
                   this.props.apiDelete(deleteData).then(
                   res => this.reloadPage() ,
                   NotificationManager.success(
                   'Care plan goal(s) deleted',
                   )
                 );
               },
           },
           {
             label: 'No',
             onClick: () => { this.reloadPage()},
           },
         ],
       });
     } 
     else 
     {
       NotificationManager.error('Please select a care plan goal(s) to be deleted');
     }
   }
}
apiRestoreDefault=(tbl)=>
{
   const restorData={tb:tbl}
   this.props.apiRestoreDefault(restorData).then(
     res=> this.reloadPage(),
     NotificationManager.success(
      'Previous records restored Successfully',
      )
   )
}

  editGoal=()=>
 {
   // alert("IN")
     if(this.state.goalSelectedLength ==1 )
     {
        this.setState({editGoal:true})
     }
     else
     {
         alert("Please select a record to modify")
     }
 }

 addCheck=()=>
{
  alert("Please deselect the record to proceed")
}

handleInput = (e, key, value) => {
    this.setState({ [key]: value });
  }


  addGoal = () => {
      //alert(this.state.custom_text);
      console.log("All goals",this.state.all_goals);
      //alert(this.state.goalSelected);
      let goal_selected_arr = this.state.goalSelected.split(',');
      
      let total_selected_goals =  this.state.custom_text!=""? this.state.custom_text+", ": "";
      let total_selected_goals_id = "";

      for(let i = 0; i< this.state.all_goals.length; i++){
          for(let j =0; j<goal_selected_arr.length; j++){
            if(this.state.all_goals[i].goal_id == goal_selected_arr[j]){
                total_selected_goals += this.state.all_goals[i].goal_name+ ", ";
                total_selected_goals_id += this.state.all_goals[i].goal_id+","
            }
          }
          
      }
      this.props.addGoalEPC(total_selected_goals, total_selected_goals_id);
      //alert(total_selected_goals);
  }

  render() {
    return (
      <div class="invesvalue">
         <Popup open={this.props.open} modal closeOnDocumentClick={false}>
        <div>
        <div class="modal-header">
          <div className="popup-title">
            <span> Add Goal <Showhelp gotohelp="add_goal_help"/></span> 
            {/* <Showhelp gotohelp="investigation_give_values"/> */}
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={() => this.props.close()}>
                ×
              </span>
            </button>
            </div>
            </div>

            <div className="modal-body addinvestigation ">
            <div
          className="col-sm-12"
       
          style={{ paddingLeft: 0, overflow: "auto" }}
          id="Graphdetails"
        >
          {/* <h5
            style={{
              backgroundColor: "#f4f4f4",
              padding: "5px 10px",
              marginBottom: 10
            }}
          >
             
          </h5> */}
          <div    id="ivntableadd" className="form-group row">
       
            {/* <label className="col-sm-3 col-form-label text-left">
              Family Name:
            </label>
            <div className="col-sm-3">
              <input type="text" className="form-control form-control-sm" />
            </div> */}
          </div>

          <div className="form-group row">
         

          <div className="col-lg-12 m-t-60 mb-3">
          <label>Enter Goal or Select From List: <br /></label>
              <input className = "col-lg-12 form-control" type="text" onChange={e => this.handleInput(e, "custom_text", e.target.value)}/ >
              </div>
          
                                          <div className="col-lg-12 m-t-60 mb-3">



		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#care-plan-goals-add-btn"  onClick={(e)=>{this.state.goalSelectedLength >0 ?this.addCheck():this.setState({addGoal:true})}}>Add</button>
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" data-toggle="modal" data-target="#care-plan-goals-edit-btn" onClick={(e)=>this.editGoal()}>Edit</button>
                                                   <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.removeGoals('','delete','')}>Remove</button>
                                                   {/* <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{marginRight:'7px'}} onClick={()=>this.apiRestoreDefault('care_plan_goals_tb')}>Restore defaults</button> */}
	                                             </div>
	                                             <div className="col-sm-12 col-md-12 col-xl-12 m-t-10 table-border">
	                                                <div className="card" style={{paddingLeft: '0px', height:'150px', overFlowY:'scroll'}}>
	                                                   <div className="card-block">
                                                            <CommonGrid
                                                                    crud={this.state.goalCrud}
                                                                    key={this.state.reloadKey}
                                                                />    
	                                                   </div>
	                                                </div>
	                                             </div>
	                                            
                                                {this.state.addGoal ?
                                                   <AddCareGoals showModal={this.state.addGoal} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                {this.state.editGoal ?
                                                   <AddCareGoals showModal={this.state.editGoal} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.goalSelected}/> :''}

                                              </div>





         
        

               
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={(e) => {
                  this.addGoal(e);
                }}
              >
                Add 
              </button>

              <button
                type="button"
                className="btn btn-primary btn-xs"
                data-dismiss="modal"
                onClick={() => this.props.close()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Popup>
        
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {

return {
    list_goals: data => dispatch(api_list_goals(data))
  };
};
const mapStateToProps = state => {
  
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalPopup);
