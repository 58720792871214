import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import {api_get_distinct_reason} from '../../../DS/DS.Reminders';
import Showhelp from '../../showHelp';

 class SelectPatients extends Component {
  constructor(props) {
    super(props);
  this.state = {
     open:this.props.showmod,
     key:0,
     reasons:[],
     isSelected:[]
     }
 
}

componentWillMount=()=>{
 this.props.getReasons().then(res=>
    {
        if(res.data.content!='')
        {
        this.setState({reasons:res.data.content})
        }
    })
}
checkd=(e,eve)=>
{
  console.log("&&&&&&&&&&&&&&&&&&&&",this.state.isSelected)
 // alert(e.target.checked)
 if(eve=='')
 {
    if(e.target.checked==true)
    {
        this.state.isSelected.push(e.target.value)
    }
    if(e.target.checked==false)
    {
      this.removePeople(e)
    }
  }
  else
  {
    this.state.isSelected.push(e)
  }
}
removePeople(e) {
  console.log("&&&&&&&&&&&&&&&&&&&&",this.state.isSelected)
  var array = [...this.state.isSelected]; // make a separate copy of the array
  var index = array.indexOf(e.target.value)
  console.log("&&&&&&&&&&&&&&&&&&&&",index)
  if (index !== -1) {
    array.splice(index, 1);
    this.setState({isSelected: array});
    console.log("&&&&&&&&&&&&&&&&&&&&",array)
  }
}
onSubmit=()=>
{
  this.props.close1(this.state.isSelected)
}
 selectAll() {
  // alert("IN")
  var items = document.getElementsByName('acs');
  for (var i = 0; i < items.length; i++) {
      items[i].checked = true;
      console.log("&&&&&&&&&&&&&&&&&&&&",items[i])
      this.checkd(items[i].value,'chk')
  }
}
deselectAll() {
  // alert("IN")
  var items = document.getElementsByName('acs');
  for (var i = 0; i < items.length; i++) {
      items[i].checked = false;
      console.log("&&&&&&&&&&&&&&&&&&&&",items[i])
     
  }
  this.setState({isSelected: []});
}

 
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}>Select patients for reminders </h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"  style={{color:'black'}} onClick={()=>this.props.close1()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px" }}>
                       <div style={{marginLeft:'20px'}}>
                        {this.state.reasons!=undefined && this.state.reasons.map((item,i)=> (
                          
                          <label> <input type="checkbox" id={i} name="acs" value={item.content} onChange={(e)=>this.checkd(e,'')} ></input>{item.content}</label>
                        ))}
                        </div>
                        <div>
                          <button className="btn btn-primary btn-xs" onClick={()=>this.selectAll()}>Select All</button>
                          <button className="btn btn-primary btn-xs" onClick={()=>this.deselectAll()}>DeSelect All</button>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.onSubmit()}>Submit</button>
                        <button type="button"  className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.props.close1('')}>Close</button>
                    </div>   
                </div>        
                    )}     
            </Popup>
        )}
    } 
    const mapDispatchToProps = dispatch => {
        return {
          getReasons: () => dispatch(api_get_distinct_reason()),
        };
      };
      
      const mapStateToProps = state => {
        //console.log(state);
        let appointments = state.getDrAppointments.getAppointments
          ? state.getDrAppointments.getAppointments
          : [];
        let get_cut_copy_details = state.getDrAppointments.cut_copy_details
          ? state.getDrAppointments.cut_copy_details
          : {};
        let logged_info =
          state.getDrAppointments.logged_info != undefined
            ? state.getDrAppointments.logged_info
            : "";
      
        return {
          appointments,
          get_cut_copy_details,
          logged_info
        };
      };
      
      export default connect(mapStateToProps, mapDispatchToProps)(SelectPatients)



