import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import {
  api_list_tests,
  
} from "./../../../../DS/DS.VisitsNotes";
import { connect } from "react-redux";
import {api_get_settings} from '../../../../DS/DS.Reminders';
import Showhelp from './../../../showHelp';

class pathology extends Component {
    state = { 
      request_date: new Date(),
      search_term: "",
      pathalogy_tests: [],
      previous_tests: [],
      clinicalComments: "",
      direct_bill:0

     }
     componentWillMount(){
    //   alert(this.props.statecontent.provider);
    const settname26={setting_name:'direct_bill'}
      this.props.apigetsettings(settname26).then(res=>{
       this.setState({direct_bill:res.data.content[0].setting_value})
      })
     }
     handleinput = (e, key) => {
      this.setState({[key]: e.target.value});
     }
    

    render() {
        return (

<div>
  <form ref={form => this.form = form}>
<div className="modal-body">
  <div className="col-sm-12" style={{marginBottom: '10px', textAlign: 'left'}}>
    
    <div className="col-sm-11">
      <div className="form-group row">
        <div className="col-sm-12">
          <div className="form-group row mb-2">
            <div className="col-sm-2">
              <label className="col-form-label text-left">Request Date:.</label>
            </div>
            <div className="col-sm-3 mt-3">
            <DatePicker
                      //ref={this.textInput}
                      ref={input => {
                        this.textInput = input;
                      }}
                      id="date-jumper"
                      inputRef={ref => {
                        this.textInput = ref;
                      }}
                      todayButton="Go to Today"
                      selected={this.props.statecontent.request_date}
                      onChange={this.props.handleDate}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      dateFormat="dd/MM/yyyy"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
            </div>
           
                <div className="col-sm-1">
                  <label className="col-form-label text-left">Provider:</label>
                </div>
                <div className="col-sm-3">
               
                  {/* <input type="text" name="search_condition" className="form-control form-control-sm rq-form-elemen" /> */}
                  {/*}
                  <select
                        className="form-control"
                        value={this.props.statecontent.provider}
                        onChange={e =>
                          this.props.handleFieldChange("provider", e.target.value)
                        }
                      >
                        <option value=""> Select Provider </option>
                        {this.props.statecontent.list_options != undefined &&
                          this.props.statecontent.list_options.provider_options != null &&
                          this.props.statecontent.list_options.provider_options.map(
                            (item, index) => (
                              //      (item, index) => (
                              <option
                                value={item.value}
                                selected={
                                  this.props.statecontent.provider === item.value
                                }
                                key={index}
                              >
                                {item.label}
                              </option>
                            )
                          )}
                      </select>
                              */}
                              <label  className="col-form-label">&nbsp;{this.props.logged_info.content.details[0].Firstname}{" "}{this.props.logged_info.content.details[0].Surname}</label>
                </div>
              
           
          </div>
          <div className="form-group row">
            <div className="col-sm-12">
              <div style={{float: 'left', width: '100%', overflow: 'auto'}}>
                <table style={{width: '100%'}}>
                  <tbody>
                  <tr>
                      <td>
                      {this.props.statecontent.test_groups.map((item,i) => (
                                  
                              
                                <span>
                                  
                                  <label>
                                    <input id="check_allintwo" 
                                     value={item.test_group_id}
                                     checked={this.props.optionitems(item.test_name)}
                                     onChange={e => {
                                      this.props.handleCheckboxSelectedGroup(
                                        e,
                                        item
                                      )}}
                                      
                                    type="checkbox" className="checkbox" style={{float: 'left', width: 'auto'}} /> &nbsp; {item.test_group}
                                    </label>
                                  
                                   </span>))}
                      </td>
                  </tr>
                    <tr>
                      <td>
                        <div style={{float: 'left', width: '100%', height: 'auto'}}>
                          <table className="calendar table table-bordered tablestyle" style={{width: '100%', marginBottom: '0px'}}>
                            <thead>
                              <tr style={{backgroundColor: '#f4f4f4'}}>
                                <th colSpan={2} style={{textAlign: 'left', width: '10%'}}> Test Names</th>
                                 
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.statecontent.pathalogy_tests.filter(item => item.type == this.props.type).map((item,i) => (
                                  (i%2==0)?
                              (
                              <tr>
                                <td style={{height: 'auto', width: '50%'}}>
                                  
                                  
                                    <input id="check_allintwo" 
                                     value={item.test_id}
                                      checked={this.props.optionitems(item.test_name)}
                                     onChange={e => {
                                      this.props.handleCheckboxSelectedTests(
                                        e,
                                        item
                                      )}}
                                      
                                    type="checkbox" className="checkbox" style={{float: 'left', width: 'auto'}} /> &nbsp; {item.test_name}
                                    
                                   
                                </td>

                                <td style={{height: 'auto', width: '50%'}}>
                                  {this.props.statecontent.pathalogy_tests[i+1]!=undefined ? 
                                 
                                    <lable><input id="check_allintwo" 
                                     value={item.test_id}
                                     onChange={e => {
                                      this.props.handleCheckboxSelectedTests(
                                        e,
                                        (this.props.statecontent.pathalogy_tests[i+1]!=undefined ? this.props.statecontent.pathalogy_tests[i+1] : '')
                                      )}}
                                    type="checkbox" className="" style={{float: 'left', width: 'auto'}} /> &nbsp; {this.props.statecontent.pathalogy_tests[i+1]!=undefined ? this.props.statecontent.pathalogy_tests[i+1].test_name : ''  }</lable>
                                    : ''}
                                    </td>
                               
                              </tr>) : ''
                              ))} 
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        <div className="form-group row" style={{marginTop: '10px'}}>
            <div className="col-sm-12">
              <div style={{float: 'left', width: '100%', overflow: 'auto'}}>
                <table style={{width: '100%'}}>
                  <tbody>
                    <tr>
                      <td>
                        <div style={{float: 'left', width: '100%', height: 'auto'}}>
                          <table className="calendar table table-bordered tablestyle" style={{width: '100%', marginBottom: '0px'}}>
                            <thead>
                              <tr style={{backgroundColor: '#f4f4f4'}}>
                               
                                <th style={{textAlign: 'left', width: '50%'}}> Selected Tests </th>
                                <th style={{textAlign: 'left', width: '50%'}}> Clinical Comments: </th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                 
                                <td style={{height: 'auto', width: '40%'}}><div className="col-sm-12" style={{paddingLeft: '0px', paddingTop: '10px', paddingRight: '0px', marginLeft: '0px'}}><textarea onChange={e => this.props.updateSelectedTests(e)} style={{float: 'left', padding: '10px', border: '1px solid #ddd', width: '100%'}} value={this.props.statecontent.selected_tests_content}  /></div></td>
                                <td style={{height: 'auto', paddingTop: '10px', width: '15%'}}>
                               
                                <textarea style={{width: '100%'}} onChange={e => this.props.handleFieldChange("clinicalComments", e.target.value)}  > </textarea>

                                </td>

                                {/* <td style={{height: 'auto', paddingTop: '10px', width: '10%'}}><button type="button" className="btn btn-primary" data-dismiss="modal" style={{padding: '2px 10px'}}
                                
                                >Print</button></td> */}
                              </tr>

                              <tr>
                                 
                                  
                                 <td style={{height: 'auto', paddingTop: '10px', width: '15%'}}>
                                
                                 {this.props.type=="pathology" &&
                                  <span>      
                                  <div className="col-sm-12" style={{paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px'}}><input type="checkbox" value = "Fasting" checked={this.props.statecontent.fasting_or_nonfasting =="Fasting"} className="" style={{float: 'left', width: 'auto'}} onChange={e => this.props.handleFieldChange("fasting_or_nonfasting", (e.target.checked? e.target.value: ""))}  /> &nbsp; Fasting</div>
                                  <div className="col-sm-12" style={{paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px'}}><input type="checkbox" value="Non Fasting" checked={this.props.statecontent.fasting_or_nonfasting =="Non Fasting"} className="" style={{float: 'left', width: 'auto'}} onChange={e => this.props.handleFieldChange("fasting_or_nonfasting", (e.target.checked? e.target.value: ""))} /> &nbsp; Non Fasting</div>
                                </span>}

                                {this.props.type=="radiology" &&
                                  <span>      
                                  <div className="col-sm-12" style={{paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px'}}><input type="checkbox" className="" checked={this.props.statecontent.fasting_or_nonfasting =="Left"}  style={{float: 'left', width: 'auto'}}  value="Left" onChange={e => this.props.handleFieldChange("fasting_or_nonfasting", (e.target.checked? e.target.value: ""))} />&nbsp;Left</div>
                                  <div className="col-sm-12" style={{paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px'}}><input type="checkbox" className="" checked={this.props.statecontent.fasting_or_nonfasting =="Right"}  style={{float: 'left', width: 'auto'}} value="Right" onChange={e => this.props.handleFieldChange("fasting_or_nonfasting", (e.target.checked? e.target.value: ""))}  /> Right</div>
                                </span>}          
 
                                 </td>
 
                                 {/* <td style={{height: 'auto', paddingTop: '10px', width: '10%'}}><button type="button" className="btn btn-primary" data-dismiss="modal" style={{padding: '2px 10px'}}
                                 
                                 >Print</button></td> */}
                               </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div className="col-sm-12">
    <div  className="col-sm-3">
      {/* <input type="checkbox" name="fasting" className=""  />   */}
                 
    
    </div>
  </div>

  <div className="col-sm-12">
  <div  className="" ></div>
    <div className="col-sm-4">
      <input type="checkbox" checked={true} className=""/> Add an entry to the actions database
    </div>
    <div className="col-sm-3" >Due on</div>
    <div className="col-sm-2" >
    <DatePicker
                      //ref={this.textInput}
                      ref={input => {
                        this.textInput = input;
                      }}
                      id="date-jumper"
                      inputRef={ref => {
                        this.textInput = ref;
                      }}
                      todayButton="Go to Today"
                      selected={this.props.statecontent.due_on}
                      onChange={this.props.handleDate2}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      dateFormat="dd/MM/yyyy"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />

    </div>

  </div>
  <div className="col-sm-12">
    <div  className="col-sm-2"><input type="checkbox" name="fasting" className=""  />  Private</div>
    <div  className="col-sm-2" ><input type="checkbox" name="fasting" className=""  />  Concession</div>
    <div  className="col-sm-2" ><label for="directBill"><input type="checkbox" name="fasting" id="directBill" className=""  value={this.state.direct_bill}
    checked={this.state.direct_bill==1 ?'checked' :''} onChange={(e)=>this.setState({direct_bill:e.target.checked})}/>  Direct Bill</label></div>
   
  </div>

  
</div>
</form>

<div className="modal-footer" style={{ marginTop: "30px" }}>
<button
                    type="button"
                    className="btn btn-primary btn-xs"  onClick={(e) => {
                      this.props.savePathology(1);
                      this.form.reset(); 
                    }}
                    data-dismiss="modal"
                  >
                    Print
                  </button>
  </div>
 </div>

 );
}
}



const mapDispatchToProps = dispatch => {
  return {
    list_tests: data => dispatch(api_list_tests(data)),
    apigetsettings:(data)=>dispatch(api_get_settings(data)),

    
  };
};
const mapStateToProps = (state) => {
 
  let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";
      console.log("************",logged_info.content.details[0].Firstname)  
  return {
    logged_info,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(pathology);
 
