import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {api_get_title} from '../../../DS/DS.Correspondence';
import {api_add_carer,api_get_carer} from '../../../DS/DS.FamilyHistory';
import Showhelp from './../../showHelp';

class Carer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open:this.props.showmodal,
      carer_title:'',
      firstname:'',
      surname:'',
      address:'',
      city:'',
      phone:'',
      alt_contact:'',
      relationship:'',
      postcode:'',
      all_title:[]
    };
  }
 componentWillMount()
 {
    this.props.apiGetTitle().then(
        req=>{this.setState({all_title:req.data.content})}
    )
    const values={
        patient_id:this.props.redux_patientId
    }
    this.props.apiListCarer({values}).then(
        req=>
        {
            const details=req.data.content
            console.log(details)
            this.setState({carer_title:details.carer_title})
            this.setState({firstname:details.firstname
                ,surname:details.surname,address:details.address,city:details.city,
            phone:details.phone,alt_contact:details.alt_contact,relationship:details.relationship,postcode:details.postcode})
        }
    )
 }

 
onClickHandler=(e)=>
{
  // alert(this.props.isEpc)
    const values={
      carer_title:this.state.carer_title,
      firstname:this.state.firstname,
      surname:this.state.surname,
      address:this.state.address,
      city:this.state.city,
      phone:this.state.phone,
      alt_contact:this.state.alt_contact,
      relationship:this.state.relationship,
      postcode:this.state.postcode,
      patient_id:this.props.redux_patientId,
      doc_id:this.props.redux_docId
    }
    this.props.apiAddCarer({values}).then(
        req=>{
            if(this.props.isEpc=='yes')
            {
                this.props.changeKey(values)
            }
            else
            {
              this.props.changeKey()
            }
            NotificationManager.success("Carer details saved")
        }
    )
}
onDeleteHandler=()=>
{

}
  render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
          <div>
            <div className="modal-header">
            {this.props.isEpc=='yes' ?
                <button type="button" className="close"  onClick={() =>this.props.changeKey('')} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>: <button type="button" className="close"  onClick={() =>this.props.changeKey()} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>}
                <span>Carer details <Showhelp gotohelp="carer_help"/></span>
            </div>
            <div className="modal-body">
                <div className="col-sm-12"style={{paddingRight: "0px",marginTop: "10px", marginBottom: "10px"}}>
                    <div className="form-group row" style={{marginTop: '30px'}}>
                        <div className="col-md-2">
                            Title :
                        </div>
                        <div className="col-md-9">
                            <select class="form-control"  style={{width:"50%",height:"25px",marginTop:'10px',backgroundColor:'#f4f4f4'}} onChange={(e)=>this.setState({carer_title:e.target.value})}
                            value={this.state.carer_title}>
                           {this.state.all_title != undefined && this.state.all_title.length> 0 && this.state.all_title.map((contactwith) => <option key={contactwith.value} value={contactwith.value}>{contactwith.display}</option>)}
                                
                            </select>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginTop: '30px'}}>
                        <div className="col-md-2">
                            Firstname :
                        </div>
                        <div className="col-md-9">
                            <input class="form-control" type="text" value={this.state.firstname} onChange={(e)=>this.setState({firstname:e.target.value})}/>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginTop: '30px'}}>
                        <div className="col-md-2">
                            Surname :
                        </div>
                        <div className="col-md-9">
                            <input class="form-control" type="text" value={this.state.surname} onChange={(e)=>this.setState({surname:e.target.value})}/>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginTop: '30px'}}>
                        <div className="col-md-2">
                            Address :
                        </div>
                        <div className="col-md-9">
                            <input class="form-control" type="text" value={this.state.address} onChange={(e)=>this.setState({address:e.target.value})}/>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginTop: '30px'}}>
                        <div className="col-md-2">
                            City/Suburb :
                        </div>
                        <div className="col-md-9">
                            <input class="form-control" type="text" value={this.state.city} onChange={(e)=>this.setState({city:e.target.value})}/>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginTop: '30px'}}>
                        <div className="col-md-2">
                            Postcode :
                        </div>
                        <div className="col-md-9">
                            <input class="form-control" type="text" value={this.state.postcode} onChange={(e)=>this.setState({postcode:e.target.value})}/>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginTop: '30px'}}>
                        <div className="col-md-2">
                            Phone contact :
                        </div>
                        <div className="col-md-9">
                            <input class="form-control" type="text" value={this.state.phone} onChange={(e)=>this.setState({phone:e.target.value})}/>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginTop: '30px'}}>
                        <div className="col-md-2">
                            Alternate contact :
                        </div>
                        <div className="col-md-9">
                            <input class="form-control" type="text" value={this.state.alt_contact} onChange={(e)=>this.setState({alt_contact:e.target.value})}/>
                        </div>
                    </div>
                    <div className="form-group row" style={{marginTop: '30px'}}>
                        <div className="col-md-2">
                            Relationship :
                        </div>
                        <div className="col-md-9">
                            <input class="form-control" type="text" value={this.state.relationship} onChange={(e)=>this.setState({relationship:e.target.value})}/>
                        </div>
                    </div>
                </div>
            </div>    
            <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-xs" onClick={(e)=>{this.onClickHandler(e)}}>
                    Save
                </button>
                <button type="button" className="btn btn-primary btn-xs" onClick={(e)=>{this.onDeleteHandler()}}>
                    Delete
                </button>
                {this.props.isEpc=='yes' ?
                    <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={() =>this.props.changeKey('')}>
                        Cancel
                    </button>
                :
                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={() =>this.props.changeKey()}>
                    Cancel
                </button>}
            </div>
          </div>
        )}
      </Popup>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    apiGetTitle: () => dispatch(api_get_title()),
    apiAddCarer: (data) => dispatch(api_add_carer(data)),
    apiListCarer:(data)=>dispatch(api_get_carer(data))
   };
};

const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
     
  return {
    redux_patientId,redux_docId
  };
};
export default connect(
    mapStateToProps,
  mapDispatchToProps
)(Carer);

