import React, { Component,useState } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import axios from 'axios';
import
{
  api_getClinical,
  api_updateClinical,
  api_deleteClinical,
  api_total_records
} from '../../DS/DS.Clinical';
import CommonGrid from "../crudcomponentV2/combinedcrud";
import RecordNote from '../patientHistory/clinical/recordnote';
import EditRecordNote from '../patientHistory/clinical/editRecordNote';
import { confirmAlert } from 'react-confirm-alert';
import Showhelp from './../showHelp';
import  {api_total_notes} from '../../DS/DS.Paitents'
class ContactNote extends Component
{

    constructor (props) {
        super(props);
        this.deleteRecord = this.deleteRecord.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.state = {
         crud: {
          jsonGridVersion: "v2.0",
          grid: true,
          viewgrid: {
            isVisible:true,
            isPagination: true,
            isFiltering: true,
            isSelection:true,
            deleteFunction:this.deleteRecord,
            isDateGroup:false,
            isYearSeparator:false,
            dateCol:'image_date',
            cellClicked:'', 
            baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
            query: {
              tb: "clinical_note",
              cols: [
                "note_id",
                "contact_date",
                "user_comments",
                "contact_type",
                "contact_method",
                "contact_with",
                "contact_attempt",
                "message_status",
                "contact_reason",
                "Firstname as from_doc",
                "method_name",
                "status_text"

                
              ],
             where: [
                {
                  type: "and",
                  key: "patient_id",
                  operator: "=",
                  value: this.props.patient_id
             },
             {
                type: "and" ,
                key: "clinical_note.isDeleted",
                operator: "=",
                value:0
            }],
            joins:[
            
             {
              joinType: "inner",
              joinTable: "contact_method",
              joinCondition: "clinical_note.contact_method = contact_method.method_id"
            },
            {
             joinType: "inner",
             joinTable: "contact_status",
             joinCondition: "clinical_note.message_status = contact_status.status_id"
           },
             {
              joinType: "inner",
              joinTable: "doctor_tb",
              joinCondition: "clinical_note.created_by = doctor_tb.doctor_id"
            }
            ],
            customCols: [  
             "CONCAT(Firstname,' ',Surname) AS docname"
             
           ],
              primary_key:"note_id",
            },
            viewtabletitle: "",
            columns: [
              { name: "contact_date", title: "Date" },
              { name:"contact_reason",title:"Reason"},
              { name:"method_name",title:"Method"},
              { name: "from_doc"  , title: "Recorded By" },
              { name: "user_comments"  , title: "Comments" },
              { name: "contact_attempt"  , title: "Note no" },
              { name: "0"  , title: "Replies" },
              { name: "status_text"  , title: "Message Status" },
              
            ],
            rows: [],
            sortinit: [{ columnName: "contact_attempt", direction: "desc" }],
            pagesize: 10
          }
        },
        checkedRec:'',
        viewEdit:false,
        checkedRecLength:0,
        key:"0",
        isVisiblerecordNote:false,
        clickedId:'',
        open:false,
        isEditVisible:false,
        showeditrecord:false,
        selectedindex:-1,
        addindex:0
      };
      
      }
      componentWillMount()
      {
        //alert("IN")
            this.setState({open:this.props.open})
            const param={sid:this.props.patient_id}
            this.props.total_records(param).then(
              req=>{
                if(req.data.content!='')
                {
               // alert(parseInt(req.data.content[0].total))
                  this.setState({addindex:parseInt(req.data.content[0].total)+1})
                }
                }
            )
      }
      deleteRecord =(selectedId,action,checklength,selectionindex) =>
      {
        if(action=='selection')
        {
                this.state.checkedRec=selectedId;
                 this.state.checkedRecLength=checklength;
                 this.state.selectedindex=parseInt(selectionindex)+1
 
        }
        if(action=='delete')
        {
         if (this.state.checkedRecLength> 0) {
              const noteData={tb_name:"clinical_note",primary_key:'note_id',clinical_id:this.state.checkedRec}
              confirmAlert({
                title: '',
                message: 'You are about to deleted a recorded note.Do you want to proceed?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                    this.props.apiDeleteClinical(noteData).then(
                    res => this.changeNote() ,
                    NotificationManager.success(
                    'Note(s) marked as delete successfully',
                    //this.changeKey()
                    )
                  );
                },
            },
            {
              label: 'No',
              onClick: () => { this.changeNote()},
            },
          ],
        });
      } else {
        NotificationManager.error('Please select a record(s) to delete');
      }
    }
          
      }
      addRecord=() =>
      {
    
        this.setState({isVisiblerecordNote:true,clickedId:this.props.sid})
      }
      editRecord=()=>
      {
        
         
            if(this.state.checkedRecLength ==1)
            {
                this.setState({isEditVisible:true})
                this.setState({clickedId:this.state.checkedRec})
                //alert(this.state.isEditVisible)
            }
            else
            {
                alert("Please select a record to modify");
                this.setState({isEditVisible:false})
            }
            
      }
      changeNote = () =>
      {
           this.setState({isVisiblerecordNote:false})
           this.setState({isEditVisible:false})
           this.setState({key:Math.random()})
           const param={sid:this.props.patient_id}
           this.props.total_records(param).then(
            req=>{
             // alert(parseInt(req.data.content[0].total))
             if(req.data.content!='')
             {
                this.setState({addindex:parseInt(req.data.content[0].total)+1})
            }}
          )
      }
      render ()
      {
        var gridstyle = {
            padding: "10px"
          };
          return(
                <Popup modal open={this.state.open} closeOnDocumentClick={false}>
                  {close => (
                    <div>
                      <div className="modal-content" >
                        <div className="modal-header head-title">
                          <button type="button" className="close"  onClick={() => {close();this.props.changeKey();}} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </button>
                          <span>All Notes <Showhelp gotohelp="view_correspondence_help"/></span>
                        </div>
                        <div className="modal-body">
                            <CommonGrid
                                style={gridstyle}
                                handleButton={this.handleButton}
                                crud={this.state.crud}
                                key={this.state.key}
                            />
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" className="btn btn-primary btn-xs" onClick={(e) => this.addRecord()}>Add</button>
                        {(this.state.isVisiblerecordNote) ?
                            <RecordNote sid={this.state.clickedId} isVisibleNote={this.state.isVisiblerecordNote} changeKey={this.changeNote}  reasontype='General' attempted={this.state.addindex} showbutton={this.props.showbutton} patient={this.props.patient_id}/> : ''}
                         <button type="button" className="btn btn-primary btn-xs" onClick={(e) => this.deleteRecord('','delete','')}>Delete</button>
                         <button type="button" className="btn btn-primary btn-xs" onClick={(e) => this.editRecord()}>View</button>
                   {/*}      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={() => {close();this.props.changeKey();}}>Cancel</button>
                      */}</div>
                      {(this.state.isEditVisible) ?
                            <EditRecordNote sid={this.state.clickedId} isVisibleNote={this.state.isEditVisible} changeKey={this.changeNote}  reasontype='General' attempted={this.state.selectedindex} showbutton={this.props.showbutton} patient={this.props.patient_id}/> : ''}
                    </div>
                         
                  )}

                </Popup>
          )
      }
      
}
const mapDispatchToProps = dispatch => {
    return {
      apiDeleteClinical: data => dispatch(api_deleteClinical(data)),
      total_records:data=>dispatch(api_total_notes(data))
     };
  };
  const mapStateToProps = state => {
  
    //  let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     
    return {
      //redux_patientId
    };
  };
  
  export default connect(
      mapStateToProps,
    mapDispatchToProps
  )(ContactNote);

//export default ViewRecord ;