import React, { Component } from 'react';
import FamilyHistory from '../familySocialHistory/familyHistory';
import Socialhistory from '../familySocialHistory/socialhistory';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import AddDemographis from './addDemographics'

class Demographic extends Component{
  state = {
    demoTable: true,
    socialTable: false,
  };
  switchTable(show) {
    if (show === "demographics") {
      this.setState({  demoTable: true,socialTable: false});
    } else if (show === "social"){
      this.setState({  demoTable: false,socialTable: true});
  
    }
  }
    render(){
        return(
         <div id="notesdiv10" className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>  
         <NotificationContainer/>
 {/*} <div className="panel panel-default">
    <div className="panel-heading panspace">
      <div className="col-lg-12 col-xl-12">
        <h4 className="panel-title">
          <a href="#" className="collapsed" aria-expanded="false">Family/Social history</a>
        </h4>
        <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}}>
          <span className="makescreen"><a href="#" title="Make This Default Screen" className="collapsed" aria-expanded="false"><i className="fa fa-star panelicon" aria-hidden="true" /></a></span>
          <a href="#" title="Email" className="collapsed" aria-expanded="false"><i className="fas fa-envelope panelicon" aria-hidden="true" /></a>
          <a href="#" title="Print" className="collapsed" aria-expanded="false"><i className="fas fa-print panelicon" aria-hidden="true" /></a>
          <a title="Export PDF" onclick="recentVisitmini();" className="collapsed" aria-expanded="false"><i className="fa fa-file-pdf-o panelicon" aria-hidden="true" /></a>
        </div>
      </div>
    </div>
        </div>*/}



      <div className="panel panel-default">
          <div
            className="panspace1"> 
            <div className="col-lg-12 col-xl-12">
              <h4
                className="panel-title link">
                <a
                  onClick={() => this.switchTable("demographics")}
                  id="obsactiv"// onclick="observationsOpennew();"
                  title="Click to see family history"
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={this.state.demoTable ? "boldd" : ""} style={{color:'white'}}>
                    Demographics
                  </span>
                </a>
                <a
                  onClick={() => this.switchTable("social")}
                  id="graphactiv"
                  style={{ float: "left", fontSize: 16, paddingLeft: 20 }}
                  //onclick="observationsClose();"
                  title="Click to see social history "
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={this.state.socialTable ? "boldd" : ""} style={{color:'white'}}>
                    Social History
                  </span>
                </a>
                </h4>
              </div>
          </div>
        </div>
        <div className="col-sm-12" style={{float: 'left', overflowY: 'auto', width: '100%', height: 655, padding: 10}}>
        <div className="col-sm-12 pl-0 pr-0">
          {this.state.demoTable ? <AddDemographis />:''}
          {this.state.socialTable ? <Socialhistory  showCancel="false"/>:''}
         </div>

   {/*} <div className="col-sm-12 pl-0 pr-0">
        <FamilyHistory />
        <Socialhistory />
       </div>
       <div className="col-sm-12 pl-0 pr-0">
          <Occupation />
           <Alcoholhistory />
        </div>
        <div className="col-sm-12 pl-0 pr-0">
        <TobaccoHistory />
      </div>  */}
      
   </div>		
  </div>

        )
    }
}
export default Demographic;
