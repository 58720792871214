import React, { Component } from "react";
import { api_patients_pastvisits } from "./../../../DS/DS.VisitsNotes";
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import { wflow, wip } from "./../../../DS/general";
import { connect } from "react-redux";
import moment from "moment";
import "./../investigation/investigationReports.css";
import ReactHtmlParser from 'react-html-parser'; 
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

import { api_set_visitnotes_details } from "../../../DS/DS.VisitsNotes";
import Showhelp from './../../showHelp';

class PastVisit extends Component {
  constructor(props) {
    super(props);

    this.handleInvestigationCrud = this.handleInvestigationCrud.bind(this);
    this.handleInvestigationRowClick = this.handleInvestigationRowClick.bind(
      this
    );
    this.getAllId = this.getAllId.bind(this);
    this.state = {
      showNote: false,
      clickedId: "",
      key: 1,
      canEdit: false,
      selectedRec: "",
      selectedRecLength: "",
      patientId: this.props.redux_patientId,
      patient_investigations: [],
      selectedInvestigations: [],
      selectedDate: "",
      selectedInvestigationsFull: [],
      showSelectedContent: [],
      showSelectedContentIndex: 0,

      show_tab: "", //table_graph
      search: {
        date: "",
        test_name: "",
        action_to_be_taken: "",
        status: "",
        given_time: "",
        date_checked: "",
        docFirstname: "",
      },
      show_confidential_only: 0,
      grid_options: {
        sort_order_new: "",
        sort_by: "patients_visit_notes.createdAt",

        total_numbers: "",
        records_per_page: "10",
        current_page: "1",
        total_pages: "",
        previous_button: false,
        next_button: false,
      },
      investigation_crud: {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.handleInvestigationCrud,
          isDateGroup: false,
          isYearSeparator: false,
          dateCol: "",
          cellClicked: this.handleInvestigationRowClick,
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_reports",
            cols: [
              "patients_report_id",
              "date",
              "test_name",
              "content",
              "status",
              "given_time",
              "checked_by_guid",
            ],
            where: [
              {
                type: "and",
                key: "isDeleted",
                operator: "!=",
                value: 1,
              },
              {
                type: "and",
                key: "patient_id",
                operator: "=",
                value: this.props.redux_patientId,
              },
            ],
            joins: [
              {
                joinType: "inner",
                joinTable: "doctor_tb",
                joinCondition:
                  "patients_reports.checked_by_guid = doctor_tb.doctor_id",
              },
            ],
            customCols: ["CONCAT(Firstname,' ',Middlename) AS docname"],
            primary_key: "patients_report_id",
          },
          viewtabletitle: "",
          columns: [
            { name: "date", title: "Date" },
            { name: "test_name", title: "Test Name" },
            { name: "content", title: "Content" },
            { name: "status", title: "Status" },
            { name: "given_time", title: "Given Time" },
            { name: "date_checked", title: "Date Checked" },
            { name: "docname", title: "Given By" },
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "asc" }],
          pagesize: 5,
          getAllIds: true,
          getallId: this.getAllId,
        },
      },
    };
  }
  closeAddValuesPopup = (e) => {
    // alert(1);
    this.setState({ show_tab: "" });
  };
  setVisitNoteDetails = (content) => {
    //take existing content
    //apend

    let existing_content = this.props.visitnotes_details + "<br><br>";
    content = content.replace(/(\r\n|\n|\r)/gm, " </br>");
    existing_content += content;

    this.props.set_visitnotes_details(existing_content);
    this.props.opentodaynotes();

    
  };
  //pagination
  gotoPage = (e, pageno) => {
    e.preventDefault();
    this.setState(
      { grid_options: { ...this.state.grid_options, current_page: pageno } },
      () => {
        this.loadinvestigations();
      }
    );
  };

  calculateTotalPages = (totalrecords) => {
    let totalpages = 0;
    if (totalrecords % this.state.grid_options.records_per_page == 0) {
      totalpages = totalrecords / this.state.grid_options.records_per_page;
    } else {
      totalpages = totalrecords / this.state.grid_options.records_per_page + 1;
    }
    return totalpages;
  };

  toggleConfidential = (e, val) => {
    this.setState(
      { grid_options: { ...this.state.grid_options, current_page: 1 } },
      () => {
        this.setState({ show_confidential_only: val }, () =>
          this.loadinvestigations()
        );
      }
    );
  };

  //sort
  soryBy = (a) => {
    //  alert(a.sort_by);

    if (this.state.grid_options.sort_by == a.sort_by) {
      //this.setState({  });
      let sort_order = this.state.grid_options.sort_order_new;
      let new_sort_order = "";
      if (sort_order == "2" || sort_order == "") {
        new_sort_order = "1";
      }
      if (sort_order == "2") {
        new_sort_order = "1";
      }

      this.setState(
        { search: { ...this.state.search, sort_order_new: new_sort_order } },
        () => console.log("new order set")
      );
      this.setState(
        {
          search: { ...this.state.search, sort_order_new: new_sort_order },
          grid_options: { ...this.state.grid_options, sort_by: a.sort_by },
        },
        () => this.loadinvestigations()
      );
    } else {
      // this.setState({ });
      this.setState(
        {
          search: { ...this.state.search, sort_order_new: "2" },
          grid_options: { ...this.state.grid_options, sort_by: a.sort_by },
        },
        () => this.loadinvestigations()
      );
    }
  };

  //filter/search
  handleSearch = (e, key) => {
    //this.setState({[key]:e.target.value});
    this.setState(
      { search: { ...this.state.search, [key]: e.target.value } },
      () => this.loadinvestigations()
    );
  };

  toggleEdit = (e) => {
    this.setState({ canEdit: !this.state.canEdit });
  };
  toggleAdd = (e) => {
    // alert(1);
    if (this.state.selectedInvestigations.length >= 1) {
      alert("Please unselect before adding");
    } else {
      this.setState({ addNew: !this.state.addNew });
    }
  };
  canResultNote = () => {
    if (this.state.selectedInvestigationsFull.length == 1) {
      this.cout_inv("investigation ", this.state.selectedInvestigations[0]);
      this.setState({ showNote: true });
    } else {
      alert("Please, Select one record to record note.");
    }
  };
  canEdit = () => {
    console.log(
      "immunisation selected length",
      this.state.selectedInvestigations
    );
    if (this.state.selectedInvestigationsFull.length == 1) {
      this.setState({ canEdit: true });
    } else {
      alert("Please, Select one record to Edit");
    }
  };
  loadinvestigations = () => {
    //alert("loading ");
    this.props
      .patient_investigations({
        patientId: this.state.patientId,
        grid_options: this.state.grid_options,
        search: this.state.search,
        show_confidential_only: this.state.show_confidential_only,
      })
      .then((res) => {
        this.setState({ patient_investigations: res.data.content });

        this.setState({
          grid_options: {
            ...this.state.grid_options,
            total_numbers: res.data.totalCount,
          },
        });
        this.setState({
          grid_options: {
            ...this.state.grid_options,
            total_pages: this.calculateTotalPages(res.data.totalCount),
          },
        });
        this.setState({ showSelectedContent: res.data.content[0] });
      });
    console.log(this.state);
  };

  cout_inv = (...args) => {
    console.log("[INVESTIGATION_REPORT___", args, "____]");
  };

  componentDidMount() {
    console.clear();

    //this.props.patient_investigations();
    this.props
      .patient_investigations({
        patientId: this.state.patientId,
        grid_options: this.state.grid_options,
        search: this.state.search,
        show_confidential_only: this.state.show_confidential_only,
      })
      .then((res) => {
       // alert("did mount loaded");
       if(res.data.content!=undefined){
        this.setState({ patient_investigations: res.data.content });

        this.setState({
          grid_options: {
            ...this.state.grid_options,
            total_numbers: res.data.totalCount,
          },
        });

        this.setState({
          grid_options: {
            ...this.state.grid_options,
            total_pages: this.calculateTotalPages(res.data.totalCount),
          },
        });
        this.setState({ showSelectedContent: res.data.content[0] });
      }
      });
    
    console.log(this.state);
  }

  componentWillReceiveProps(nextProps) {
    //    console.log('---------recieve props changed',nextProps);

    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });

      this.props
        .patient_investigations({
          patientId: nextProps.redux_patientId,
          grid_options: this.state.grid_options,
          search: this.state.search,
          show_confidential_only: this.state.show_confidential_only,
        })
        .then((res) => {
          this.setState({ patient_investigations: res.data.content });
          this.setState({
            grid_options: {
              ...this.state.grid_options,
              total_numbers: res.data.totalCount,
            },
          });
          this.setState({
            grid_options: {
              ...this.state.grid_options,
              total_pages: this.calculateTotalPages(res.data.totalCount),
            },
          });
          this.setState({ showSelectedContent: res.data.content[0] });
        });
    }
  }
  // renderPagination = () => {
  //   let total = this.state.grid_options.total_numbers;
  //   let pageno = this.state.grid_options.current_page;

  //   let totalpages = total/pageno;
  //   this.setState({ grid_options: { ...this.state.grid_options, total_numbers:  totalpages } });
  //   this.setState({total_pages: totalpages});

  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.grid_options.total_numbers = this.state.grid_options.total_numbers)
    ) {
    } else {
      // this.renderPagination();
    }
  }
  handleInvestigationRowClick = (id, action) => {
    console.log("rowclicked", action, id);
  };

  changeKey = () => {
    this.setState({ showNote: false });
    this.setState({ key: Math.random() });
    this.getAllId();
  };

  handleInvestigationCrud = (selectedId, action, checklength) => {
    if (action == "delete") {
      //console.log
      if (this.state.selectedRecLength_acute > 0) {
        const immData = { immunisation_id: this.state.selectedRec_acute };
        confirmAlert({
          title: "Delete record",
          message: "Do you want to delete the record?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                // this.changeKey();
              },
            },
            {
              label: "No",
              onClick: () => {
                //this.changeKey();
              },
            },
          ],
        });
      } else {
        NotificationManager.error("Please select a record to delete");
      }
    }
    if (action == "selection") {
      // this.setState({selectedRec: selectedId});
      // this.setState({selectedRecLength: checklength});
      this.state.selectedRec_acute = selectedId;
      this.state.selectedRecLength_acute = checklength;
      //  this.mapOld('acute');
      let item = [];
      item.push(selectedId.split(","));
      this.state.selectedAcute = item;
      // this.setState({selectedAcute: item});
    }

    console.log("pasthistorystate", this.state);
  };

  getAllId = (allids) => {
    console.log("adllids", allids);
  };

  checkboxstatus = (id, date) => {
    let found = false;
    for (let i = 0; i < this.state.selectedInvestigationsFull.length; i++) {
      if (this.state.selectedInvestigationsFull[i].patients_report_id === id) {
        found = true;
      }
    }
    return found;
  };
  setTrClass = (y, z, id) => {
    let classs = "";
    classs += z % 2 == 0 ? " firstColor " : " secondColor ";
    classs +=
      id ==
      (this.state.showSelectedContent != undefined &&
        this.state.showSelectedContent.patients_report_id)
        ? " selectedBg "
        : "";
    classs += y ? " yearSeperator " : "";
    return classs;
  };
  showSelectedContent(e, item, i) {
    this.setState({ showSelectedContent: item });
    this.setState({ showSelectedContentIndex: i });
    console.log(this.state);
  }
  handleCheckAllbox = (e, ids) => {
    console.log(this.state, ids);
    this.setState({
      selectedInvestigations: e.target.checked ? ids : [],
      selectedInvestigationsFull: e.target.checked
        ? this.state.patient_investigations
        : [],
    });
  };
  next_prev = (e, typ) => {
    e.preventDefault();
    let index = this.state.showSelectedContentIndex;
    if (typ == "next") {
      index = index + 1;
      this.setState({ showSelectedContentIndex: index });
      this.setState({
        showSelectedContent: this.state.patient_investigations[index],
      });
    }
    if (typ == "prev") {
      index = index - 1;
      this.setState({ showSelectedContentIndex: index });
      this.setState({
        showSelectedContent: this.state.patient_investigations[index],
      });
    }
  };
  modifySelected = (e, type) => {
    e.preventDefault();
    if (this.state.selectedInvestigationsFull.length > 0) {
      let params = {
        patient_id: this.state.patient_id,
        type: type,
        ids: this.state.selectedInvestigationsFull,
      };

      if (params.type == "delete") {
        confirmAlert({
          title: "",
          message:
            "You are about to delete a investigation record. Do you want to proceed?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                this.props.modify_investigations(params);
                this.setState({
                  selectedInvestigations: [],
                  selectedInvestigationsFull: [],
                });
                this.props
                  .patient_investigations({
                    patientId: this.state.patientId,
                    grid_options: this.state.grid_options,
                    search: this.state.search,
                    show_confidential_only: this.state.show_confidential_only,
                  })
                  .then((res) => {
                    this.setState({ patient_investigations: res.data.content });
                    this.setState({
                      grid_options: {
                        ...this.state.grid_options,
                        total_numbers: res.data.totalCount,
                      },
                    });
                    this.setState({
                      grid_options: {
                        ...this.state.grid_options,
                        total_pages: this.calculateTotalPages(
                          res.data.totalCount
                        ),
                      },
                    });
                  });
                NotificationManager.success("Successfully Deleted");
              },
            },
            {
              label: "No",
              onClick: () => {},
            },
          ],
        });
      } else {
        this.props.modify_investigations(params).then(() => {
          this.setState({
            selectedInvestigations: [],
            selectedInvestigationsFull: [],
          });
          this.props
            .patient_investigations({
              patientId: this.state.patientId,
              grid_options: this.state.grid_options,
              search: this.state.search,
              show_confidential_only: this.state.show_confidential_only,
            })
            .then((res) => {
              this.setState({ patient_investigations: res.data.content });
              this.setState({
                grid_options: {
                  ...this.state.grid_options,
                  total_numbers: res.data.totalCount,
                },
              });
              this.setState({
                grid_options: {
                  ...this.state.grid_options,
                  total_pages: this.calculateTotalPages(res.data.totalCount),
                },
              });
            });
          NotificationManager.success("Marked as Given");
        });
      }
    } else {
      alert("Select any record");
    }
  };

  handleCheckbox = (e, data, id) => {
    let idd = e.target.value;
    let date = data.date;
    //    if (this.state.selectedInvestigations.includes(idd)) {
    //this.state.selectedInvestigations.patients_report_id
    if (
      this.state.selectedInvestigationsFull.filter(
        (i) => i.patients_report_id === id
      ).length > 0
    ) {
      console.log("already checked - now removing");
      //removing
      this.setState({
        selectedInvestigations: [
          ...this.state.selectedInvestigations.filter(
            (patients_report_id) => patients_report_id !== idd
          ),
        ],
        selectedInvestigationsFull: [
          ...this.state.selectedInvestigationsFull.filter(
            (data) => data.patients_report_id !== idd
          ),
        ],
        selectedDate: "",
        //editData: {},
      });
    } else {
      //setting checkboxes
      console.log("checking new - now adding");
      this.setState({
        selectedInvestigations: [...this.state.selectedInvestigations, idd],
        selectedDate: data.date,
      });

      //having existing checkboxes + only selected date when any of the date already exist
      //having existing checkboxes + all dates of selected date
      let found = false;
      for (let i = 0; i < this.state.selectedInvestigationsFull.length; i++) {
        if (this.state.selectedInvestigationsFull[i].date === date) {
          found = true;
        }
      }

      if (found == true) {
        this.setState({
          selectedInvestigationsFull: this.state.selectedInvestigationsFull.concat(
            this.state.patient_investigations.filter(function(investigation) {
              return investigation.patients_report_id == id;
            })
          ),
        });
      } else {
        this.setState({
          selectedInvestigationsFull: this.state.selectedInvestigationsFull
            .filter(function(investigation) {
              return investigation.date !== date;
            })
            .concat(
              this.state.patient_investigations.filter(function(investigation) {
                return investigation.date == date;
              })
            ),
        });
      }
    }
    // console.log(id, data, this.state.selectedInvestigations, this.state.selectedInvestigationsFull)
  };

  render() {
    var gridstyle = {
      padding: "10px",
    };
    let z = 0;
    return (
      <div
        id="notesdiv5"
        className="col-md-12"
        style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "#ffffff" }}
      >
        <div className="panel panel-default">
          <div
            className="panel-heading"
            style={{
              float: "right",
              width: "200px",
              padding: "4px 0px 3px 10px",
              marginBottom: "10px", marginTop: "-29px"
            }}
          >
            <NotificationContainer />
            <div
              className="col-lg-12 col-xl-12"
              style={{ padding: "5px 10px" }}
            >
              <div
                style={{
                  width: "auto",
                  height: 30,
                  position: "absolute",
                  right: 15,
                  top: 0,
                }}
              >
                <span className="makescreen">
                  <a
                    href="#"
                    title="Make This Default Screen"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-star panelicon"
                      aria-hidden="true"
                    />
                  </a>
                </span>
                <a
                  href="#"
                  title="Send SMS"
                  data-toggle="modal"
                  data-target="#sendsms"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-sms panelicon"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  title="Email"
                  data-toggle="modal"
                  data-target="#sendmail"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-envelope panelicon"
                    aria-hidden="true"
                  />
                </a>
                <a
                  href="#"
                  title="Print"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-print panelicon"
                    aria-hidden="true"
                  />
                </a>
                {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
                <a
                  title="Export PDF"
                  onclick="recentVisitmini();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-file-pdf-o panelicon"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6" style={{ marginBottom: 10 }}></div>
        <div
          className="col-sm-6 pl-0"
          style={{ marginBottom: 10, textAlign: "right" }}
        ></div>
        <div className="col-sm-6 pl-0">
          <div
            style={{ paddingLeft: "0px", overflow: "scroll", height: "435px" }}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <table
                        className="investigationTable table-bordered    tablestyle newcolor "
                        style={{ float: "left", width: 1100 }}
                      >
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#f4f4f4",
                              curser: "pointer",
                            }}
                            onclick="Investigationview();"
                          >
                            <th
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                color: "#333",
                                padding: "10px",
                              }}
                              onClick={(e) =>
                                this.soryBy({
                                  sort_by: "patients_reports.review_date",
                                })
                              }
                            >
                              {/* <input
                                onChange={(e) =>
                                  this.handleCheckAllbox(
                                    e,
                                    this.state.patient_investigations
                                      // .filter(item => item.acute_chronic == 'acute')
                                      .map((item) => item.visit_notes_id)
                                  )
                                }
                                checked={
                                  this.state.patient_investigations.length > 0
                                    ? this.state.selectedInvestigations
                                        .length ===
                                      this.state.patient_investigations.length
                                    : false
                                }
                                type="checkbox"
                                style={{
                                  float: "left",
                                  marginRight: "5px",
                                  width: "50",
                                }}
                              />{" "} */}
                              Date
                            </th>
                            
                            <th
                              style={{
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px",
                              }}
                              onClick={(e) =>
                                this.soryBy({ sort_by: "doctor_tb.Firstname" })
                              }
                            >
                              {" "}
                            Doctor
                            </th>
                            <th
                              style={{
                                width: "250",
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px",
                              }}
                              onClick={(e) =>
                                this.soryBy({
                                  sort_by: "patients_reports.reason_for_visit",
                                })
                              }
                            >
                              Reason for Visit
                            </th>

                            <th
                              style={{
                                width: "94",
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px",
                              }}
                              onClick={(e) =>
                                this.soryBy({
                                  sort_by: "patients_reports.visit_type",
                                })
                              }
                            >
                              {" "}
                            Visit Type
                            </th>
                             
                            <th
                              style={{
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px",
                              }}
                              onClick={(e) =>
                                this.soryBy({
                                  sort_by: "patients_reports.review_date",
                                })
                              }
                            >
                              {" "}
                             Review Date
                            </th>
                            {/* <th
                              style={{
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px"
                              }}
                              onClick={e=> this.soryBy({sort_by: "Comment" })}
                            >
                              Comment
                            </th> */}
                            {/* <th
                              style={{
                                color: "#333",
                                fontSize: "16px",
                                padding: "10px"
                              }}
                              onClick={e =>
                                this.soryBy({ sort_by: "Provider" })
                              }
                            >
                              Provider
                            </th> */}
                          </tr>

                          <tr>
                            <th>
                              <input
                                type="text"
                                onChange={(e) => this.handleSearch(e, "review_date")}
                              />
                            </th>
                            <th>
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.handleSearch(e, "docFirstname")
                                }
                              />
                            </th>
                            <th>
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.handleSearch(e, "reason_for_visit")
                                }
                              />
                            </th>
                            
                            <th>
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.handleSearch(e, "visit_type")
                                }
                              />
                            </th>

                         

                            <th>
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.handleSearch(e, "review_date")
                                }
                              />
                            </th>
                            {/* <th>
                              <input
                                type="text"
                                onChange={e =>
                                  this.handleSearch(e, "date_checked")
                                }
                              />
                            </th> */}
                            {/* <th>
                              <input
                                type="text"
                                onChange={e => this.handleSearch(e, "doctor_tb.Firstname")}
                              />
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.patient_investigations!=undefined && this.state.patient_investigations.map((item, i) => (
                            <tr
                              onClick={(e) =>
                                this.showSelectedContent(e, item, i)
                              }
                              rec={
                                i > 0 &&
                                item.date ===
                                  this.state.patient_investigations[i - 1]
                                    .review_date
                                  ? z
                                  : z++
                              }
                              className={this.setTrClass(
                                i > 0 &&
                                  moment(item.review_date).format("YYYY") ===
                                    moment(
                                      this.state.patient_investigations[i - 1]
                                        .review_date
                                    ).format("YYYY")
                                  ? false
                                  : true,
                                z,
                                item.visit_notes_id
                              )}
                            >
                              <td>
                                {/* <input
                                  type="checkbox"
                                  name={item.visit_notes_id}
                                  id="check_allin"
                                  value={item.visit_notes_id}
                                  checked={this.checkboxstatus(
                                    item.visit_notes_id,
                                    item.review_date
                                  )}
                                  onChange={(e) =>
                                    this.handleCheckbox(
                                      e,
                                      item,
                                      item.visit_notes_id
                                    )
                                  }
                                /> */}
                                &nbsp;
                                {moment(item.review_date).format("DD/MM/YYYY")}{" "}
                              </td>
                              <td style={{ fontSize: "14px" }}>
                                {" "}
                                {"Dr " + item.docFirstname}
                              </td>
                              <td style={{ fontSize: "14px" }}>
                                {" "}
                                {item.reason_for_visit}
                              </td>
                            
                              <td style={{ fontSize: "14px" }}>
                                {item.visit_type}
                              </td>
                           
                              <td style={{ fontSize: "14px" }}>
                                {/* {item.status === null
                                  ? "-"
                                    
                                    " " } */}
                                {moment(item.review_date).format("DD/MM/YYYY")}
                              </td>
                              {/* <td style={{ fontSize: "14px" }}>Normal</td> */}
                              {/* <td style={{ fontSize: "14px" }}>
                                {item.provider}
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="clearfix card-footer dx-g-bs4-paging-panel">
            <nav className="float-right d-none d-sm-flex">
              <ul className="pagination m-0">
                <li
                  className={
                    this.state.grid_options.current_page > 1
                      ? "page-item"
                      : "page-item disabled"
                  }
                >
                  <a
                    className="page-link"
                    aria-label="Previous"
                    onClick={(e) =>
                      this.state.grid_options.current_page > 1 &&
                      this.gotoPage(
                        e,
                        parseInt(this.state.grid_options.current_page) - 1
                      )
                    }
                    href="#"
                  >
                    <span aria-hidden="true">«</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>

                {Array.apply(null, {
                  length: this.state.grid_options.total_pages,
                }).map((e, i) => (
                  <li
                    className={
                      this.state.grid_options.current_page == i + 1
                        ? "page-item active"
                        : "page-item  "
                    }
                  >
                    <a
                      className="page-link"
                      aria-label
                      href="#"
                      onClick={(e) => this.gotoPage(e, i + 1)}
                    >
                      {" "}
                      {i + 1}
                    </a>
                  </li>
                ))}

                <li
                  className={
                    this.state.grid_options.current_page <
                    this.state.grid_options.total_pages
                      ? "page-item"
                      : "page-item disabled"
                  }
                >
                  <a
                    className="page-link"
                    aria-label="Next"
                    href="#"
                    onClick={(e) =>
                      this.state.grid_options.current_page <
                        this.state.grid_options.total_pages &&
                      this.gotoPage(
                        e,
                        parseInt(this.state.grid_options.current_page) + 1
                      )
                    }
                  >
                    <span aria-hidden="true">»</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
            <nav className="float-right d-sm-none">
              <ul className="pagination m-0">
                <li className="page-item disabled">
                  <a className="page-link" aria-label="Previous" href="#">
                    <span aria-hidden="true">«</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
                &nbsp;
                <li className="page-item disabled">
                  <a className="page-link" aria-label="Next" href="#">
                    <span aria-hidden="true">»</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>

            <span className="float-right d-sm-none mr-4">
              <span className="d-inline-block align-middle">1 of 1</span>
            </span>
          </div>
        </div>
        <div
          className="col-sm-6"
          style={{
            paddingLeft: "10px",
            overflow: "scroll",
            height: "469px",
            border: "1px solid #ddd",
          }}
          id="Investigationdetails"
        >
          {this.state.showSelectedContentIndex > 0 && (
            <a
              title="Previous"
              onClick={(e) => this.next_prev(e, "prev")}
              style={{ paddingRight: 5, marginRight: "-10px", float: "right" }}
              className="collapsed"
              aria-expanded="false"
            >
              <i
                className="fa fa-arrow-circle-up"
                aria-hidden="true"
                style={{ float: "right", marginTop: 10, fontSize: 16 }}
              />
            </a>
          )}{" "}
          <br />
          {this.state.patient_investigations!=undefined && this.state.patient_investigations.length - 1 !=
            this.state.showSelectedContentIndex && (
            <a
              onClick={(e) => this.next_prev(e, "next")}
              title="Next"
              onclick="detailsvisit1();"
              style={{ paddingRight: 5, float: "right" }}
              className="collapsed"
              aria-expanded="false"
            >
              <i
                className="fa fa-arrow-circle-down"
                aria-hidden="true"
                style={{
                  float: "right",
                  marginTop: 21,
                  fontSize: 16,
                  marginRight: -20,
                }}
              />{" "}
            </a>
          )}
          <span>
            {(this.state.patient_investigations!=undefined && this.state.showSelectedContent != undefined &&
              this.state.patient_investigations.length) > 0 && (
              <h5
                style={{
                  backgroundColor: "#f4f4f4",
                  padding: "10px",
                  marginLeft: "-10px",
                  width: "105%",
                  marginBottom: 32,
                }}
              >
                {moment(this.state.showSelectedContent.review_date).format(
                  "DD/MM/YYYY"
                )}{" "}
                - {this.state.showSelectedContent.reason_for_visit}{" "}
                <button
                  className="btn btn-info"
                  onClick={(e) =>
                    this.setVisitNoteDetails(
                      this.state.showSelectedContent.notes
                    )
                  }
                >
                  {" "}
                  Copy to Notes {" "}
                </button>
              </h5>
            )}

            <div
              style={{
                backgroundColor: "white",
                minHeight: "50px",
                marginTop: "-30px",
                 
              }}
            >
              { this.state.patient_investigations!=undefined && this.state.showSelectedContent != undefined &&
                this.state.patient_investigations.length > 0 &&
                
                ReactHtmlParser(this.state.showSelectedContent.notes)
                }
            </div>
          </span>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    patient_investigations: (data) => dispatch(api_patients_pastvisits(data)),
    set_visitnotes_details: (data) =>
      dispatch(api_set_visitnotes_details(data)),
  };
};

const mapStateToProps = (state) => {
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  let visitnotes_details =
    state.getDrAppointments.visitnotes_details != undefined
      ? state.getDrAppointments.visitnotes_details
      : "";
  return {
    redux_patientId,
    visitnotes_details,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastVisit);
