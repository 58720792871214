import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../../showHelp';


class cns extends Component {
    state = {  }
    render() {
        return (
            <Popup lockScroll={true} trigger={<a  title="CNS" className="collapsed" aria-expanded="false"><i className="fa fa-heart" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>} position="right center"   modal
            closeOnDocumentClick={false}
            >
             {close => (

                 <div className="cnslay">

              <div className="modal-header" 
              //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
               >

                      <button type="button" className="c
                      lose" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> CNS <Showhelp gotohelp="cns_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>CNS</h4> */}
                    </div>
                    <div className="modal-body"   style={{overflowY: "scroll", height: "450px" }}>
                        <h4 style={{}}>History:</h4>


        <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Headache</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Headache === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Headache")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Headache === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Headache")}
                    /> No

                        </div>

                        <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Neck stiffness</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.NeckStiffness === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "NeckStiffness")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.NeckStiffness === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "NeckStiffness")}
                    /> No

                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Photophobia</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Photofobia === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Photofobia")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Photofobia === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Photofobia")}
                    /> No

                        </div>

                        <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Hyperacusis</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Hyperacusis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Hyperacusis")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Hyperacusis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Hyperacusis")}
                    /> No
                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Fits</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Fits === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Fits")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Fits === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Fits")}
                    /> No

                        </div>

                        <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Faints</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Faints === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Faints")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Faints === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Faints")}
                    /> No

                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Weakness</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Weakness === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Weakness")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Weakness === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Weakness")}
                    /> No
                        </div>

                        <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Numbness</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Numbness === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Numbness")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Numbness === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Numbness")}
                    /> No
                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Slurred speech</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.SlurredSpeech === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "SlurredSpeech")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.SlurredSpeech === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "SlurredSpeech")}
                    /> No
                        </div>

                        <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Incoordination</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Incoordination === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Incoordination")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Incoordination === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Incoordination")}
                    /> No

                        </div>

                 </div>



                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Confusion</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Confusion === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Confusion")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Confusion === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Confusion")}
                    /> No

                        </div>

                        <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Disorientation</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Disorientation === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Disorientation")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Disorientation === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Disorientation")}
                    /> No
                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Tremor </label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Tremor === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Tremor")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Tremor === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns", "Tremor")}
                    /> No
                        </div>

                        <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>


                        </div>

                 </div>

                 &nbsp;
                 <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                 <h4 style={{}}>Examination:</h4>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Essential tremor</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.EssentialTremor === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "EssentialTremor")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.EssentialTremor === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "EssentialTremor")}
                    /> No

                        </div>

                        <div className="col-sm-1" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Upper facial palsy</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.UpperFacialPalsy === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "UpperFacialPalsy")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.UpperFacialPalsy === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "UpperFacialPalsy")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.UpperFacialPalsy === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "UpperFacialPalsy")}
                    /> Neither
                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Intension tremor</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.IntensionTremor === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "IntensionTremor")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.IntensionTremor === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "IntensionTremor")}
                    /> No
                        </div>

                        <div className="col-sm-1" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Lower facial palsy</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.LowerFacialPalsy === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LowerFacialPalsy")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.LowerFacialPalsy === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LowerFacialPalsy")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.LowerFacialPalsy === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LowerFacialPalsy")}
                    /> Neither

                        </div>

                 </div>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Involuntary movements</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.InvoluntaryMovements === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "InvoluntaryMovements")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.InvoluntaryMovements === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "InvoluntaryMovements")}
                    /> No
                        </div>

                        <div className="col-sm-1" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Arms wickness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.ArmsWickness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmsWickness")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.ArmsWickness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmsWickness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.ArmsWickness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmsWickness")}
                    /> Neither

                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Cogwheel rigidity</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CogwheelRigidity === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "CogwheelRigidity")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.CogwheelRigidity === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "CogwheelRigidity")}
                    /> No

                        </div>

                        <div className="col-sm-1" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Leg weakness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.LegWeakness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegWeakness")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.LegWeakness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegWeakness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.LegWeakness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegWeakness")}
                    /> Neither
                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>

                        </div>

                        <div className="col-sm-1" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Arm numbness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.ArmNumbness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmNumbness")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.ArmNumbness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmNumbness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.ArmNumbness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "ArmNumbness")}
                    /> Neither
                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>

                        </div>

                        <div className="col-sm-1" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Leg numbness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.LegNumbness === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegNumbness")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.LegNumbness === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegNumbness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.LegNumbness === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LegNumbness")}
                    /> Neither
                        </div>

                 </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>


                        </div>

                        <div className="col-sm-1" style={{textAlign:'left'}}>

                        </div>
                    <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Foot drop</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.FootDrop === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "FootDrop")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.FootDrop === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "FootDrop")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.FootDrop === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "FootDrop")}
                    /> Neither
                        </div>

                 </div>


                 <div className="col-sm-12">
                 <div className="col-sm-1" style={{textAlign:'left'}}>

                        </div>
                  <div className="col-sm-3" style={{textAlign:'center'}}>
                  <h4 style={{}}>Right eye:</h4>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '',textAlign:'center'}}>
                        <h4 style={{}}>Left eye:</h4>

                        </div>


                     </div>

            <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Ptosis</label>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.RightEyePtosis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightEyePtosis")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RightEyePtosis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightEyePtosis")}
                    /> No

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.LeftEyePtosis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftEyePtosis")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.LeftEyePtosis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftEyePtosis")}
                    /> No

                        </div>

             </div>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>EOM</label>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                           <input type="checkbox"
                           value="Normal" 
                           checked={this.props.vals_examination.RightEyeEOM == "Normal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightEyeEOM")}
                           
                           /> Normal &nbsp;
                           <input type="checkbox" 
                            value="Abnormal" 
                            checked={this.props.vals_examination.RightEyeEOM == "Abnormal"}
                            onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightEyeEOM")}
                           
                           /> Abnormal
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                           <input type="checkbox"  
                            value="Normal" 
                            checked={this.props.vals_examination.LeftEyeEOM == "Normal"}
                            onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftEyeEOM")}
                            
                           /> Normal &nbsp;
                           <input type="checkbox" 
                            value="Abnormal" 
                            checked={this.props.vals_examination.LeftEyeEOM == "Abnormal"}
                            onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftEyeEOM")}
                            
                            /> Abnormal

                        </div>

            </div>

                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Fields</label>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                           <input type="checkbox"  
                             value="Normal" 
                             checked={this.props.vals_examination.RightFields == "Normal"}
                             onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightFields")}
                           /> Normal &nbsp;
                           <input type="checkbox" 
                             value="Abnormal" 
                             checked={this.props.vals_examination.RightFields == "Abnormal"}
                             onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightFields")}
                           /> Abnormal

                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                           <input type="checkbox"  
                           value="Normal" 
                           checked={this.props.vals_examination.LeftFields == "Normal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftFields")}
                           /> Normal &nbsp;
                           <input type="checkbox" 
                             value="Abnormal" 
                             checked={this.props.vals_examination.LeftFields == "Abnormal"}
                             onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftFields")}
                           />Abnormal

                        </div>


             </div>


                 <div className="col-sm-12">
                  <div className="col-sm-2" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Pupils</label>
                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                           <input type="checkbox"  
                           
                           value="Normal" 
                           checked={this.props.vals_examination.RightPupils == "Normal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightPupils")}


                           /> Normal &nbsp;
                           <input type="checkbox" 
                           
                           value="Abnormal" 
                           checked={this.props.vals_examination.RightPupils == "Abnormal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "RightPupils")}

                           /> Abnormal

                        </div>
                        <div className="col-sm-3" style={{float: 'left',marginTop: '15px'}}>
                        <input type="checkbox"  
                           
                           value="Normal" 
                           checked={this.props.vals_examination.LeftPupils == "Normal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftPupils")}


                           /> Normal &nbsp;
                           <input type="checkbox" 
                           
                           value="Abnormal" 
                           checked={this.props.vals_examination.LeftPupils == "Abnormal"}
                           onChange={e => this.props.handleOptionChange(e, "cns_examination", "LeftPupils")}

                           /> Abnormal

                        </div>

                     </div>
           </div>

                <div className="modal-footer" >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                       onClick={e => 
                        {this.props.handleSaveOptions(e, "cns");
                        close();}
                        }
                      >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
 </div>





)}
</Popup>
         );
    }
}

export default cns ;
