import React, { Component } from "react";
import Popup from "reactjs-popup";
import { api_visitnotes_list_options } from "./../../../../DS/DS.VisitsNotes";
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class general extends Component {
  state = {
    list_options: {},
  };
  componentDidMount() {
    this.props.visitnotes_list_options().then((res) => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }
  render() {
    return (
      <Popup
        style={{ minHeight: "500px" }}
        trigger={
          <a title="General" className="collapsed" aria-expanded="false">
            <i
              className="fa fa-heartbeat"
              style={{ fontSize: "20px", padding: "10px" }}
              aria-hidden="true"
            ></i>
          </a>
        }
        position="right center"
        modal
        closeOnDocumentClick={false}
      >
        {(close) => (
          <div className="general">
            <div
              className="modal-header"
              // style={{
              //   width: "100%",
              //   backgroundColor: "#ddd",
              //   padding: "5px 15px",
              // }}
            >
              <button
                type="button"
                className="c
                      lose"
                style={{
                  position: "absolute",
                  right: "10px",
                  fontSize: "25px",
                }}
                onClick={() => close()}
                data-dismiss="modal"
                aria-label="Close"
              >
                ×
              </button>
              <span> General <Showhelp gotohelp="general_help"/></span>
              {/* <h4
                style={{
                  backgroundColor: "#ddd",
                  float: "left",
                  width: "100%",
                  fontSize: "25px",
                  margintop: "-5px",
                }}
              >
                General
              </h4> */}
            </div>
            <div className="modal-body"
            style={{overflowY: "scroll", height: "450px", padding:'15px!important' }}
            >
              <h4 style={{}}>History:</h4>
              <div className="col-sm-12">
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Fever</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <label>
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Fever === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Fever")
                    }
                  />{" "}
                  Yes
                  </label>
                  <label>
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Fever === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Fever")
                    }
                  />{" "}
                  No
                  </label>
                </div>

                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Lathargy</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Lathargy === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Lathargy")
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Lathargy === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Lathargy")
                    }
                  />{" "}
                  No
                </div>
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Malaise</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Malaise === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Malaise")
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Malaise === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Malaise")
                    }
                  />{" "}
                  No
                </div>
              </div>
              <div className="col-sm-12">
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Anorexia</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Anorexia === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Anorexia")
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Anorexia === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Anorexia")
                    }
                  />{" "}
                  No
                </div>

                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Nausea</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Nausea === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Nausea")
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Nausea === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Nausea")
                    }
                  />{" "}
                  No
                </div>
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Giddiness</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.Giddiness === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Giddiness")
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.Giddiness === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "Giddiness")
                    }
                  />{" "}
                  No
                </div>
              </div>
              <div className="col-sm-12">
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Weight loss</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.WeightLoss === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "WeightLoss")
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.WeightLoss === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "WeightLoss")
                    }
                  />{" "}
                  No
                </div>

                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Weight gain</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.WeightGain === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "WeightGain")
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.WeightGain === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(e, "general", "WeightGain")
                    }
                  />{" "}
                  No
                </div>
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Recent travel</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals.RecentTravel === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general",
                        "RecentTravel"
                      )
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals.RecentTravel === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general",
                        "RecentTravel"
                      )
                    }
                  />{" "}
                  No
                </div>
              </div>
              &nbsp;
              <hr
                style={{
                  backgroundColor: "#ddd",
                  height: "1px",
                  border: "none",
                }}
              />
              <h4>Examination:</h4>
              <div className="col-sm-12">
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Clinically anaemic:</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals_examination.Clinically === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Clinically"
                      )
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals_examination.Clinically === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Clinically"
                      )
                    }
                  />{" "}
                  No
                </div>

                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Jaundiced</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals_examination.Jaundiced === "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Jaundiced"
                      )
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals_examination.Jaundiced === "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Jaundiced"
                      )
                    }
                  />{" "}
                  No
                </div>
                <div className="col-sm-4 "></div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Pulse:</label>
                </div>
                <div
                  className="col-sm-2 "
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    style={{ width: "70px" }}
                    value={this.props.vals_examination.pulse}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "pulse"
                      )
                    }
                  />
                </div>

                <div
                  className="col-sm-3 "
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <select
                    style={{ width: "100%" }}
                    value={this.props.vals_examination.PulseUnit}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "PulseUnit"
                      )
                    }
                  >
                    {this.state.list_options != undefined &&
                      this.state.list_options.pulse_option != null &&
                      this.state.list_options.pulse_option.map(
                        (item, index) => (
                          <option value={item.value}> {item.label} </option>
                        )
                      )}
                  </select>
                </div>
                <div className="col-sm-4 "></div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Blood Pressure:</label>
                </div>
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Sitting</label>
                </div>

                <div
                  className="col-sm-1 "
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    style={{ width: "70px" }}
                    value={this.props.vals_examination.BloodPressureSitting}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "BloodPressureSitting"
                      )
                    }
                  />
                </div>

                <div className="col-sm-1 " style={{}}>
                  <h3 style={{ marginTop: "15px", paddingLeft: "30px" }}>/</h3>
                </div>
                <div
                  className="col-sm-1 "
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    style={{ width: "70px" }}
                    value={
                      this.props.vals_examination.BloodPressureSittingRight
                    }
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "BloodPressureSittingRight"
                      )
                    }
                  />
                </div>
                <div className="col-sm-4 "></div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 " style={{ textAlign: "left" }}></div>
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "1px" }}>Standing</label>
                </div>

                <div
                  className="col-sm-1 "
                  style={{ float: "left", marginTop: "1px" }}
                >
                  <input type="text" style={{ width: "70px" }} />
                </div>

                <div className="col-sm-1 " style={{}}>
                  <h3 style={{ marginTop: "1px", paddingLeft: "30px" }}>/</h3>
                </div>
                <div
                  className="col-sm-1 "
                  style={{ float: "left", marginTop: "1px" }}
                >
                  <input type="text" style={{ width: "70px" }} />
                </div>
                <div className="col-sm-4 "></div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 " style={{ textAlign: "left" }}></div>
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "1px" }}>Lying</label>
                </div>

                <div
                  className="col-sm-1 "
                  style={{ float: "left", marginTop: "1px" }}
                >
                  <input type="text" style={{ width: "70px" }} />
                </div>

                <div className="col-sm-1 " style={{}}>
                  <h3 style={{ marginTop: "5px", paddingLeft: "30px" }}>/</h3>
                </div>
                <div
                  className="col-sm-1 "
                  style={{ float: "left", marginTop: "1px" }}
                >
                  <input type="text" style={{ width: "70px" }} />
                </div>
                <div className="col-sm-4 "></div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-1 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Temp:</label>
                </div>
                <div className="col-sm-2">
                  <input style={{ marginTop: "15px" }}
                    type="text" value={this.props.vals_examination.Temp}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Temp"
                      )
                    }
                  />
                </div>
                
                <div
                  className="col-sm-2 "
                  style={{ marginTop: "10px",marginLeft:'25px' }}
                >
                  <select style={{ width: "100%" }}
                   value={this.props.vals_examination.TempUnit}
                   onChange={(e) =>
                     this.props.handleOptionChange(
                       e,
                       "general_examination",
                       "TempUnit"
                     )
                   }>
                    <option value="Oral">Oral</option>
                    <option value="Axillary">Axillary</option>
                    <option value="Tympanic">Tympanic</option>
                    <option value="Rectal">Rectal</option>
                    <option value="Temporal artery">Temporal artery</option>
                  </select>
                </div>
                <div className="col-sm-1" style={{ textAlign: "left", marginTop: "15px" }}>
                  <input
                    type="text"
                    style={{ width: "70px", display:'none' }}
                    value={this.props.vals_examination.pulse}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "pulse"
                      )
                    }
                  />
                  Resp.rate
                </div>
                <div
                  className="col-sm-3"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    value={this.props.vals_examination.RespRate}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "RespRate"
                      )
                    }
                  />
                </div>
                
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-1" style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Weight(kg): </label>
                </div>
                <div
                  className="col-sm-1"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    style={{ width: "70px" }}
                    value={this.props.vals_examination.WeightKG}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "WeightKG"
                      )
                    }
                  />
                </div>
                <div className="col-sm-1" style={{ textAlign: "left" }}></div>

                <div className="col-sm-1" style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Height(cm): </label>
                </div>
                <div
                  className="col-sm-1"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    style={{ width: "70px" }}
                    value={this.props.vals_examination.HeightCM}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "HeightCM"
                      )
                    }
                  />
                </div>
                <div className="col-sm-1" style={{ textAlign: "left" }}></div>
                <div className="col-sm-1" style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>BSL:</label>
                </div>
                <div
                  className="col-sm-1"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    style={{ width: "70px" }}
                    value={this.props.vals_examination.BSL}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "BSL"
                      )
                    }
                  />
                </div>

                <div
                  className="col-sm-3"
                  style={{
                    float: "left",
                    marginTop: "10px",
                    paddingLeft: "30px",
                  }}
                >
                  <select style={{ width: "100%" }}
                   value={this.props.vals_examination.BSLUnit}
                   onChange={(e) =>
                     this.props.handleOptionChange(
                       e,
                       "general_examination",
                       "BSLUnit"
                     )
                   }>
                    <option value="Fasting">Fasting</option>
                    <option value="Non-Fasting">Non-Fasting</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2" style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>
                    Waist measurement:
                  </label>
                </div>
                <div
                  className="col-sm-1"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    style={{ width: "70px", paddingRight: "30px" }}
                    value={this.props.vals_examination.WaistMeasurement}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "WaistMeasurement"
                      )
                    }
                  />
                </div>
                <div className="col-sm-1" style={{ textAlign: "left" }}></div>

                <div className="col-sm-2" style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Hip measurement:</label>
                </div>
                <div
                  className="col-sm-1 "
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    style={{ width: "70px", paddingRight: "30px" }}
                    value={this.props.vals_examination.HeadMeasurement}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "HeadMeasurement"
                      )
                    }
                  />
                </div>
                <div className="col-sm-3" style={{ textAlign: "left" }}></div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>Dehydration</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="checkbox"
                    value="Yes"
                    checked={this.props.vals_examination.Dehydration == "Yes"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Dehydration"
                      )
                    }
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="checkbox"
                    value="No"
                    checked={this.props.vals_examination.Dehydration == "No"}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "Dehydration"
                      )
                    }
                  />{" "}
                  No
                </div>

                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input type="text" style={{ width: "70px" }} /> %
                </div>

                <div className="col-sm-6" style={{}}></div>
              </div>
              <div className="col-sm-12 ">
                <div className="col-sm-2 " style={{ textAlign: "left" }}>
                  <label style={{ marginTop: "15px" }}>02 Saturation:</label>
                </div>
                <div
                  className="col-sm-2"
                  style={{ float: "left", marginTop: "15px" }}
                >
                  <input
                    type="text"
                    style={{ width: "70px" }}
                    value={this.props.vals_examination.O2Saturation}
                    onChange={(e) =>
                      this.props.handleOptionChange(
                        e,
                        "general_examination",
                        "O2Saturation"
                      )
                    }
                  />{" "}
                  %
                </div>

                <div className="col-sm-8" style={{}}></div>
              </div>
            </div>
            <div className="modal-footer" 
            // style={{ marginTop: "450px" }}
            >
              <button
                type="button"
                className="btn btn-primary btn-xs"  data-dismiss="modal"
                onClick={(e) => {
                  this.props.handleSaveOptions(e, "general_popup");
                  close();
                }}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary btn-xs"  onClick={() => close()}
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    visitnotes_list_options: (data) =>
      dispatch(api_visitnotes_list_options(data)),
  };
};

export default connect(null, mapDispatchToProps)(general);
