import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_visitnotes_list_options} from './../../../../DS/DS.VisitsNotes';
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class gynae extends Component {
  state = { 
    list_options: {}
  }
  componentDidMount() {
    this.props.visitnotes_list_options().then(res => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }
    render() {
        return (
            <Popup trigger={<a  title="Gynae" className="collapsed" aria-expanded="false"><i className="fa fa-heart" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>} position="right center"   modal
            closeOnDocumentClick={false}
           >
             {close => (

                 <div className="eyelay">

               <div className="modal-header" 
              //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
               >

                      <button type="button" className="c
                      lose" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> Gyane <Showhelp gotohelp="gyane_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Eye</h4> */}
                    </div>
                    <div className="modal-body"   style={{overflowY: "scroll", height: "450px" }}>
                        <h4 style={{}}>History:</h4>


                        <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Pv bleeding</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PvBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PvBleeding")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PvBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PvBleeding")}
                    /> No
                      &nbsp; &nbsp;
                    </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Pv discharge</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PvDischarge === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PvDischarge")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PvDischarge === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PvDischarge")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Amenorrhoea</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Amenorrhoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Amenorrhoea")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Amenorrhoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Amenorrhoea")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Dysmenorrhoea</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Dysmenorrhoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Dysmenorrhoea")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Dysmenorrhoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Dysmenorrhoea")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Vulval Itch</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.VulvalItch === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "VulvalItch")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.VulvalItch === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "VulvalItch")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                 
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Intermenstrutal Bleeding</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.IntermenstrutalBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "IntermenstrutalBleeding")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.IntermenstrutalBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "IntermenstrutalBleeding")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Post-Coital Bleeding </label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PostCoitalBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PostCoitalBleeding")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PostCoitalBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PostCoitalBleeding")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                   
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Post Menopausal Bleeding</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PostMenopausalBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PostMenopausalBleeding")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PostMenopausalBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PostMenopausalBleeding")}
                    /> No
                      &nbsp; &nbsp;
                      </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                    <label style={{marginTop: '15px'}}> Dyspareunia</label>
                  </div>
                  <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Dyspareunia === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Dyspareunia")}
                    /> Yes 
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Dyspareunia === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "Dyspareunia")}
                    /> No
                   </div>
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                    <label style={{marginTop: '15px'}}> Unprotected Intercourse</label>
                  </div>
                  <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.UnprotectedIntercourse === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "UnprotectedIntercourse")}
                    /> Yes 
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.UnprotectedIntercourse === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "UnprotectedIntercourse")}
                    /> No
                   </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                    <label style={{marginTop: '15px'}}>Heavy Periods</label>
                  </div>
                  <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.HeavyPeriods === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "HeavyPeriods")}
                    /> Yes 
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.HeavyPeriods === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "HeavyPeriods")}
                    /> No
                   </div>
              
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                    <label style={{marginTop: '15px'}}>Irregular Period</label>
                  </div>
                  <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.IrregularPeriod === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "IrregularPeriod")}
                    /> Yes 
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.IrregularPeriod === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "IrregularPeriod")}
                    /> No
                   </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                    <label style={{marginTop: '15px'}}>Pregnancy Test Positive</label>
                  </div>
                  <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PregnancyTestPositive === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PregnancyTestPositive")}
                    /> Yes 
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PregnancyTestPositive === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae", "PregnancyTestPositive")}
                    /> No
                   </div>
                </div>


                &nbsp;
                <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                <h4 style={{}}>Examination:</h4>

                &nbsp;

                
               
               <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Vaginal Discharge</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.VaginalDischarge == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "VaginalDischarge")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox" 
                      value="No"
                      checked={this.props.vals_examination.VaginalDischarge == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "VaginalDischarge")}
                    /> No
                      &nbsp; &nbsp;
                    </div>
                    
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Cervix Suspicious</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CervixSuspicious == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervixSuspicious")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.CervixSuspicious == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervixSuspicious")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>CST Performed</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CSTPerformed == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CSTPerformed")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.CSTPerformed == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CSTPerformed")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Cervical Polyp</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CervicalPolyp == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervicalPolyp")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.CervicalPolyp == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervicalPolyp")}
                    /> No
                      &nbsp; &nbsp;
                  
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Cervical Ectopy</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.CervicalEctopy == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervicalEctopy")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Pterygium == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "CervicalEctopy")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Cystocele</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Cystocele == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "Cystocele")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Cystocele == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "Cystocele")}
                    /> No
                      &nbsp; &nbsp;
                  
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Rectocele</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Rectocele == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "Rectocele")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Rectocele == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "Rectocele")}
                    /> No
                      &nbsp; &nbsp;
                        </div>
                
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Bulky Uterus</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.BulkyUterus == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "BulkyUterus")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.BulkyUterus == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "BulkyUterus")}
                    /> No
                      &nbsp; &nbsp;
                        </div>

                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Uterine Tenderness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.UterineTenderness == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "UterineTenderness")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.UterineTenderness == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "UterineTenderness")}
                    /> No
                      &nbsp; &nbsp;
                        </div>

                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Retroverted Uterus</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.RetrovertedUterus == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "RetrovertedUterus")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RetrovertedUterus == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "RetrovertedUterus")}
                    /> No
                      &nbsp; &nbsp;
                 
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Forniceal Tenderness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.FornicealTenderness == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "FornicealTenderness")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.FornicealTenderness == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "FornicealTenderness")}
                    /> No
                      &nbsp; &nbsp;
                  
                        </div>

                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Forniceal Mass</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.FornicealMass == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "FornicealMass")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.FornicealMass == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gynae_examination", "FornicealMass")}
                    /> No

                        </div>

                      
      




            </div>

</div>


                <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                       onClick={e => 
                        {this.props.handleSaveOptions(e, "gynae");
                        close();}
                        }
                        >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>


            </div>

)}
</Popup>
         );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    visitnotes_list_options: data => dispatch(api_visitnotes_list_options(data)),
    
  };
};

export default connect(null, mapDispatchToProps)(gynae);