import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Button,
    CardTitle,
    CardText,
    Row,
    Col,
  } from "reactstrap";
  import classnames from "classnames";
import Demographic  from './demographic'
import DailyLiving from './dailyLiving'
import RiskFactors from './riskFactors';
import Showhelp from './../../showHelp';

class DimentiaCare extends Component {
    constructor(props)
    {
      super(props);
      this.state={
        addValues:false,
        key:0,
        dibedata:[],
        setDate:false,
        open:this.props.open,
        activeTab: "1",
        tabId:1

        
      }
    }
    componentWillMount=()=>
    {
     
    }
    showRef=()=>
    {
        confirmAlert({
            title: 'Reference',
            message: "Supadoc acknowledges the assistance od Prof Henry Brodaty and Dr Allan Shell, from the Dimentia Collaborative Research Centre at UNSW , as well as Alzheimer's Australia NQDCI funding, in the development of the Dementia Assessment tool now available to all Supadoc customers",
            buttons: [
            {
                label: 'OK',
            },
       
      ],
    });
    }
    toggle=(val)=>
    {
        this.setState({activeTab:val,tabId:val})
    }
    render() {
        return (
            <Popup   position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
             {close => (
              <div> 
                  <div className="modal-header" style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}>
                    <button type="button" className="close" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => this.props.changeKey()} data-dismiss="modal" aria-label="Close">
                      ×
                    </button>
                    <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Dementia Assessment <Showhelp gotohelp="add_dementia_assessment_help"/></h4>
                  </div>
                  <div className="modal-body" style={{overflowY: 'scroll',height:'780px'}}>
                  <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="2">
                            <div
                            className="modal-header">
                            <button
                                type="button"
                                className="close"
                                style={{
                                position: "absolute",
                                right: "10px",
                                fontSize: "25px",
                                }}
                                onClick={() =>this.props.changeKey()}
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 class="popup-title visitpop" style={{textAlign:"left"}}>
                                Copies
                            </h4>
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div
                            className="modal-header">
                            <button
                                type="button"
                                className="close"
                                style={{
                                position: "absolute",
                                right: "10px",
                                fontSize: "25px",
                                }}
                                onClick={() => this.props.changeKey()}
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 class="popup-title visitpop" style={{textAlign:"left"}}>
                                Previous Request
                            </h4>
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <div
                            className="modal-header">
                            <button
                                type="button"
                                className="close"
                                style={{
                                position: "absolute",
                                right: "10px",
                                fontSize: "25px",
                                }}
                                onClick={() => this.props.changeKey()}
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 class="popup-title visitpop" style={{textAlign:"left"}}>
                                Previous Result
                            </h4>
                            </div>
                        </TabPane>
                        <TabPane tabId="5">
                            <div
                            className="modal-header">
                            <button
                                type="button"
                                className="close"
                                style={{
                                position: "absolute",
                                right: "10px",
                                fontSize: "25px",
                                }}
                                onClick={() => this.props.changeKey()}
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            <h4 class="popup-title visitpop" style={{textAlign:"left"}}>
                                Setup Favourites
                            </h4>
                            </div>
                        </TabPane>
                    </TabContent>
                    <Row>
                    <div className="col-sm-3">
                        <Nav tabs vertical pills style={{ border: "none" }}>
                        <NavItem>
                            <NavLink
                            className={classnames({
                                active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                                this.toggle("1");
                            }}
                            >
                            <a
                                href="#"
                                title="Demographic"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#Pathologypop"
                                className="collapsed"
                                aria-expanded="false"
                                style={{fontSize:'15px'}}
                            >
                                <i
                                className="fas fa-id-card"
                                style={{ fontSize: "32px", padding: "10px" }}
                                aria-hidden="true"
                                />
                                Demographic
                            </a>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({
                                active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                                this.toggle("2");
                            }}
                            >
                            <a
                                href="#"
                                title="Daily Living"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#copiespath"
                                className="collapsed"
                                aria-expanded="false"
                                style={{fontSize:'15px'}}
                            >
                                <i
                                className="fas fa-house-user"
                                style={{ fontSize: "32px", padding: "10px" }}
                                aria-hidden="true"
                                />
                                Daily Living
                            </a>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({
                                active: this.state.activeTab === "3",
                            })}
                            onClick={() => {
                                this.toggle("3");
                            }}
                            >
                            <a
                                href="#"
                                title="Risk Factors"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#previousrequests"
                                className="collapsed"
                                aria-expanded="false"
                                style={{fontSize:'15px'}}
                            >
                                <i
                                className="far fa-user-nurse"
                                style={{ fontSize: "32px", padding: "10px" }}
                                aria-hidden="true"
                                />
                                Risk Factors
                            </a>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({
                                active: this.state.activeTab === "4",
                            })}
                            onClick={() => {
                                this.toggle("4");
                            }}
                            >
                            <a
                                href="#"
                                title="Review"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#previousresults"
                                className="collapsed"
                                aria-expanded="false"
                                style={{fontSize:'15px'}}
                            >
                                <i
                                className="fas fa-file-signature"
                                style={{ fontSize: "32px", padding: "10px" }}
                                aria-hidden="true"
                                />
                                Review
                            </a>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({
                                active: this.state.activeTab === "5",
                            })}
                            onClick={() => {
                                this.toggle("5");
                            }}
                            >
                            <a
                                href="#"
                                title="Hisotry and Examination"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#setupfavourites"
                                className="collapsed"
                                aria-expanded="false"
                                style={{fontSize:'15px'}}
                            >
                                <i
                                className="fas fa-stethoscope"
                                style={{ fontSize: "32px", padding: "10px" }}
                                aria-hidden="true"
                                />
                            </a>
                            History and Examination
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({
                                active: this.state.activeTab === "5",
                            })}
                            onClick={() => {
                                this.toggle("5");
                            }}
                            >
                            <a
                                href="#"
                                title="Education"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#setupfavourites"
                                className="collapsed"
                                aria-expanded="false"
                                style={{fontSize:'15px'}}
                            >
                                <i
                                className="fas fa-user-graduate"
                                style={{ fontSize: "32px", padding: "10px" }}
                                aria-hidden="true"
                                />
                            </a>
                           Education
                            </NavLink>
                        </NavItem>
                        </Nav>
                    </div>
                    <div className="col-sm-9">
                       
                        <TabContent activeTab={this.state.activeTab}>
                       <div className="col-sm-12">
                       <div className="col-sm-3">
                       
                        <label style={{marginTop:'15px'}}>  
                          <input type="checkbox" className="" style={{float: 'left', width: 'auto', marginLeft:'5px', marginRight:'5px'}}  
                         />Consent obtained</label>
                       </div>
                       <div className="col-sm-6">
                       
                         <label>  
                             Assesment Location
                            <select >
                                <option value="Surgery">Surgery</option>
                                <option value="Home">Home</option>
                            </select>
                        </label>
                            </div>
                        </div>
                        <TabPane tabId="1">
                         <Demographic/>
                        </TabPane>
                        <TabPane tabId="2">
                         <DailyLiving/>
                        </TabPane>
                        <TabPane tabId="3">
                         <RiskFactors/>
                        </TabPane>
                        <TabPane tabId="4">
                            {/*}<Previousresult
                            statecontent={this.state}
                            type={this.props.type}
                        />*/}
                        </TabPane>
                        <TabPane tabId="5">
                        {/*} <Setupfav
                            statecontent={this.state}
                            type={this.props.type}
                            deleteLab={this.deleteLab}
                            deleteTest={this.deleteTest}
                            deleteGroup={this.deleteGroup}
                            handleAddText={this.handleAddText}
                            handleAddGroup={this.handleAddGroup}
                            handleAdd={this.handleAddText}
                            toggleFav={this.toggleFav}
                            addnewtest={this.addnewtest}
                            addnewgroup={this.addnewgroup}
                            list_options={this.list_options}
                    />*/}
                        </TabPane>
                        </TabContent>
                    </div>
                    </Row>
                    <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        {/* <div className="modal-footer" style={{ marginTop: "30px" }}>
                        
                        <button type="button" className="btn btn-default" style={{marginRight:'2px',width:'70px',height:'35px'}}  data-dismiss="modal" onClick={() => close()} aria-label="Close">Cancel</button>
                        </div> */}
                    </TabPane>
                    <TabPane tabId="2">
                     
                    </TabPane>
                    <TabPane tabId="3">
                        <div className="modal-footer" style={{ marginTop: "40px" }}>
                        <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                            marginRight: "5px",
                            width: "70px",
                            height: "35px",
                            }}
                            onClick={(e) => {
                            this.savePathology(2);
                            }}
                            data-dismiss="modal"
                        >
                            Print
                        </button>
                        </div>
                    </TabPane>
                    <TabPane tabId="4">
                        <div className="modal-footer" style={{ marginTop: "45px" }}>
                        {/* <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                            marginRight: "5px",
                            width: "70px",
                            height: "35px",
                            }}
                            onClick={(e) => {
                            this.savePathology();
                            }}
                            data-dismiss="modal"
                        >
                            Print
                        </button> */}
                        </div>
                    </TabPane>
                    <TabPane tabId="5">
                        <div className="modal-footer" style={{ marginTop: "5px" }}>
                        {/* <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                            marginRight: "5px",
                            width: "70px",
                            height: "35px",
                            }}
                            data-dismiss="modal"
                        >
                            Print
                        </button> */}
                        <button
                            type="button"
                            className="btn btn-primary btn-xs"  data-dismiss="modal"
                            onClick={() => this.props.changeKey()}
                            aria-label="Close"
                        >
                            Cancel
                        </button>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
            <div className="modal-footer"  style={{marginTop:'0px'}}>
                  <button type="button" className="btn btn-primary btn-xs" onClick={()=>this.showRef()}>Reference</button>
                  <button type="button" className="btn btn-primary btn-xs">Preview</button>
                  <button type="button" className="btn btn-primary btn-xs">Print</button>
                  <button type="button" className="btn btn-primary btn-xs">Save</button>
                  <button type="button" className="btn btn-primary btn-xs"  data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>Cancel</button>
                </div>
              </div>
            )}
        </Popup>
      )}
  }
/*const mapDispatchToProps = (dispatch) => {
	return {
        getDiabetic: data => dispatch(api_get_diabetic(data)),
        addReminder: data => dispatch(api_add_reminder(data))
  }
}*/
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, null) (DimentiaCare);

