import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager} from 'react-notifications';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw  } from "draft-js";
import {stateToHTML} from 'draft-js-export-html';
import {api_add_certificate,api_get_detail} from  '../../../../DS/DS.MedicalCertificate';
import jsPDF from 'jspdf';
import './certificate.css';
import htmlToDraft from 'html-to-draftjs';
import cicon from "../../../../assets/img/logo-white.png";
import moment from 'moment';
import SendMail from './sendMail'
import Showhelp from './../../../showHelp';

class InsertViewFile extends Component {
  constructor(props) {
    super(props);
     this.state={
      open:this.props.showModal,
      editorState: '',
      reason:'',
      defhtml:'',
      issuedate:new Date(),
      certi:'',
      addressData:'',
      sendModal:false,
      key:0,
      words:''
    }
  }

  componentWillMount=()=>{
    console.log("*************",this.props.certificate)
    if(this.props.certficate==1)
    {
      this.setState({certi:'Work',words:'for'})
    }
    else if(this.props.certficate==2)
    {
      this.setState({certi:'School',words:'to attend'})
    }
    else{
      this.setState({certi:'University',words:'to attend'})
    }
    console.log(this.props.redux_patient)
    const htm = '<p><img src='+cicon+'/><div style="text-align:center">Medical Center Name </div><div style="text-align: right">Dr. '+this.props.redux_docData.Firstname+'.'+this.props.redux_docData.Surname+'<br>Medical center address</div><br><div style="text-align:center"><strong>Medical Certificate ['+moment(new Date(this.state.issuedate)).format('DD-MM-YYYY')+']</strong></div><br><div>This is to certify that '+this.props.redux_patient.Firstname +'.'+this.props.redux_patient.Surname+' has a medical condition and will be unfit to attend '+this.state.certi+ 'from '+moment(new Date(this.props.startdate)).format('DD-MM-YYYY')+' to '+moment(new Date(this.props.enddate)).format('DD-MM-YYYY')+ ' inclusive</div><br><br><divstyle="text-align:left">Dr. '+this.props.redux_docData.Firstname+'.'+this.props.redux_docData.Surname+'</div></p>'
   
    //const htm=''

    const contentBlock = htmlToDraft(htm);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

  
  
    this.setState({editorState: editorState})
    const doc={docid:this.props.redux_docId}
    this.setState({addressData:this.props.redux_docData.Address1})
   /* this.props.getdetail(doc).then(
      req=>{this.setState({addressData:req.data.content.address_line})
    console.log(">>>>>>>>>>>",req.data.content)
    }
    )*/

  }
  onEditorStateChange = editorState => {
    console.log("editor notes", editorState);
  this.setState({
    editorState
  });
  
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
  let html = stateToHTML(editorState.getCurrentContent());
  console.log(html)
  this.setState({reason:html})
};
  
  clickedData=()=>
  {
    const savedata={
      patientid:this.props.redux_patientId,
      startdate:this.props.startdate,
      enddate:this.props.enddate,
      reason:this.state.reason,
      doc_id:this.props.redux_docId,
      cer_type:this.props.certificate
    }
    this.props.savecertificate(savedata).then(
      req=>{
        if(req.data.status=='success')
        {
          if(this.props.pagetype=='view')
          {
            this.props.changeKey()
          }
          if(this.props.pagetype=='print')
          {
            let data = EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML('<p>' + this.state.reason+ '</p>')
              ));
              var doc=new jsPDF();
              var docs=document.getElementById('certificate');
              
              doc.fromHTML(docs,10,10)
              console.log(doc);
          //    window.print()
              doc.save("medicalCertificate.pdf")
              this.props.changeKey()

             /* var content = document.getElementById('certificate');
              var pri = document.getElementById('toprint').contentWindow;
              pri.document.open();
              pri.document.write(content.innerHTML);
              pri.document.close();
              pri.focus();
              pri.print();*/
           
          }
        }
      }
       //  alert("IN")}
    )
  }
  print=()=>{
    var doc=new jsPDF();
    var docs=document.getElementById('certificate');
    console.log(docs);
  //  doc.fromHTML(docs,10,10)
    //    doc.save("generated.pdf")
    doc.fromHTML(docs, 0, 0, {
      'width': 100 // max width of content on PDF
    //  'elementHandlers': specialElementHandlers
  },
  function(bla){doc.save('saveInCallback.pdf');},
  10);
  }
  reloadPage=()=>
  {
      this.setState({key:Math.random(),sendModal:false})
  }
  render() {
    return (
      <Popup open={this.state.open} closeOnDocumentClick={false} position="right center"   modal >
        {close => (
            <div>
		        <div class="modal-header">
            <h6 style={{float:'left', fontSize: '16px', padding:'5px 10px'}}> Medical Certificates <Showhelp gotohelp="insert_certificate_help"/> </h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()}>
                            <span aria-hidden="true">×</span>
                        </button>
                </div>
                <div class="modal-body" style={{height:'500px'}} id="certificate" ref="certificate">
                  <div id="toprint" ref="toprint" style={{margin:'10px'}}>
                  <div class="row">
                    <div class="col-md-6" style={{ left: '0px',width: '50%'}}>
                      <div style={{top: '50%',width: '100%',padding: '20px'}}>
                          <img src={cicon} />
                      </div>
                    </div>
                    <div class="col-md-6" style={{right: '0px',width: '50%'}}>
                      <div style={{top: '50%',width: '100%',padding: '20px' ,marginTop: '20px',textAlign: 'right'}}>
                          <span>Dr. {this.props.redux_docData.Firstname}. {this.props.redux_docData.Surname}</span><br/>
                          <span>{this.state.addressData}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div style={{textAlign:'center',fontWeight: 'bold',fontSize:'16px'}}>Medical Certificate</div>
                  </div>
                  <div class="row" style={{marginLeft: '20px',marginTop: '12px'}}>
                    <div style={{textAlign:'left'}}>{moment(new Date(this.state.issuedate)).format('DD-MM-YYYY')}</div>
                  </div><br/><br/>
                  <div class="row" style={{marginLeft: '20px',marginTop: '12px'}}>
                          <div style={{textAlign:'center'}}>THIS IS TO CERTIFY THAT</div>
                  </div>
                  <div class="row" style={{marginLeft: '20px',marginTop: '12px'}}>
                      <div >{this.props.redux_patient.Firstname} {this.props.redux_patient.Surname} has a medical condition and will be unfit {this.state.words}  {this.state.certi} from &nbsp;&nbsp;  {moment(new Date(this.props.startdate)).format('DD-MM-YYYY')} &nbsp;&nbsp;   to &nbsp;&nbsp; 
                            {moment(new Date(this.props.enddate)).format('DD-MM-YYYY')} &nbsp;inclusive</div>
                  </div><br/><br/>
                  <div class="row" style={{marginLeft: '20px',marginTop: '15px'}}>
                      <div>Dr {this.props.redux_docData.Firstname} {this.props.redux_docData.Surname}</div>
                  </div>
                  </div>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" data-toggle="modal" data-target="#mcertificate1"  onClick={()=>this.print()}> Print</button>
                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" data-toggle="modal" data-target="#sendmail" onClick={()=>this.setState({sendModal:true})}>Email</button>
                <button type="button" class="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.clickedData()}>Save</button>
                <button type="button" class="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>this.props.changeKey()}>Cancel</button>
                </div>
                {this.state.sendModal ?
            <SendMail showModal={this.state.sendModal} changeKey={this.reloadPage}/> : ''}
            </div>
        )}
                {/*}  <Editor
                                  name={this.state.reason}
                                  editorState={this.state.editorState}
                                  onEditorStateChange={this.onEditorStateChange}
                    /> */}
		          
       </Popup>
    )}
} 
const mapDispatchToProps = dispatch => {
    return {
      savecertificate:data=>dispatch(api_add_certificate(data)),
      getdetail:data=>dispatch(api_get_detail(data))
         };
  };
  const mapStateToProps = state => {
  
    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
    let redux_docData  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0]:'';
    let redux_patient  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0]:'';
   console.log(">>>",redux_docData)
    return {
        redux_patientId,redux_docId,redux_docData,redux_patient
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(InsertViewFile);


