import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import { api_getTitle,api_getGender} from "../../../DS/DS.Appointments";
import DatePicker from "react-datepicker";
import {get_selected_states} from '../../../DS/DS.EPC'
 class addDemographics extends Component {
    constructor(props)
    {
        super(props);
        this.state=
        {
          key:0,
          list_title:[],
          list_gender:[],
          date_of_birth:new Date(),
          list_countries:[],
          selectedCountry:'Aboriginal'
        }
    }
    componentWillMount=()=>
    {
        this.props.getTitle().then(res=>
            {
              this.setState({list_title: res.data.content})
            })
            
        this.props.getGender().then(res=>
            {
             this.setState({list_gender: res.data.content})
            })    
        
            this.props.getCountry().then(res=>
                {
                    console.log("&&&&&&&&&&&&&&&&&&&&&&&&&",res.data.content)

                    this.setState({list_countries: res.data.content})
                })
    }
    reloadPage=()=>
    {
        this.setState({showcarer:false})
        this.setState({key:Math.random()})
    }
    handleRadio=(val,e)=>
    {
        this.setState({elite_athelte:val})
    }
    handleSafeRadio=(val,e)=>
    {
        if(val==2)
        {
            alert("Ring the ntional Sexual Assault,Domestic Family Violence Counselling service on 1800RESPECT (1800 737 732) or visit their website to find a local service: 'https://www.1800respect.org.au/'")
            this.setState({is_safe:val})
        }
        else
        {
            this.setState({is_safe:val})
        }

    }
    saveHandler=()=>
    {
        const values={
            marital_status:this.state.marital_status,
            sexuality:this.state.sexuality,
            elite_athelte:this.state.elite_athelte,
            healt_directive:this.state.healt_directive,
            attorney:this.state.attorney,
            on_screen:this.state.on_screen,
            recreational_act:this.state.recreational_act,
            accomodation:this.state.accomodation,
            lives_with:this.state.lives_with,
            has_carer:this.state.has_carer,
            is_carer:this.state.is_carer,
            is_safe:this.state.is_safe,
            other_history:this.state.other_history,
            patient_id:this.props.redux_patientId,
            doc_id:this.props.doc_id
        }
        this.props.apiAddSocial({values}).then(
            req=>
            {
                NotificationManager.success('Social history saved for the patient')
            }
        )
    }
    handleChnage=(e)=>
    {
        this.setState({has_carer:e.target.value})
        if(e.target.value==2)
        {
            this.setState({disable_btn:true})
        }
        else
        {
            this.setState({disable_btn:false})
        }

    }
    render() { 
        return ( 
            <div className="col-sm-12 mb-1 mt-1">
                    <div style={{padding:'10px 10px 10px 0', overflowY: 'auto' }}>
                        <div className="col-sm-12"  >
                            <div className="col-sm-1 col-padding" style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>Title:</label>
                            </div>
                            <div className="col-sm-3 col-padding" style={{textAlign:'left'}}>
                                <select class="form-control" value={this.state.title}
                                onChange={(e)=>this.setState({title:e.target.value})}>
                                    {this.state.list_title.map((item, index) => (
                                  <option
                                    value={item.value}
                                    selected={this.state.title === item.value}
                                    key={index}
                                  >{item.display}</option>
                                  ))}
                                </select>
                            </div> 
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-1 col-padding" style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>Surname:</label>
                            </div>
                            <div className="col-sm-7 col-padding" style={{textAlign:'left'}}>
                            <input type="text"  class="form-control"
                                value={this.state.surname} onChange={(e)=>this.setState({surname:e.target.value})}></input>
                      
                            </div> 
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-1 col-padding" style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>First name:</label>
                            </div>
                            <div className="col-sm-7" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.firstname} onChange={(e)=>this.setState({firstname:e.target.value})}></input>
                                     
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="col-sm-1 " style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>Middle name:</label>
                            </div>
                            <div className="col-sm-7 " style={{textAlign:'left'}}>
                            <input type="text"  class="form-control"
                                value={this.state.middlename} onChange={(e)=>this.setState({on_screen:e.target.value})}></input>
                      
                            </div>
                        </div>
                        
                        <div className="col-sm-12">
                            <div className='col-sm-4 col-padding'>
                                <label style={{marginTop:'20px'}}>Prefferred name:</label>
                            </div>
                            <div className='col-sm-6 col-padding'>
                                <input type="text"  class="form-control"
                                value={this.state.prefferedname} onChange={(e)=>this.setState({prefferedname:e.target.value})}></input>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className='col-sm-2 col-padding'>
                                <label style={{marginTop:'20px'}}>Date of Birth</label>
                            </div>
                            <div className='col-sm-2 col-padding'>
                                <DatePicker
                                    selected={this.state.date_of_birth}
                                    onChange={this.handleDate}
                                    dateFormat="dd/MM/yyyy"
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                />     
                            </div>
                            <div className="col-sm-1 " style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>Age:</label>
                            </div>
                            <div className='col-sm-2 col-padding'>
                                <input type="text"  class="form-control"
                                value={this.state.age}></input>
                            </div>
                            <div className="col-sm-1 " style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>Sex:</label>
                            </div>
                            <div className="col-sm-2 ">
                                <select
                                    type="text"
                                    value={this.state.sex}
                                    class="form-control form-control-sm"
                                    onChange={e =>
                                        this.handleFieldValueChanges("sex", e.target.value)
                                    }
                                    >
                                    {
                                        this.state.list_gender.map((item, index) => (
                                        <option
                                            value={item.value}
                                            selected={this.state.sex === item.value}
                                            key={index}
                                        >
                                            {item.display}
                                        </option>
                                        ))}
                                </select>
                            </div>
                           
                        </div>

                        <div className="col-sm-12">
                            <div className="col-sm-1 " style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>ATSI:</label>
                            </div>
                            <div className="col-sm-3 " style={{textAlign:'left'}}>
                                <select class="form-control" value={this.state.selectedCountry}
                                    onChange={(e)=>this.setState({selectedCountry:e.target.value})}>
                                        {this.state.list_countries.map((item, index) => (
                                        <option
                                        value={item.state_name}
                                        selected={this.state.selectedCountry === item.state_name}
                                        key={index}
                                        >{item.state_name}</option>
                                    ))}
                                    <option value="Other">Other</option>
                                    </select>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-3" style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>Address Line 1:</label>
                            </div>
                            <div className="col-sm-8" style={{textAlign:'left'}}>
                                <input type="text"  class="form-control"
                                value={this.state.addressline1}></input>
                            </div>
                            
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-3" style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>Address Line 2:</label>
                            </div>
                            <div className="col-sm-8" style={{textAlign:'left'}}>
                                <input type="text"  class="form-control"
                                value={this.state.addressline2}></input>
                            </div>
                            
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-3" style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>City/Suburb</label>
                            </div>
                            <div className="col-sm-3" style={{textAlign:'left'}}>
                                <input type="text"  class="form-control"
                                value={this.state.city}></input>
                            </div>
                            <div className="col-sm-3" style={{textAlign:'left'}}>
                                <label style={{marginTop:'20px'}}>Postcode</label>
                            </div>
                            <div className="col-sm-3" style={{textAlign:'left'}}>
                                <input type="text"  class="form-control"
                                value={this.state.postcode}></input>
                            </div>
                            
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Home Phone</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.honephone}></input>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Work Phone</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.wrkphone}></input>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Mobile Phone</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.monilephone}></input>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-4" style={{float:'left'}}>
                                <label style={{marginTop: '20px'}}> E-mail</label>
                            </div>
                            <div className="col-sm-8">
                            <input type="text"  class="form-control"
                                value={this.state.email}></input>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Medicare No.</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.honephone}></input>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Line</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.wrkphone}></input>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Medicare expiry</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.monilephone}></input>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Pension/HCC No.</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.honephone}></input>
                            </div>
                            <div className="col-sm-3" style={{marginTop:''}}>
                              <select>
                                  <option>Pensioner Concession</option>
                                  <option>Health Care</option>
                                  <option>Commonwealth</option>
                              </select>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Pension expiry</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.monilephone}></input>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>DVA No.</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.honephone}></input>
                            </div>
                            <div className="col-sm-3" style={{marginTop:''}}>
                              <select>
                                  <option>Gold</option>
                                  <option>White</option>
                                  <option>Lilac</option>
                                  <option>Orange</option>
                              </select>
                            </div>
                            
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Record No.</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.honephone}></input>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <label style={{marginTop: '20px'}}>Religion</label>
                            </div>
                            <div className="col-sm-2" style={{marginTop:''}}>
                                <input type="text"  class="form-control"
                                value={this.state.honephone}></input>
                            </div>
                            
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-6" style={{float:'left'}}></div>
                                <div className="col-sm-6" align="right" style={{float:'right', marginTop:'10px',marginBottom:'15px'}}>
                                    <button className="btn btn-primary btn-xs" onClick={()=>this.saveHandler()}>Save</button>
                                  {this.props.showCancel=='false' ?'':
                                    <button className="btn btn-primary btn-xs" onClick={()=>this.reloadPage()}>Cancel</button>}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                );
            }
    }
  
const mapDispatchToProps = dispatch => {
    return {
        getTitle:()=> dispatch(api_getTitle()),
        getGender :()=>dispatch(api_getGender()),
        getCountry:()=>dispatch(get_selected_states())
    
     };
  };
  
  const mapStateToProps = state => {
  
      let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
      let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
       
    return {
      redux_patientId,redux_docId
    };
  };
  export default connect(
      mapStateToProps,
      mapDispatchToProps
  )(addDemographics);
  
