import React, { Component,useState } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {wflow} from './../../../DS/general';
import axios from 'axios';
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import RecordNote from '../clinical/recordnote';
//import EditRecordNote from './editRecordNote';
import { confirmAlert } from 'react-confirm-alert';
import {api_move_to,api_move_to_clinical,api_move_to_investigation} from '../../../DS/DS.Correspondence';
import Showhelp from './../../showHelp';

class MoveLocation extends Component
{

    constructor (props) {
        super(props);
        this.state = {
          open:false,
          radioId:''

          };
      
      }
      componentWillMount()
      {
           this.setState({open:this.props.showNote})
      }
      shiftData = () =>
      {
        if(this.props.popfor=="inData")
        {
          if(this.state.radioId==1)
          {
            const moveData= {
              colname:'isInOut',
              val:'1',
              ids:this.props.sid,
              contactto:'Correspondence Out',
              contactfrom:'Correspondence In'
            }
            this.props.apimoveDataTo(moveData).then(
              req => this.props.changeKey(),
              NotificationManager.success("Record Moved") 
            )
          }
          if(this.state.radioId==2)
          {
            const movedata={
              ids:this.props.sid
            }
              this.props.apimovetoinvestigation(movedata).then(
                req=>
                {
                  if(req.data.status=="success")
                  {
                   NotificationManager.success("Record moved to investigation") 
                  }
                  else
                  {
                    NotificationManager.success("Error encountered while moving record to investigation") 
                  }
                  this.props.changeKey()
                }
                )
          }
          if(this.state.radioId==3)
          {
            const moveData= {
              ids:this.props.sid
            }
            this.props.apimovetoClinical(moveData).then(
              req => this.props.changeKey(),
              NotificationManager.success("Record moved to correspondence sent") 
            )
          }
  
        }
        if(this.props.popfor=="outData")
        {
          if(this.state.radioId==1)
          {
            const moveData= {
              colname:'isInOut',
              val:'0',
              ids:this.props.sid,
              contactto:'Correspondence In',
              contactfrom:'Correspondence Out'
            }
            this.props.apimoveDataTo(moveData).then(
              req => this.props.changeKey(),
              NotificationManager.success("Record moved to correspondence received") 
            )
          }
        }
        /*if(this.state.radioId==3)
        {
          const moveData= {
            colname:'isDeleted',
            val:'1',
            ids:this.props.sid
          }
          this.props.apimoveDataTo(moveData).then(
            req => this.props.changeKey(),
            NotificationManager.success("Record Deleted") 
          )
        }*/

      }
      render ()
      {
        var gridstyle = {
            padding: "10px"
          };
          return(


            <Popup
            modal
             open={this.state.open}
             closeOnDocumentClick={false}>
              {close => (
                <div>
                   <div className="modal-content" style={{width:'auto', marginleft:'-200px'}}>
                          <div className="modal-header" style={{width: '100%', padding: '0px 15px'}}>
                          <h6 style={{float:'left', fontSize: '16px', padding:'5px 10px'}}>Move Location <Showhelp gotohelp="move_location_help"/></h6>
                                      <button type="button" className="close"  onClick={() => {close();this.props.changeKey();}} data-dismiss="modal" aria-label="Close" style={{marginTop:'10px'}}>
                                          <span aria-hidden="true">×</span>
                                      </button>
                          </div>
                          <div className="modal-body">
                          {this.props.popfor=="inData"?
                           
                              <div className="col-sm-12" style={{marginTop:'10px'}}>				
							                    <div className="col-sm-3">
                                    <label>
							                      <input type="radio" value="1" style={{margin: '8px 0px 3px 5px', float:'left'}} name="movetype" onChange={(e) => this.setState({ radioId: 1 })}/>
                                    <span style={{marginTop:'5px', paddingLeft:'5px', marginRight:'5px', float:'left'}}>Communication Sent</span>
                                    </label>
							                    </div>
                                  <div className="col-sm-3">
                                    <label>
                                     <input type="radio" value="2" style={{margin: '8px 0px 3px 5px', float:'left'}} name="movetype" onChange={(e) => this.setState({ radioId: 2 })}/>
                                     <span style={{marginTop:'5px', paddingLeft:'5px', marginRight:'5px', float:'left'}}>Investigation reports</span>
                                     </label>
                                  </div>
                                    <div class="col-sm-3">
                                    <label>
                                      <input type="radio" value="3" style={{margin: '8px 0px 3px 5px', float:'left'}} name="movetype" onChange={(e) => this.setState({ radioId: 3 })}/>
                                      <span style={{marginTop:'5px', paddingLeft:'5px', marginRight:'5px', float:'left'}}>Clinical Images</span>
                                      </label>
                                    </div>
						                  </div>:''}
                              {this.props.popfor=="outData"?
                           
                              <div className="col-sm-12" style={{marginTop:'10px'}}>						
							                    <div className="col-sm-3">
                                    <label>
							                      <input type="radio" value="1" style={{margin: '8px 0px 3px 5px', float:'left'}} name="movetype" onChange={(e) => this.setState({ radioId: 1 })}/>
                                    <span style={{marginTop:'5px', paddingLeft:'5px', marginRight:'5px', float:'left'}}>Correspondence received</span>
                                    </label>
							                    </div>
                                  <div className="col-sm-3">
                                    <label>
                                     <input type="radio" value="2" style={{margin: '8px 0px 3px 5px', float:'left'}} name="movetype" onChange={(e) => this.setState({ radioId: 2 })}/>
                                     <span style={{marginTop:'5px', paddingLeft:'5px', marginRight:'5px', float:'left'}}>Care plans</span>
                                     </label>
                                  </div>
                              </div>:''}
                          </div>
                    </div>
                    <div class="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary btn-xs"
                        onClick={(e) => this.shiftData()}
                    >
                    Submit
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary btn-xs"
                        data-dismiss="modal"
                        onClick={() => {close();this.props.changeKey();}}
                    >
                      Cancel
                    </button>
                </div>
                   </div>     
                    )}
                </Popup>
          )
      }
      
}

const mapDispatchToProps = dispatch => {
  return {
    apimoveDataTo: data => dispatch(api_move_to(data)),
    apimovetoClinical:data=>dispatch(api_move_to_clinical(data)),
    apimovetoinvestigation:data=>dispatch(api_move_to_investigation(data))
   };
};
  
export default connect(
      null,
    mapDispatchToProps
  )(MoveLocation);

//export default MoveLocation ;