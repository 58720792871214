import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {api_get_patient,api_get_doctor,api_upload_image,api_assign_mail} from '../../../DS/DS.Inbox'
import { data } from 'jquery';
import { TableEditColumn } from '@devexpress/dx-react-grid';
import './inbox.css';
import SearchDoctors from './../Correspondence/searchDoctors';
import Showhelp from './../../showHelp';

class mailAssignment extends Component {
    constructor(props)
    {
      super(props);
      this.state=
      {
        key:0,
        open:this.props.open,
        observations:new Date(),
        selectedPatient:'',
        patient_list:[],
        doctor_list:[],
        selectedFile:'',
        selectedDoctor:this.props.redux_docId[0].doctor_id,
        description:'',
        img:'',
        showSelectedContent:[],
        selectedindex:-1,
        searchDoc:false

      }
    }
    componentWillMount=()=>
    {
    //  alert()
   
      this.props.getDoctor().then(res=>{
        this.setState({doctor_list:res.data.content})
        
      })
    }

    handleDate=date=>
    {
      this.setState({observations:date})
    }
    onChangeHandler=event=>{
      console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&",event)
      this.setState({selectedFile:event.target.files})
    }
    saveData=()=>
    {

      {/**ALTER TABLE `inbox_tb` ADD `subject` TEXT NOT NULL AFTER `upload_date`, ADD `contact` INT NOT NULL AFTER `subject`, ADD `store_in` VARCHAR(255) NOT NULL AFTER `contact`, ADD `category` VARCHAR(255) NOT NULL AFTER `store_in`;
 */}
      const data = new FormData()
      data.append('image',this.state.selectedFile)
      
        this.props.uploadimage(data).then(res=>
          {
            const userData={
              img:res.data.content,
              patient:this.state.selectedPatient,
              doctor:this.state.selectedDoctor,
              seldate:this.state.observations,
              sub:this.state.subject,
              contact:this.state.from_guid,
              store:this.state.storein,
              category:this.state.category
            }
              this.props.assignMail(userData).then(res=>
                {
                  this.setState(
                  {
                    observations:new Date(),
                    selectedPatient:'',
                    selectedFile:'',
                    selectedDoctor:this.props.redux_docId[0].doctor_id,
                    description:'',
                    img:'',
                    key:Math.random()
                  })
                })
          })
        }
        onsearchchange=(e)=>
        {
          this.props.searchPatient({pat:e.target.value}).then(res=>{
            this.setState({patient_list:res.data.content})
          })
        }
        setTrClass = (id) => {
          let classs = "";
          classs +=
            id == this.state.selectedindex
              ? " selectedBg "
              : "";
          return classs;
        };
        showSelectedContent(e, item, i) {
          this.setState({selectedindex:i})
      
      
      
         
        }
        serachDoctor=()=>
        {
            this.setState({searchDoc:true})
        }
        addSelectedDoc=(id)=>
        {
            this.setState({searchDoc:false})
            if(id!='')
            {
            this.setState({selected_doc_id:id.contact_id,from_guid:id.display+''+id.first_name+'.'+id.surname})
            }
        }
    render() {
        return (
            <Popup open={this.state.open} closeOnDocumentClick={false} position="right center"   modal>
             {close => (
              <div> 
                  <div className="modal-header" style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}>
                    <button type="button" className="close" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => this.props.closeAll()} data-dismiss="modal" aria-label="Close">
                      ×
                    </button>
                    <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Mail Assignment <Showhelp gotohelp="mail_assignment_help"/></h4>
                  </div>
                  <div className="modal-body" style={{overflowY: 'scroll',height:'500px'}}>
                      <div className="col-md-12">
                        <div className="col-md-3">
                        <span style={{color: '#000', float: 'left', marginTop: 10}}> Patient</span>  
                        </div>
                        <div className="col-md-5">
                            <label className="label-input100" style={{marginRight: 0, marginLeft: '-25px', marginBottom: 0, fontSize: 12, paddingTop: 0, paddingBottom: 0}}>
                                <input type="text" className="form-control form-control-sm" defaultValue="" style={{padding: '5px 10px', marginLeft: 10,marginTop: 1, float: 'left'}} 
                                  onChange={(e)=>this.onsearchchange(e)}/></label></div>
                          <div>
                          <div className="col-md-2">
                                  <label>Upload Document</label>
                              </div>
                              <div className="col-md-1">
                                {/*<input name="file" type="file"  onChange={this.onChangeHandler} required/>*/}
                                <input directory="" webkitdirectory="" type="file" onChange={this.onChangeHandler}/>

                              </div>
                          </div>        
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-11">
                          <table style={{margin:'10px'}}>
                              <tr style={{    background: 'lightgreen',margin: '10px',padding: '10px'}}>
                                  <td style={{padding:'10px',width:'400px'}}>
                                    Name
                                  </td>
                                  <td style={{padding:'10px',width:'100px'}}>
                                    DOB
                                  </td>
                              </tr>
                              {this.state.patient_list!=undefined && this.state.patient_list.length > 0 && this.state.patient_list.map((item,i) => (
                              <tr  onClick={e =>
                                this.showSelectedContent(e, item, i) }
                                className={this.setTrClass(i)}>
                                <td>{item.Firstname} {item.Surname}</td>
                                <td>{item.DOB}</td>
                              </tr>
                              ))}
                          </table>
                          </div>
                      </div>
                      <div className="col-md-12"  style={{margin:'10px'}}>
                            <div className="col-md-1">
                              <label>User</label>
                          </div>
                          <div className="col-md-2"  style={{marginTop:'-10px'}}>
                                <select value={this.state.selectedDoctor} onChange={(e)=>this.setState({selectedDoctor:e.target.value})}>
                                {this.state.doctor_list.length > 0 && this.state.doctor_list.map((item, i) => (
                                  <option value={item.doctor_id}>{item.Firstname}</option>
                              ))}
                              </select>
                          </div>
                          <div className="col-md-1">
                              <label>Date</label>
                          </div>
                          <div className="col-md-2"   style={{marginTop:'-20px'}} >
                             <DatePicker
                                         className="datePicker"
                                         onChange={this.handleDate}
                                         dateFormat="dd/MM/yyyy"
                                         id="clinicalcalendar" 
                                         selected={
                                                this.state.dateShow
                                                    ? Date.parse(moment(new Date()).format('MM/DD/YYYY'))
                                                    : Date.parse(this.state.observations)
                                                }
                                             
                                        />  
                        </div>
                        <div className="col-md-1">Subject</div>
                            <div className="col-md-2">
                              <input type="text" value={this.state.subject} onChange={(e)=>this.setState({subject:e.target.value})}/>
                            </div>
                            
                        </div>
                        <div className="col-md-12">
                        <div className="col-sm-2" style={{ paddingLeft: '20px',marginTop: '20px'}}>
                            <label>Contact :</label>
                        </div>
                        <div className="col-sm-3" style={{marginTop: '10px'}}>
                            <input type="text" class="form-control" value={this.state.from_guid} /> 
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-primary btn-xs mt-4" onClick={()=> this.serachDoctor()} >Search</button>
                        </div>
                        <div className="col-md-1">Store in</div>
                        <div className="col-md-3">
                          <select value={this.state.storein} onChange={(e)=>this.setState({storein:e.target.value})}>
                               <option value="corres_in">Correspondence In</option>
                               <option value="clini">Clinical</option>
                               <option value="inve">Investigation</option>                 
                          </select>
                        </div>
                        {(this.state.searchDoc) ?
                            <SearchDoctors searchDoc={this.state.searchDoc} changeKey={this.addSelectedDoc}/> : ''}
                        </div>
                        <div className="col-md-12">
                          <div className="col-md-1">Category</div>
                          <div className="col-md-3">
                              <select value={this.state.category} onChange={(e)=>this.setState({category:e.target.value})}>
                                  <option value="">Select Category</option>
                                  <option value="refe">Acceptance of Referal</option>
                                  <option value="refe1">Acknowledge of Referal</option>
                                  <option value="refe2">Admision Notification</option>
                              </select>
                          </div>
                    </div> 
                    </div>  
                    
                   <div className="modal-footer">
                     <button type="button" className="btn btn-primary-xs" onClick={(e)=>this.saveData()}>Save </button>
                     <button type="button" className="btn btn-primary-xs" onClick={(e)=>this.props.closeAll()}>Close </button>
                   </div>
               </div>
               
             )}
            </Popup>
        )}
}
const mapDispatchToProps = (dispatch) => {
	return {
        getDoctor:()=>dispatch(api_get_doctor()),
        uploadimage : data => dispatch(api_upload_image(data)),
        assignMail:data=>dispatch(api_assign_mail(data)),
        searchPatient:data=>dispatch(api_get_patient(data))
  }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, mapDispatchToProps) (mailAssignment);