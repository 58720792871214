import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    api_add_correspondence,
    api_get_recieved,
    api_get_sent
} from '../../../DS/DS.Correspondence';
//import Files from 'react-files';
import { api_get_title,api_contact_category,api_new_contact,api_contact_address } from '../../../DS/DS.Correspondence';
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
//import "react-datepicker/dist/react-datepicker.css"
import SearchDoctors from './searchDoctors';
import AddAddress from './addAddress';
import Showhelp from './../../showHelp';

class NewContact extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            titles:[],
            selectedTitle:1,
            radioId:1,
            greetingMsg:'Sir/Madam',
            indiDate:true,
            compDate:false,
            disable:false,
            contact_cat:[],
            showAdd:false,
            addressData:[],
            surname:'',
            firstname:'',
            selectedCat:'',
            mobile:'',
            provider:'',
            pki:'',
            email:'',
            msgngprovider:'',
            pager:'',
            ah:'',
            health:'',
            skype:'',
            accountid:'',
            comment:'',
            website:'',
            contact_type:0,
            showEdit:false,
            selectedindex:-1,
            showSelectedContent:[]




        }
    }
    componentDidMount(){
        this.setState({open:this.props.showmodal})
        this.props.getTitle().then(
            req=>{
                this.setState({
                    titles: req.data.content ,
                })
            }
        )
        this.props.getContactCategory().then(res => {
            this.setState({
                contact_cat: res.data.content ,
            })}
        )
    }
    editAddress=(data,index)=>
    {
        this.setState({showEdit:false})
        this.state.addressData[index]=data;
        this.state.selectedindex=-1;
        this.state.showSelectedContent=[];
    }
    deleteAddress=()=>{
        console.log("***",this.state.selectedindex)
        //this.state.addressData.pop(this.state.selectedindex)
        this.state.addressData.splice(this.state.selectedindex, 1);
        this.setState({key:Math.random()})
    }
  
    handleRadioButtion=(rid)=>
    {
        if(rid==1)
        {
            this.setState({indiDate:true,compDate:false,contact_type:0})
            this.setState({disable:false})

        }
        if(rid==2)
        {
            this.setState({indiDate:false,compDate:true,contact_type:1})
            this.setState({disable:true})
        }
    }
    newAddress=(data)=>{
        console.log(">>>>>>>>",data)
        this.setState({showAdd:false})
        if(data!='')
        {
            this.state.addressData.push(data)
        }    
     }
    addContact=()=>{
        const allData=
        {
         title:this.state.selectedTitle,
         surname:this.state.surname,
         firstname:this.state.firstname,
         greeting:this.state.greetingMsg,
         selectedCat:this.state.selectedCat,
         mobile:this.state.mobile,
         provider:this.state.provider,
         pki:this.state.pki,
         email:this.state.email,
         msgngprovider:this.state.msgngprovider,
         pager:this.state.pager,
         ah:this.state.ah,
         health:this.state.health,
         skype:this.state.skype,
         accountid:this.state.accountid,
         comment:this.state.comment,
         website:this.state.website,
         contact_type:this.state.contact_type

        }
       this.props.addNewContact(allData).then(
        req=>{
            console.log(req.data);
      //      alert("IN");
            const address={
                address:this.state.addressData,
                contact_id:req.data.content
            }
            this.props.addContactAddress(address);
            this.props.changeKey();
        }
       )
    }
    showSelectedContent(e, item, i) {
        this.setState({ showSelectedContent: item,selectedindex: i });
       
      }
      setTrClass = (id) => {
        let classs = "";
        classs +=
          id == this.state.selectedindex
            ? " selectedBg "
            : "";
        return classs;
      };
    render() {
        return ( 
        <Popup 
        position="right center"   modal
        closeOnDocumentClick={false}
        open={this.state.open}
        >
         {close => (
                <div>
                   <div className="modal-header">
                   <h6 style={{float:'left', fontSize: '16px', padding:'5px 10px'}}>Contact details <Showhelp gotohelp="new_contact_help"/></h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{marginTop:'15px'}}>
                                    <span aria-hidden="true" onClick={()=>{this.props.changeKey('');close();}}>×</span>
                                </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">				
							    <div className="col-sm-2">
                                    <label>
							            <input type="radio" value="1" style={{margin: '8px 0px 3px 5px', float:'left'}} name="movetype" onChange={(e) => this.handleRadioButtion(1)}  checked={this.state.indiDate}/>
                                        <span style={{marginTop:'5px', paddingLeft:'5px', marginRight:'5px', float:'left'}}>Individual</span>
                                    </label>
							    </div>
                                <div className="col-sm-3">
                                    <label>
                                     <input type="radio" value="2" style={{margin: '8px 0px 3px 5px', float:'left'}} name="movetype" onChange={(e) =>  this.handleRadioButtion(2)}  checked={this.state.compDate}/>
                                     <span style={{marginTop:'5px', paddingLeft:'5px', marginRight:'5px', float:'left'}}>Company/Institution</span>
                                    </label>
                                </div>
                              </div>
                            <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0">Title:</label>
                                <div className="col-sm-3  pl-0">
                                    <select name="select" className="form-control" style={{padding: '.0rem .5rem', height: '30px', fontSize: '14px'}} onChange={(e) => this.setState({selectedTitle: e.target.value })}
                                   disabled={(this.state.disable)? "disabled" : ""}>
                                            {this.state.titles != undefined && this.state.titles.length> 0 && this.state.titles.map((title) => <option key={title.value} value={title.value}>{title.display}</option>)}
                                    </select>
                                </div>
                                <label className="col-sm-2 col-form-label text-right">First name:</label>
                                    <div className="col-sm-3">
                                        <input type="text" className="form-control form-control-sm" disabled={(this.state.disable)? "disabled" : ""}  value={this.state.firstname} onChange={(e) => this.setState({ firstname: e.target.value })}/>
                                    </div>
                            </div>
                            <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0">Surname:</label>
                                <div className="col-sm-3 pl-0">
                                    <input type="text" className="form-control form-control-sm" disabled={(this.state.disable)? "disabled" : ""} value={this.state.surname} onChange={(e) => this.setState({ surname: e.target.value })}/>
                                </div>
                                <div className="col-sm-3">
                                    <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-primary" style={{width:'60px'}}>Select</button>
                                </div>
                            </div>
                        <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0">Greeting:</label>
                                <div className="col-sm-3 pl-0">
                                    <input type="text" className="form-control form-control-sm" value={this.state.greetingMsg} onChange={(e) => this.setState({ greetingMsg: e.target.value })}/>
                                </div>
                            </div>
                            <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0">Category:</label>
                                <div className="col-sm-3 pl-0">
                                <select name="select" className="form-control" style={{padding: '.0rem .5rem', height: '30px', fontSize: '14px'}} onChange={(e) => this.setState({selectedCat: e.target.value })}>
                                    <option value=''>Select Category</option>
                                            {this.state.contact_cat != undefined && this.state.contact_cat.length> 0 && this.state.contact_cat.map((cntcat) => <option key={cntcat.category_id} value={cntcat.category_id}>{cntcat.contact_name}</option>)}
                                </select>
                                </div>
                            </div>
                            <div className="form-group row mt-2">				
                                <label className="col-sm-2 col-form-label text-left pr-0">Addresses:</label>
                                <div className="col-sm-7 pl-0">
                                    <div className="card" style={{paddingRight:'0px',paddingLeft: '0px', overFlowY:'auto', height:'130px', border:'1px solid #ccc'}}>
                                        <div className="card-block">
                                            <table className="calendar table" style={{background:'#ffffff'}}>
                                                <tbody>
                                                    <tr style={{background:'#f4f4f4'}}>
                                                        <td style={{ padding: '5px 0px'}}>&nbsp;Address</td>
                                                        <td style={{ padding: '5px 0px'}}>&nbsp;Phone</td>
                                                        <td style={{ padding: '5px 0px'}}>&nbsp;Fax</td>
                                                    </tr>
                                                    {this.state.addressData != undefined && this.state.addressData.length> 0 && this.state.addressData.map((address,i) =>
                                                    <tr onClick={e =>
                                                        this.showSelectedContent(e, address, i) } className={this.setTrClass(i)}>
                                                        <td>{address.addressLine1}{address.addressLine2}</td>
                                                        <td>{address.phone}</td>
                                                        <td>{address.fax}</td>
                                                    </tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 pl-0">
                                    <button type="button" className="btn btn-primary btn-xs" style={{width:'60px', margin:'0 5px 5px 0'}} data-toggle="modal" data-target="#contactaddress" onClick={(e)=>{this.setState({showAdd:true})}}>Add</button>
                                    <button type="button" className="btn btn-primary btn-xs" style={{width:'60px', margin:'0 5px 5px 0'}} data-toggle="modal" data-target="#editcontactaddress" onClick={(e)=>{this.setState({showEdit:true})}}>Edit</button>
                                    <button type="button" className="btn btn-primary btn-xs" style={{width:'60px', margin:'0 5px 5px 0'}} data-dismiss="modal" onClick={(e)=>this.deleteAddress()}>Delete</button>
                                    <button type="button" className="btn btn-primary btn-xs" style={{width:'60px'}} data-dismiss="modal">Import</button>
                                </div>
                                {(this.state.showAdd) ? <AddAddress showmodal={this.state.showAdd} changeKey={this.newAddress} add='add'/> : '' }
                                {(this.state.showEdit) ? <AddAddress showmodal={this.state.showEdit} changeKey={this.editAddress} add='' sendData={this.state.showSelectedContent} sendId={this.state.selectedindex}/> : '' }
                                                     
                            </div>
                            <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0"  > Mobile Phone:</label>
                                <div className="col-sm-2 pl-0">
                                    <input type="text" className="form-control form-control-sm" disabled={(this.state.disable)? "disabled" : ""} onChange={(e) => this.setState({mobile: e.target.value })}/>
                                </div>
                                <label className="col-sm-1 col-form-label text-right"  >Pager:</label>
                                <div className="col-sm-2">
                                    <input type="text" className="form-control form-control-sm" disabled={(this.state.disable)? "disabled" : ""} onChange={(e) => this.setState({pager: e.target.value })}/>
                                </div>
                                <label className="col-sm-2 col-form-label text-right">A/H phone:</label>
                                <div className="col-sm-2">
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.setState({ah: e.target.value })}/>
                                </div>
                            </div>
                            <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0">Provider No:</label>
                                <div className="col-sm-3 pl-0">
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.setState({provider: e.target.value })}/>
                                </div>
                                <label className="col-sm-2 col-form-label text-left pl-0">Health Identifier:</label>
                                <div className="col-sm-3">
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.setState({health: e.target.value })}/>
                                </div>
                            </div>
                            <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0">PKI key ID:</label>
                                <div className="col-sm-3 pl-0">
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.setState({pki: e.target.value })}/>
                                </div>
                                <label className="col-sm-2 col-form-label text-left">Skype</label>
                                <div className="col-sm-3">
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.setState({skype: e.target.value })}/>
                                </div>
                            </div>
                            <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0">Email:</label>
                                <div className="col-sm-3 pl-0">
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.setState({email: e.target.value })}/>
                                </div>
                                <label className="col-sm-2 col-form-label text-left">Website:</label>
                                <div className="col-sm-3">
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.setState({website: e.target.value })}/>
                                </div>
                            </div>
                            <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0">Messaging provider:</label>
                                <div className="col-sm-3 pl-0">
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.setState({msgngprovider: e.target.value })}/>
                                </div>
                                <label className="col-sm-2 col-form-label text-left">Account ID(if applicable):</label>
                                <div className="col-sm-3">
                                    <input type="text" className="form-control form-control-sm" onChange={(e) => this.setState({accountid: e.target.value })}/>
                                </div>
                            </div>
                            <div className="form-group row">				
                                <label className="col-sm-2 col-form-label text-left pr-0">Comment:</label>
                                <div className="col-sm-9 pl-0">
                                    <textarea className="col-sm-12" style={{float:'left',height:'50px', border:'1px solid #ccc'}} onChange={(e) => this.setState({comment: e.target.value })}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{float:'left', width:'100%', marginTop:'25px'}}>
                            <button type="button" className="btn btn-primary btn-xs" onClick={()=>this.addContact()}>Use Contact</button>
                            <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" onClick={()=>{this.props.changeKey('');close();}}>Cancel</button>
                        </div>
                    </div>
                
          )}
          </Popup>)
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
        getTitle:()=>dispatch(api_get_title()),
        getContactCategory:()=>dispatch(api_contact_category()),
        addNewContact:(data)=>dispatch(api_new_contact(data)),
        addContactAddress:(data)=>dispatch(api_contact_address(data))
    
  }
}

export default connect (null, mapDispatchToProps) (NewContact);
