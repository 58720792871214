import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../showHelp';


class recordnote extends Component {
    state = {  }
    render() {
        return (
            <Popup  trigger={<button id="session-timeout-dialog-logout" type="button" className="btn btn-primary btn-xs">Record Note <Showhelp gotohelp="enhanced_record_help"/></button>} position="right center"   modal
            closeOnDocumentClick={false}
            >
             {close => (
                    <div>

                    <div className="modal-header" style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}>

                    <button type="button" className="close" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                    ×
                    </button>
                    <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>EPC notes-Annie Jones</h4>
                    </div>

					<div className="modal-body">
                    <div className="form-group row pl-3" style={{marginTop:'10px'}}>
                    <h4>Patient contact details</h4>
                    </div>

                    <div className="form-group row" style={{marginTop:'10px'}}>
							<label className="col-sm-2 col-form-label text-left">Patient name:</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Age</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
                            <div className="col-sm-2">
							<label className="col-form-label text-left">Preferred contact via</label>
							</div>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>


                        <div className="form-group row" style={{marginTop:'10px'}}>
							<label className="col-sm-2 col-form-label text-left">Home phone</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Work phone </label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
                            <div className="col-sm-2">
							<label className="col-form-label text-left">Mobile phone</label>
							</div>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
                        <div className="form-group row pl-3" style={{marginTop:'10px'}}>
                        <h4>Other contact's details</h4>
                       </div>



                        <div className="form-group row" style={{marginTop:'10px'}}>
							<label className="col-sm-2 col-form-label text-left">Contact type</label>
							<div className="col-sm-2">
							<select name="user" className="form-control" >
								<option></option>
								</select>
							</div>
							
							<div className="col-sm-8">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>

                        <div className="form-group row" style={{marginTop:'10px'}}>
							<label className="col-sm-2 col-form-label text-left">Home phone</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
							<label className="col-sm-2 col-form-label text-left">Work phone </label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
                            <div className="col-sm-2">
							<label className="col-form-label text-left">Mobile phone</label>
							</div>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						</div>
                        <div className="form-group row pl-3" style={{marginTop:'10px'}}>
                        <h4>Communication details</h4>
                       </div>

                        <div className="form-group row" style={{marginTop:'10px'}}>
							<label className="col-sm-2 col-form-label text-left">Contact reason</label>
							<div className="col-sm-2">
							<select name="user" className="form-control" >
								<option></option>
								</select>
							</div>
							<label className="col-sm-2 col-form-label text-left">Contact method </label>
							<div className="col-sm-2">
							<select name="user" className="form-control" >
								<option></option>
								</select>
							</div>
                            <div className="col-sm-2">
							<label className="col-form-label text-left">Contact with</label>
							</div>
							<div className="col-sm-2">
							<select name="user" className="form-control" >
								<option></option>
								</select>
							</div>
						</div>


                        <div className="form-group row" style={{marginTop:'10px'}}>
							<label className="col-sm-2 col-form-label text-left">Contact date</label>
							<div className="col-sm-2">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						 <div className="col-sm-8">
							
							</div>
							
						</div>


                        <div className="form-group row" style={{marginTop:'10px'}}>
							<label className="col-sm-2 col-form-label text-left">Contact attempt no</label>
							<div className="col-sm-1">
							<input type="text" className="form-control form-control-sm"/>
							</div>
						 <div className="col-sm-9">
							
							</div>
							
						</div>
                        <div className="form-group row pl-3" style={{marginTop:'10px'}}>
                        <h4>User comments</h4>
                        </div>
                        <div className="form-group row" style={{marginTop:'10px'}}>
							
							<div className="col-sm-12">
							<textarea style={{width: '100%', height: '100px', border: '1px solid #cccccc'}} defaultValue={""} />
							</div>
						 
							
						</div>



                        <div className="form-group row" style={{marginTop:'10px'}}>
							
							<div className="col-sm-7">
							
							</div>
							
                            <div>
							<label className="col-sm-2 col-form-label text-left">Message stutus</label>
							</div>
							<div className="col-sm-2">
							<select name="user" className="form-control" >
								<option></option>
								</select>
							</div>
						</div>
						</div>

<div className="modal-footer"  style={{marginTop:'10px'}}>
<div className="col-sm-2">
<button type="button" className="btn btn-primary btn-xs">View document</button>
</div>
<div className="col-sm-7"></div>
<div className="col-sm-3">
<button type="button" className="btn btn-primary btn-xs">Save </button>
  <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal" aria-label="Close">Cancel</button>
  </div>
</div>





</div>



)}
</Popup>
         );
    }
}

export default  recordnote ;
