import React, { Component } from "react";
import Addimmunisations from "./addimmunisations";
import Editimmunisations from "./editimmunisations--";
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import {
  api_add_immunisations,
  api_DeleteImmunisation,
  api_list_vaccines,
  api_addChildImmunisations,
  api_list_patient_immunisations
} from "./../../../DS/DS.Immunisations";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { api_delete_immunisations } from "../../../DS/DS.Immunisations";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import './immunisation.css';

class Immunisations extends Component {
  constructor(props) {
    super(props);
    this.deleteFunction = this.deleteFunction.bind(this);
    //  this.editClick = this.editClick.bind(this);
    //  this.onRowClicked = this.onRowClicked.bind(this);
    this.state = {
      popupType: "",
      patient_immunisations: [],
      key: "1",
      selectedRec: "",
      selectedRecLength: "",
      vaccines_master: [],
      patientId: this.props.redux_patientId,
      
      drId: 1,
      crud: {
        jsonGridVersion: "v2.0",
        grid: true,
        logged_info: [],
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.deleteFunction,
          isDateGroup: true,
          isYearSeparator: false,
          dateCol: "date",
          cellClicked: this.onRowClicked,
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          query: {
            tb: "patients_immunization",
            cols: [
              "immunization_id",
              "DATE_FORMAT(date,'%d-%m-%Y') as date",
              "sequence",
              "batch_no",
              "site",
              "comment",
              "patients_immunization.createdAt as createdAt",
              "vaccine_master_tb.name as vaccine",
              "diseases_master_tb.name as disease",
              "doctor_tb.Firstname as dr",
              "given.Firstname as GivenDr"
            ],
            primary_key: "immunization_id",
            joins: [
              {
                joinType: "left",
                joinTable: "vaccine_master_tb",
                joinCondition:
                  "vaccine_master_tb.vaccine_id = patients_immunization.vaccine_id"
              },
              {
                joinType: "left",
                joinTable: "diseases_master_tb",
                joinCondition:
                  "diseases_master_tb.diseases_id = patients_immunization.diseases_id"
              },
              {
                joinType: "left",
                joinTable: "doctor_tb",
                joinCondition:
                  "doctor_tb.doctor_id = patients_immunization.ordered_by_guid"
              },
              {
                joinType: "left",
                joinTable: "doctor_tb given",
                joinCondition:
                  "given.doctor_id = patients_immunization.given_by_guid"
              }

            ],
            where: [
              {
                type: "and",
                key: "patient_id",
                operator: "=",
                value: this.props.redux_patientId
              },
              {
                type: "and",
                key: "patients_immunization.isDeleted",
                operator: "!=",
                value: 1
              }

            ]
          },

          viewtabletitle: "",
          columns: [
            { name: "date", title: "Date given" },
            { name: "disease", title: "Diseases" },
            { name: "vaccine", title: "Vaccine" },
            { name: "batch_no", title: "Batch No." },
            { name: "sequence", title: "Sequence" },
            { name: "dr", title: "Ordered by" },
            { name: "GivenDr", title: "Given by" },
            { name: "site", title: "Site" },
            { name: "comment", title: "Comment" },
            { name: "createdAt", title: "Date recorded" }
          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "desc" }],
          pagesize: 10
        }
      },

      viewEdit: false
    };
  }
  componentWillMount() {
   
    this.props.list_vaccines().then(res => {
      this.setState({ vaccines_master: res.data.content });
    });
    this.props
      .list_patient_immunisations({ patient_id: this.state.patientId })
      .then(res => {
        this.setState({ patient_immunisations: res.data.content });
      });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ logged_info: nextProps.logged_info });
    this.setState({ drId: nextProps.logged_info.content.details[0].doctor_id });
    if (nextProps.redux_patientId !== this.props.redux_patientId) {
      this.setState({ patientId: nextProps.redux_patientId });
    }
  }

  toggleEdit = e => {
    // this.state.viewEdit = false;
    this.setState({ viewEdit: !this.state.viewEdit });
    this.changeKey();
    console.log(this.state);
  };

  openAddPopup = e => {
    //this.state.viewEdit = true;


    if (this.state.selectedRecLength > 0) {
      alert('Please unselect records before adding.');
    } else {
      this.setState({ viewEdit: true });
      this.setState({ popupType: 'add' });
      
      console.log(this.state);
    }
    
  };

  isAlreadyexist = id => {
    let isAlreadyexist = false;
    let new_arr = [];
    new_arr = this.state.patient_immunisations.filter(
      item => item.vaccine_id == id
    );
    if (new_arr.length > 0) {
      isAlreadyexist = true;
    } else {
      isAlreadyexist = false;
    }
    return isAlreadyexist;
    /*
    vacinid: id,
    patient_id: this.state.patientId,
    drId: this.state.drId
    */
  };

  addChildImmunisations = (e, id) => {
    if (e.target.value == "on") {
      alert("Cannot revert child immunisation entry!");
    } else {
      confirmAlert({
        title: "",
        message:
          "You are adding an child immunisation record which cannot revert in future. Do you want to proceed?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props
                .addChildImmunisations({
                  vacinid: id,
                  patient_id: this.state.patientId,
                  drId: this.state.drId
                })
                .then(res => this.changeKey());
            }
          },
          {
            label: "No",
            onClick: () => {
              //  this.changeKey();
            }
          }
        ]
      });
    }
  };

  deleteFunction = (selectedId, action, checklength) => {
    console.log("imm", selectedId);
    if (action == "delete") {
      //console.log
     
      if (this.state.selectedRecLength > 0) {

      //  alert("delete"+this.state.selectedRec);
        let showalert = false;
        let arra = this.state.selectedRec.split(',');
        for(let ii=0; ii<this.state.patient_immunisations.length; ii++){
          for(let jj=0; jj<arra.length; jj++){
              if(this.state.patient_immunisations[ii].child==1 && this.state.patient_immunisations[ii].immunization_id == arra[jj]){
                //alert(arra[jj]);
                showalert = true;
                arra.splice(jj, 1);
              }
          }

        }
        if(showalert){
          alert("Selected Child Immunisation Record(s) will be excluded from deleting!");
        }

        const immData = { immunisation_id: arra };
          
        if(arra.length>0){
        confirmAlert({
          title: "",
          message: "You are about to delete immunisation record(s). Do you want to proceed?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                this.props.DeleteImmunisation(immData).then(res => {
                  NotificationManager.success("Record deleted");
                  this.changeKey();
                  this.props
                    .list_patient_immunisations({
                      patient_id: this.state.patientId
                    })
                    .then(res => {
                      this.setState({
                        patient_immunisations: res.data.content
                      });
                    });
                });
              }
            },
            {
              label: "No",
              onClick: () => {
                this.changeKey();
              }
            }
          ]
        }); }
        else{
          //alert("c")
          this.changeKey();
        }
      } else {
        NotificationManager.error("Please select a record to delete");
      }
    }
    if (action == "selection") {
 
      // this.setState({selectedRec: selectedId});
      // this.setState({selectedRecLength: checklength});
      this.state.selectedRec = selectedId;
      this.state.selectedRecLength = checklength;
    }
  };

  editClick(selectedId, action) {
 
 
    if (action == "edit") {
      if (this.state.selectedRecLength == 1) {
        setTimeout(
          function() {
            
            this.setState({ 
              viewEdit: true ,
              popupType: 'edit'
            
            });
          }.bind(this),
          1000
        );

        
      } else {
        alert("Please select single record to edit");
      }
    }
  }

  onRowClicked = clickedId => {
    console.log("imm", clickedId);
  };

  changeKey = () => {
    // this.setState({viewEdit:false});
    this.setState({ key: Math.random() });
    this.props
      .list_patient_immunisations({ patient_id: this.state.patientId })
      .then(res => {
        this.setState({ patient_immunisations: res.data.content });
      });
  };

  addimmunisations2 = data => {
    
    this.props.add_immunisations(data).then(res => this.changeKey());

    NotificationManager.success("Record Added Successfully!");
  };

  groupBy = (data, key) => {
    return data.reduce(function(acc, item) {
      (acc[item[key]] = acc[item[key]] || []).push(item);
      return acc;
    }, {});
  };
  
  render() {
    var gridstyle = {
      padding: "10px"
    };
    const data = this.groupBy(
      this.state.vaccines_master.filter(item => item.child == 1),
      "months"
    );
    return (
      <div className="">
        <div className="panel panel-default">
        
          <div
            className="panel-heading panspace">
            <div className="col-lg-12 col-xl-12">
            
              <h4
                className="panel-title">
                <a href="#" className="collapsed" aria-expanded="false" >
                  {" "}
                  General Immunisations{" "}
                </a>
              </h4>
              
              <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}}>
                <span className="makescreen">
                  <a href="#" title="Make This Default Screen" className="collapsed" aria-expanded="false">
                    <i className="fa fa-star panelicon" aria-hidden="true" />
                  </a>
                </span>
                <a  href="#"  title="Email"  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-envelope panelicon"
                    aria-hidden="true" />
                </a>
                <a
                  href="#"
                  title="Print"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-print panelicon"
                    aria-hidden="true" />
                </a>
                {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
                <a
                  href="#"
                  title="Export PDF"
                  onclick="recentVisitmini();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-file-pdf-o panelicon"
                    aria-hidden="true" />
                </a>
             
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6" style={{ marginTop: "10px" }}>
        
          {/*<button id="session-timeout-dialog-logout" data-toggle="modal" data-target="#addimm" type="button" className="btn btn-default" style={{marginRight: '10px', padding: ''}}>Add</button>*/}

          <button
            onClick={e => this.openAddPopup(e)}
            id="session-timeout-dialog-logout"
            data-toggle="modal"
            data-target="#editimm"
            type="button"
            className="btn btn-primary btn-xs" style={{
              marginRight: "5px" }}>
            Add
          </button>
          <button
            onClick={e => this.editClick("", "edit")}
            id="session-timeout-dialog-logout"
            data-toggle="modal"
            data-target="#editimm"
            type="button"
            className="btn btn-primary btn-xs"style={{
              marginRight: "5px" }}>
            Edit
          </button>

          <button
            onClick={e => this.deleteFunction("", "delete", "")}
            id="session-timeout-dialog-logout"
            type="button"
            className="btn btn-primary btn-xs" style={{
              marginRight: "5px" }}>
            Delete
          </button>
        </div>
       
        <div className="col-lg-12 pl-0 pr-0 immundet">
          {this.props.patient_age < 19 && (
            <div className="col-lg-4">
              {Object.keys(data).map(objKey => (
                <p key={objKey}>
                 
                    <b>{objKey}:</b>
               
                  {this.state.vaccines_master
                    .filter(vacin => vacin.months == objKey)
                    .map(vacin => (
                      <div className="col-lg-12" style={{ textAlign: "right" }}>
                        <label>
                          {" "}
                          {vacin.name}{" "}
                          <input
                            type="checkbox"
                            value={
                              this.isAlreadyexist(vacin.vaccine_id)
                                ? "on"
                                : "off"
                            }
                            checked={this.isAlreadyexist(vacin.vaccine_id)}
                            onChange={e =>
                              this.addChildImmunisations(e, vacin.vaccine_id)
                            }
                          />{" "}
                        </label>
                      </div>
                    ))}
                </p>
              ))}
            </div>
          )}
{this.props.patient_age < 19 ? (
          <div
            className="col-lg-8"
            style={{
              float: "left",
              width: ""
            }}
          >
            <h5 class="sheadtitle">Immunisation Details:</h5>
            <div className="componentcssimm">
            <CommonGrid
              style={gridstyle}
              key={this.state.key}
              handleButton={this.handleButton}
              crud={this.state.crud}
            />
</div>
             
          </div>):
           (
          <div
            className="col-lg-12">
            <CommonGrid
              style={gridstyle}
              key={this.state.key}
              handleButton={this.handleButton}
              crud={this.state.crud}
            />

             
          </div>)}
        </div>
        {this.state.viewEdit && (
          <Addimmunisations
          editId={this.state.selectedRec}
            mode={this.state.popupType}
            open={this.state.viewEdit}
            close={e => this.toggleEdit(e)}
            selectedRec={this.state.selectedRec}
            drId={this.state.drId}
            patientId={this.state.patientId}
            addimmunisations1={this.addimmunisations2}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  let redux_patientId =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].patient_id
      : "";
  let patient_age =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0].age
      : "";
      let logged_info =
        state.getDrAppointments.logged_info != undefined
          ? state.getDrAppointments.logged_info
          : "";   

  return {
    redux_patientId,
    patient_age, logged_info
  };
};

const mapDispatchToProps = dispatch => {
  return {
    add_immunisations: data => dispatch(api_add_immunisations(data)),
    addChildImmunisations: data => dispatch(api_addChildImmunisations(data)),
    DeleteImmunisation: data => dispatch(api_DeleteImmunisation(data)),
    list_vaccines: data => dispatch(api_list_vaccines(data)),
    list_patient_immunisations: data =>
      dispatch(api_list_patient_immunisations(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Immunisations);
