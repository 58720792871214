import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import moment from "moment";
//import "./myList.css";
import CommonGrid from "../../crudcomponentV2/combinedcrud";
import DatePicker from "react-datepicker";
//import SelectPatient from "./selectPatient";
import "react-datepicker/dist/react-datepicker.css";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import "./../../superadmin/managelists/listPage.css";
import {
  api_list_mytasks,
  api_delete_task,
  api_add_mytask,
  api_loadEditRecord
} from "../../../DS/DS.VisitnotesMyList";
import {api_summary_billing} from "./../../../DS/DS.VisitnotesDiagnosis";
import cicon from "./../../../assets/img/cicon.png";
import Showhelp from '../../showHelp';

class billingHistory extends Component {
  constructor(props) {
    super(props);
    //  this.state.showGrid =props.showGrid;
    //console.log( props.doc_id);
    this.getAllId = this.getAllId.bind(this);
    this.handleMyTasksCrud = this.handleMyTasksCrud.bind(this);
    this.state = {
      patients_billing: [],
      SelectPatient: '',
      taskid: ""
,      key: 1,
      selectedRec: "",
      selectedRecLength: "",
      mytasks: [],
      date: new Date(),
      patient_id: this.props.patientId,
      //doctor_id: this.props.doctor_id,
      doctor_id: this.props.logged_info.content.details[0].doctor_id,
      selectedPatientId: this.props.selectedPatientDetails.patient_id,
      showGrid: props.showGrid,
      patient_name:
        this.props.selectedPatientDetails.Firstname != ""
          ? this.props.selectedPatientDetails.Firstname
          : "",
      patient_DOB:
        this.props.selectedPatientDetails.DOB != ""
          ? moment(this.props.selectedPatientDetails.DOB).format("DD/MM/YYYY")
          : "",
      Action: "",

      mylist_crud: {
        jsonGridVersion: "v2.0",
        grid: true,
        viewgrid: {
          isVisible: true,
          isPagination: true,
          isFiltering: true,
          isSelection: true,
          deleteFunction: this.handleMyTasksCrud,
          isDateGroup: false,
          isYearSeparator: false,
          dateCol: "date",
          //cellClicked: this.handleInvestigationRowClick,
          cellClicked: "",
          baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
          highlightprev:true,
          query: {
            tb: "patients_careplan_items",
            cols: [
                "DATE_FORMAT(patients_careplan.recordedAt,'%d-%m-%Y') as date",
              "invoice_no",
              "careplan_id",
              "name",
              "item_no",
              "doctor_id",
              "price",
              "invoice_status",
              "DATE_FORMAT(patients_careplan.recordedAt,'%d-%m-%Y %H:%i:%S') as createdAt",
              
             
              "patients.Firstname as patient"
            ],
            where: [
              {
                type: "and",
                key: "patients_careplan.patient_id",
                operator: "=",
                value: this.props.selectedPatientDetails.patient_id
              },
              {
                type: "and",
                key: "care_plan_master_tb.isDeleted",
                operator: "=",
                value: 0
              }
            ],
            joins: [
              {
                joinType: "inner",
                joinTable: "care_plan_master_tb",
                joinCondition: "care_plan_master_tb.care_plan_id  = patients_careplan_items.careplan_id "
              }, 
              {
                joinType: "inner",
                joinTable: "patients_careplan",
                joinCondition: "patients_careplan.patients_careplan_id   = patients_careplan_items.patients_careplan_id "
              }
            ],
            customCols: [],
            primary_key: "patients_careplan_items_id"
          },
          viewtabletitle: "",
          columns: [
            
            { name: "date", title: "Date" },

            { name: "invoice_no", title: "Invoice No" },
            { name: "careplan_id", title: "Service ID" },
            { name: "name", title: "Service" },
            { name: "item_no", title: "MBS Item" },
            { name: "doctor_id", title: "Provider" },
            { name: "price", title: "Fee" },
            { name: "price", title: "Paid" },
            { name: "price", title: "Owing" },
            { name: "doctor_id", title: "Recorded By" },
            { name: "createdAt", title: "Recorded On" },

          ],
          rows: [],
          sortinit: [{ columnName: "date", direction: "asc" }],
          pagesize: 10,
          getAllIds: true,
          getallId: this.getAllId
        }
      },
      
    };
  }
  getAllId =() => {

  }
  componentDidMount(){
    
  }
  handleMyTasksCrud = () => {}
  componentDidMount(){
    this.props.api_summary_billing({patient_id: this.props.patient_id}).then(res=>{
      this.setState({patients_billing: res.data.content});
    }).then(() => console.log(this.state, "summary state"));
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPatientDetails !== this.props.selectedPatientDetails) {
      console.log("billing history selected patient", nextProps, this.props.selectedPatientDetails);
      this.setState({ logged_info: nextProps.logged_info });
       



      //this.state.patients_billing.length
      this.props.api_summary_billing({patient_id: this.props.patient_id}).then(res=>{
       
        this.setState({patients_billing: res.data.content});
        //this.setState({patients_careplan_items: res.data.content.items});
      }).then(() => console.log(this.state, "summary state"));
    
    
    
    }
  }
   

  changeKey = () => {
    this.setState({ key: Math.random() });
  };

  render() {
    var gridstyle = {
      padding: "10px"
    };
     
      /* For Link use showGrid=1 while calling */
      return (
        <Popup
        open={this.props.open}
        close={e => this.props.close()}  

          // trigger={
          //   <button style={{ paddingBottom: "10px" }}>
          //     <u style={{ textDecoration: "none", fontSize: "14px" }}>
          //      Billing History
          //     </u>{" "}
          //   </button>
          // }
          position="right center"
          modal
          closeOnDocumentClick={false}
        >
          
            <div className="componentcss">
              <div className="modal-header mb-4">
                <div class="popup-title">
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => {this.props.close();}}
                    // style={{ marginTop: "10px" }}
                    style={{ position: 'absolute', right: '10px', fontSize: '25px' }}
                    aria-label="Close"
                  >
                    ×
                  </button>
                  <span>
                    Billing Summary <Showhelp gotohelp="billing_summary_help"/>
                  </span>
                </div>
              </div>
              <div className="modal-body mylistbody">
                <div style={{ float: "left", width: "100%", height: "470px" , overflowY: "scroll" }}>
                    
                  <div className="mylist">
                   {/* <CommonGrid
                    style={gridstyle}
                    key={this.state.key}
                    handleButton={this.handleButton}
                    crud={this.state.mylist_crud}
                  />  */}

                                  <table className="calendar table ">
                                    <thead>
                                      <tr>
                                        <th style={{width: '200px'}}>Date</th>
                                        <th>Invoice No</th>
                                        <th>Service ID</th>
                                        <th>Service </th>
                                        <th>MBS Item</th>
                                        <th>Provider</th>
                                        <th> Billed To</th>
                                        <th>Status</th>
                                        <th>Fee</th>
                                        <th>Paid</th>
                                       
                                        <th>Owing</th>
                                        <th> Recorded By</th>
                                        <th> Recorded On</th>

                                      </tr>
                                    </thead>
                                    
                                    <tbody>
                                       
                                      {this.state.patients_billing.length>0 && this.state.patients_billing.map((item)=> (
                                      <tr

                                      
                                     className={item.status!="Sent"? 'red_highlight': 'white_highlight'}
                                       
                                       >
                                        <td> {moment(item.recordedAt).format('DD/MM/YYYY')}</td>
                                      <td> {item.patients_careplan_id} </td>
                                      <td> {item.item_no} </td>
                                      <td> {item.name} </td>
                                      <td> {item.item_no} </td>
                                      <td> {item.doc_name} </td>
                                      <td> {item.patient_name} </td>
                                      <td> {item.status} </td>
                                      <td> {parseFloat(item.amt)+parseFloat(item.amt * parseFloat(item.tax)/100)} </td>
                                      <td> {item.status=="Sent"?parseFloat(item.amt)+(item.amt * parseFloat(item.tax)/100):"-"} </td>
                                      <td> {item.status!="Sent"?parseFloat(item.amt)+(item.amt * parseFloat(item.tax)/100):"-"} </td>
                                      <td> Dr.{item.doc_name} </td>
                                      <td> {moment(item.recordedAt).format('DD/MM/YYYY')}</td>

                                      </tr>
                                      ))}
                                       
                                    </tbody>
                                  </table>
                  


                  </div>  
                </div>
                 
              </div>
              <div class="modal-footer" style={{ float:'left', width:'100%'}}>
              
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => {this.props.close();}}
                >
                  {" "}
                  Cancel{" "}
                </button>
              </div>
            </div>
        
        </Popup>
      );
    
     
  }
}

const mapStateToProps = state => {
  // console.log(state);
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  console.log(">>>>>>", state.getDrAppointments.getPatientDetails);
  let logged_info =
  state.getDrAppointments.logged_info != undefined
    ? state.getDrAppointments.logged_info
    : "";   
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
  } else {
    selectedPatientDetails = "";
  }
  console.log("selectedPatientDetails",selectedPatientDetails);
  return {
    selectedPatientDetails, logged_info
  };
};

const mapDispatchToProps = dispatch => {
  return {
    api_summary_billing: data => dispatch(api_summary_billing(data)),
     
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(billingHistory);
