import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import moment from "moment";
import { connect } from 'react-redux';
import {NotificationContainer,NotificationManager,} from 'react-notifications';
import {
    api_searchPatients
    
  } from "./../../../DS/DS.Appointments";
import Showhelp from './../../showHelp';

class selectPatient extends Component {
  constructor(props) {
    super(props);
    this.state={
        patients: [],
        searchType: [],
        searchText: "",
        isActive:""
    }
  }
     
  handleChangeText = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
    this.searchPatient(e.target.value);
  };
  searchPatient(searchvalue) {
      this.setState({searchText: searchvalue});
    var searchParam = {
      searchType: this.state.searchType,
      searchText: searchvalue
    };
    this.props.searchPatients(searchParam).then(patient => {
      this.setState({ patients: patient.data.content });
    });
  }
  toggleActive = i => {
    if (i === this.state.isActive) {
      this.setState({
        isActive: null
      });
    } else {
      this.setState({
        isActive: i
      });
    }
  };
 
 render() {
    return (
      <Popup
       open={this.props.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
         
            <div>
                <div className="modal-header">
                <div className="popup-title">
                    <span id="">Select Patient <Showhelp gotohelp="select_patients_values"/></span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                    <span aria-hidden="true" onClick={(e)=>this.props.closeSelectPatient()} >×</span>
                    </button>
                </div>
                </div>
                <div className="modal-body" style={{color: '#000'}}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <div className="col-sm-3 pr-0 pl-0"> 
                                    <label> Search by Name / DOB:</label>
                                </div>
                                <div className="col-sm-4 pr-0 cursor">
                                    <input type="text" name="text"   onKeyPress={event => {
                  if (event.key === "Enter") {
                    this.searchPatient();
                  }
                }} className="form-control" 
                
                value={this.state.searchText}
                className="form-control input-lg app-seaqch"
                placeholder="Search by Name / Dob / Medical No"
                onChange={this.handleChangeText}
                /> 
                                    
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row row-distance">
                        <div className="col-sm-12 pl-1"> 
                        <div
              className="box-body no-padding patsearch "
              style={{
                height: "400px",
                overflowY: "scroll",
                marginLeft: "20px",
                marginTop: "10px"
              }}
            >
              <table className="table table-striped table-hover input-sm">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Address</th>
                    <th>D.O.B.</th>
                    <th>Medicare No</th>
                  </tr>
                  {this.state.patients != undefined && this.state.patients.length > 0 ? (
                    this.state.patients.map((patient, i) => (
                      <tr
                        className="pointercursor"
                        style={
                          this.state.isActive === i
                            ? { background: "black", color: "white" }
                            : { background: "" }
                        }
                        key={i}
                        name="patient_id"
                        onClick={() => {
                          this.props.getPatient(patient, i);
                          this.toggleActive(i);
                          
                        }}
                      >
                        <td>{patient.Firstname}</td>
                        <td>{patient.age}</td>
                        <td>
                          {patient.Address1 != null
                            ? patient.Address1 + ", "
                            : ""}
                          {patient.Address2 != null
                            ? patient.Address2 + ", "
                            : ""}
                          {patient.City != null ? patient.City + ", " : ""}
                          {patient.Postcode}
                        </td>
                        <td> {moment(patient.DOB).format("DD-MM-YYYY")} 
                        
                        
                        
                        </td>
                        <td> </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No Records Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
                        </div>
                         
                         
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col-sm-6"> </div>
                        <div className="col-sm-6">
                            
                           
                            <button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close"  onClick={(e)=>this.props.closeSelectPatient()}>Select Patient </button> 
                            <button type="button" className="btn btn-default" data-dismiss="modal" aria-label="Close"  onClick={(e)=>this.props.closeSelectPatient()}>Cancel</button> 
                        </div>
                    </div>
                </div>
                 
            </div>
         
        </Popup>
    )}
}

const mapDispatchToProps = dispatch => {
    return {
        searchPatients: searchParam => dispatch(api_searchPatients(searchParam))
        
     };
  };
  const mapStateToProps = state => {
  
    let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
    
    return {
        redux_userId
    };
  };
  export default connect(mapStateToProps,mapDispatchToProps)(selectPatient);
