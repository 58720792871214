import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {api_add_new_Pregnancy,api_get_pregnancy_no,api_get_parity,api_get_misscarriages,api_get_termination,api_check_pregno} from '../../../DS/DS.ObstetricHistory'
import { api_getDoctors } from '../../../DS/DS.Appointments';
import Showhelp from './../../showHelp';


class PregnancyDetails extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showModal,
            tosave:[],
            pregnancy_no:'',
            ivf:false,
            managed_by:0,
            description:'',
            cravidity:'',
            parity:'',
            miscarriages:'',
            terminations:''
        }
    }

componentWillMount=()=>
{
  this.setState({tosave:this.props.saveData})
  this.props.getDoctors().then(res => {
    this.setState({
      docs: res.data.content ,
    })});
    const patient=this.props.redux_patientId
    this.props.getPregnancyNo({pat:patient}).then(res=>
        {
            if(res.data.content!='')
            {
            console.log(">>>>>>>>",res.data.content[0].total)
            this.setState({pregnancy_no :parseInt(res.data.content[0].total) + 1,cravidity:parseInt(res.data.content[0].total) + 1})
            }
        })
        this.props.getParity({pat:patient}).then(res=>
            {
                if(res.data.content!='')
                {
                    this.setState({parity :parseInt(res.data.content[0].total)})
                }
            }) 

    this.props.getMisscarriages({pat:patient}).then(res=>
                {
                    if(res.data.content!='')
                    {
                        this.setState({miscarriages :parseInt(res.data.content[0].total)})
                    }
                }) 

    this.props.getTermination({pat:patient}).then(res=>
                    {
                        if(res.data.content!='')
                        {
                            this.setState({terminations :parseInt(res.data.content[0].total)})
                        }
                    }) 
        
     console.log("&&&&&&&&&&&&&",this.props)   
}
alertPregnancy=(val)=>
{
    this.props.checkpregno({pat:this.props.redux_patientId,pregno:val}).then(res=>{
        if(res.data.content!='')
        {
           if(res.data.content[0].total==0)
           {
            this.setState({pregnancy_no:val})
           } 
           else
           {
               alert('Pregnancy no. exists.Please change pregnancy no.')
               this.setState({pregnancy_no:this.state.pregnancy_no})
           }
        }
    })
}
saveData=()=>
{
   
    const saveConst={
        edcbyScan:this.props.saveData.edcbyscan,
        usescan:this.props.saveData.usescan,
        lmpscan:this.props.saveData.lmpscan,
        scanweek:this.props.saveData.scanweek,
        scanday:this.props.saveData.scanday,
        finaldate:this.props.saveData.finaldate,
        lmp:this.props.saveData.lmp,
        edc:this.props.saveData.edc,
        preg:this.state.pregnancy_no,
        ivf:this.state.ivf,
        description:this.state.description,
        crav:this.state.cravidity,
        par:this.state.parity,
        misca:this.state.miscarriages,
        term:this.state.terminations,
        pati:this.props.redux_patientId,
        docid:this.props.redux_docId,
        

    }
    console.log("&&&&&&&&&&&&&&",saveConst)
    this.props.addPregnancy({constdata:saveConst}).then(res=>
        {
            this.props.changeKey() 
            //this.props.changeKey() //this.props.changeKey() //this.props.changeKey()
        })
}

render(){
    return(
        <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
        {close => (
            <div>  
                <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                    <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}> Pregnancy Details <Showhelp gotohelp="pregnancy_details_help"/></h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{color:'black'}}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body newpostpopup" style={{ fontSize: "14px" }}>
                    <div className="col-sm-12" style={{marginBottom: '10'}}>
                        <div className="form-group row">
                            <div className="col-sm-3 pr-0"><label className="col-form-label text-left">Pregnancy number:</label></div>
                            <div className="col-sm-5"><input type="text" name="search_condition" className="form-control" value={this.state.pregnancy_no} onChange={(e)=>this.alertPregnancy(e.target.value)} /></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3"><label className="col-form-label text-left">Managed by:</label></div>
                            <div className="col-sm-5">
                            <select style={{width:"50%",height:"25px"}} onChange={(e) => this.setState({ from_guid: e.target.value })} className="selectoption">
                                <option value={this.state.from_guid}></option>
                                { this.state.docs !=undefined && this.state.docs.map((item,i) => (
                                <option value={item.doctor_id}> {item.Firstname} </option>
                                ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12 mt-3">
                                <textarea style={{width: '100%', height: 80, float: 'left', border: '1px solid rgb(221, 221, 221)', padding: 10}} defaultValue={""} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-4"><input type="checkbox" name="search_condition" onChange={(e)=>this.setState({ivf:e.target.checked})} 
                            checked={this.state.ivf==1? true:''}/> IVF Pregnancy</div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-2"><label className="col-form-label text-left">Gravidity:</label></div>
                            <div className="col-sm-3"><input type="text" name="search_condition" className="form-control" onChange={(e)=>this.setState({cravidity:e.target.value})} value={this.state.cravidity}/></div>
                            <div className="col-sm-2"><label className="col-form-label text-left">Parity:</label></div>
                            <div className="col-sm-3"><input type="text" name="search_condition" className="form-control"  onChange={(e)=>this.setState({parity:e.target.value})} value={this.state.parity} /></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-2"><label className="col-form-label text-left">Miscarriages:</label></div>
                            <div className="col-sm-3"><input type="text" name="search_condition" className="form-control"  onChange={(e)=>this.setState({miscarriages:e.target.value})} value={this.state.miscarriages}/></div>
                            <div className="col-sm-2"><label className="col-form-label text-left">Terminations:</label></div>
                            <div className="col-sm-3"><input type="text" name="search_condition" className="form-control"  onChange={(e)=>this.setState({terminations:e.target.value})} value={this.state.terminations}/></div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button"  onClick={() => this.saveData()} className="btn btn-primary btn-xs" data-dismiss="modal">Save</button>
                    <button type="button"  onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs" data-dismiss="modal">Cancel</button>
                </div>   
            </div>     
        )}     
        </Popup>
      )
   }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
   //console.log(state.getDrAppointments.logged_info.content.details[0])
  return {
    redux_patientId,redux_docId
  };
};
const mapDispatchToProps = (dispatch) => {
	return {
    getDoctors: data => dispatch(api_getDoctors(data)),
    getPregnancyNo:data=>dispatch(api_get_pregnancy_no(data)),
    addPregnancy:data=>dispatch(api_add_new_Pregnancy(data)),
    getParity:data=>dispatch(api_get_parity(data)),
    getMisscarriages:data=>dispatch(api_get_misscarriages(data)),
    getTermination:data=>dispatch(api_get_termination(data)),
    checkpregno:data=>dispatch(api_check_pregno(data))
    

    
  }
}
export default connect (mapStateToProps, mapDispatchToProps) (PregnancyDetails);



