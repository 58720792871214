import React, { Component } from 'react';


class previousresult extends Component {
    state = {  }
   
    render() { 
        return ( 
           
                      <div>

                      <div className="modal-body">
                        
                            <div className="col-sm-11">
                                <table className="calendar table table-bordered tablestyle" style={{float: 'left', overflow: 'scroll', width: '100%'}}>
                                <thead>
                                    <tr style={{backgroundColor: '#f4f4f4'}}>
                                    <th>Date</th>
                                    <th>Test Name</th>
                                    <th>Checked by</th>
                                    <th>Date Checked</th>
                                    <th>Action</th>
                                    <th>Comment</th>
                                    <th>Provider</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td style={{height: '200px'}}>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    </tr>        
                                </tbody>
                                </table>
                                <div className="m-t-0" style={{float: 'left', overflowY: 'scroll', paddingLeft: '0px', width: '100%', height: '200px'}}>
                                <table className="calendar table table-bordered" style={{backgroundColor: '#ffffff'}}>
                                    <tbody>
                                    <tr style={{backgroundColor	: '#f4f4f4'}}>
                                        <td style={{padding: '5px 0px 5px 10px'}}><img src="images/pdfscreenshot.png" style={{width: '100%'}} /></td>
                                    </tr>												
                                    </tbody>
                                </table>
                                </div>
                               
                                <div className="col-sm-12">
                                
                                <div className="col-sm-4" style={{paddingRight: '',marginTop:"15px"}}>
                                    <input type="checkbox" className=""/>&nbsp;Add an entry to the actions database
                                </div>
                                <div className="col-sm-3" style={{marginTop:"15px"}}>Due on</div>
                                <div className="col-sm-2" style={{marginTop:"15px"}}> <input type="text" defaultValue="11-09-2019" style={{paddingLeft: '0px'}} name="search_condition" className="" /></div>
                                <div  className="col-sm-3" ></div>
                                </div>
                            </div>
                     </div>

               </div>

         );
    }
}
 
export default previousresult ;