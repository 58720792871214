import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_visitnotes_list_options} from './../../../../DS/DS.VisitsNotes';
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class gastrointestinal extends Component {
  state = { 
    list_options: {}
  }
  componentDidMount() {
    this.props.visitnotes_list_options().then(res => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }
    render() {
        return (
            <Popup lockScroll={false} trigger={<a  title="Gastro-intestinal" className="collapsed" aria-expanded="false"><i className="fa fa-hospital-o" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>} position="right center"   modal
            closeOnDocumentClick={false}
         >
             {close => (

     <div className="gstro">
            <div className="modal-header" 
            // style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
            >

                      <button type="button" className="c
                      lose" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> Gastro-Intestinal <Showhelp gotohelp="gastro_intestinal_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Gastro-intestinal</h4> */}
                    </div>



         <div className="modal-body"   style={{overflowY: "scroll", height: "450px" }}>
            <h4 style={{}}>History:</h4>

            <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Abdominal pain</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.AbdominalPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "AbdominalPain")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.AbdominalPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "AbdominalPain")}
                    /> No
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Nausea</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Nausea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Nausea")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Nausea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Nausea")}
                    /> No
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Vomiting</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Vomiting === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Vomiting")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Vomiting === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Vomiting")}
                    /> No
                        </div>



                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Heartburn</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Hurtburn === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Hurtburn")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Hurtburn === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Hurtburn")}
                    /> No
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Dysphagia</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Dysphagia === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Dysphagia")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Dysphagia === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Dysphagia")}
                    /> No
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Anorexia</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Anorexia === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Anorexia")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Anorexia === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Anorexia")}
                    /> No
                        </div>



                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Reflux</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Reflux === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Reflux")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Reflux === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Reflux")}
                    /> No

                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Haematemesis</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Haematemesis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Haematemesis")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Haematemesis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Haematemesis")}
                    /> No

                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Weight loss</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.WeightLoss === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "WeightLoss")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.WeightLoss === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "WeightLoss")}
                    /> No
                        </div>



                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Diarrhoea</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Diarrhoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Diarrhoea")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Diarrhoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Diarrhoea")}
                    /> No
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Constipation</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Constipation === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Constipation")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Constipation === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "Constipation")}
                    /> No
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Changed bowel habit</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.ChangedBowelHabit === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "ChangedBowelHabit")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.ChangedBowelHabit === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "ChangedBowelHabit")}
                    /> No

                        </div>



                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>PR bleeding</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.PrBleeding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "PrBleeding")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.PrBleeding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal", "PrBleeding")}
                    /> No
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>

                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>

                        </div>
                    </div>
                    &nbsp;
                  <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                    <h4 style={{}}>Examination:</h4>

                    <div className="col-sm-12">

                        <div className="col-sm-5" style={{float: 'left',marginTop: '15px'}}>
                        <input type="checkbox"  /> No tenderness &nbsp; &nbsp;
                           <input type="checkbox" /> No masses
                        </div>
                      </div>
                      <div className="col-sm-12">
                      <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '15px'}}>Tenderness</label>

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '15px'}}> Mass</label>

                    </div>

                  </div>

                  <div className="col-sm-12">

                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> RUQ &nbsp; &nbsp;

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> RUQ

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Guarding</label>

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Gaurding === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Gaurding")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Gaurding === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Gaurding")}
                    /> No
                        </div>
                  </div>
                  <div className="col-sm-12">

                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> Epigastric &nbsp; &nbsp;

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> Epigastric

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Rigidity</label>

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Rigidity === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Rigidity")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Rigidity === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Rigidity")}
                    /> No

                        </div>
                  </div>

                  <div className="col-sm-12">

                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> LUQ &nbsp; &nbsp;

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> LUQ

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Rebound</label>

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Rebound === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Rebound")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Rebound === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Rebound")}
                    /> No

                        </div>
                  </div>

                  <div className="col-sm-12">

                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> R Loin &nbsp; &nbsp;

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> R Loin

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Distension</label>

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Distension == "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Distension")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Distension == "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Distension")}
                    /> No

                        </div>
                  </div>




                  <div className="col-sm-12">

                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> Central &nbsp; &nbsp;

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> Central

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Hepatomegaly</label>

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Hepatomegaly === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Hepatomegaly")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Hepatomegaly === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Hepatomegaly")}
                    /> No

                        </div>
                  </div>


                  <div className="col-sm-12">

                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> L Loin &nbsp; &nbsp;

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> L Loin

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Splenomegaly</label>

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Splenomegaly === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Splenomegaly")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Splenomegaly === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Splenomegaly")}
                    /> No
                        </div>
                  </div>



                  <div className="col-sm-12">

                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> RIF &nbsp; &nbsp;

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> RIF

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Bowel sounds</label>

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.BowelSound === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "BowelSound")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.BowelSound === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "BowelSound")}
                    /> No
                        </div>
                  </div>


                  <div className="col-sm-12">

                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> Suprapubic &nbsp; &nbsp;

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> Suprapubic

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>
                    <label style={{marginTop: '5px'}}>Ascites</label>

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Ascites === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Ascites")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Ascites === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Ascites")}
                    /> No

                        </div>
                  </div>




                  <div className="col-sm-12">

                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> LIF &nbsp; &nbsp;

                    </div>
                    <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                    <input type="checkbox"  /> LIF

                    </div>
                    <div className="col-sm-2" style={{float: 'left'}}>


                    </div>

                  </div>

                  <div className="col-sm-12">
                    <div className="col-sm-4" style={{float: 'left',marginTop: '5px'}}>
                    <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                    <h4 style={{}}>PR Exam:</h4>

                    </div>
                    <div className="col-sm-3" style={{float: 'left',marginTop: '5px'}}>
                    <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                    <h4 style={{}}>Hernias:</h4>

                    </div>

                  </div>
                  <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>PR Blood</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PrBlood === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrBlood")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PrBlood === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrBlood")}
                    /> No

                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Right Inguinal</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.RightInguinal === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "RightInguinal")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RightInguinal === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "RightInguinal")}
                    /> No
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Left Inguinal</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.LeftInguinal === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "LeftInguinal")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.LeftInguinal === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "LeftInguinal")}
                    /> No

                        </div>



                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>PR Mass</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PrMass === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrMass")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PrMass === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrMass")}
                    /> No
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Right Femoral</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.RightFemoral === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "RightFemoral")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.RightFemoral === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "RightFemoral")}
                    /> No
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Left Femoral</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.LeftFemoral === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "LeftFemoral")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.LeftFemoral === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "LeftFemoral")}
                    /> No
                        </div>



                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>PR Tenderness</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.PrTenderness === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrTenderness")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.PrTenderness === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "PrTenderness")}
                    /> No
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Umbilical</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Umbilical === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Umbilical")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Umbilical === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Umbilical")}
                    /> No
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Incisional</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Incisional === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Incisional")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Incisional === "No"}
                      onChange={e => this.props.handleOptionChange(e, "gastrointestinal_examination", "Incisional")}
                    /> No
                        </div>

                   </div>



                   <div className="col-sm-12">
                   <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Prostatomegaly:</label>
                        </div>
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Prostatomegaly}
                            onChange={e => this.props.handleOptionChange(e, "general_examination", "Prostatomegaly")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Prostatomegaly != null &&
                          this.state.list_options.Prostatomegaly.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                 </div>

            </div>



          <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                      onClick={e => 
                        {this.props.handleSaveOptions(e, "gastrointestinal");
                        close();}
                        }
                        >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>

   </div>


)}
</Popup>
         );
    }
}


const mapDispatchToProps = dispatch => {
  return {
    visitnotes_list_options: data => dispatch(api_visitnotes_list_options(data)),
    
  };
};

export default connect(null, mapDispatchToProps)(gastrointestinal);