import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_visitnotes_list_options} from './../../../../DS/DS.VisitsNotes';
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class cardiovascular extends Component {
    state = { 
        list_options: {}
      }
      componentDidMount() {
        this.props.visitnotes_list_options().then(res => {
          //   console.log("addPatientForm addPatientForm",res);
          this.setState({ list_options: res.data.content });
          console.log(this.state.list_options);
        });
      }
    render() {
        return (
            <Popup trigger={<a  title="Cardiovascular" className="collapsed" aria-expanded="false"><i className="fa fa-h-square" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>} position="right center"   modal
            closeOnDocumentClick={false}>
             {close => (
                 <div className="cardiovascular">
                     <div className="modal-header" 
                      
                     >

                      <button type="button" className="close" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span>Cardiovascular <Showhelp gotohelp="cardiovascular_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Cardiovascular</h4> */}
                    </div>
                    <div className="modal-body"    style={{overflowY: "scroll", height: "450px" }}>
                        <h4 style={{}}>History:</h4>

                    <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Chest Pain</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.ChestPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "ChestPain")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.ChestPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "ChestPain")}
                    /> No

                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>

     <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Dyspnoea</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Dyspnoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Dyspnoea")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Dyspnoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Dyspnoea")}
                    /> No

                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Orthospnoea</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Orthospnoea === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Orthospnoea")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Orthospnoea === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Orthospnoea")}
                    /> No
                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Nocturnal Dyspnoea</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.fever === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "fever")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.fever === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "fever")}
                    /> No
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Swollen ankles</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.SwollenAnkles === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "SwollenAnkles")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.SwollenAnkles === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "SwollenAnkles")}
                    /> No

                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Palpitations</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Palpitations === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Palpitations")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Palpitations === "No"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular", "Palpitations")}
                    /> No

                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Claudication</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.Claudication == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "Cardiovascular", "Claudication")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.Claudication == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "Cardiovascular", "Claudication")}
                    /> Left
                      &nbsp; &nbsp;
                      <input
                      type="checkbox"
                      value="Both"
                      checked={this.props.vals.Claudication == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "Cardiovascular", "Claudication")}
                    /> Both
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.Claudication == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "Cardiovascular", "Claudication")}
                    /> Neither
                           
                           
                           

                        </div>
                </div>
                &nbsp;
                <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                <h4 >Examination:</h4>
                <div className="col-sm-12 ">
                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>JVP:</label>
                        </div>
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                            <input type="text" style={{width: '70px'}} 
                            value={this.props.vals_examination.JVP}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "JVP")}
                            /> cm
                        </div>

                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Ankle oedema:</label>
                        </div>

                        <div className="col-sm-2 " style={{float: 'left',marginTop: '15px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.AnkleOedema}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "AnkleOedema")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.ankle_oedema != null &&
                          this.state.list_options.ankle_oedema.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-3 "></div>

                        </div>

            <div className="col-sm-12 ">
                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Apex beat:</label>
                        </div>
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.ApexBeat}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "ApexBeat")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.apex_beat != null &&
                          this.state.list_options.apex_beat.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>intercostal space</label>
                        </div>

                        <div className="col-sm-4 " style={{float: 'left',marginTop: '5px'}}>
                            <input type="text" style={{width: '70px'}} 
                            value={this.props.vals_examination.IntercostalSpace}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "IntercostalSpace")}

                            
                            /> cm from the midline
                        </div>

                       <div className="col-sm-2 "></div>

                        </div>

            <div className="col-sm-12 ">
                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Hearts sounds:</label>
                        </div>
                        <div className="col-sm-3 " style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.HeartsSounds}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "HeartsSounds")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.hearts_sounds != null &&
                          this.state.list_options.hearts_sounds.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                           <input type="checkbox"  /> No mumers
                        </div>
                         <div className="col-sm-5 "></div>

                    </div>

                    <div className="col-sm-12 ">
                            <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Mumers:</label>
                        </div>

                            <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Volume</label>
                        </div>
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Volume}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Volume")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Volume != null &&
                          this.state.list_options.Volume.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Description</label>
                        </div>
                        <div className="col-sm-2 " style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.HeartsSounds}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "HeartsSounds")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.hearts_sounds != null &&
                          this.state.list_options.hearts_sounds.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Radiation</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Radiation}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Radiation")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Radiation != null &&
                          this.state.list_options.Radiation.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2 " >
                    </div>
                 </div>
                 <div className="col-sm-12 ">
                 <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '10px'}}>Carotid bruit</label>
                        </div>
                 <div className="col-sm-5" style={{float: 'left',marginTop: '5px'}}>
                 <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.CarotidBruit == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "CarotidBruit")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.CarotidBruit == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "CarotidBruit")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.CarotidBruit == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "CarotidBruit")}
                    /> Neither
                          
                           

                        </div>
                        </div>
            <div className="col-sm-12 ">
                            <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Peripheral pulses:</label>
                        </div>
                        <div className="col-sm-1 " style={{textAlign:'left'}}>

                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Right</label>
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>left</label>
                        </div>
                        <div className="col-sm-1 " style={{textAlign:'left'}}>

                    </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Right</label>
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>left</label>
                        </div>
                 </div>

            <div className="col-sm-12 ">
                          <div className="col-sm-2 " style={{textAlign:'left'}}>
                         </div>
                            <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '0px'}}>Brachial</label>
                        </div>

                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Brachialright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Brachialright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option!= null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Brachialleft}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Brachialleft")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option1 != null &&
                          this.state.list_options.Brachial_option1.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '0px'}}>Femoral</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Femoralright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Femoralright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Femoral}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Femoral")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
               </div>

                 <div className="col-sm-12 ">
                          <div className="col-sm-2 " style={{textAlign:'left'}}>
                         </div>
                            <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '0px'}}>Radial</label>
                        </div>

                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Radialright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Radialright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>

                          
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Radial}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Radial")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Popliteal</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Poplitealright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Poplitealright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.Popliteal}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "Popliteal")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                 </div>

                 <div className="col-sm-12 ">
                          <div className="col-sm-2 " style={{textAlign:'left' ,marginTop: '5px'}}>
                         </div>
                            <div className="col-sm-1 " style={{textAlign:'left',marginTop: '5px'}}>

                        </div>

                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>

                        </div>
                        <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>PT</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.PTright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "PTright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.PT}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "PT")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                 </div>

                 <div className="col-sm-12 ">
                          <div className="col-sm-2 " style={{textAlign:'left' ,marginTop: '5px'}}>
                         </div>
                            <div className="col-sm-1 " style={{textAlign:'left',marginTop: '5px'}}>

                        </div>

                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>

                        </div>
                        <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>DP</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.DPright}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "DPright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.DP}
                            onChange={e => this.props.handleOptionChange(e, "cardiovascular_examination", "DP")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Brachial_option != null &&
                          this.state.list_options.Brachial_option.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                   </div>

             </div>

                 <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" style={{padding:'6px 12px', fontSize:'14px',width:'auto',height:'auto'}} data-dismiss="modal"  onClick={e => 
                    {this.props.handleSaveOptions(e, "cardiovascular");
                    close();}
                    }>Save</button>
                      <button type="button" className="btn btn-primary btn-xs" style={{padding:'6px 12px', fontSize:'14px',width:'auto',height:'auto'}} onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>


      </div>

)}
</Popup>
         );
    }
}

const mapDispatchToProps = dispatch => {
    return {
      visitnotes_list_options: data => dispatch(api_visitnotes_list_options(data)),
      
    };
  };
  
  export default connect(null, mapDispatchToProps)(cardiovascular);