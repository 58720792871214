import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {api_add_new_Pregnancy} from '../../../DS/DS.ObstetricHistory'
import PregnancyDetails from './pregnancyDetails'
import Showhelp from './../../showHelp';

class AddObstetricHistory extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            open:this.props.showmodal,
            key:0,
            disableLmpDate:true,
            disableScanDate:true  ,
            cycleLength:28,
            lmpDate: new Date(),
            scanDate:new Date(),
            disableSave:true,
            edcLmpDate:'',
            allWeekDates1:'',
            allWeekDates2:'',
            w1:'',
            w2:'',
            w3:'',
            w4:'',
            w5:'',
            w6:'',
            w7:'',
            w8:'',
            w9:'',
            w10:'',
            w11:'',
            w12:'',
            w13:'',
            w14:'',
            w15:'',
            w16:'',
            w17:'',
            w18:'',
            w19:'',
            w20:'',
            w21:'',
            w22:'',w23:'',
            w24:'',
            w25:'',w26:'',w27:'',w28:'',w29:'',w30:''
            ,w31:'',w32:'',w33:'',w34:'',w35:'',w36:'',w37:'',w38:'',w39:'',w40:'' ,
            list:[],
            EDCDataTextare:'',
            openPreg:false,
            key:0,
            pasData:[],
            scanweek:0,
            scandays:0,
            edcbyScan:'',
            useScan:false,
            finaldate:new Date(),
            outcomeText:''
        }
    }
    componentWillMount=()=>
    {
        
       
    }
    /*Enable disable date boxes*/
    enableDates=(valFor,val)=>
    {
        if(valFor=='LMP' && val==true){
            this.setState({disableLmpDate:false})
            this.calculateEDCByDate()
            this.getWeeks();
        }
        else if(valFor!='Scan'){ 
            this.setState({disableLmpDate:true})
        }

        if(valFor=='Scan' && val==true){
            this.setState({disableScanDate:false})
        }
        else if(valFor!='LMP'){ 
            this.setState({disableScanDate:true})
        }
    }
    /*EDC Calculations */
    calculateEDCByDate=()=>
    {
        if(this.state.cycleLength=='')
        {
            let now = new Date();
            now.setDate(this.state.lmpDate.getDate()+280-14);
            this.setState({edcLmpDate: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)})
            const tex='EDC By Date :'+new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)
            this.setState({EDCDataTextare:tex})
            console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&',tex)
        }

        if(this.state.cycleLength==28)
        {
            let now = new Date();
            now.setDate(this.state.lmpDate.getDate()+280);
         this.setState({edcLmpDate: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)})
         const tex='EDC By Date :'+new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)
         this.setState({EDCDataTextare:tex})
        }
        if(this.state.cycleLength!=28 && this.state.cycleLength!='')
        {
            let now = new Date();
            now.setDate(this.state.lmpDate.getDate()+this.state.cycleLength);
            this.setState({edcLmpDate: now})
            const tex='EDC By Date :'+new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)
            this.setState({EDCDataTextare:tex})     
        }
        
//        var combinedString='The EDC by LMP is'.this.state.edcLmpDate;
  //      this.setState({EDCDataTextare:combinedString})
     
    }
    handleDate = date => {
        console.log(">>>>>>>>",date)
        this.setState({ lmpDate: date });
         this.calculateEDCByDate() 
    };
    addCycleLength=(val)=>
    {
        this.setState({cycleLength:val})
     //   this.calculateEDCByDate();
        if(this.state.cycleLength!=28 && this.state.cycleLength!='')
        {
           // alert("3",this.state.cycleLength)
            console.log("****** Cycle length",this.state.cycleLength)
            console.log("****** lmp date day length",this.state.lmpDate.getDate()+280)
            let now = new Date();
            console.log("****** now-old",now)
            now.setDate(this.state.lmpDate.getDate()+this.state.cycleLength);
            console.log("****** now",this.state.lmpDate.getDate()+this.state.cycleLength)
            console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(now));
        //    this.setState({edcLmpDate: now})
            this.setState({edcLmpDate: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)})
        }
     /* let now = new Date();
      console.log("****** now-old",now)
      now.setDate(this.state.lmpDate.getDate()+parseInt(val)+280);
      console.log("****** now",now)
      //console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(now));
     // this.setState({edcLmpDate: now})
      this.setState({edcLmpDate: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)})
 */
    }

    getWeeks=()=>{
       
        let now2 = new Date();

     //now.setDate(this.state.lmpDate.getDate() +7);
    // now2.setDate(this.state.lmpDate.getDate() +14);
     //console.log("&&&&&&&",now)
     //this.setState({allWeekDates1:now,allWeekDates2:now2})
        var rows = [];
        for (var i = 1; i < 41; i++) {
            let now = new Date();
            console.log(7 * parseInt(i))
            console.log("DATE::::::::::::::::::::::",this.state.lmpDate.getDate())
            now.setDate(this.state.lmpDate.getDate() + parseInt(7 * parseInt(i)));
            console.log("FINAL DATE>>>>>>>>>>>>>>>>>>>",this.state.lmpDate.getDate() + (7 * parseInt(i)))
            rows.push({k:i,v:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)});
           // this.state.list.push({k:i,v:now});
        }
          this.setState({w1:rows[0].v,w2:rows[1].v,w3:rows[2].v,w4:rows[3].v,w5:rows[4].v,w6:rows[5].v,w7:rows[6].v,w8:rows[7].v,w9:rows[8].v,w10:rows[9].v
                   ,w11:rows[10].v,w12:rows[11].v,w13:rows[12].v,w14:rows[13].v,w15:rows[14].v,w16:rows[15].v,w17:rows[16].v,w18:rows[17].v,w19:rows[18].v,w20:rows[19].v
                   ,w21:rows[20].v,w22:rows[21].v,w23:rows[22].v,w24:rows[23].v,w25:rows[24].v,w26:rows[25].v,w27:rows[26].v,w28:rows[27].v,w29:rows[28].v,w30:rows[29].v
                   ,w31:rows[30].v,w32:rows[31].v,w33:rows[32].v,w34:rows[33].v,w35:rows[34].v,w36:rows[35].v,w37:rows[36].v,w38:rows[37].v,w39:rows[38].v,w40:rows[39].v 
});
//   console.log(this.state.list)
    }
    saveAllData=()=>
    {
        
        
        this.setState({pasData:{
            lmp:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format( this.state.lmpDate),
            edc:this.state.edcLmpDate,
            scanday:this.state.scandays,
            scanweek:this.state.scanweek,
            lmpscan:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format( this.state.scanDate),
            edcbyscan:this.state.edcbyScan,
            usescan:this.state.useScan,
            finaldate:this.state.finaldate
        }})
        console.log("***********",this.state.pasData)
        this.setState({openPreg:true})
    }
    cancelPopUp=()=>
    {
        this.setState({openPreg:false})
        this.props.changeKey();

    }
    
    closePop=()=>
    {
        this.setState({key:Math.random(),openPreg:false})
        this.props.changeKey();
    }
    calculateEDCByScan=(valfor,val)=>
    {
        if(valfor=='week')
        {
            this.setState({scanweek:val})
        }
        if(valfor=='day')
        {
            this.setState({scandays:val})
        }
        if(this.state.scandays!='' || this.state.scanweek!='')
        {

            if(this.state.scanweek!='' && this.state.scandays=='')
            {
                let now = new Date();
                now.setDate(this.state.scanDate.getDate() + 280- (parseInt(this.state.scanweek) * 7));
                this.setState({edcbyScan: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)})
                const tex='EDC By Scan Date :'+new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)
                this.setState({EDCDataTextare:tex})
            
            }

            if(this.state.scandays!='' && this.state.scanweek=='')
            {
                let now = new Date();
                now.setDate(this.state.scanDate.getDate() +280 - parseInt(this.state.scandays));
                this.setState({edcbyScan: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)})
                const tex='EDC By Scan Date :'+new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)
                this.setState({EDCDataTextare:tex})
            }

            if(this.state.scandays!='' && this.state.scanweek!='')
            {
                let now = new Date();
                now.setDate(this.state.scanDate.getDate() +280 - (parseInt(this.state.scanweek) * 7) - parseInt(this.state.scandays));
                this.setState({edcbyScan: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)})
                const tex='EDC By Scan Date :'+new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)
                this.setState({EDCDataTextare:tex})
            }
        }
    }
    getScanWeeks=()=>{
       
        let now2 = new Date();
        if(this.state.edcbyScan!='' )
        {
            let sub= 280 ;
            console.log("**********************",this.state.scanweek)
            console.log("**********************",this.state.scandays)
            now2.setDate(this.state.scanDate.getDate() - parseInt(this.state.scanweek * 7) - parseInt(this.state.scandays));

            if(this.state.scanweek!='' && this.state.scandays!='')
            {
            //    now2.setDate(this.state.scanDate.getDate() - parseInt(this.state.scanweek * 7) - parseInt(this.state.scandays));
              //  alert("************************ 1",now2)
            }
            if(this.state.scandays!='' )
            {
                //now2.setDate(this.state.scanDate.getDate() - parseInt(this.state.scanweek * 7));
               //alert("************************2",now2)
            }
            if(this.state.scandays=='' && this.state.scandays!='')
            {
                //now2.setDate(this.state.scanDate.getDate() - parseInt(this.state.scandays));
                //alert("************************3",now2)
            }
        
     console.log("************************",this.state.scanDate)
        var rows = [];
        for (var i = 1; i < 41; i++) {
            let now = new Date();
            console.log(7 * parseInt(i))
            console.log("************************DATE::::::::::::::::::::::",now2)
            now.setDate(now2.getDate() + parseInt(7 * parseInt(i)));
            console.log("************************FINAL DATE>>>>>>>>>>>>>>>>>>>",this.state.lmpDate.getDate() + (7 * parseInt(i)))
            rows.push({k:i,v:new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(now)});
           // this.state.list.push({k:i,v:now});
        }
          this.setState({w1:rows[0].v,w2:rows[1].v,w3:rows[2].v,w4:rows[3].v,w5:rows[4].v,w6:rows[5].v,w7:rows[6].v,w8:rows[7].v,w9:rows[8].v,w10:rows[9].v
                   ,w11:rows[10].v,w12:rows[11].v,w13:rows[12].v,w14:rows[13].v,w15:rows[14].v,w16:rows[15].v,w17:rows[16].v,w18:rows[17].v,w19:rows[18].v,w20:rows[19].v
                   ,w21:rows[20].v,w22:rows[21].v,w23:rows[22].v,w24:rows[23].v,w25:rows[24].v,w26:rows[25].v,w27:rows[26].v,w28:rows[27].v,w29:rows[28].v,w30:rows[29].v
                   ,w31:rows[30].v,w32:rows[31].v,w33:rows[32].v,w34:rows[33].v,w35:rows[34].v,w36:rows[35].v,w37:rows[36].v,w38:rows[37].v,w39:rows[38].v,w40:rows[39].v 
});
//   console.log(this.state.list)
}
    }
    selectMethod=(chk)=>
    {
       
        if(chk==true)
        {
            this.setState({useScan:'Yes',finaldate:this.state.edcbyScan})
            this.getScanWeeks();
        }
        else
        { this.setState({useScan:'No',finaldate:this.state.edcLmpDate})
            this.getWeeks();
        }
    }
    render() {
        return ( 
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}> Gestational details <Showhelp gotohelp="add_obstetric_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.changeKey()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body newpostpopup" style={{ fontSize: "14px" }}>
                        <div className="col-sm-12" style={{marginBottom: 10, textAlign: 'left'}}>
                            <div className="form-group row">
                                <div className="col-sm-2"><label className="col-form-label text-left">Date LMP:</label></div>
                                <div className="col-sm-1"><input type="checkbox" name="lmp_box" id="lmp_box" onChange={(e)=>this.enableDates('LMP',e.target.checked)} /></div>
                                <div className="col-sm-2" >
                                        <DatePicker
                                         className="datePicker"
                                         selected={this.state.lmpDate}
                                             onChange={this.handleDate}
                                            dateFormat="dd/MM/yyyy"
                                            id="clinicalcalendar" 
                                            disabled={this.state.disableLmpDate}
                                        />    
                                </div>
                                <div className="col-sm-2"><label className="col-form-label text-left">Cycle Length:</label></div>
                                <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" value={this.state.cycleLength} onChange={(e)=>this.addCycleLength(e.target.value)} /></div>
                                <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" value={this.state.edcLmpDate}/></div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-2"><label className="col-form-label text-left">Date of Scan:</label></div>
                                <div className="col-sm-1"><input type="checkbox" name="scan_box" id="scan_box" onChange={(e)=>this.enableDates('Scan',e.target.checked)}/></div>
                                <div className="col-sm-2">
                                <DatePicker
                                         className="datePicker"
                                         selected={this.state.scanDate}
                                             onChange={this.handleDate}
                                            dateFormat="dd/MM/yyyy"
                                            id="clinicalcalendar" 
                                            disabled={this.state.disableScanDate}
                                        /> 
                                </div>
                                <div className="col-sm-2"><label className="col-form-label text-left">Weeks:</label></div>
                                <div className="col-sm-1"><input type="text" name="search_condition" className="form-control" value={this.state.scanweek} onChange={(e)=>this.setState({scanweek:e.target.value})} onBlur={(e)=>{this.calculateEDCByScan('week',e.target.value)}}/></div>
                                <div className="col-sm-1"><label className="col-form-label text-left">Days:</label></div>
                                <div className="col-sm-1"><input type="text" name="search_condition" className="form-control"  value={this.state.scandays} onChange={(e)=>this.setState({scandays:e.target.value})} onBlur={(e)=>{this.calculateEDCByScan('day',e.target.value)}}/></div>
                                <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" value={this.state.edcbyScan} /></div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-12 mt-3"><textarea style={{width: '100%', height: 80, float: 'left', border: '1px solid rgb(221, 221, 221)', padding: 10}} defaultValue={""} disabled="true" value={this.state.EDCDataTextare}/></div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-12 text-center mb-3" style={{marginTop:'-15px'}}><input type="checkbox" name="search_condition" onChange={(e)=>this.selectMethod(e.target.checked)} />Use scan date   
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <table style={{width: '100%'}}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div style={{width: '100%', height: 60}}>
                                                    <table className="table-bordered" style={{float: 'left', width: '100%'}}>
                                                        <colgroup>
                                                            <col style={{width: 40}} />
                                                            <col style={{width: 150}} />
                                                            <col style={{width: 40}} />
                                                            <col style={{width: 150}} />
                                                            <col style={{width: 40}} />
                                                            <col style={{width: 150}} />
                                                            <col style={{width: 40}} />
                                                            <col style={{width: 150}} />
                                                        </colgroup>
                                                        <thead>
                                                            <tr style={{backgroundColor: 'rgb(244, 244, 244)'}}>
                                                                <th>Week</th>
                                                                <th>Start date</th>
                                                                <th>Week</th>
                                                                <th>Start date</th>
                                                                <th>Week</th>
                                                                <th>Start date</th>
                                                                <th>Week</th>
                                                                <th>Start date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{overflowY: 'auto'}} >
                                                            <tr>
                                                                <td>1</td>
                                                                <td>{this.state.w1}</td>
                                                                <td>11</td>
                                                                <td>{this.state.w11}</td>
                                                                <td>21</td>
                                                                <td>{this.state.w21}</td>
                                                                <td>31</td>
                                                                <td>{this.state.w31}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>{this.state.w2}</td>
                                                                <td>12</td>
                                                                <td>{this.state.w12}</td>
                                                                <td>22</td>
                                                                <td>{this.state.w22}</td>
                                                                <td>32</td>
                                                                <td>{this.state.w32}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>{this.state.w3}</td>
                                                                <td>13</td>
                                                                <td>{this.state.w13}</td>
                                                                <td>23</td>
                                                                <td>{this.state.w23}</td>
                                                                <td>33</td>
                                                                <td>{this.state.w33}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4</td>
                                                                <td>{this.state.w4}</td>
                                                                <td>14</td>
                                                                <td>{this.state.w14}</td>
                                                                <td>24</td>
                                                                <td>{this.state.w24}</td>
                                                                <td>34</td>
                                                                <td>{this.state.w34}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>{this.state.w5}</td>
                                                                <td>15</td>
                                                                <td>{this.state.w15}</td>
                                                                <td>25</td>
                                                                <td>{this.state.w25}</td>
                                                                <td>35</td>
                                                                <td>{this.state.w35}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td>{this.state.w6}</td>
                                                                <td>16</td>
                                                                <td>{this.state.w16}</td>
                                                                <td>26</td>
                                                                <td>{this.state.w26}</td>
                                                                <td>36</td>
                                                                <td>{this.state.w36}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7</td>
                                                                <td>{this.state.w7}</td>
                                                                <td>17</td>
                                                                <td>{this.state.w17}</td>
                                                                <td>27</td>
                                                                <td>{this.state.w27}</td>
                                                                <td>37</td>
                                                                <td>{this.state.w37}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>8</td>
                                                                <td>{this.state.w8}</td>
                                                                <td>18</td>
                                                                <td>{this.state.w18}</td>
                                                                <td>28</td>
                                                                <td>{this.state.w28}</td>
                                                                <td>38</td>
                                                                <td>{this.state.w38}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>9</td>
                                                                <td>{this.state.w9}</td>
                                                                <td>19</td>
                                                                <td>{this.state.w19}</td>
                                                                <td>29</td>
                                                                <td>{this.state.w29}</td>
                                                                <td>39</td>
                                                                <td>{this.state.w39}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>10</td>
                                                                <td>{this.state.w10}</td>
                                                                <td>20</td>
                                                                <td>{this.state.w20}</td>
                                                                <td>30</td>
                                                                <td>{this.state.w30}</td>
                                                                <td>40</td>
                                                                <td>{this.state.w40}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button"  onClick={() => this.saveAllData()} className="btn btn-primary btn-xs" data-dismiss="modal">
                            Save
                    </button>
                    <button type="button"  onClick={() => this.props.changeKey()} className="btn btn-primary btn-xs" data-dismiss="modal">
                        Cancel
                    </button>
                    {this.state.openPreg?
                    <PregnancyDetails showModal={this.state.openPreg} saveData={this.state.pasData} changeKey={this.closePop}/>:''}
                </div>   
            </div>
        )}
    </Popup>
   )}
}   

const mapDispatchToProps = dispatch => {
    return {
             apiaddpregnancy: (data) => dispatch(api_add_new_Pregnancy(data)),
           
            };
  };
  const mapStateToProps = state => {
   console.log(">>> doc data",state.getDrAppointments.logged_info)
     let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';
     let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].doctor_id:'';
 
   return {
     redux_patientId,redux_docId
   };
 };
  export default connect(mapStateToProps,mapDispatchToProps)(AddObstetricHistory);