import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import { connect } from 'react-redux';
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
  import AdminHeader from "../../adminheader";
  import Leftsidemenu from "../../leftmenu";
  import Footer from "../../footer";
  import CommonGrid from "../../crudcomponentV2/combinedcrud";
  import { confirmAlert } from 'react-confirm-alert';
  import AddCareGoals from './addCareGoals';
  import {api_delete_selection} from '../../../DS/DS.ListPage';
  import AddCareTask from './addCareTask';
  import AddProblem from './addProblem';
  import {api_restore_default} from '../../../DS/DS.CarePlans';
class CarePlans extends Component
{
    constructor(props)
    {
        super(props)
        this.state={
         reloadKey:0,
         //goal states
         addGoal:false,
         editGoal:false,
         goalSelected:'',
         goalSelectedLength:-1,
         //task states
         addTask:false,
         editTask:false,
         taskSelected:'',
         taskSelectedLength:-1,
         //template states
         openTemplate:false,
         //problem states
         addProblem:false,
         editProblem:false,
         problemSelected:'',
         problemSelectedLength:-1,
      
         goalCrud:
         {
           jsonGridVersion: "v2.0",
           grid: true,
           viewgrid: {
             isVisible:true,
             isPagination: true,
             isFiltering: true,
             isSelection:true,
             deleteFunction:this.removeGoals,
             isDateGroup:false,
             isYearSeparator:false,
             dateCol:'',
             cellClicked:'',
             baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
             query: {
               tb: "care_plan_goals_tb",
               cols: [
                 "goal_id",
                 "goal_name"
               ],
               where: [
                     {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                     },
            ],
               primary_key:"goal_id",
             },
             viewtabletitle: "",
             columns: [
               { name: "goal_name", title: "Care plan goals" }
             ],
             rows: [],
             sortinit: [{ columnName: "goal_id", direction: "desc" }],
             pagesize: 10,
             getAllIds:false,
             getallId:''
           }
   
         },
         taskCrud:
         {
           jsonGridVersion: "v2.0",
           grid: true,
           viewgrid: {
             isVisible:true,
             isPagination: true,
             isFiltering: true,
             isSelection:true,
             deleteFunction:this.removeTask,
             isDateGroup:false,
             isYearSeparator:false,
             dateCol:'',
             cellClicked:'',
             baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
             query: {
               tb: "care_plan_task_tb",
               cols: [
                 "task_id",
                 "task_name"
               ],
               where: [
                     {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                     },
            ],
               primary_key:"task_id",
             },
             viewtabletitle: "",
             columns: [
               { name: "task_name", title: "Care plan task" }
             ],
             rows: [],
             sortinit: [{ columnName: "task_id", direction: "desc" }],
             pagesize: 10,
             getAllIds:false,
             getallId:''
           }
   
         },
         problemCrud:
         {
           jsonGridVersion: "v2.0",
           grid: true,
           viewgrid: {
             isVisible:true,
             isPagination: true,
             isFiltering: true,
             isSelection:true,
             deleteFunction:this.removeProblem,
             isDateGroup:false,
             isYearSeparator:false,
             dateCol:'',
             cellClicked:'',
             baseurl: `${process.env.REACT_APP_BASE_URL}api=general&action=q`,
             query: {
               tb: "condition_master_tb",
               cols: [
                 "condition_id",
                 "name"
               ],
               where: [
                     {
                        type: "and",
                        key: "isDeleted",
                        operator: "=",
                        value: 0
                     },
            ],
               primary_key:"condition_id",
             },
             viewtabletitle: "",
             columns: [
               { name: "name", title: "Problem" }
             ],
             rows: [],
             sortinit: [{ columnName: "condition_id", direction: "desc" }],
             pagesize: 10,
             getAllIds:false,
             getallId:''
           }
   
         },
        }
    }
 reloadPage=()=>{
      this.setState({
          addGoal:false, 
          editGoal:false,
          addTask:false,
          editTask:false,
          openTemplate:false,
          addProblem:false,
          editProblem:false
          
         })
      this.setState({
          goalSelected:'',
          goalSelectedLength:-1,
          taskSelected:'',
          taskSelectedLength:-1,
          problemSelected:'',
          problemSelectedLength:-1

         })
      this.setState({reloadKey:Math.random()})
   }
removeGoals=(ids,action,totalLength)=>
   {
   if(action=='selection')
   {
     this.state.goalSelected=ids;
     this.state.goalSelectedLength=totalLength;
   }
   if(action=='delete')
   {
       if (this.state.goalSelectedLength> 0) {
             const deleteData={tb_name:'care_plan_goals_tb',ids:this.state.goalSelected,primary_key:'goal_id'}
             confirmAlert({
               title: '',
               message: 'You are about to delete selected care plan goal(s). Do you want to proceed ?',
               buttons: [
               {
                   label: 'Yes',
                   onClick: () => {
                   this.props.apiDelete(deleteData).then(
                   res => this.reloadPage() ,
                   NotificationManager.success(
                   'Care plan goal(s) deleted',
                   )
                 );
               },
           },
           {
             label: 'No',
             onClick: () => { this.reloadPage()},
           },
         ],
       });
     } 
     else 
     {
       NotificationManager.error('Please select a care plan goal(s) to be deleted');
     }
   }
}
 //edit goal 
 editGoal=()=>
 {
   // alert("IN")
     if(this.state.goalSelectedLength ==1 )
     {
        this.setState({editGoal:true})
     }
     else
     {
         alert("Please select a record to modify")
     }
 }

 removeTask=(ids,action,totalLength)=>
   {
   if(action=='selection')
   {
     this.state.taskSelected=ids;
     this.state.taskSelectedLength=totalLength;
   }
   if(action=='delete')
   {
       if (this.state.taskSelectedLength> 0) {
             const deleteData={tb_name:'care_plan_task_tb',ids:this.state.taskSelected,primary_key:'task_id'}
             confirmAlert({
               title: '',
               message: 'you are about to delete care plan task(s). Do you want to proceed?',
               buttons: [
               {
                   label: 'Yes',
                   onClick: () => {
                   this.props.apiDelete(deleteData).then(
                   res => this.reloadPage() ,
                   NotificationManager.success(
                   'Care plan task(s) deleted',
                   )
                 );
               },
           },
           {
             label: 'No',
             onClick: () => { this.reloadPage()},
           },
         ],
       });
     } 
     else 
     {
       NotificationManager.error('Please select a care plan task to be deleted');
     }
   }
}
 //edit task 
 editTask=()=>
 {
   // alert("IN")
     if(this.state.taskSelectedLength ==1 )
     {
        this.setState({editTask:true})
     }
     else
     {
         alert("Please select a record to modify")
     }
 }
 removeProblem=(ids,action,totalLength)=>
 {
 if(action=='selection')
 {
   this.state.problemSelected=ids;
   this.state.problemSelectedLength=totalLength;
 }
 if(action=='delete')
 {
     if (this.state.problemSelectedLength> 0) {
           const deleteData={tb_name:'condition_master_tb',ids:this.state.problemSelected,primary_key:'condition_id'}
           confirmAlert({
             title: '',
             message: 'You are about to delete problem(s). Do you want to proceed?',
             buttons: [
             {
                 label: 'Yes',
                 onClick: () => {
                 this.props.apiDelete(deleteData).then(
                 res => this.reloadPage() ,
                 NotificationManager.success(
                 'Problem(s) deleted',
                 )
               );
             },
         },
         {
           label: 'No',
           onClick: () => { this.reloadPage()},
         },
       ],
     });
   } 
   else 
   {
     NotificationManager.error('Please select record(s) to be deleted');
   }
 }
}
//edit task 
editProblem=()=>
{
 // alert("IN")
   if(this.state.problemSelectedLength ==1 )
   {
      this.setState({editProblem:true})
   }
   else
   {
       alert("Please select a record to modify")
   }
}
addCheck=()=>
{
  alert("Please deselect the record to proceed")
}
apiRestoreDefault=(tbl)=>
{
   const restorData={tb:tbl}
   this.props.apiRestoreDefault(restorData).then(
     res=> this.reloadPage(),
     NotificationManager.success(
      'Previous records restored Successfully',
      )
   )
}
render(){
      return (
          <div>
              <AdminHeader /><Leftsidemenu />
              <NotificationContainer />
                <section className="content-header" style={{marginLeft: '240px'}}>
                    <h1>
                        Care Plan
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
                        <li className="active">Care Plan</li>
                    </ol>
                </section>
                {/* MAIN PAGE*/ }
                  <section className="content" style={{marginLeft: '240px'}}>
                     <div className="row">
                        <div className="col-xs-12">
                           <div className="box">
		                        <div className="col-xs-12" style={{margin:'0px auto', float:'none'}}>
                                 <div className="box-body">
				                        <div className="col-sm-12 col-md-12 col-xl-12">		
							                  <div id="pageContent">
                                          <div className="form-group row m-b-10" style={{marginTop:'20px'}}>
                                            <div className="col-sm-6">
                                          <div className="col-lg-12 m-t-60">
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#care-plan-goals-add-btn" style={{width:'100px', marginRight:'7px'}}  onClick={(e)=>{this.state.goalSelectedLength >0 ?this.addCheck():this.setState({addGoal:true})}}>Add</button>
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#care-plan-goals-edit-btn" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.editGoal()}>Edit</button>
                                                   <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.removeGoals('','delete','')}>Remove</button>
                                                   <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{marginRight:'7px'}} onClick={()=>this.apiRestoreDefault('care_plan_goals_tb')}>Restore defaults</button>
	                                             </div>
	                                             <div className="col-sm-12 col-md-12 col-xl-12 m-t-10 table-border">
	                                                <div className="card" style={{paddingLeft: '0px', height:'150px', overFlowY:'scroll'}}>
	                                                   <div className="card-block">
                                                            <CommonGrid
                                                                    crud={this.state.goalCrud}
                                                                    key={this.state.reloadKey}
                                                                />    
	                                                   </div>
	                                                </div>
	                                             </div>
	                                            
                                                {this.state.addGoal ?
                                                   <AddCareGoals showModal={this.state.addGoal} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                {this.state.editGoal ?
                                                   <AddCareGoals showModal={this.state.editGoal} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.goalSelected}/> :''}

                                              </div>
                                           

                                              <div className="col-sm-6">
                                             <div className="col-lg-12 m-t-60">
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#care-plan-goals-add-btn" style={{width:'100px', marginRight:'7px'}}  onClick={(e)=>{this.state.taskSelectedLength >0 ?this.addCheck():this.setState({addTask:true})}}>Add</button>
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#care-plan-goals-edit-btn" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.editTask()}>Edit</button>
                                                   <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.removeTask('','delete','')}>Remove</button>
                                                   <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{marginRight:'7px'}} onClick={()=>this.apiRestoreDefault('care_plan_task_tb')}>Restore defaults</button>
	                                             </div>
	                                                <div className="col-sm-12 col-md-12 col-xl-12 m-t-10 table-border">
	                                                   <div className="card" style={{paddingLeft: '0px', height:'150px', overFlowY:'scroll' }}>
	                                                      <div className="card-block">
                                                         <CommonGrid
                                                                    crud={this.state.taskCrud}
                                                                    key={this.state.reloadKey}
                                                                />   
	                                                      </div>
	                                                   </div>
	                                                </div>
                                                 
                                                   {this.state.addTask ?
                                                      <AddCareTask showModal={this.state.addTask} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                   {this.state.editTask ?
                                                    <AddCareTask showModal={this.state.editTask} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.taskSelected}/> :''}
                                                </div>
                                                </div>
                                                <div className="form-group row m-b-10" style={{marginTop:'20px'}}>
                                                  <div className="col-sm-6">
                                                <div className="col-lg-12 m-t-60">
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#care-plan-goals-add-btn" style={{width:'100px', marginRight:'7px'}}  onClick={(e)=>{this.state.problemSelectedLength > 0 ?this.addCheck():this.setState({addProblem:true})}}>Add</button>
		                                             <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#care-plan-goals-edit-btn" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.editProblem()}>Edit</button>
                                                   <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" style={{width:'100px', marginRight:'7px'}} onClick={(e)=>this.removeProblem('','delete','')}>Remove</button>
                                               </div>
	                                                <div className="col-sm-12 col-md-12 col-xl-12 m-t-10 table-border">
	                                                   <div className="card" style={{paddingLeft: '0px', height:'150px', overFlowY:'scroll' }}>
	                                                      <div className="card-block">
                                                         <CommonGrid
                                                                    crud={this.state.problemCrud}
                                                                    key={this.state.reloadKey}
                                                                />   
	                                                      </div>
	                                                   </div>
	                                                </div>
                                                  
                                                   {this.state.addProblem ?
                                                      <AddProblem showModal={this.state.addProblem} popFor='Add' reloadPage={this.reloadPage}/> :''}
                                                   {this.state.editProblem ?
                                                    <AddProblem showModal={this.state.editProblem} popFor='Edit' reloadPage={this.reloadPage} ids={this.state.problemSelected}/> :''}
                                                </div> 
                                                </div>
                                                <div className="col-sm-6"></div>
                                              {/*  <div className="form-group row m-t-10">
	                                                <div className="col-sm-12 col-md-2 col-xl-2">
		                                                <label className="col-form-label text-left">&nbsp;</label>			
	                                                </div>
	                                                <div className="col-sm-12 col-md-8 col-xl-8">
		                                                <button id="session-timeout-dialog-keepalive" type="button" className="btn btn-default" data-toggle="modal" data-target="#care-plan-templates" onClick={(e)=>this.setState({openTemplate:true})}>Setup Care Plan templates</button>
	                                                </div>
	                                             </div>*
                                                {this.state.openTemplate ?
                                                <AddPlanTemplate  showModal={this.state.openTemplate} popFor='Add' reloadPage={this.reloadPage}/> :''}*/}
                                             </div>	
					                           </div>
				                           </div>
                                       <div className="modal-footer modal-footer-bottom-margin">
                                          <div className="row">
                                             <div className="col-sm-10"> </div>
                                             <div className="col-sm-2">
                                                <button type="button" className="btn btn-default" data-dismiss="modal">Save</button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </section>
                        <Footer />
         </div>   
    )}
   }
   const mapDispatchToProps = dispatch => {
      return {
          apiDelete:data =>dispatch(api_delete_selection(data)),
          apiRestoreDefault:data=> dispatch(api_restore_default(data))
       };
    };
    const mapStateToProps = state => {
    
      let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
      
      return {
          redux_userId
      };
    };
    
    
    export default connect(mapStateToProps,mapDispatchToProps)(CarePlans);
  