import React, { Component } from "react";
import moment from "moment";
import cicon from "../../../../assets/img/logo-white.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./print.css";
import Showhelp from './../../../showHelp';

class previousrequest extends Component {
  state = {
    show_preview: false,
    selectedContent: [],
  };
  //    closePreview = () => {
  //     this.setState({showCreateaccount:false});
  //    }

  // openPreview = ()  => {
  //      this.setState({showCreateaccount:true});
  // }

  preview = (item) => {
    this.setState({ selectedContent: item });
    this.props.selectedContentinPreview(item);
    console.log(item);
  };

  render() {
    return (
      <div>
        <div className="modal-body">
          <div className="col-sm-12">

          
            <div
              className="col-sm-12"
              style={{ height: "300px", overflow: "scroll" }}
            >
              <br />
              <br />
              <table
                className="  table table-bordered "
                style={{ float: "left", width: "100%" }}
              >
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#f4f4f4",
                      padding: "7px !important",
                    }}
                  >
                    <th>Request date</th>
                    <th>Test requested</th>

                    <th>Laboratory</th>
                    <th>Status</th>
                    {/* <th>Options</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.props.statecontent.previous_requests != undefined &&
                    this.props.statecontent.previous_requests.map((item) => (
                      <tr onClick={(e) => this.preview(item)}>
                        <td>
                          &nbsp;{moment(item.request_date).format("DD/MM/YYYY")}
                        </td>
                        <td>&nbsp;{item.test_id}</td>

                        <td>&nbsp;{item.lab_details_name}</td>
                        <td>&nbsp;{item.radio_status}</td>
                        {/* <td>&nbsp;<b onClick={e => this.preview(item)}> View </b></td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div
              className="col-sm-12 " id="section-to-print"
              style={{ height: "300px", overflow: "scroll", textAlign: "left" }}
            >
             
              {this.state.selectedContent.length != 0 && (
                <div>
                  <div class="row">
                    <div class="col-md-6" style={{ left: '0px',width: '50%'}}>
                      <div style={{top: '50%',width: '100%',padding: '20px'}}>
                          <img src={cicon} />
                      </div>
                    </div>
                    <div class="col-md-6" style={{right: '0px',width: '50%'}}>
                      <div style={{top: '50%',width: '100%',padding: '20px' ,marginTop: '20px',textAlign: 'right'}}>
                          <span> </span><br/>
                          <span></span>
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "left" }} className="col-md-6">
                    {this.props.type != "radiology" && (
                      <h3> PATHOLOGY REQUEST </h3>
                    )}

                    {this.props.type == "radiology" && (
                      <h3> RADIOLOGY REQUEST </h3>
                    )}
                  </div>
                  <div className="col-md-6"> </div>

                  <div className="col-md-8">
                    <b>Patient Details </b><br />
                    {this.state.selectedContent.Patient_Firstname} <br />
                    Age: {this.state.selectedContent.age} <br />
                    Gender: {this.state.selectedContent.gender == 1 ? 'Male': 'Female'} <br />
                    Medicare No: {this.state.selectedContent.Patient_Medicare}
                    

                    <hr /><br />

                   


                    <b>Requested Tests </b><br />
                    {this.state.selectedContent.test_id} <br />
                    <br /><br />
                    <b> Status </b> <br />
                    {this.state.selectedContent.radio_status} <br />
                    <br /><br />
                    <b> Copies </b><br /><pre>
                    {this.state.selectedContent.copies}</pre>
                    <br /><br />
                    <b> Due Date:  </b><br />
                    {moment(this.state.selectedContent.due_date).format("DD/MM/YYYY")}
                      <br />
                    <br />
                    <b>Notes: </b><br />
                    <b>Patient Allergies </b><br />
                    {this.props.statecontent.previous_requests[0].allergies !=undefined && this.props.statecontent.previous_requests[0].allergies.length>0 && this.props.statecontent.previous_requests[0].allergies.map(alitem => (
                      <span>{alitem.allergy_name+","}</span>
                    ))} <br /><br /><br />

                      Yours Faithfully<br />
                      <br /><br />
                      Authorised Signature

                  </div>
                  <div className="col-md-4">

                  <button  className="btn btn-primary btn-xs" onClick={e => {window.print()}}> Print </button> 
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-sm-12 mt-3">
            <div className="col-sm-4" style={{ textAlign: "left" }}>
              <input type="checkbox" className="" />
              &nbsp;Add an entry to the actions database
            </div>
            <div className="col-sm-3">Due on</div>
            <div className="col-sm-2">
              <DatePicker
                //ref={this.textInput}
                ref={(input) => {
                  this.textInput = input;
                }}
                id="date-jumper"
                inputRef={(ref) => {
                  this.textInput = ref;
                }}
                todayButton="Go to Today"
                selected={this.props.statecontent.due_on}
                onChange={this.props.handleDate2}
                showMonthDropdown
                useShortMonthInDropdown
                showYearDropdown
                dateFormatCalendar="MMMM"
                dateFormat="dd/MM/yyyy"
                yearDropdownItemNumber={15}
                scrollableYearDropdown
              />
            </div>
            <div className="col-sm-3"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default previousrequest;
