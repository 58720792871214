 import React, { Component } from 'react';
import FamilyHistory from './familyHistory';
import Occupation from './occupation';
import TobaccoHistory from './tobaccoHistory';


import Socialhistory from './socialhistory';
import Alcoholhistory from './alcoholhistory';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import Showhelp from './../../showHelp';

class FamilySocialhistory extends Component{
  state = {
    familyTable: true,
    socialTable: false,
    occupationTable: false,
    alcoholTable: false,
    tobaccoTable: false
  };
  switchTable(show) {
    if (show === "family") {
      this.setState({  familyTable: true,socialTable: false,occupationTable: false,alcoholTable: false,tobaccoTable: false});
    } else if (show === "social"){
      this.setState({  familyTable: false,socialTable: true,occupationTable: false,alcoholTable: false,tobaccoTable: false});
    }else if (show === "occupation"){
      this.setState({  familyTable: false,socialTable: false,occupationTable: true,alcoholTable: false,tobaccoTable: false});
    }else if (show === "alcohol"){
      this.setState({  familyTable: false,socialTable: false,occupationTable: false,alcoholTable: true,tobaccoTable: false});
    }else if (show === "tobacco"){
      this.setState({  familyTable: false,socialTable: false,occupationTable: false,alcoholTable: false,tobaccoTable: true});
  
    }
  }
    render(){
        return(
         <div id="notesdiv10" className="col-md-12" style={{paddingLeft: 0, paddingRight: 0}}>  
         <NotificationContainer/>
 {/*} <div className="panel panel-default">
    <div className="panel-heading panspace">
      <div className="col-lg-12 col-xl-12">
        <h4 className="panel-title">
          <a href="#" className="collapsed" aria-expanded="false">Family/Social history</a>
        </h4>
        <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}}>
          <span className="makescreen"><a href="#" title="Make This Default Screen" className="collapsed" aria-expanded="false"><i className="fa fa-star panelicon" aria-hidden="true" /></a></span>
          <a href="#" title="Email" className="collapsed" aria-expanded="false"><i className="fas fa-envelope panelicon" aria-hidden="true" /></a>
          <a href="#" title="Print" className="collapsed" aria-expanded="false"><i className="fas fa-print panelicon" aria-hidden="true" /></a>
          <a title="Export PDF" onclick="recentVisitmini();" className="collapsed" aria-expanded="false"><i className="fa fa-file-pdf-o panelicon" aria-hidden="true" /></a>
        </div>
      </div>
    </div>
        </div>*/}



      <div className="panel panel-default">
          <div
            className="panel-heading panspace">
            <div className="col-lg-12 col-xl-12">
              <h4
                className="panel-title link">
                <a
                  onClick={() => this.switchTable("family")}
                  id="obsactiv"// onclick="observationsOpennew();"
                  title="Click to see family history"
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={this.state.familyTable ? "boldd" : ""}>
                    Family History
                  </span>
                </a>
                <a
                  onClick={() => this.switchTable("social")}
                  id="graphactiv"
                  style={{ float: "left", fontSize: 16, paddingLeft: 20 }}
                  //onclick="observationsClose();"
                  title="Click to see social history "
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={this.state.socialTable ? "boldd" : ""}>
                    Social History
                  </span>
                </a>
                <a
                  onClick={() => this.switchTable("occupation")}
                  id="graphactiv"
                  style={{ float: "left", fontSize: 16, paddingLeft: 20 }}
                //  onclick="observationsClose();"
                  title="Click to see occupation details"
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={this.state.occupationTable ? "boldd" : ""}>
                    Occupation                  
                  </span>
                </a>
                <a
                  onClick={() => this.switchTable("alcohol")}
                  id="graphactiv"
                  style={{ float: "left", fontSize: 16, paddingLeft: 20 }}
                  onclick="observationsClose();"
                  title="Click to see alcohol history"
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  <span className={this.state.alcoholTable ? "boldd" : ""}>
                    Alcohol/Tobacco History
                  </span>
                </a>
                <a
                  onClick={() => this.switchTable("tobacco")}
                  id="graphactiv"
                  style={{ float: "left", fontSize: 16, paddingLeft: 20 }}
                //  onclick="observationsClose();"
                  title="Click to see tobacco history"
                  className="collapsed pointer"
                  aria-expanded="false"
                >
                  {/*<span className={this.state.tobaccoTable ? "boldd" : ""}>
                    Tobacco History
                  </span>*/}
                </a>
              </h4>
              <div style={{ width: "auto", height: 30, position: "absolute", right: 15, top: 0}} >
                <span className="makescreen">
                  <a
                    href="#"
                    title="Make This Default Screen"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    <i
                      className="fa fa-star panelicon" />
                  </a>
                </span>
                <a
                  href="#"
                  title="Email"
                  onclick="recentObs3();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-envelope panelicon"
                    aria-hidden="true" />
                </a>
                <a
                  href="#"
                  title="Print"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fas fa-print panelicon"
                    aria-hidden="true"  />
                </a>
                {/*<a href="#" title="Export XLs"><i class="fas fa-file-excel-o" aria-hidden="true" style="font-size:12px; margin-right:5px;"></i></a>*/}
                <a
                  title="Export PDF"
                  onclick="recentVisitmini();"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i
                    className="fa fa-file-pdf-o panelicon"
                    aria-hidden="true"  />
                </a>
              </div>
            </div>
          </div>
        </div>












  <div className="col-sm-12" style={{float: 'left', overflowY: 'auto', width: '100%', height: 655, padding: 10}}>
      <div className="col-sm-12 pl-0 pr-0">
          {this.state.familyTable ? <FamilyHistory />:''}
          {this.state.socialTable ? <Socialhistory />:''}
          {this.state.occupationTable ? <Occupation />:''}
          {this.state.alcoholTable ? <Alcoholhistory />:''}
          {this.state.tobaccoTable ? <TobaccoHistory />:''}
      </div>

   {/*} <div className="col-sm-12 pl-0 pr-0">
        <FamilyHistory />
        <Socialhistory />
       </div>
       <div className="col-sm-12 pl-0 pr-0">
          <Occupation />
           <Alcoholhistory />
        </div>
        <div className="col-sm-12 pl-0 pr-0">
        <TobaccoHistory />
      </div>  */}
      
   </div>		
  </div>

        )
    }
}
export default FamilySocialhistory;
