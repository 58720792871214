import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Showhelp from './../showHelp';
import {api_get_patients,api_delete_patient} from './../../DS/DS.Paitents';
import EditPatientDetails from './../appointment/editPatientDetails';
import AddPatient from './addPatient'
import ContactNote from './contactNote';
class enlistPatient extends Component {
    constructor(props)
    {
      super(props);
      this.state=
      {
        open:this.props.open,
        patient_list:[],
        selectedindex:-1,
        openEdit:false,
        key:0,
        selectedPatient:0,
        openContact:false,
        openAddPatient:false,
        searchedtext:''
      }
    }



    
    componentWillMount=()=>
    {
        console.log('&&&&&&&&&&&&&&&&&&&&',this.props)
       
    }
    searchText=(val)=>
    {
        this.setState({searchedtext:val})
        const searchData={
            searchType:'name',
            searchText:val
        }
        this.props.getPatient(searchData).then(res=>{
            if(res.data.content!='')
            {
                this.setState({patient_list:res.data.content,key:Math.random()})
            }
        })
    }
    setTrClass = (id) => {
        let classs = "";
        classs +=
          id == this.state.selectedindex
            ? " selectedBg "
            : "";
        return classs;
      };
      showSelectedContent(e, item, i) {
        this.setState({selectedindex:i,selectedPatient:item.patient_id})
      }
      closePatientDetails=()=>
      {
          this.setState({openEdit:false,key:Math.random(),openContact:false,openAddPatient:false})
      }
      checkRecord=(checkfor)=>
      {
        if(this.state.selectedindex==-1)
        {
            alert("Please select a record to proceed")
        }
        else
        {
            if(checkfor=='view')
            {
                this.setState({openEdit:true})
            }
            if(checkfor=='contact')
            {
                this.setState({openContact:true})
            }
            if(checkfor=='add')
            {
                this.setState({openAddPatient:true})
            }
        }
      }
      deleteRecord=()=>
      {
          this.props.deletePatient({patid:this.state.selectedPatient}).then(res=>
            {
               
                this.searchText(this.state.searchedtext);
            })
      }
    render(){
        return(
            <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open} >
            {close => (
                <div>  
                    <div className="modal-header" style={{width:'auto', backgroundColor:'#f4f4f4', padding: '5px 15px',color:'black'}}>
                        <h6 style={{backgroundColor:'', float:'left', width:'90%', padding:'5px 10px', fontSize:'16px',marginBottom:'0px'}}>Patient List <Showhelp gotohelp="patient_list_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.props.closeAll()} style={{color:'black'}}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ fontSize: "14px",overflow: 'scroll',height:'850px' }}>
                        <div className="row">
                            <div className="col-sm-12 c">
	                            <div className="card" style={{paddingLeft: '0px',border:'1px solid #ccc'}}>
		                            <div className="card-block">
		                                    
                                        <section class="content">
                                            <div class="row">
                                             <div class="col-md-12 search-div" style={{paddingBottom:'5px'}}>
                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                    <label for="search">Search for:</label>
                                                    <input type="text" id="search" onChange={(e)=>this.searchText(e.target.value)}/>
                                                </div>
                                                <div class="col-md-3 col-sm-4 col-xs-12">
                                                    <input type="checkbox" id="inactive" />
                                                    <label for="inactive" id="inactive-label" style={{fontWeight:'normal',paddingLeft:'5px'}} class="disabled">include inactive</label>
                                                </div>
                                                <div class="col-md-5 col-sm-4 col-xs-12">
                                                    <input type="checkbox" id="deceased" />
                                                    <label for="deceased" id="deceased-label" style={{fontWeight:'normal',paddingLeft:'5px'}} class="disabled">include deceased</label>
                                                </div>  
                                            </div>
                                            <div class="col-xs-12">
                                                <div class="box">
                                                    <div class="box-body">
                                                        <div class="table-div" style={{overFlowY:'auto',height:'45vh'}}>
                                                            <table id="example1" class="table table-bordered table-striped tableFixHead">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Address</th>
                                                                        <th>D.O.B</th>
                                                                        <th>Age</th>
                                                                        <th>Medicare No.</th>
                                                                        <th>Medicare expiry</th>
                                                                        <th>File No.</th>
                                                                        <th>Home phone</th>
                                                                        <th>Work phone</th>
                                                                        <th>Mobile</th>
                                                                        <th>Patient ID</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody id="table-body">
                                                                    {this.state.patient_list!=undefined && this.state.patient_list.map((item,i)=>(
                                                                        <tr  onClick={e =>
                                                                            this.showSelectedContent(e, item, i) }
                                                                            className={this.setTrClass(i)} >
                                                                        <td>{item.Firstname} {' '} {item.Surname}</td>
                                                                        <td>{item.Address1} {item.city}</td>
                                                                        <td>{item.DOB}</td>
                                                                        <td>{item.age}</td>
                                                                        <td>{item.medicare_no}</td>
                                                                        <td>{item.expiry}</td>
                                                                        <td>{item.RecordNo}</td>
                                                                        <td>{item.home_phone}</td>
                                                                        <td>{item.work_home}</td>
                                                                        <td>{item.medicare_no}</td>
                                                                        <td>{item.patient_id}</td>
                                                                    </tr>    
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <hr/>
                                                        <div class="bottom-buttons" style={{marginTop:'10px'}}>
                                                            <div class="col-md-6 col-sm-7">
                                                            </div>
                                                            <div class="col-md-6 col-sm-5 col-xs-12 text-right">
                                                                <button type="button" name="submit" class="btn btn-primary" id="contact" onClick={(e)=>this.checkRecord('contact')}>Contact notes</button> 
                                                                <button type="button" class="btn btn-primary" onClick={(e)=>this.checkRecord('view')}>View details</button>
                                                                <button type="button" class="btn btn-primary" onClick={()=>this.setState({openAddPatient:true})}>Add new</button>
                                                                <button type="button" class="btn btn-primary" onClick={(e)=>this.checkRecord('view')}>Add to family</button>
                                                                <button type="button" class="btn btn-primary" onClick={()=>this.deleteRecord()}>Delete</button>
                                                            </div>
                                                        </div>
                                                        {this.state.openEdit ?
                                                        <EditPatientDetails
                                                        patient_id={this.state.selectedPatient}
                                                          open={this.state.openEdit}
                                                          close={e => this.closePatientDetails()}
                                                          enlist_pat='yes'
                                                        />
                                                        :''
                                                        }
                                                        {this.state.openAddPatient ? 
                                                            <AddPatient
                                                            thenaddpatient={this.closePatientDetails()}
                                                            patient={this.state.selectedPatient}
                                                            open={this.state.openAddPatient}
                                                            />
                                                         : ""}
                                                         {this.state.openContact ?
                                                        <ContactNote
                                                             patient_id={this.state.selectedPatient}
                                                          open={this.state.openContact}
                                                          close={e => this.closePatientDetails()}
                                                          enlist_pat='yes'
                                                        />
                                                        :''
                                                        }
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                 </section>
                            </div>
	                    </div>
                    </div>
                </div>
            </div>
        </div>
        )}
        </Popup>
    )}           
}
const mapDispatchToProps = (dispatch) => {
	return {
        getPatient: (data) => dispatch(api_get_patients(data)),
        deletePatient:(data)=> dispatch(api_delete_patient(data))
     
  }
}
const mapStateToProps = state => {

    let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails:'';
    let redux_docId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details:'';
  
  return {
    redux_patientId,redux_docId
  };
  };
export default connect (mapStateToProps, mapDispatchToProps) (enlistPatient);