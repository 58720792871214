import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import logo from './../../assets/img/logo-white.png';
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Form,
} from 'react-bootstrap';
import './login.css';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { api_login, api_getUserInfo, api_setLoggedInfoRedux } from '../../DS/DS.Users';

class Login extends Component {
  state = {
    userName: '',
    password: '',
    error: '',
  };

  componentWillMount() {
   // const history=new useHistory();
    this.props.api_getUserInfo().then(loginUser => {
      console.log("logindata",loginUser);
      this.props.setLoggedInfoRedux(loginUser);

    if(loginUser && loginUser.status === 'success'){
      this.props.setLoggedInfoRedux(loginUser);
      //this.props.history.push(null, 'appointments');

//alert(">>>>>>>>>")
      window.location = '/appointments';

    }


    });
  }

  validateForm = () => {
    return this.state.userName.length > 0 && this.state.password.length > 0;
  };

  handleSubmit = async event => {
    //alert("in")
   // const history = useHistory();
    event.preventDefault();
    const loginState = await this.props.api_login({
      username: this.state.userName,
      password: this.state.password,
    });
    if (loginState && loginState.status !== 'error') {
     // alert(loginState.content.role)
      if (loginState.content.role === 'doctor') {
        window.location = '/appointments';
      //history.push("/appointments");
      } else {
        window.location = '/superadmin/lists';
      }
    } else {
      this.setState({
        error: 'login failed! Please check username and password',
      });
    }
  };

  setEmail = value => {
    this.setState({
      userName: value,
      error: '',
    });
  };

  setPassword = value => {
    this.setState({
      password: value,
      error: '',
    });
  };


  render() {
    return (
      <div className="Login" style={{width:'350px', marginLeft: '37%'}}>

<div className="login-box-body" style={{paddingBottom: '22px'}}>
  <p className="login-box-msg">

  <img src={logo} alt="Supadoc"  style={{padding: 'unset'}}/>


  </p>
  <form onSubmit={e => this.handleSubmit(e)}>
    <div className="form-group has-feedback">
      <input type="email" className="form-control" placeholder="Email"  value={this.state.userName}
              onChange={e => this.setEmail(e.target.value)} />
      <span className="glyphicon glyphicon-envelope form-control-feedback" />
    </div>
    <div className="form-group has-feedback">
      <input type="password" className="form-control" placeholder="Password"
      value={this.state.password}
      onChange={e => this.setPassword(e.target.value)}
      type="password"
      />
      <span className="glyphicon glyphicon-lock form-control-feedback" />
    </div>
    <div className="row">
    <div className="col-xs-12">
    {this.state.error ? (
            <div className="alert alert-danger" role="alert">
              {this.state.error}
            </div>
          ) : null}
    </div>
    </div>
    <div className="row">



      {/* /.col */}

      <div className="col-xs-11 mt-3 text-center">

      <button className="btn btn-primary btn-block btn-flat" type="submit"
      style={{margin:'0px auto', width:'auto'}}
        disabled={!this.validateForm()}
        type="submit"
        onClick={(e) => this.handleSubmit(e)}
      > Sign In</button>
        {/*<a className="btn btn-primary btn-block btn-flat" style={{marginLeft:'15px'}}
        disabled={!this.validateForm()}
        type="button"
        onClick={() => this.handleSubmit()}
        >
          Sign In

        </a> */}
      </div>
      {/* /.col */}
    </div>
  </form>


</div>



      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    api_login: data => dispatch(api_login(data)),
    setLoggedInfoRedux: data => dispatch(api_setLoggedInfoRedux(data)),
    api_getUserInfo: () => dispatch(api_getUserInfo()),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(Login);
