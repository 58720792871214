import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TableGroupRow } from '@devexpress/dx-react-grid';
import  {api_patientdetails_savecomments, api_patientdetails_saveBookingDetailsapi, api_patientdetails_savePatientDetails } from './../DS/DS.Appointments';
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import PatientHistory from './patientHistory/combinedpatienthistory';
import Reminders from "./patientHistory/reminders/reminders";
import {wflow} from  './../DS/general';
import Finalisevisit from './crudcomponent/finalisevisit';
//import CreateAccount from './../components/appointment/createAccount';
import Viewallergy from './patientHistory/allergies/viewallergy';
import CreateAccount from "./../components/appointment/createAccount";
import MedicalCertificate from './patientHistory/visitNotes/medicalCertificate/medicalCertificate';
import PathologyPop from './patientHistory/visitNotes/toppanel/pathologyPop';
import Showhelp from './showHelp';

class PatientDetails extends Component {
  constructor(props){
    super(props);
    console.log(">>>>>>**",this.props.selectedPatientDetails)
    this.state = {
      patient_id : this.props.selectedPatientDetails.patient_id,
      detailsAboutPatient: this.props.selectedPatientDetails.detailsAboutPatient,
      comments: this.props.selectedPatientDetails.comments,
      detailsAboutBooking: this.props.selectedPatientDetails.detailsAboutBooking,
      showPatientHistory: false,
      isTabOpen: false,
      reminderModal:false,
      key:0, 
      showCreateaccount: false,
      singledaypopup:false,
      pathologypop:false,
      color:''
      
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
  }
 
  
     componentDidMount(){
       if(this.state.patient_id!=undefined){
        this.setState({showPatientHistory: true}); 
       }
      console.log('&&&&&&&&&&&&&&&&&&&&',this.props.selectedPatientDetails)
    
     
  }
  componentWillMount=()=>
  {
    console.log('&&&&&&&&&&&&&&&&&&&&')
    console.log(this.props.selectedPatientDetails.allergies)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPatientDetails.patient_id !== this.state.patient_id) {
      //alert("loading receive props");
      this.setState({showPatientHistory: true});
      this.setState({ detailsAboutPatient: nextProps.selectedPatientDetails.detailsAboutPatient });
      this.setState({ comments: nextProps.selectedPatientDetails.comments });
      this.setState({ detailsAboutBooking: nextProps.selectedPatientDetails.detailsAboutBooking });
      this.setState({ patient_id: nextProps.selectedPatientDetails.patient_id });
      
      console.log('&&&&&&&&&&&&&&&&&&&&',nextProps.selectedPatientDetails.gender)
      if(nextProps.selectedPatientDetails.gender=='Male')
      {
        this.setState({color:'deepskyblue'})
      }
      else if(nextProps.selectedPatientDetails.gender=='Female')
      {
        this.setState({color:'#e756cf'})
      }
      else
      {
        this.setState({color:'#93908c'})
      }
      wflow("Loading Patient Details Tab - state receiveprops", nextProps, this.state);
    }
  }
  closeCreateAccount = () => {
    this.setState({showCreateaccount:false});
  }

  toggleCreateaccount = ()  => {
     this.setState({showCreateaccount:true});
  }

  tabopen = () => {
    if(this.state.isTabOpen==true){
      this.setState({isTabOpen:false});
    }
    if(this.state.isTabOpen==false){
      this.setState({isTabOpen:true});
    }
  }
     handleChange(event) {
        this.setState({
          comments: event.target.value
        });
    }

    handleChange2(event) {
      this.setState({
        detailsAboutPatient: event.target.value
      });

    }

    handleChange3(event) {

      this.setState({
        detailsAboutBooking: event.target.value
      });

    }

    saveComments(e){ 
      e.preventDefault();
      let savedata = {
        patient_id : this.state.patient_id,
        comments: this.state.comments
      }
      this.props.saveCommentsapi(savedata);
      NotificationManager.success("Patient Comments Saved Successfully!");
      console.log("save coments");
    }
    savePatientDetails(e){
      e.preventDefault();
      let recordDate=new Date().toLocaleString();
      let savedata = {
        patient_id : this.state.patient_id,
        detailsAboutPatient: this.state.detailsAboutPatient+'-' + recordDate
      }
      this.props.savePatientDetailsapi(savedata);
      NotificationManager.success("Patient Details Saved Successfully!");
      this.setState({
        detailsAboutPatient: this.state.detailsAboutPatient+'-' + recordDate
      });
      console.log("save aptients")
    }
    saveBookingDetails(e){
      e.preventDefault();
      let savedata = {
        patient_id : this.state.patient_id,
        detailsAboutBooking : this.state.detailsAboutBooking
      }
      this.props.saveBookingDetailsapi(savedata);
      NotificationManager.success("Patient Booking Details Saved Successfully!");
      console.log("save bookings")
    }
    callReminder =()=>
    {
      this.setState({reminderModal:true})
    }
    updatePage=()=>
    {
      this.setState({reminderModal:false,singledaypopup:false,pathologypop:false})
      this.setState({key:Math.random()})
    }
    openSingleDay=()=>
    {
     // alert(">>>>>>>>>>>>>>>")
      this.setState({singledaypopup:true}) 
    }
    openPathoogyPopUp=()=>
    {
      this.setState({pathologypop:true}) 
    }
    render() {


      const {selectedPatientDetails } = this.props;
        return (

            <div>
              <NotificationContainer />
                <div className="col-md-12">
  {/* Box Comment */}
  {/*<div className="box box-widget box-default collapsed-box my-sm-1">*/}
    <div className="box-header with-border" style={{padding: 0, border: '0.5px solid #ddd', /*backgroundColor:'#ececec'*/backgroundImage: 'linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%)',backgroundRepeat: 'repeat-x'}}>
      <div className="user-block" style={{padding:'7px 65px',border:'0px solid #ddd',
      borderTopLeftRadius: '3px',borderTopRightRadius: '3px',color: '#ffffff!important',
      background:this.state.color}}>
      <div>
  <h4 className="panel-title3" style={{float: 'left', width: '100%'}}>
      <label style={{float: 'left', marginRight: 5,'fontWeight': 'normal'}}>Patient Details - <Showhelp gotohelp="patient_details_help"/></label>

      <label className="label-input100" style={{paddingBottom: 0, paddingTop: 0}}>&nbsp;<span style={{fontWeight: 600, color: '#fff'}}> {selectedPatientDetails.Firstname} </span></label>
      {/* <label className="label-input100" style={{paddingBottom: 0, paddingTop: 0}}>Arrived : <span className="arrived" style={{fontSize: 16, fontWeight: 'normal', color: '#ff0000'}}>&nbsp; {(this.props.selectedPatientDetails.isArrived != undefined && selectedPatientDetails.isArrived.isArrived==1)? 'Yes':'No'}</span></label> */}
      <label className="label-input100" style={{paddingBottom: 0, paddingTop: 0}}>&nbsp;<span style={{fontWeight: 600, color: '#fff'}}>{selectedPatientDetails.DOB} ({selectedPatientDetails.age} Years)</span></label>
      <label className="label-input100" style={{paddingBottom: 0, paddingTop: 0}}>&nbsp;<span style={{fontWeight: 600, color: '#fff'}}> {selectedPatientDetails.phone} </span></label>
      <label className="label-input100" style={{paddingBottom: 0, paddingTop: 0}}>Comments : <span style={{fontWeight: 600, color: '#fff'}}>&nbsp; {selectedPatientDetails.comments!=''? 'Yes': 'No'}</span></label>
      <label className="label-input100" style={{paddingBottom: 0, paddingTop: 0}}>Allergies : <span style={{fontSize: 12, fontWeight: 'normal', color: '#017a0a', background: '#ffffff', padding: '5px 10px 5px 10px',  borderRadius: '50px'}}>{this.props.selectedPatientDetails.allergies != undefined? 'Yes': 'No'}</span></label>
      <label className="label-input100" style={{paddingBottom: 0, paddingTop: 0}}>Pension : <span style={{fontSize: 12, fontWeight: 'normal', color: '#017a0a', background: '#ffffff', padding: '5px 10px 5px 10px',  borderRadius: '50px'}}>{selectedPatientDetails.pensionNo!=''? 'Yes':'No'} </span></label>
      <label className="label-input100" style={{paddingBottom: 0, paddingTop: 0}}>Medicare : <span style={{fontSize: 12, fontWeight: 'normal', color: '#017a0a', background: '#ffffff', padding: '5px 10px 5px 10px',  borderRadius: '50px'}}>{selectedPatientDetails.medicare_no!=null? 'Yes':'No'}</span></label>
    </h4>
  <div style={{float: 'right', margin: '-33px 30px 0px 0px'}}>
  {/*<button id="session-timeout-dialog-logout" data-toggle="modal" data-target="#finalisevisit" type="button" className="btn btn-default" style={{float: 'left', margin: '-4px  10px 0px 0px', width: 100}}>Finalise Visit</button>*/}
   <Finalisevisit toggleCreateaccount={this.toggleCreateaccount} activetagPatientDetails={this.props.activetagPatientDetails} />
   <CreateAccount open={this.state.showCreateaccount}  closeCreateAccount={this.closeCreateAccount}/>
   <button type="button"  data-toggle="modal" data-target="#myModaltest" className="btn btn-success btn-xs" style={{float: 'left', margin: '0px 10px 0px 0px'}} onClick={()=>{this.callReminder()}}>Reminders</button>
   {(this.state.reminderModal)?
   <Reminders showModal={this.state.reminderModal} changeKey={this.updatePage} patient=''/>:''}
   
   {(this.state.singledaypopup)?
   <MedicalCertificate showModal={this.state.singledaypopup} changeKey={this.updatePage} isSingle="Yes"/>:''}
  {(this.state.pathologypop)?
      <PathologyPop type="pathology" showNote={this.state.pathologypop} changeKey={this.updatePage}/>
  :''}
    <ul style={{float: 'left','listStyleType': 'none', paddingLeft:'0px'}}>
      {/*<li style="width:25px; height:25px; padding: 0px 0px 0px 0px; margin-right:5px; text-align:center; font-size:18px; float:left; border-radius:20px; background-color:#none; color:#333333;" title="Medical Certificate">
<i class="fas fa-certificate" aria-hidden="true" style="font-size:24px; margin-right:10px;"></i></li>*/}
      <li style={{width: 25, height: 25, padding: '5px 0px 0px 0px', marginRight: 5, textAlign: 'center', fontSize: 12, float: 'left', borderRadius: 20, backgroundColor: '#ffffff', color: '#333333',cursor: 'pointer'}} title="Single day -Work" onClick={()=>{this.openSingleDay()}}>S</li>
      <li style={{width: 25, height: 25, padding: '5px 0px 0px 0px', marginRight: 5, fontSize: 12, textAlign: 'center', float: 'left', borderRadius: 20, backgroundColor: '#ffffff', color: '#333333'}} title="Favorite blood test" onClick={()=>{this.openPathoogyPopUp()}}><i className="fas fa-vials" aria-hidden="true" style={{fontSize: 12, paddingLeft: 5, textAlign: 'center', marginRight: 5}} /></li>
      <li style={{width: 25, height: 25, padding: '5px 0px 0px 0px', fontSize: 12, textAlign: 'center', float: 'left', borderRadius: 20, backgroundColor: '#ffffff', color: '#333333'}} title="Bulk billed 23">B</li>
    </ul>
  </div></div>

      </div>
      {/* /.user-block */}
      <div className="box-tools" style={{top: 15}}>
        <button onClick={this.tabopen} type="button" className="btn btn-box-tool" data-widget="collapse" style={{background:'#f1f1f1'}}>
          {!this.state.isTabOpen && <i className="fa fa-plus fa-2x" />}
          {this.state.isTabOpen && <i className="fa fa-minus fa-2x" />}
        </button>
      </div>
      {/* /.box-tools */}
    </div>
    {/* /.box-header */}
   {this.state.isTabOpen &&  <div className="box-body" style={{backgroundColor: '#ffffff'}}>

    <div className="col-sm-12 col-md-3 col-xl-3 m-t-0 m-b-0" style={{paddingLeft: 0}}>
  <div className="form-group row">
    <label className="col-sm-4 col-form-label text-left patientcell">Address</label>
    <label className="col-sm-8 col-form-label text-left patientcell1"> &nbsp; {selectedPatientDetails.Address1} {selectedPatientDetails.Address2} {selectedPatientDetails.City} {selectedPatientDetails.Postcode} </label>
    <label className="col-sm-4 col-form-label text-left patientcell">Medicare No</label>
    <label className="col-sm-8 col-form-label text-left patientcell1"> &nbsp; {selectedPatientDetails.medicare_no}</label>
    <label className="col-sm-4 col-form-label text-left patientcell">Occupation</label>
    <label className="col-sm-8 col-form-label text-left patientcell1"> &nbsp; {selectedPatientDetails.occupation}</label>
    <label className="col-sm-4 col-form-label text-left patientcell">Age</label>
    <label className="col-sm-8 col-form-label text-left patientcell1">&nbsp; {selectedPatientDetails.age} Yrs</label>
    <label className="col-sm-4 col-form-label text-left patientcell">Sex</label>
    <label className="col-sm-8 col-form-label text-left patientcell1">&nbsp; {selectedPatientDetails.gender}</label>
    <label className="col-sm-4 col-form-label text-left patientcell">Phone</label>
    <label className="col-sm-8 col-form-label text-left patientcell1"> &nbsp; {selectedPatientDetails.phone}</label>
    <label className="col-sm-4 col-form-label text-left patientcell">Pension No</label>
    <label className="col-sm-8 col-form-label text-left patientcell1"> &nbsp; {selectedPatientDetails.pensionNo}</label>
    <label className="col-sm-4 col-form-label text-left patientcell">Tobacco</label>
    <label className="col-sm-8 col-form-label text-left patientcell1 tobacco" style={{cursor: 'pointer'}}>&nbsp;  {selectedPatientDetails.isTobacco==1? 'Yes':'No'}</label>
    <label className="col-sm-4 col-form-label text-left patientcell">Work</label>
    <label className="col-sm-8 col-form-label text-left patientcell1"> &nbsp; {selectedPatientDetails.occupation!= null ? 'Yes':'No'}</label>
    <label className="col-sm-4 col-form-label text-left patientcell ">Alcohol</label>
    <label className="col-sm-8 col-form-label text-left patientcell1 alcohol" style={{cursor: 'pointer'}}> &nbsp;  {selectedPatientDetails.isAlcohol==1? 'Yes':'No'}</label>
    <label className="col-sm-4 col-form-label text-left patientcell">Allergies</label>
    <label className="col-sm-8 col-form-label text-left patientcell"><div style={{paddingTop: 0, paddingBottom: 0}} className="allergySys patientcell1">&nbsp; {(this.props.selectedPatientDetails.allergies != undefined ? this.props.selectedPatientDetails.allergies.length : '0')}</div></label>
  </div>
</div>


<div>
  <div className="col-sm-12 col-md-4 col-xl-4 m-b-0" style={{paddingRight: 0, marginTop: 2}}>
     <div className="card" style={{paddingRight: 0, paddingLeft: 0, marginTop: 0,'borderRadius': '4px','marginBottom':'5px'}}>
      <div className="card-block">
        <div className="panel panel-default" style={{float: 'left', width: '100%', marginBottom: 0}}>
          <div className="panel-heading" style={{float: 'left', width: '100%', padding: 5,height: '25px'}}>
            <div className="col-lg-12 col-xl-12" style={{paddingLeft: 0, paddingRight: 0}}>
              <h6 className="panel-title" style={{float: 'left', width: '100%', fontWeight: 'bold', display: 'inline', marginRight: 10}}>
                <a href="#" style={{float: 'left', fontSize: 12}}>Comments</a>
              </h6>
              <div style={{width: 'auto', height: 30, position: 'absolute', right: 0, top: 2}}>
                <a onClick={(e) => this.saveComments(e)} href="#" title="Save"><i className="fa fa-save" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>
              </div>
            </div>
          </div>
        </div>
        <textarea

        value={selectedPatientDetails.isArrived.comments}
        name="comments"
        onChange={(event) => {this.handleChange(event)}}
        placeholder="Comment"
        style={{height: 95, width: '100%', border: '1px solid #cccccc', marginBottom: 0, marginTop: 0, padding: 10}}


        />
      </div>
    </div>
    <div className="card" style={{paddingRight: 0, paddingLeft: 0, marginTop:0, overflow: 'scroll', height: 138, border: '1px solid #ccc'}}>
      <div className="card-block">
        <div className="panel panel-default" style={{float: 'left', width: '100%', marginBottom: 0}}>
          <div className="panel-heading" style={{float: 'left', width: '100%', padding: 5}}>
            <div className="col-lg-12 col-xl-12" style={{paddingLeft: 0, paddingRight: 0}}>
              <h6 className="panel-title" style={{float: 'left', width: '100%', fontWeight: 'bold', display: 'inline', marginRight: 10}}>
                <a href="#" style={{float: 'left', fontSize: 12}}>Allergies</a>
              </h6>
              <div style={{width: 'auto', height: 30, position: 'absolute', right: 0}}>
               {/* <a href="#" title="Save"><i className="fa fa-save" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>*/}
                <Viewallergy/> 
               {/* <a href="#" title="Delete"><i className="fa fa-trash" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>*/}
                <a href="#" title="Print"><i className="fa fa-print" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>
              </div>
            </div>
          </div>
        </div>
        <table style={{width: '100%'}}>
          <tbody><tr style={{backgroundColor: '#f4f4f4'}}>
              {/*<td style={{padding: '5px 0px', width: '10%'}}>&nbsp; <input type="checkbox" /></td>*/}
              <td style={{padding: '5px 0px', width: '40%'}}>&nbsp;Item</td>
              <td style={{padding: '5px 0px', width: '30%'}}>&nbsp;Reaction</td>
              <td style={{padding: '5px 0px', width: '30%'}}>&nbsp;Severity</td>
            </tr>
          </tbody></table>
        <div style={{float: 'left', width: '100%', display: 'block'}}>
          <table style={{width: '100%', marginBottom: 0}} className="calendar table table-bordered">
            <tbody>
            {this.props.selectedPatientDetails.allergies != undefined ? this.props.selectedPatientDetails.allergies.map((allergy) => (
              <tr key={allergy.allergy_name}>

              {/*}  <td style={{ width: '10%'}}>&nbsp;<input type="checkbox" value={allergy.patients_allergies_id} /> </td> */}
                <td style={{ width: '40%'}}>&nbsp;{allergy.allergy_name}</td>
                <td style={{ width: '30%'}}>&nbsp;{allergy.reaction}</td>
                <td style={{ width: '30%'}}>&nbsp;{allergy.severity}</td>
              </tr>
            )) : <tr><td colSpan='3'>No Allergies</td></tr>}


            </tbody></table>
        </div>
      </div>
    </div>
  </div>
  <div className="col-sm-12 col-md-5 col-xl-5 m-b-0" style={{paddingRight: 0, marginTop: 5}}>
    <div className="col-sm-12  col-md-6 col-xl-6 m-b-0" style={{paddingLeft: 0}}>
      <div className="panel panel-default" style={{float: 'left', width: '100%', marginBottom: 0}}>
        <div className="panel-heading" style={{float: 'left', width: '100%', padding: 5}}>
          <div className="col-lg-12 col-xl-12" style={{paddingLeft: 0, paddingRight: 0}}>
            <h6 className="panel-title" style={{float: 'left', width: '100%', fontWeight: 'bold', display: 'inline', marginRight: 10}}>
              <a href="#" style={{float: 'left', fontSize: 12}}>Details About Patient</a>
            </h6>
            <div style={{width: 'auto', height: 30, position: 'absolute', right: 0, top: 2}}>
              <a href="#" title="Save" onClick={(e) => this.savePatientDetails(e)}><i className="fa fa-save" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>
            </div>
          </div>
        </div>
      </div>
      <textarea name="detailsAboutPatient"
     value={selectedPatientDetails.appointment_note}
       onChange={(event) => {this.handleChange2(event)}} placeholder="For Doctor's Use" style={{height: 238, width: '100%', border: '1px solid #cccccc', marginBottom: 0, marginTop: 0, padding: 10}} defaultValue={""} />
    </div>
    <div className="col-sm-12 col-md-6 col-xl-6 m-b-0" style={{paddingRight: 0, paddingLeft: 0}}>
      <div className="panel panel-default" style={{float: 'left', width: '100%', marginBottom: 0}}>
        <div className="panel-heading" style={{float: 'left', width: '100%', padding: 5}}>
          <div className="col-lg-12 col-xl-12" style={{paddingLeft: 0, paddingRight: 0}}>
            <h6 className="panel-title" style={{float: 'left', width: '100%', fontWeight: 'bold', display: 'inline', marginRight: 10}}>
              <a href="#" style={{float: 'left', fontSize: 12}}>Details About Booking</a>
            </h6>
            <div style={{width: 'auto', height: 30, position: 'absolute', right: 0, top: 2}}>
              <a href="#" title="Save" onClick={(e) => this.saveBookingDetails(e)}><i className="fa fa-save" aria-hidden="true" style={{fontSize: 12, marginRight: 5}} /></a>
            </div>
          </div>
        </div>
      </div>
      <textarea name="detailsAboutBooking" onChange={this.handleChange3} value={selectedPatientDetails.general_note} placeholder="For Receptionist's Use" style={{height: 238, width: '100%', border: '1px solid #cccccc', marginBottom: 0, marginTop: 0, padding: 10}} defaultValue={""} />
    </div>

  </div>
</div>
   



    </div>
  }
  {/* </div> */}
  {/* /.box */}
</div>

{this.state.showPatientHistory && <PatientHistory selectedPatientDetails={selectedPatientDetails.Firstname} key={this.state.patient_id} path={this.props.path}/>}
{/*true && <PatientHistory selectedPatientDetails={selectedPatientDetails.Firstname} key={this.state.patient_id} path={this.props.path}/>*/}
            </div>
         );
    }
}

//export default PatientDetails;
const mapStateToProps = state => {
  console.log(".................",state.getDrAppointments);
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
  let selectedPatientDetails;
  console.log(">>>>>>", patientDetails);
  if (patientDetails !='') {
    
    selectedPatientDetails = patientDetails[0];
    console.log("IN",selectedPatientDetails);
  } else {
   
    selectedPatientDetails = "";
    console.log("else");
  }
  return {
    selectedPatientDetails
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    saveCommentsapi : (data) => dispatch(api_patientdetails_savecomments(data)),
    saveBookingDetailsapi : (data) => dispatch(api_patientdetails_saveBookingDetailsapi(data)),
    savePatientDetailsapi : (data) => dispatch(api_patientdetails_savePatientDetails(data)),


  }

}

export default connect (mapStateToProps, mapDispatchToProps) (PatientDetails);
