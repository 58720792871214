import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import { connect } from 'react-redux';
import {
    NotificationContainer,
    NotificationManager,
  } from 'react-notifications';
  import AdminHeader from "../../adminheader";
  import Leftsidemenu from "../../leftmenu";
  import Footer from "../../footer";
  import CommonGrid from "../../crudcomponentV2/combinedcrud";
  import { confirmAlert } from 'react-confirm-alert';
  import {api_list_reason} from '../../../DS/DS.ListPage';
  import {api_add_care_goal,api_update_care_goal} from '../../../DS/DS.CarePlans';
class AddCareGoals extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            open:this.props.showModal,
            errors:[],
            goalname:''


            
        }   
    
    }
    componentWillMount=()=>
  {
   //   alert(this.props.popFor)
    if(this.props.popFor=='Edit')
    {
        const getData={tbl:'care_plan_goals_tb',pk:'goal_id',ids:this.props.ids}
        this.props.listCareGoal(getData).then(
            res=>{
                this.setState({goalname:res.data.content.goal_name})
                
            }
        )
       
    }
   
  } 
    handleValidation(){
        let errors = {};
        let formIsValid = true;
      
        //Description name
        if(this.state.goalname==''){
           formIsValid = false;
           errors["goalname"] = "Cannot be empty";
        }
         this.setState({errors: errors});
        return formIsValid;
      }
      onClickHandler=(e)=>
      {
        e.preventDefault();
        if(this.handleValidation()){
            const goalData={
                        goalname:this.state.goalname,
                        userid:this.props.redux_userId
                    }
                    this.props.addCareGoal(goalData).then(
                      req=>{
                             NotificationManager.success('New care plan goal created successfully',)
                             this.props.reloadPage()
                          }
                  );
        }
    } 
    updateRecord=(e)=>
    {
        e.preventDefault();
        if(this.handleValidation()){
            const goalData={
                goalname:this.state.goalname,
                ids:this.props.ids,
                userid:this.props.redux_userId
            }
            this.props.updateCareGoal(goalData).then(
                  req=>{
                             NotificationManager.success('Selected care plan goal modified successfully',)
                             this.props.reloadPage()
                          }
                  );
        }
    }   
render (){
    return(
        <Popup open={this.state.open} closeOnDocumentClick={false} position="right center"   modal>
        {close => (
            <div>
                <div className="modal-header">
                    <span className="modal-title-2" id="exampleModalLabel"> Care plan goal  </span>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e)=>this.props.reloadPage()}> 
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                                <div className="row">
                        <div className="col-sm-12">
                                        <div className="form-group">
                            <div className="col-sm-3"> 
                                <label> Goal : </label>
                            </div>
                            <div className="col-sm-9 cursor">
                                            <form>
                                                <input type="text" name="text" className="form-control" value={this.state.goalname} onChange={(e)=>this.setState({goalname:e.target.value})}/>
                                                <span className="error" style={{color:'red'}}>{this.state.errors["goalname"]}</span>
                                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                                <div className="col-sm-8"> </div>
                        <div className="col-sm-4">
                        {this.props.popFor=='Add' 
                        ? <button type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.onClickHandler(e)}>Save</button>
                        : <button type="button" className="btn btn-primary btn-xs" onClick={(e)=>this.updateRecord(e)}>Update</button>
                        }  <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" aria-label="Close"  onClick={(e)=>this.props.reloadPage()}>Cancel</button> 
                </div>
                        </div>
                </div>
            </div>
        )}
       </Popup> 
     )}
    } 
    const mapDispatchToProps = dispatch => {
        return {
            addCareGoal: data => dispatch(api_add_care_goal(data)),
            listCareGoal : data => dispatch(api_list_reason(data)),
            updateCareGoal: data => dispatch(api_update_care_goal(data)),
         };
      };
      const mapStateToProps = state => {
      
        let redux_userId  = state.getDrAppointments.logged_info!=undefined?state.getDrAppointments.logged_info.content.details[0].user_id:'';
        
        return {
            redux_userId
        };
      };
      export default connect(mapStateToProps,mapDispatchToProps)(AddCareGoals);
 