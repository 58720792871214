import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { connect } from "react-redux";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import {api_savewordfill, api_deletewordfill, api_listwordfill} from "./../../../DS/DS.VisitsNotes"
import Showhelp from './../../showHelp';

class wordfill extends Component {
    state = { 
      wordfillList: [],
      addshortcut: "",
      addtext: "",
      addform: false

     }
    savewordfill = () => {
      this.props.savewordfill(this.state).then(()=> {
        this.props.listwordfill().then((res) => {
          this.setState({wordfillList: res.data.content});

          this.setState({
            addform: false, 
            addshortcut: "",
            addtext:""
          })
        })
        NotificationManager.success(
          "New wordfill record added Successfully!"
        );
      });
      //alert("saving into master");
    }
    deletewordfill = (id) => {
      this.props.deletewordfill({id: id}).then(() => {
        this.props.listwordfill().then((res) => {
          this.setState({wordfillList: res.data.content});
        })
        NotificationManager.success(
          "Wordfill Record Deleted Successfully!"
        );
      })
    }
    componentDidMount(){
      this.props.listwordfill().then((res) => {
        this.setState({wordfillList: res.data.content});
      })
    }
    render() { 
        return ( 
          <Popup trigger={<button id="session-timeout-dialog-logout" data-toggle="modal" data-target="#autofill" type="button" class="btn btn-primary btn-xs">Word Fill</button>} position="right center"   modal
            closeOnDocumentClick={false}>
             {close => (  
              <div>
                <div className="modal-header " style={{ backgroundColor: '#f4f4f4', padding: '5px 15px'}}>
                <h5 class="col-sm-12 sheadtitle">Word Fill <Showhelp gotohelp="word_fill_values"/></h5>
                  <button type="button" className="close"  onClick={() => close()} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div >
                    <div  className="col-sm-10 mt-3">
                        {this.state.addform==true && 
                        <span>
                          <div className="row">
                            <div className="col-sm-2">
                              Short Cut 
                            </div>
                            <div className="col-sm-3">
                              <input type="text" 
                              value={this.state.addshortcut}
                              onChange={e=> {this.setState({addshortcut: e.target.value})}}
                              /> 
                            </div>
                          </div> 
                          <div className="row">
                            <div className="col-sm-2">
                              Text: 
                            </div>
                            <div className="col-sm-3">
                              <textarea  value={this.state.addtext}
                              onChange={e=> {this.setState({addtext: e.target.value})}}></textarea>
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-2">
                            <button  onClick={e=>this.savewordfill()}> Add1 </button>
                          </div>
                        </div>
                    </span>
                 }
             
             

      <table className="calendar table table-bordered tablestyle" style={{float: 'left', overflow: 'scroll', width: '100%'}}>
        <thead>
          <tr style={{backgroundColor: '#f4f4f4'}}>
            <th style={{textAlign: 'left'}}>Select</th>
            <th style={{textAlign: 'left'}}>Shortcut</th>
            <th style={{textAlign: 'left'}}>text</th>
            <th style={{textAlign: 'left'}}>Option</th>
          </tr>
        </thead>
        <tbody>
          {this.state.wordfillList.length>0 && this.state.wordfillList.map(item => (
          <tr>
            <td> 

            <input type="radio"  style={{float: 'left', width: 'auto', marginRight: '5px'}} name="search_condition" className="" 
                             onChange={e => this.props.handleOptionChange(item.text, "wordfill", "text")}

                            />
            </td>
            <td  > {item.shortcut}</td>
            <td > {item.text} </td>
            <td > 
              
            <i
                              onClick={(e) => this.deletewordfill(item.wordfillid)}
                              class="fa fa-trash"
                            ></i>
               </td>
          </tr>        ))}
        </tbody>
      </table>
    </div>
    <div className="col-sm-2 mt-3">
      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal" style={{width:'80px', marginBottom:'5px'}} onClick={e=>this.setState({addform: true})}>Add</button>
      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"style={{width:'80px', marginBottom:'5px'}} >Edit</button>
      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"style={{width:'80px', marginBottom:'5px'}} >Delete</button>
      {/* <a href="#" title="Recording" className="collapsed" aria-expanded="false">
        <img src="images/recordingCircel.png" style={{marginTop: '5px', width: '25px', height: '25px', marginLeft: '20px'}} /></a> */}
    </div>
  </div>
  <div className="form-group row">
    <div className="col-sm-9
    ">
      {/* <textarea style={{float: 'left', padding: '10px', border: '1px solid #ddd', width: '100%'}} defaultValue={""} /> */}
    </div>
  </div>
</div>
<div class="modal-footer">
<button type="button" class="btn btn-primary btn-xs" data-dismiss="modal"
    onClick={e => 
      {this.props.handleSaveOptions(e, "wordfill");
      close();}}>Insert</button> 
    {/* <button 
                       onClick={e => 
                        {this.props.handleSaveOptions(e, "wordfill");
                        close();}
                        }
                      type="button" className="btn btn-primary" style={{marginRight:'5px',width:'70px',height:'35px'}} data-dismiss="modal">Save</button> */}

<button type="button" class="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Close</button>
      </div>
      
      
      
      
      </div>
      
      )}
                    </Popup> 
      
      
      );
    }
}
const mapDispatchToProps = dispatch => {
  return {
    savewordfill: data => dispatch(api_savewordfill(data)),
    listwordfill: data => dispatch(api_listwordfill(data)),
    deletewordfill: data => dispatch(api_deletewordfill(data)),
    
  };
};

export default connect(null, mapDispatchToProps)(wordfill);