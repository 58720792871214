import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
//import './addPatientHistory.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import {wflow} from './../../DS/DS.General';
import axios from 'axios';
import Showhelp from './../showHelp';
import { api_getTitle} from "./../../DS/DS.Appointments";
import {api_getAccount,api_save_account_holder} from './../../DS/DS.Paitents';

class addAccountHolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open:false,
      patient_id:this.props.patient_id,
      isChecked: false,
      errors:[],
      list_title:[],
      title:'',
      firstname:'',
      surname:'',
      addline1:'',
      addline2:'',
      city:'',
      postcode:'',
      phone:'',
      fax:'',
      email:''
     
    };
  }
 componentWillMount()
 {
   console.log("*******",this.props)
    this.setState({open:this.props.showform})
    this.props.getTitle().then(res=>
      {
        this.setState({list_title: res.data.content})
      })
      alert("in")
     /* this.props.getData({pat:this.props.patient_id}).then(res=>
        {
          this.setState({
            title:res.data.content[0].holder_title,
            firstname:res.data.content[0].firstname,
            surname:res.data.content[0].surname,
            addline1:res.data.content[0].address_line1,
            addline2:res.data.content[0].address_line2,
            city:res.data.content[0].city,
            postcode:res.data.content[0].zipcode,
            phone:res.data.content[0].phone,
            fax:res.data.content[0].fax,
            email:res.data.content[0].holder_email,
           
          })
        })*/
 }
 onClickHandler=()=>
 {
   const vals={
      title:this.state.title,
      firstname:this.state.firstname,
      surname:this.state.surname,
      addline1:this.state.addline1,
      addline2:this.state.addline2,
      city:this.state.city,
      postcode:this.state.postcode,
      phone:this.state.phone,
      fax:this.state.fax,
      email:this.state.email,
   }
   this.props.saveData(vals).then(res=>
    {
      this.props.changeKey();
    })
 }

  render() {
    return (
      <Popup
       open={this.state.open}
       closeOnDocumentClick={false}
        position="right center"   modal
      >
        {close => (
          <div className="clinicin">
            <div className="modal-header">
                <button type="button" className="close"  onClick={() =>this.props.changeKey()} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
                <span>Account Payer</span>
                <Showhelp gotohelp="account_payer_help"/>
              </div>
              <div className="modal-body newpostpopup">
              <div
                className="col-sm-12"
                style={{
                  paddingRight: "0px",
                  marginTop: "10px",
                  marginBottom: "10px"
                }}
              >
               <div className="form-group row" style={{marginTop: '30px'}}>
                 <div className="col-md-2">Title</div>
                 <div className="col-md-3">
                 <select  className="form-control"  value={this.state.title} onChange={e =>
                        this.setState({title: e.target.value})
                      } >
                        {
                        this.state.list_title.map((item, index) => (
                          <option
                            value={item.value}
                            selected={this.state.title === item.value}
                            key={index}
                          >
                            {item.display}
                          </option>
                        ))}
                    </select>
                 </div>
              </div>
               <div className="form-group row mt-3">
               <div className="col-md-2">First name</div>
               <div className="col-md-3">
                  <input type="text"
                    value={this.state.firstname}
                    className="form-control form-control-sm"
                    onChange={(e)=>this.setState({firstname:e.target.value})}
                    />
               </div>
               </div>
               <div className="form-group row">
               <div className="col-md-2">Surname/Company name</div>
               <div className="col-md-3">
                  <input type="text"
                    value={this.state.surname}
                    className="form-control form-control-sm"
                    onChange={(e)=>this.setState({surname:e.target.value})}
                    />
               </div>
              </div> 
              <div className="form-group row mt-3">
              <div className="col-md-2">Address line 1: </div>
               <div className="col-md-3">
                  <input type="text"
                    value={this.state.addline1}
                    className="form-control form-control-sm"
                    onChange={(e)=>this.setState({addline1:e.target.value})}
                    />
               </div>
               </div>
               <div className="form-group row">
                <div className="col-md-2">Address line 2: </div>
                <div className="col-md-3">
                    <input type="text"
                      value={this.state.addline2}
                      className="form-control form-control-sm"
                      onChange={(e)=>this.setState({addline2:e.target.value})}
                      />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-2">City/Suburb: </div>
                <div className="col-md-3">
                    <input type="text"
                      value={this.state.city}
                      className="form-control form-control-sm"
                      onChange={(e)=>this.setState({city:e.target.value})}
                      />
                </div>
                <div className="col-md-2">Postcode: </div>
                <div className="col-md-3">
                    <input type="text"
                      value={this.state.postcode}
                      className="form-control form-control-sm"
                      onChange={(e)=>this.setState({postcode:e.target.value})}
                      />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-2">Phone: </div>
                <div className="col-md-3">
                    <input type="text"
                      value={this.state.phone}
                      className="form-control form-control-sm"
                      onChange={(e)=>this.setState({phone:e.target.value})}
                      />
                </div>
                <div className="col-md-2">Fax: </div>
                <div className="col-md-3">
                    <input type="text"
                      value={this.state.fax}
                      className="form-control form-control-sm"
                      onChange={(e)=>this.setState({fax:e.target.value})}
                      />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-2">E-mail address: </div>
                <div className="col-md-3">
                    <input type="text"
                      value={this.state.email}
                      className="form-control form-control-sm"
                      onChange={(e)=>this.setState({email:e.target.value})}
                      />
                </div>
                </div>
              </div>
              </div>
              <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary btn-xs"
                    onClick={(e)=>{this.onClickHandler(e) ;}}
                >
                  Save
                </button>
              
                <button
                  type="button"
                  className="btn btn-primary btn-xs"
                  data-dismiss="modal"
                  onClick={() =>this.props.changeKey()}
                >
                  Cancel
                </button>
              </div>
              </div>
          
        )}
      </Popup>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getTitle:()=> dispatch(api_getTitle()),
    getData:(data)=> dispatch(api_getAccount(data)),
    saveData:(data)=>dispatch(api_save_account_holder(data))

   };
};

const mapStateToProps = state => {

  //  let redux_patientId  = state.getDrAppointments.getPatientDetails!=undefined?state.getDrAppointments.getPatientDetails[0].patient_id:'';

  return {
   // redux_patientId
  };
};
export default connect(
    mapStateToProps,
  mapDispatchToProps
)(addAccountHolder);

//export default AddClinicalRecord;
