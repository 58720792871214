import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import {api_visitnotes_list_options} from './../../../../DS/DS.VisitsNotes';
import { connect } from "react-redux";
import Showhelp from './../../../showHelp';

class eye extends Component {
  state = { 
    list_options: {}
  }
  componentDidMount() {
    this.props.visitnotes_list_options().then(res => {
      //   console.log("addPatientForm addPatientForm",res);
      this.setState({ list_options: res.data.content });
      console.log(this.state.list_options);
    });
  }
    render() {
        return (
            <Popup trigger={<a  title="Eye" className="collapsed" aria-expanded="false"><i className="fa fa-heart" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>} position="right center"   modal
            closeOnDocumentClick={false}
           >
             {close => (

                 <div className="eyelay">

               <div className="modal-header" 
              //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
               >

                      <button type="button" className="c
                      lose" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> EYE <Showhelp gotohelp="eye_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Eye</h4> */}
                    </div>
                    <div className="modal-body"   style={{overflowY: "scroll", height: "450px" }}>
                        <h4 style={{}}>History:</h4>


                        <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Visual deterioration</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.VisualDeterioration === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.VisualDeterioration === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.VisualDeterioration === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualDeterioration")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Visual loss</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.VisualLoss === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualLoss")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.VisualLoss === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualLoss")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.VisualLoss === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "VisualLoss")}
                    /> Neither

                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Something in eye</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.SomethingInEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SomethingInEye")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.SomethingInEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SomethingInEye")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.SomethingInEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SomethingInEye")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Itchy eye</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.ItcyEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "ItcyEye")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.ItcyEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "ItcyEye")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.ItcyEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "ItcyEye")}
                    /> Neither

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Red eye</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.RedEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "RedEye")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.RedEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "RedEye")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.RedEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "RedEye")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Sore eye</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.SoreEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SoreEye")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.SoreEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SoreEye")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.SoreEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "SoreEye")}
                    /> Neither

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Watering eye </label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.WateringEye === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "WateringEye")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.WateringEye === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "WateringEye")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.WateringEye === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "WateringEye")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Eye discharge</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.EyeDischarge === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "EyeDischarge")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.EyeDischarge === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "EyeDischarge")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.EyeDischarge === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "EyeDischarge")}
                    /> Neither

                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}> Double vision </label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.DoubleVision === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "DoubleVision")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.DoubleVision === "No"}
                      onChange={e => this.props.handleOptionChange(e, "eye", "DoubleVision")}
                    /> No

                        </div>

                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>


                        </div>
                </div>


                &nbsp;
                <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                <h4 style={{}}>Examination:</h4>

                &nbsp;

                <div className="col-sm-12 ">

                            <div className="col-sm-1 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '0px'}}>Visual acuity:</label>
                        </div>

                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                        <label style={{marginTop: '0px'}}>Without glasses:</label>
                        </div>
                        <div className="col-sm-1" style={{float: 'left',marginTop: '0px'}}>
                        <label style={{marginTop: '0px'}}>Right</label>
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                        <select style={{width:"100%"}}
                        value={this.props.vals_examination.withoutglassesright}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withoutglassesright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                          
                            </select>
                        </div>
                        <div className="col-sm-1" style={{float: 'left',marginTop: '0px'}}>
                        <label style={{marginTop: '0px'}}>Left</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.withoutglassesLeft}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withoutglassesLeft")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_Left != null &&
                          this.state.list_options.withoutglasses_Left.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                        <div className="col-sm-1" style={{float: 'left',marginTop: '0px'}}>
                        <label style={{marginTop: '0px'}}>Both</label>
                        </div>

                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.withoutglassesboth}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withoutglassesboth")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>


               </div>
               <div className="col-sm-12 ">

                            <div className="col-sm-1 " style={{textAlign:'left'}}>

                        </div>

                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                        <label style={{marginTop: '0px'}}>With glasses:</label>
                        </div>
                        <div className="col-sm-1" style={{float: 'left',marginTop: '0px'}}>
                        <label style={{marginTop: '0px'}}>Right</label>
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left'}}>
                        <select style={{width:"100%"}}
                        value={this.props.vals_examination.withglassesright}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withglassesright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1" style={{float: 'left',marginTop: '0px'}}>
                        <label style={{marginTop: '0px'}}>Left</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.withglassesLeft}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withglassesLeft")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>

                        <div className="col-sm-1" style={{float: 'left',marginTop: '0px'}}>
                        <label style={{marginTop: '0px'}}>Both</label>
                        </div>

                        <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.withglassesboth}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withglassesboth")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.withoutglasses_right != null &&
                          this.state.list_options.withoutglasses_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>


               </div>
               &nbsp;


               <div className="col-sm-12 ">

                            <div className="col-sm-1 " style={{textAlign:'left',marginTop: '10px'}}>

                        </div>

                        <div className="col-sm-2" style={{float: 'left',marginTop: '10px'}}>
                        <label style={{marginTop: '0px'}}>Foreign body:</label>
                        </div>
                        <div className="col-sm-1" style={{float: 'left',marginTop: '10px'}}>
                        <label style={{marginTop: '0px'}}>Right</label>
                        </div>
                        <div className="col-sm-2 " style={{textAlign:'left',marginTop: '10px'}}>
                        <select style={{width:"100%"}}
                        value={this.props.vals_examination.Foreignbody}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "withglassesright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.foreignbody_right != null &&
                          this.state.list_options.foreignbody_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-1" style={{float: 'left',marginTop: '10px'}}>
                        <label style={{marginTop: '0px'}}>Left</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '10px'}}>
                            <select style={{width:"100%"}}
                            value={this.props.vals_examination.foreignbody_right}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "foreignbodyright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.foreignbody_right != null &&
                          this.state.list_options.foreignbody_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                            </select>
                        </div>



               </div>


               <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Redness</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Redness == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Redness")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox" 
                      value="Left"
                      checked={this.props.vals_examination.Redness == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Redness")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.Redness == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Redness")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Discharge</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Discharge == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Discharge")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Discharge == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Discharge")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Discharge == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Discharge")}
                    /> Neither
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Swelling</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Swelling == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Swelling")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Swelling == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Swelling")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Swelling == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Swelling")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Ulcer</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Ulcer == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ulcer")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Ulcer == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ulcer")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Ulcer == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ulcer")}
                    /> Neither

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Pterygium</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Pterygium == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Pterygium")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Pterygium == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Pterygium")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Pterygium == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Pterygium")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Ingrowing lashes</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.IngrowingLashes == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "IngrowingLashes")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.IngrowingLashes == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "IngrowingLashes")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.IngrowingLashes == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "IngrowingLashes")}
                    /> Neither

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Ectropion</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Ecropion == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ecropion")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Ecropion == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ecropion")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Ecropion == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Ecropion")}
                    /> Neither
                        </div>
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Entropion</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Entropion == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Entropion")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Entropion == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Entropion")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Entropion == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Entropion")}
                    /> Neither
                        </div>

                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Hyphaema</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Hyphaema == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Hyphaema")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Hyphaema == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Hyphaema")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Hyphaema == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Hyphaema")}
                    /> Neither
                        </div>

                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Cataract</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Cataract == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Cataract")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Cataract == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Cataract")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Cataract == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Cataract")}
                    /> Neither
                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Papilloedema</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals_examination.Papilloedema == "Right"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Papilloedema")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals_examination.Papilloedema == "Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Papilloedema")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals_examination.Papilloedema == "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "Papilloedema")}
                    /> Neither
                        </div>

                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Colour vision</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Normal"
                      checked={this.props.vals_examination.ColourVision == "Normal"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "ColourVision")}
                    /> Normal 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Abnormal"
                      checked={this.props.vals_examination.ColourVision == "Abnormal"}
                      onChange={e => this.props.handleOptionChange(e, "eye_examination", "ColourVision")}
                    /> Abnormal

                        </div>

                      
                        
                </div>


                <div className="col-sm-12 ">

                         <div className="col-sm-1 " style={{textAlign:'left',marginTop: '15px'}}>
                         <label style={{marginTop: '0px'}}>Retinopathy:</label>
                     </div>

                     <div className="col-sm-1" style={{float: 'left',marginTop: '15px'}}>
                     <label style={{marginTop: '0px'}}>Right:</label>
                     </div>

                     <div className="col-sm-2 " style={{textAlign:'left'}}>
                     <select style={{width:"100%"}}
                     value={this.props.vals_examination.Retinopathyright}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "Retinopathyright")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Retinopathy_right != null &&
                          this.state.list_options.Retinopathy_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                         </select>
                     </div>
                     <div className="col-sm-1" style={{float: 'left',marginTop: '15px'}}>
                     <label style={{marginTop: '0px'}}>Left</label>
                     </div>
                     <div className="col-sm-2" style={{float: 'left',marginTop: '0px'}}>
                         <select style={{width:"100%"}}
                         value={this.props.vals_examination.Retinopathyleft}
                            onChange={e => this.props.handleOptionChange(e, "eye_examination", "Retinopathyleft")}
                            >

                          {this.state.list_options != undefined &&
                          this.state.list_options.Retinopathy_right != null &&
                          this.state.list_options.Retinopathy_right.map(
                            (item, index) => (

                                <option value={item.value} > {item.label} </option>
                            
                            ))}
                         </select>
                     </div>






            </div>

</div>


                <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                       onClick={e => 
                        {this.props.handleSaveOptions(e, "eye");
                        close();}
                        }
                        >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>


            </div>

)}
</Popup>
         );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    visitnotes_list_options: data => dispatch(api_visitnotes_list_options(data)),
    
  };
};

export default connect(null, mapDispatchToProps)(eye);