import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import axios from "axios";
import {
  api_searchPatients,
  api_bookappointment,
  addPaitentData,
  api_editappointment,
  api_addTags,
  api_removeTags,
  api_appointment_types_booking_sources,
  api_getPatientDetails,
  api_appoinment_type,
  api_appointment_length
} from "./../../DS/DS.Appointments";
import { wflow, wip } from "./../../DS/general";
import PatientDetails from "../PatientDetails";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Patienttags from "./tags";
import AddPatientForm from "./AddPatientForm";
import EditPatientDetails from "./editPatientDetails";
class Addappointment extends Component {
  state = {
    patientDetailsPopup: false,
    appointment_more_tab: false,
    add_app: this.props.addAppointmentTab,
    showPatientDetails: this.props.showPatientDetails,
    editAppointmentDetails: this.props.editAppointmentDetails,
    appointment_types: [],
    booking_sources: [],
    patients: [],
    selectedPatientId: "",
    logged_info: [],
    selectedPatient: [],
    data: {
      searchText: "",
      searchType: "",
      userType: ""
    },
    appointment_type: "",
    fixappointment: {
      appoinment_id: "",
      patient_id: "1",
      appointment_type: "",
      dressing: "",
      appointment_length: "",
      rememberMe: "",
      booked_through: "",
      appointment_comments: "",
      recurring: "",
      add_list_cancellation: "",
      cancellation_provider: "",
      send_reminder: false,

      is_urgent: "",
      appointment_status: ""
    },
    show_app_patient_form: false,
    tags_patient: [],
    activetagPatientDetails: [],
    drId: '',
    list_appoinment_types:[],
    list_appointment_lengths:[]
  };

  removeTag = (e, data) => {
    wflow("PATIENTTAGS removing "+data.Firstname, data);
    let tags =this.state.tags_patient;
    let newtags = [];
    let latestTag = [];
    let nextTag = [];
    let found = false;
    let removeId = "";
     for(let a=0; a<tags.length; a++){
       if(tags[a].patient_id == data.patient_id){
        found = true;
        //break;
        removeId  = tags[a].patient_id;
     
        //delete thisIsObject[key]; 
       } else {
         newtags.push(tags[a]);
         if(found==true){
           nextTag = tags[a];
         //  alert("next tab"+ tags[a].Firstname);
           found = false;
         }
         latestTag = tags[a];

       }

     } 

     latestTag = nextTag.length!=0? nextTag: latestTag;

    this.setState({activetagPatientDetails: latestTag});
    this.props.getPatientDetails({ patient_id: latestTag });
    this.setState({tags_patient: newtags});
    //this.props.addTags(newtags);
    this.props.removeTag(removeId);
  }

  loadPatient = (e, data) => {
    e.preventDefault();
    wflow("PATIENTTAGS loading patient from tag" , data);

    this.setState({ showPatientDetails: true });
    this.setState({ add_app: false });
    this.setState({ editAppointmentDetails: false });
    this.setState({activetagPatientDetails: data});
    //alert("active tab"+ data.Firstname);
    //active = data
    this.props.getPatientDetails({ patient_id: data });
  };
  

  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.logged_info.content.details[0].doctor_id!=this.state.drId){
      this.setState({ logged_info: nextProps.logged_info });
     // alert( nextProps.logged_info.content.details[0].doctor_id);
      this.setState({ drId: nextProps.logged_info.content.details[0].doctor_id });
    }

    if (
      nextProps.selectedPatientDetails.patient_id !==
      this.state.selectedPatientId
    ) {

      
   
    
    //load initial state tags
    let tags = nextProps.patientTags;
    let found = false;
     for(let a=0; a<tags.length; a++){
       if(tags[a].patient_id == this.props.selected_thisappointment.patient_id){
        found = true;
        break;
       }
     } 
     if(!found){
          //pushing into component state
           tags.push(this.props.selected_thisappointment);
           this.setState({activetagPatientDetails: this.props.selected_thisappointment}); 
          //pushing into initial state
           //this.props.addTags(this.props.selected_thisappointment);
           this.props.addTags(tags);
           this.setState({ tags_patient: tags });
          wflow("PATIENTTAGS - INSERTING", nextProps.patientTags, this.props.selected_thisappointment);
     }else
     {
          wflow("PATIENTTAGS - NOT INSERTING",  nextProps.patientTags, this.props.selected_thisappointment);
     }
 
    

      /*
      this.props.addTags(nextProps.selected_thisappointment);
      //.then(() => {
      let tags = nextProps.patientTags;
      tags.push(nextProps.selected_thisappointment);
      this.setState({ tags_patient: tags });
      wflow(
        "this appointment in add appintment jsx",
        nextProps.selected_thisappointment
      );
      wflow("tags in add appointment", this.state.tags_patient);
*/


      //});

      //let tag_patient = nextProps.patientTags;

      /*
      for(let j=0; j<tag_patient.length; j++){
        if(tag_patient[j].patient_id == nextProps.selectedPatientDetails.patient_id){
          isExistInTags = true;
          break;
        }
      }
      if(isExistInTags==false){
        tag_patient.push(nextProps.selectedPatientDetails);
        this.setState({tag_patient});
      }
      */

      //alert("setting default values");
      this.setState({ selectedPatient: nextProps.selectedPatientDetails });

      this.setState({
        fixappointment: {
          ...this.state.fixappointment,
          appointment_id: nextProps.selected_thisappointment.appoinment_id
        }
      });
      //this.setState({  fixappointment: {...this.state.fixappointment, appointment_type : nextProps.selected_thisappointment.appoinment_type }})
      this.setState({
        fixappointment: {
          ...this.state.fixappointment,
          appointment_length:
            nextProps.selected_thisappointment.appointment_length
        }
      });
      this.setState({
        fixappointment: {
          ...this.state.fixappointment,
          booked_through: nextProps.selected_thisappointment.booked_through
        }
      });

      var fixappointment = { ...this.state.fixappointment };

      fixappointment.appointment_type =
        nextProps.selected_thisappointment.appoinment_type;
      fixappointment.appoinment_id =
        nextProps.selected_thisappointment.appoinment_id;
      fixappointment.dressing = nextProps.selected_thisappointment.dressing;
      fixappointment.appointment_length =
        nextProps.selected_thisappointment.appointment_length;
      fixappointment.rememberMe =
        nextProps.selected_thisappointment.rememberMe == 1 ? true : false;
      fixappointment.booked_through =
        nextProps.selected_thisappointment.booked_through;
      fixappointment.appointment_comments =
        nextProps.selected_thisappointment.comments;
      fixappointment.recurring =
        nextProps.selected_thisappointment.recurring == 1 ? true : false;
      fixappointment.add_list_cancellation =
        nextProps.selected_thisappointment.add_list_cancellation == 1
          ? true
          : false;
      fixappointment.cancellation_provider =
        nextProps.selected_thisappointment.cancellation_provider == 1
          ? true
          : false;
      fixappointment.send_reminder =
        nextProps.selected_thisappointment.send_reminder == 1 ? true : false;

      this.setState({ fixappointment });



      setTimeout(
        function() {
          //Start the timer

          wflow(
            "PATIENTTAGS Loading Patient Details, Edit Mode, - add appointment",
            nextProps,
            this.state
          );
        }.bind(this),
        3000
      );
    }
  }


  openpatientdetails = (e, id) => {
    alert(">>>",id)
    this.setState({selectedEditPatient: id});
    setTimeout(
      function() {
        //Start the timer
        this.openPatientDetails();
      }.bind(this),
      2000
    );
    
  }

  openPatientDetails = () => {
    this.setState({ patientDetailsPopup: true });
  };

  closePatientDetails = () => {
    this.setState({ patientDetailsPopup: false });
  };

  handleScrollToElement2(event) {
    setTimeout(
      function() {
        //Start the timer
        wflow("scrolling to addmoredetail");
        const tesNode2 = ReactDOM.findDOMNode(this.refs.refaddmore);
        if (1) {
          tesNode2.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }.bind(this),
      2000
    );
  }

  handleReset = e => {
    e.preventDefault();
    let reset_fixappointment = this.state.fixappointment;
    reset_fixappointment.appoinment_id = "";
    reset_fixappointment.patient_id = "";
    reset_fixappointment.appointment_type = "";
    reset_fixappointment.appointment_length = "";
    reset_fixappointment.rememberMe = "";
    reset_fixappointment.booked_through = "";
    reset_fixappointment.appointment_comments = "";
    reset_fixappointment.recurring = "";
    reset_fixappointment.add_list_cancellation = "";
    reset_fixappointment.cancellation_provider = "";
    reset_fixappointment.send_reminder = false;
    reset_fixappointment.appointment_status = "";
    reset_fixappointment.dressing = "";

    this.setState({ fixappointment: reset_fixappointment });
  };
  componentDidMount() {

    this.props.getAppointmenttype().then(res => {
      if(res)
      {
        this.setState({list_appoinment_types:res.data.content})
      }
    })

    this.props.getappointmentlength().then(res=>
      {
        this.setState({list_appointment_lengths:res.data.content})
      })

    wflow("selected appointment details add appointment jsx", this.props.selected_thisappointment);



    if (
      this.props.editAppointmentDetails == false &&
      this.state.add_app == true
    ) {
      let reset_fixappointment = this.state.fixappointment;
      reset_fixappointment.appoinment_id = "";
      reset_fixappointment.patient_id = "";
      reset_fixappointment.appointment_type = "";
      reset_fixappointment.appointment_length = "";
      reset_fixappointment.rememberMe = "";
      reset_fixappointment.booked_through = "";
      reset_fixappointment.appointment_comments = "";
      reset_fixappointment.recurring = "";
      reset_fixappointment.add_list_cancellation = "";
      reset_fixappointment.cancellation_provider = "";
      reset_fixappointment.send_reminder = false;
      reset_fixappointment.appointment_status = "";
      reset_fixappointment.dressing = "";
      this.setState({ fixappointment: reset_fixappointment });
    }
    wflow("passed appointments details", this.props.selected_thisappointment);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api=patients&action=api_appointment_types_booking_sources`,
        1
      )
      .then(res => {
        this.setState({ booking_sources: res.data.content.booking_sources });
      });
    //.then(res => this.setState({booking_sources: res.data.content.booking_sources}));

    document.getElementById("addAppointments").scrollIntoView({
      behavior: 'smooth'
    });
    wflow(
      "true false status",
      "edit mode",
      this.props.editAppointmentDetails,
      "add mode",
      this.state.add_app,
      "patient details tab",
      this.state.showPatientDetails,
      "add form",
      this.state.show_app_patient_form,
      "appointment more tab",
      this.state.appointment_more_tab
    );
    if (this.props.editAppointmentDetails == true) {
      this.setState({ appointment_more_tab: true });
    }
  }
  saveeditAppointmentDetails = e => {
    var bookParam = {
      appointment_id: this.state.selectedPatientId,
      doctor_id: this.props.selectedTableProp.dr,
      patient_id: this.state.selectedPatientId,
      date: this.props.selectedTableProp.date,
      time: this.props.selectedTableProp.time,
      booked_through: this.state.fixappointment.booked_through,
      appointment_type: this.state.fixappointment.appointment_type,
      is_urgent: this.state.fixappointment.is_urgent,
      appointment_status: this.state.fixappointment.appointment_status,
      dateSearchParam: {
        start_datetime: this.props.cdate
      },
      doctorSearchParam: {
        doctor_guid: this.props.cdoctor,
        start_datetime:
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate() +
          " 06:00:00",
        end_datetime: new Date(new Date().setDate(new Date().getDate() + 6))
      },
      fixappointment: this.state.fixappointment
    };
    wflow("booking appointment", bookParam);
    this.props.editAppointment(bookParam);

    this.setState({ appointment_more_tab: false });

    this.setState({ add_app: false });
    NotificationManager.success("Appointment Updated Successfully!");
  };
  handleChange = e =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });

  handleChangeText = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
    this.searchPatient(e.target.value);
  };

  handleSelectChange = val => {
    this.setState({
      data: { ...this.state.data, userType: val.value }
    });
  };

  handleAppointmentChange = e => {
    if (e.target.name == "send_reminder") {
      this.setState({
        fixappointment: {
          ...this.state.fixappointment,
          send_reminder: !this.state.fixappointment.send_reminder
        }
      });
    }

    this.setState({
      fixappointment: {
        ...this.state.fixappointment,
        [e.target.name]: e.target.value
      }
    });

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleAppointmentSelectChange = val => {
    this.setState({
      fixappointment: { ...this.state.fixappointment, userType: val.value }
    });
  };

  searchPatient(searchvalue) {
    var searchParam = {
      searchType: this.state.data.searchType,
      searchText: searchvalue
    };
    this.props.searchPatients(searchParam).then(patient => {
      this.setState({ patients: patient.data.content });
    });
  }

  getPatient(p) {
    this.setState({ selectedPatientId: p.patient_id });
    this.setState({ selectedPatient: p });
    this.setState({ appointment_more_tab: true });
  }

  forceRefresh() {
    // Force a render without state change...
    window.location.reload();
  }
  BookAppointment() {
    let canBook = true;
    if (
      new Date() >
      new Date(
        this.props.selectedTableProp.date +
          " " +
          this.props.selectedTableProp.time
      )
    ) {
      alert("Couldn't book appointment in Past Date/time.");
      canBook = false;
    } else {
      if (this.props.allAppointments.length > 0) {
        for (let i = 0; i < this.props.allAppointments.length; i++) {
          if (
            this.props.allAppointments[i].doctor_id ==
              this.props.selectedTableProp.dr &&
            this.props.allAppointments[i].patient_id ==
              this.state.selectedPatientId &&
            //(this.props.allAppointments[i].date == this.props.selectedTableProp.date) &&
            this.props.selectedTableProp.date +
              " " +
              this.props.selectedTableProp.time +
              ":00" ==
              this.props.allAppointments[i].start_time
          ) {
            canBook = false;
            alert(
              "Couldn't book appointment, Already same person found on timeslot."
            );
            this.setState({ patients: [] });
            this.setState({ appointment_more_tab: false });
            this.setState({ data: { ...this.state.data, searchText: "" } });
            break;
          } else {
          }
        }
      }
    }

    if (canBook) {
      var bookParam = {
        doctor_id: this.props.selectedTableProp.dr,
        patient_id: this.state.selectedPatientId,
        date: this.props.selectedTableProp.date,
        time: this.props.selectedTableProp.time,
        booked_through: this.state.fixappointment.booked_through,
        appointment_type: this.state.fixappointment.appointment_type,
        is_urgent: this.state.fixappointment.is_urgent,
        appointment_status: this.state.fixappointment.appointment_status,
        other_details: this.state.fixappointment,
        dateSearchParam: {
          start_datetime: this.props.cdate
        },
        doctorSearchParam: {
          doctor_guid: this.props.cdoctor,
          start_datetime:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth() + 1) +
            "-" +
            new Date().getDate() +
            " 06:00:00",
          end_datetime: new Date(new Date().setDate(new Date().getDate() + 6))
        },
        fixappointment: this.state.fixappointment
      };
      wflow("booking appointment", bookParam);
      this.props.bookAppointment(bookParam);

      this.setState({ patients: [] });
      this.setState({ appointment_more_tab: false });
      this.setState({ data: { ...this.state.data, searchText: "" } });
      NotificationManager.success("Appointment Booked Successfully!");
    }
  }

  thenaddpatient = () => {
    this.setState({ patients: [] });
    this.setState({ appointment_more_tab: false });
    this.setState({ show_app_patient_form: false });
    this.setState({ data: { ...this.state.data, searchText: "" } });
    NotificationManager.success("New Patient Added Successfully!");
  };

  toggleActive = i => {
    if (i === this.state.isActive) {
      this.setState({
        isActive: null
      });
    } else {
      this.setState({
        isActive: i
      });
    }
  };

  toggleAddPatientForm() {
    this.setState({
      show_app_patient_form: !this.state.show_app_patient_form
    });
  }
  setSelectedContent=(len)=>
  {
  //  alert(this.state.fixappointment.appointment_type)
   // alert(len.appoinment_type_id)
    this.state.fixappointment.appointment_length=len.appointment_interval
  }
  componentWillMount =()=>
  {
    if(this.props.showmod)
    {
      this.setState({appointment_more_tab:true})
    }
  }
  render() {
    return (
      <div id="addAppointments">
        {this.state.add_app && !this.props.editAppointmentDetails && (
          <div  className="col-md-6 mt-2 my-2 mb-2 searchlay">
            <div className="input-group" style={{ paddingLeft: "10px" }}>
              <input
                name="searchText"
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    this.searchPatient();
                  }
                }}
                type="text"
                value={this.state.data.searchText}
                className="form-control input-lg app-seaqch searchin"
                placeholder="Search by Name / Dob / Medical No"
                onChange={this.handleChangeText}
              />
              <span className="input-group-btn searchsp">
                <button
                  style={{ marginRight:'5px' }}
                  type="button"
                  className="btn btn-default  btn-flat seachicon"
                  onClick={() => this.searchPatient()}
                >
                  <i className="fa fa-search fa-2x" aria-hidden="true"></i>
                </button>
                <a
                      onClick={() => this.toggleAddPatientForm()}
                      className="btn m-1 seachbtn"   >
                      New Patient
                    </a>
              </span>
            </div>
          </div>
        )}

        {this.state.add_app && !this.props.editAppointmentDetails && (
          <div className="col-md-12 p-1  pl-1">
            {/* <NotificationContainer /> */}
            <div
              className="box-body no-padding patsearch "
              style={{
                height: "200px",
                overflow: "scroll",
                marginLeft: "0px"
              }}
            >
              <table className="table table-striped table-hover input-sm">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Address</th>
                    <th>Mobile No</th>
                    <th>D.O.B.</th>
                    <th>Medicare No</th>
                    <th>Action</th>
                  </tr>
                  {this.state.patients != undefined && this.state.patients.length > 0 ? (
                    this.state.patients.map((patient, i) => (
                      <tr
                        className="pointercursor"
                        style={
                          this.state.isActive === i
                            ? { background: "#57b846", color: "blue" }
                            : { background: "" }
                        }
                        key={i}
                        name="patient_id"
                        onClick={() => {
                          this.getPatient(patient, i);
                          this.toggleActive(i);
                          this.handleScrollToElement2();
                        }}
                      >
                        <td>{patient.Firstname}{" "}{patient.Surname}</td>
                        <td>{patient.age}</td>
                        <td>
                          {patient.Address1 != null
                            ? patient.Address1 + ", "
                            : ""}
                          {patient.Address2 != ''
                            ? patient.Address2 + ", "
                            : ""}
                          {patient.City != null ? patient.City + ", " : ""}
                          {patient.Postcode}
                        </td>
                        <td>
                          {patient.home_phone}
                        </td>
                        <td> {moment(patient.DOB).format("DD-MM-YYYY")} 
                        
                        
                        
                        </td>
                        <td> {patient.medicare_no}</td>
                        <td> 
                          <button className=" btn btn-primary btn-xs" onClick={e => this.openpatientdetails(e, patient.patient_id)}> View/Edit </button>

                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" class="text-center">No Records Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {this.state.appointment_more_tab && (
          <div className="col-md-12 p-1">
            <div className="box box-widget m-0 box box-widget box-default  my-sm-1">
              <div className="box-header with-border">
                <div
                className="panel-heading"
                >

              <EditPatientDetails
              patient_id={
                this.state.selectedEditPatient != undefined &&
                this.state.selectedEditPatient
              }
                open={this.state.patientDetailsPopup}
                close={e => this.closePatientDetails()} 
              />

                  <div className="user-block">
                    <div>
                      <span className="username" data-widget="collapse">
                        <label
                          className="label-input100"
                          style={{
                            paddingBottom: '0px',
                            paddingTop: '7px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            marginLeft: '-50px'
                          }}
                        >
                          New Appointment :{" "}
                        </label>

                        <label
                          className="label-input100"
                          style={{ paddingBottom: '0px', paddingTop: '7px', fontSize:'16px', fontWeight: 'bold' }}
                        >
                          Name :{" "}
                          <span
                             style={{ color: '#b0ebdb', fontStyle: 'italic', fontSize: '16px' }}

                          >
                            {this.state.selectedPatient.Firstname}{" "}{this.state.selectedPatient.Surname}{" "}
                          </span>
                        </label>
                        <label
                          className="label-input100"
                          style={{ paddingBottom: '0px', paddingTop: '7px', fontSize:'16px', fontWeight: 'bold' }}
                        >
                          {" "}
                          DOB :{" "}
                          <span
                           style={{ color: '#b0ebdb', fontStyle: 'italic', fontSize: '16px' }}

                          >
                            {" "}
                            {this.state.selectedPatient.DOB != ""
                              ? moment(this.state.selectedPatient.DOB).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}{" "}
                            ({this.state.selectedPatient.age} Years){" "}
                          </span>
                        </label>
                        <label
                          className="label-input100"
                          style={{ paddingBottom: '0px', paddingTop: '7px', fontSize:'16px', fontWeight: 'bold' }}

                        >
                          {" "}
                          Sex :{" "}
                          <span
                             style={{ color: '#b0ebdb', fontStyle: 'italic', fontSize: '16px' }}


                          >
                            {this.state.selectedPatient.display}
                          </span>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="appdetails">
                  <div  className="col-lg-2 pr-0">
                    <div className="form-group">
                      <span className="input-sm">Appointment Type:</span>
                      <input
                        type="hidden"
                        name="appoinment_id"
                        value={this.state.fixappointment.appoinment_id}
                      />
                      <select
                        name="appointment_type"
                        onChange={e => this.handleAppointmentChange(e)}
                        value={this.state.fixappointment.appointment_type}
                        multiple
                        className="form-control input-sm"
                        style={{maxHeight: '212px', padding: '10px', fontSize: '12px', fontWeight:'normal'}}
                      >
                       {this.state.list_appoinment_types != null && this.state.list_appoinment_types.map((item, index) => (
                          <option
                            value={item.appoinment_type_id}
                            selected={this.state.fixappointment.appointment_type === item.appoinment_type_id}
                            key={index}
                            onClick={e =>
                              this.setSelectedContent(item) }>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                   {/* commented by kandan} <span className="input-sm">Patient Comments :</span>
                      <textarea
                        onChange={e => this.handleAppointmentChange(e)}
                        name="dressing"
                        className="form-control"
                        rows={3}
                        placeholder="Details"
                        value={this.state.fixappointment.dressing}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                            <div className="form-group">*/}
                      <span className="input-sm">Appointment Length:</span>
                      <select
                        onChange={e => this.handleAppointmentChange(e)}
                        value={this.state.fixappointment.appointment_length}
                        name="appointment_length"
                        className="input100"
                        style={{
                          padding: ".0rem .5rem",
                          height: 30,
                          borderBottom: "1px solid #ccc",
                          fontSize: 12
                        }}
                      >
                        {this.state.list_appointment_lengths != null && this.state.list_appointment_lengths.map((item, index) => (
                          <option
                            value={item.appointment_interval}
                            selected={this.state.fixappointment.appointment_length === item.appointment_interval}
                            key={index}
                          >
                            {item.appointment_interval}
                          </option>
                        ))}
                        
                        
                      </select>
                    </div>
                    <div className="form-group">
                      <div className="checkbox">
                        <span className="input-sm">
                          <label>
                            <input
                              onChange={e => this.handleAppointmentChange(e)}
                              name="rememberMe"
                              checked={this.state.fixappointment.rememberMe}
                              type="checkbox"
                            />{" "}
                            Remember me
                          </label>
                        </span>
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="form-group">
                      <span className="input-sm">Booked by:</span>
                      <select
                        value={this.state.fixappointment.booked_through}
                        onChange={e => this.handleAppointmentChange(e)}
                        name="booked_through"
                        multiple
                        className="form-control input-sm"
                        style={{ maxHeight: '284px', fontSize: '12px', padding: '10px', fontWeight:'normal' }}
                      >


                        <option selected={true} value={'d'+this.props.logged_info.content.details[0].doctor_id}> 
                        {this.props.logged_info.content.details[0].Firstname} </option>
                        {this.state.booking_sources.length > 0 &&
                          this.state.booking_sources.map(source => (
                            <option value={source.source_id}>
                              {source.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-8">
                  <div className="form-group">
                    <span className="input-sm">Patient Comments :</span>
                      <textarea
                        onChange={e => this.handleAppointmentChange(e)}
                        name="dressing"
                        className="form-control"
                        rows={3}
                        placeholder="Details"
                        value={this.state.fixappointment.dressing}
                      />
                    </div>

                    <div className="form-group">
                      <span className="input-sm"> Appointment Comments </span>
                      <textarea
                        onChange={e => this.handleAppointmentChange(e)}
                        name="appointment_comments"
                        className="form-control"
                        rows={3}
                        placeholder="Please provide appointment comments here.."
                        value={this.state.fixappointment.appointment_comments}
                        style={{ height: 100 }}
                      />
                      <div className="form-group m-0">
                      {/* <div className="checkbox m-0">
                        <span className="input-sm p-0">
                          <label>
                            <input
                              onChange={e => this.handleAppointmentChange(e)}
                              name="recurring"
                              type="checkbox"
                              checked={this.state.fixappointment.recurring}
                            />{" "}
                            Recurring Appointment
                          </label>
                        </span>
                      </div> */}
                    </div>
                    <div className="form-group m-0">
                      {/* <div className="checkbox m-0">
                        <span className="input-sm p-0">
                          <label>
                            <input
                              onChange={e => this.handleAppointmentChange(e)}
                              name="add_list_cancellation"
                              type="checkbox"
                              checked={
                                this.state.fixappointment.add_list_cancellation
                              }
                            />{" "}
                            Add to Waiting list for cancellation
                          </label>
                        </span>
                      </div> */}
                    </div>
                    <div className="form-group m-0">
                      {/* <div className="checkbox m-0">
                        <span className="input-sm p-0">
                          <label>
                            <input
                              onChange={e => this.handleAppointmentChange(e)}
                              name="cancellation_provider"
                              type="checkbox"
                              checked={
                                this.state.fixappointment.cancellation_provider
                              }
                            />{" "}
                            Will See any provider for cancellation
                          </label>
                        </span>
                      </div> */}
                    </div>
                    <div className="form-group m-0">
                      {/* <div className="checkbox m-0">
                        <span className="input-sm p-0">
                          <label>
                            <input
                              onChange={e => this.handleAppointmentChange(e)}
                              name="send_reminder"
                              type="checkbox"
                              //value={this.state.fixappointment.send_reminder}
                              checked={this.state.fixappointment.send_reminder}
                            />{" "}
                            Send Reminder
                          </label>
                        </span>
                      </div> */}
                    </div>
                    </div>
                    
                  </div>

                  <div className="app-design text-right mt-5">
                    {!this.props.editAppointmentDetails && (
                      <a
                        onClick={() => this.BookAppointment()}
                        href="#"
                        className="btn btn-primary m-1"
                      >
                        Book Appointment
                      </a>
                    )}
                    <a
                      onClick={() => this.toggleAddPatientForm()}
                      className="btn btn-primary m-1"
                    >
                      New Patient
                    </a>
                    <a
                      onClick={e => this.handleReset(e)}
                      href="#"
                      className="btn btn-primary m-1"
                    >
                      Reset
                    </a>
                    {this.props.editAppointmentDetails && (
                      <a
                        onClick={() => this.saveeditAppointmentDetails()}
                        href="#"
                        className="btn btn-primary m-1"
                      >
                        Save
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <span ref="refaddmore"> &nbsp; </span>
        {this.state.showPatientDetails &&
          !this.props.editAppointmentDetails && (
            <Patienttags
            activePatient = {this.state.activetagPatientDetails.patient_id}
            statetags = {this.state.tags_patient}
              removeTag={this.removeTag}
              loadPatient={this.loadPatient}
              key={this.props.patientTags}
              //tags={this.props.patientTags}
              path={this.props.path}
              activePatientGender = {this.state.activetagPatientDetails}
            />
          )}
        {/*<PatientDetails path={this.props.path}/>*/}
        {this.state.showPatientDetails &&
          !this.props.editAppointmentDetails && (
            <PatientDetails path={this.props.path} />
          )}
         {/* {this.state.add_app &&
          this.state.patients.length &&
          !this.state.editAppointmentDetails && (
            <div className="col-md-12">
              <div className="app-design text-right m-3">
                <a href="#" className="btn  btn-primary m-1">{this.props.selectedTableProp.date}{this.props.selectedTableProp.time}{this.props.selectedTableProp.dr}</a>
              </div>
            </div>
          )}  */}

        <div className="col-md-12">
          {this.state.show_app_patient_form ? (
            <AddPatientForm
              thenaddpatient={this.thenaddpatient}
              patient={this.state.selectedPatient}
              addPatientAction={this.props.addPatientAction}
            />
          ) : ""}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  console.log(state);
  let allAppointments = state.getDrAppointments.getAppointments
    ? state.getDrAppointments.getAppointments
    : [];
  let patientTags = state.getDrAppointments.patientTags
    ? state.getDrAppointments.patientTags
    : [];
  let patientDetails = state.getDrAppointments.getPatientDetails
    ? state.getDrAppointments.getPatientDetails
    : [];
    let logged_info =
    state.getDrAppointments.logged_info != undefined
      ? state.getDrAppointments.logged_info
      : "";   
  let selectedPatientDetails;
  if (patientDetails.length > 0) {
    selectedPatientDetails = patientDetails[0];
    wflow("set selected patient details", selectedPatientDetails);
  } else {
    selectedPatientDetails = "";
  }
  wflow(
    "Loading Patient Details - Adding patient tags mapstatetoprops",
    selectedPatientDetails,
    patientTags
  );
  return {
    allAppointments,
    selectedPatientDetails,
    patientTags,
    logged_info
  };
};

const mapDespatchToProps = dispatch => {
  return {
    searchPatients: searchParam => dispatch(api_searchPatients(searchParam)),
    bookAppointment: bookParam => dispatch(api_bookappointment(bookParam)),
    editAppointment: bookParam => dispatch(api_editappointment(bookParam)),
    addPatientAction: data => dispatch(addPaitentData(data)),
    addTags: data => dispatch(api_addTags(data)),
    removeTag: data => dispatch(api_removeTags(data)),
    getPatientDetails: patient_id => dispatch(api_getPatientDetails(patient_id)),
    getAppointmenttype:()=>dispatch(api_appoinment_type()),
    getappointmentlength:()=>dispatch(api_appointment_length())
  };
};

export default connect(mapStateToProps, mapDespatchToProps)(Addappointment);
