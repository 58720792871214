import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager} from 'react-notifications';
import Showhelp from './../../showHelp';

class MetricConversion extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            weight:'',
            calfor:'',
            selectedConversion:0,
            result:'',key:0

           
        }
    }
    componentDidMount(){
        this.setState({open:this.props.showmodal})
     //   alert(this.props.add);
        if(this.props.add=='')
        {
            this.setState({
               
            })
        }
        
    }
    calculate=()=>
    {
        if(this.state.calfor!='')
        {
            if(this.state.selectedConversion==1)
            {
                console.log("********************************")
                const finalvalue=Math.round(parseInt(this.state.calfor) * 30.48)
                console.log("********************************",finalvalue)
                this.setState({result:finalvalue})
            }
            if(this.state.selectedConversion==2)
            {
                    console.log("********************************")
                    const finalvalue=Math.round(parseInt(this.state.calfor) * 30.48)
                    console.log("********************************",finalvalue)
                    this.setState({result:finalvalue})
            }
            if(this.state.selectedConversion==3)
            {
                    const finalvalue=Math.round(parseInt(this.state.calfor) * 6.35029)
                    this.setState({result:finalvalue})
            }
        }
    }
    addValue(valfor,val)
    {
        if(valfor=='cal')
        {
            this.setState({calfor:val,key:Math.random()})
           this.calculate()
        }
        if(valfor=='conve')
        {
            this.setState({selectedConversion:val,key:Math.random()})
            
            if(this.state.calfor!='')
            {
       
                if(val==1)
                {
                    console.log("********************************")
                    const finalvalue=Math.round(parseInt(this.state.calfor) * 30.48)
                    console.log("********************************",finalvalue)
                    this.setState({result:finalvalue})
                }
                if(val==2)
                {
                    console.log("********************************")
                    const finalvalue=Math.round(parseInt(this.state.calfor) * 0.393701)
                    console.log("********************************",finalvalue)
                    this.setState({result:finalvalue})
                }
                if(val==3)
                {
                    const finalvalue=Math.round(parseInt(this.state.calfor) * 6.35029)
                    this.setState({result:finalvalue})
                }
                if(val==4)
                {
                    const finalvalue=Math.round(parseInt(this.state.calfor) * 453.592)
                    this.setState({result:finalvalue})
                }
                if(val==5)
                {
                    const finalvalue=Math.round(parseInt(this.state.calfor) * 0.00220462)
                    this.setState({result:finalvalue})
                }
                if(val==6)
                {
                    const finalvalue=Math.round(parseInt(this.state.calfor) * -17.2222)
                    this.setState({result:finalvalue})
                }
                if(val==7)
                {
                    const finalvalue=Math.round(parseInt(this.state.calfor) * 33.8)
                    this.setState({result:finalvalue})
                }
            }
        }
       // this.calculate()


    }
    render() {
        return ( 
        <Popup position="right center"   modal closeOnDocumentClick={false} open={this.state.open}>
         {close => (
                <div>
                  <div className="modal-header">
                  <h6 style={{float:'left', fontSize: '16px', padding:'5px 10px'}}>Metric conversion <Showhelp gotohelp="metric_conversion_help"/></h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{marginTop:'16px'}}>
                            <span aria-hidden="true" onClick={()=>{this.props.changeKey('');close();}}>×</span>
                        </button>
                  </div>
                  <div className="modal-body newpostpopup" style={{ fontSize: "14px" }}>
                    <div className="col-sm-12" style={{marginBottom: '10'}}>
                        <div className="form-group row">
                            <div className="col-sm-3"><label className="col-form-label text-left">Enter value:</label></div>
                            <div className="col-sm-8"><input type="text" name="search_condition" className="form-control" 
                            value={this.state.calfor} onBlur={(e)=>{this.addValue('cal',e.target.value)}} onChange={(e)=>this.setState({calfor:e.target.value})}/></div>
                        </div>
                        
                        <div className="form-group row">
                            <div className="col-sm-3"><label className="col-form-label text-left">Enter value:</label></div>
                            <div className="col-sm-8">
                                <select class="form-control" onChange={(e)=>{this.addValue('conve',e.target.value)}}>
                                    <option value="0">Select</option>
                                    <option value="1">feet/inches to centimetres</option>
                                    <option value="2">centimetres to inches</option>
                                    <option value="3">stones/pounds to kg</option>
                                    <option value="4">pounds/ounces to grams</option>
                                    <option value="5">grams to pounds/ounces</option>
                                    <option value="6">degrees fahrenite to celsius</option>
                                    <option value="7">degrees celsius to fahrenite</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3"><label className="col-form-label text-left">Result</label></div>
                            <div className="col-sm-2"><input type="text" name="search_condition" className="form-control" 
                            value={this.state.result}/></div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button"  onClick={() => this.props.passValue(this.state.result)} className="btn btn-primary btn-xs" data-dismiss="modal">Close</button>
                </div>
            </div>
        )}
    </Popup>)
}}

export default MetricConversion;
