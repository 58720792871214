import React, { Component } from 'react';
import Immunisations from './immunisations/Immunisations';
import ClinicalImages from './clinical/clinicalImages';
import ObstetricHistory from './obstetrichistory/ObstetricHistory';
import Correspondence from './Correspondence/correspondence';
import EnhancedPrimaryCare from './enhancedprimary/enhancedPrimaryCare';
import FamilySocialHistory from './familySocialHistory/combined';
import InvestigationReports from './investigation/investigationReports';
import Medications from './medications/medications';
import Observations from './observations/observations';
import PastHistory from './pastHistory/pastHistory';
import VisitsNotes from './visitNotes/visitsNotes';
import WomenHistory from './womenHistory/womenhistory';
import WorkinProgress from './workinProgress';
import Mainpage from '../main';
import { connect } from 'react-redux';
import './combinedpatienthistory.css';
class patientHistory extends Component {
  constructor(props) {
    super(props);
    this.renderingComponents = this.renderingComponents.bind(this);
  }

  state = {
    renderComponents: '',
    patientDetails: this.props.selectedPatientDetails,
  };
  //componentWillMount(){
  componentDidMount() {
    console.log(this.props.selectedPatientDetails);
    let goto = this.props.path;

    this.setState({
      renderComponents: goto.substring(1),
    });
    console.log("&&&&&&&&&&&&&&&&&&&&&&&",this.props.redux_patient)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.redux_patient !== this.props.redux_patient) {
      this.setState({ patientDetails: nextProps.redux_patient });
      console.log("COMBINED PATIENT HISTORY"+this.state.patientDetails);
    }
  }
  renderingComponents = data => {
    this.setState({
      renderComponents: data,
    });

    if (window.history.pushState) {
      let urlPath = '#' + data;
      //alert(urlPath);
      window.history.pushState(
        { html: 'SupaDoc', pageTitle: 'SupaDoc ' + data },
        '',
        urlPath
      );
    } else {
      //  document.location.href = "/"+data;
    }
  };
  render() {
    let componentData = null;
    switch (this.state.renderComponents) {
      case 'workinprogress':
        componentData = <WorkinProgress />;

        break;
      case 'visitsnotes':
        componentData = <VisitsNotes />;
        break;
      case 'Immunisations':
        componentData = <Immunisations />;
        break;
      case 'Medications':
        componentData = <Medications />;
        break;
      case 'observations':
        componentData = <Observations />;
        break;
      case 'pasthistory':
        componentData = <PastHistory />;
        break;
      case 'investigation':
        componentData = (
          <InvestigationReports
            navToVisitNotes={() => this.renderingComponents('visitsnotes')}
          />
        );
        break;
      case 'Correspondence':
        componentData = <Correspondence />;
        break;
      case 'FamilySocialHistory':
        componentData = <FamilySocialHistory />;
        break;
      case 'ClinicalImages':
        componentData = <ClinicalImages />;
        break;
        case 'ObstetricHistory':
        componentData = <ObstetricHistory />;
        break; 
      case 'WomenHistory':
        componentData = <WomenHistory />;
        break;
      case 'EnhancedPrimaryCare':
        componentData = <EnhancedPrimaryCare />;
        break;
      default:
        componentData = <VisitsNotes />;
    }
    //alert(this.state.renderComponents);
    return (
      <div style={{ padding: '13px' }}>
        <div
          className="panel panel-default"
          style={{
            float: 'left',
            width: '100%',
            marginTop: 3,
            marginBottom: 10,
          }}
        >
          <div
            id="collapseThreepat"
            className="panel-collapse collapse in"
            role="tabpanel"
            aria-labelledby="headingThreepat"
          >
            <div
              className="panel-body"
              style={{ float: 'left', width: '100%', padding: 0 }}
            >
              <div
                className="panel-body"
                style={{ float: 'left', width: '100%', padding: 4 }}
              >
                <div
                  className="col-md-12"
                  style={{
                    marginTop: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    backgroundColor: '#fafafa',
                  }}
                >
                  <div
                    className="col-md-2"
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      height: 695,
                      overflowY: 'auto',
                    }}
                  >
                    <div className="well no-padding">
                      <div>
                        <ul className="navpat nav-list nav-menu-list-style">
                          <li>
                            <label className="tree-toggle nav-header glyphicon-icon-rpad" style={{background:'#1ebea5'}}>
                              <span
                                className="glyphicon glyphicon-folder-close m5"
                                style={{ color: '#ffffff' }}
                              />
                              <span
                                style={{ color: '#ffffff', fontSize: '18px' }}
                              >
                                {this.state.patientDetails!=undefined && this.state.patientDetails.length > 0 &&
                                  this.state.patientDetails.Firstname}
                                {this.props.selectedPatientDetails}
                              </span>
                              <span className="menu-collapsible-icon glyphicon glyphicon-chevron-down" style={{color:'#eaf8e6' }} /> 
                            </label>
                            <ul
                              className="navpat nav-list bullets"
                              style={{ padding: '0' }}
                            >
                              <li
                                onClick={() =>
                                  this.renderingComponents('visitsnotes')
                                }
                              >
                                <label className={this.state.renderComponents=="visitsnotes"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign">Visit Notes</div>
                                </label>
                              </li>
                              <li
                                onClick={() =>
                                  this.renderingComponents('Medications')
                                }
                              >
                                <label className={this.state.renderComponents=="Medications"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign2">Medications</div>
                                </label>
                              </li>
                              <li
                                onClick={() =>
                                  this.renderingComponents('observations')
                                }
                              >
                              <label className={this.state.renderComponents=="observations"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign3">Observations</div>
                                </label>
                              </li>
                              <li
                                onClick={() =>
                                  this.renderingComponents('Immunisations')
                                }
                              >
                                 <label className={this.state.renderComponents=="Immunisations"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign4">
                                    Immunisations
                                  </div>
                                </label>
                              </li>
                              <li
                                onClick={() =>
                                  this.renderingComponents('pasthistory')
                                }
                              >
                                 <label className={this.state.renderComponents=="pasthistory"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign14">
                                    Past History
                                  </div>
                                </label>
                              </li>
                              <li
                                onClick={() =>
                                  this.renderingComponents('investigation')
                                }
                              >
                                 <label className={this.state.renderComponents=="investigation"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign5">
                                    Investigation reports
                                  </div>
                                </label>
                              </li>
                              <li
                                onClick={() =>
                                  this.renderingComponents('Correspondence')
                                }
                              >
                               <label className={this.state.renderComponents=="Correspondence"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign6">
                                    Correspondence
                                  </div>
                                </label>
                              </li>
                              <li
                                onClick={() =>
                                  this.renderingComponents(
                                    'FamilySocialHistory'
                                  )
                                }
                              >
                             <label className={this.state.renderComponents=="FamilySocialHistory"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign10">
                                    Family/Social history
                                  </div>
                                </label>
                              </li>
                              <li
                                onClick={() =>
                                  this.renderingComponents('ClinicalImages')
                                }
                              >
                               <label className={this.state.renderComponents=="ClinicalImages"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign11">
                                    Clinical Images
                                  </div>
                                </label>
                              </li>
                              {this.props.redux_patient.gender=='Female'?
                              <li
                                onClick={() =>
                                  this.renderingComponents('ObstetricHistory')
                                }
                              >
                              <label className={this.state.renderComponents=="ObstetricHistory"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign15">
                                    Obstetric History
                                  </div>
                                </label>
                              </li>:''}
                              {this.props.redux_patient.gender=='Female'?
                              <li
                                onClick={() =>
                                  this.renderingComponents('WomenHistory')
                                }
                              >
                                  <label className={this.state.renderComponents=="WomenHistory"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign12">
                                    Women's History
                                  </div>
                                </label>
                              </li>:''}
                              <li
                                onClick={() =>
                                  this.renderingComponents('EnhancedPrimaryCare')
                                }
                              >
                              <label className={this.state.renderComponents=="EnhancedPrimaryCare"? 'tree-toggle nav-header combined_highlight' : 'tree-toggle nav-header'}>
                                  <div className="newdesign13">
                                    Enhanced Primary Care
                                  </div>
                                </label>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-10 componentcss"
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      height: 695,
                      backgroundColor: 'white',
                    }}
                  >
                    {componentData}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let redux_patient =
    state.getDrAppointments.getPatientDetails != undefined
      ? state.getDrAppointments.getPatientDetails[0]
      : '';
  return {
    redux_patient,
  };
};

export default connect(mapStateToProps)(patientHistory);
