import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../../showHelp';



class respiratory extends Component {
    state = {  }
    render() {
        return (
            <Popup trigger={<a  title="Respiratory" className="collapsed" aria-expanded="false"><i className="fa fa-ambulance" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>} position="right center"   modal
            closeOnDocumentClick={false}>
             {close => (
        <div className="respirat">

                 <div className="modal-header" 
                //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
                 >

                      <button type="button" className="c
                      lose" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> Respiratory <Showhelp gotohelp="respiratory_help"/> </span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Respiratory</h4> */}
                    </div>

                    <div className="modal-body"   style={{overflowY: "scroll", height: "450px" }}>
                        <h4 style={{}}>History:</h4>





                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Cough</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Cough === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Cough")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Cough === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Cough")}
                    /> No
                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Sputum</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: ''}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Sputum === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Sputum")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Sputum === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Sputum")}
                    /> No

                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Haemoptysis</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Haemoptysis === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Haemoptysis")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Haemoptysis === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Haemoptysis")}
                    /> No

                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Wheeze</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Wheeze === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Wheeze")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Wheeze === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Wheeze")}
                    /> No

                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Pleutritic Pain</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Pleutritic === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Pleutritic")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Pleutritic === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "Pleutritic")}
                    /> No
                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>
                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Childhood asthama</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.asthama === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "asthama")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.asthama === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory", "asthama")}
                    /> No
                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>
               &nbsp;
               <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
                <h4 style={{}}>Examination:</h4>
                &nbsp;


                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Respiratory distress </label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.respiratoryDistress === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "respiratoryDistress")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.respiratoryDistress === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "respiratoryDistress")}
                    /> No

                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>
                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Recession</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Recession === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Recession")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Recession === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Recession")}
                    /> No
                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>

                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Using accessory muscles</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.UsingAccessoryMuscles === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "UsingAccessoryMuscles")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.UsingAccessoryMuscles === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "UsingAccessoryMuscles")}
                    /> No

                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Reduces air entry</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.ReducesAirEntry === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "ReducesAirEntry")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.ReducesAirEntry === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "ReducesAirEntry")}
                    /> No
                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>creps</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Creps === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Creps")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Creps === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Creps")}
                    /> No
                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-3 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '5px'}}>Rhonchi</label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '5px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals_examination.Rhonchi === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Rhonchi")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals_examination.Rhonchi === "No"}
                      onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Rhonchi")}
                    /> No

                        </div>
                        <div className="col-sm-8 ">

                        </div>
                </div>

                <div className="col-sm-12 ">
                            <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Peak flow:  </label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left',marginTop: '15px'}}>
                            <input type="text" style={{width: '70px'}}
                            value={this.props.vals_examination.PeakFlow}
                            onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "PeakFlow")}
                            />
                        </div>
                        <div className="col-sm-2" style={{textAlign:'left'}}>
                        <button type="button" className="btn btn-primary btn-xs" style={{marginRight:'5px',width:'',height:'auto',marginTop: '15px'}} data-dismiss="modal">Calculator</button>
                        </div>
                 </div>

                 <div className="col-sm-12 ">
                            <div className="col-sm-3" style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Chest measurement(cm):  </label>
                        </div>
                        <div className="col-sm-2" style={{float: 'left'}}>
                        <label style={{marginTop: '15px'}}>Inspiration  </label>

                        </div>
                        <div className="col-sm-2" style={{textAlign:'left',marginTop: '15px'}}>
                        <input type="text" style={{width: '70px'}}
                        value={this.props.vals_examination.Inspiration}
                        onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Inspiration")}
                        />
                        </div>
                 </div>
                 <div className="col-sm-12 ">
                            <div className="col-sm-3" style={{textAlign:'left'}}>

                        </div>
                        <div className="col-sm-2" style={{float: 'left'}}>
                        <label style={{marginTop: '15px'}}>Expiration </label>

                        </div>
                        <div className="col-sm-2" style={{textAlign:'left',marginTop: '15px'}}>
                        <input type="text" style={{width: '70px'}} 
                        value={this.props.vals_examination.Expiration}
                        onChange={e => this.props.handleOptionChange(e, "respiratory_examination", "Expiration")}
                        />
                        </div>
                 </div>

             </div>

                 <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                      onClick={e => 
                        {this.props.handleSaveOptions(e, "respiratory");
                        close();}
                        }

                      >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>
 </div>




)}
</Popup>
         );
    }
}

export default respiratory;
