import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import Showhelp from './../../../showHelp';


class musculoskeletal extends Component {
    state = {  }
    render() {
        return (
            <Popup trigger={<a title="Musculo-skeletal" className="collapsed" aria-expanded="false"><i className="fa fa-user-md" style={{'fontSize':'20px', padding:'10px'}} aria-hidden="true"></i></a>} position="right center"   modal
            closeOnDocumentClick={false}>
             {close => (

                 <div className="muscluo">

       <div className="modal-header" 
      //  style={{width: '100%', backgroundColor: '', padding: '5px 15px'}}
       >

                      <button type="button" className="c
                      lose" style={{position: 'absolute',right: '10px',fontSize: '25px'}} onClick={() => close()} data-dismiss="modal" aria-label="Close">
                      ×
                      </button>
                      <span> Musculo skeletal <Showhelp gotohelp="musculo_help"/></span>
                      {/* <h4 style={{backgroundColor: '', float: 'left', width: '100%', fontSize: '25px', margintop: '-5px'}}>Musculo-skeletal</h4> */}
                    </div>
                    <div className="modal-body"   style={{overflowY: "scroll", height: "450px" }}>
                        <h4 style={{}}>History:</h4>



                        <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Neck pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.NeckPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "NeckPain")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.NeckPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "NeckPain")}
                    /> No

                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Back pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.BackPain === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "BackPain")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.BackPain === "No"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "BackPain")}
                    /> No

                        </div>
                </div>


                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Shoulder pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.ShoulderPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ShoulderPain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.ShoulderPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ShoulderPain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.ShoulderPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ShoulderPain")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Hip pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.HipPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HipPain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.HipPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HipPain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.HipPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HipPain")}
                    /> Neither
                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Elblow pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.ElblowPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ElblowPain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.ElblowPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ElblowPain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.ElblowPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ElblowPain")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Knee pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.KneePain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "KneePain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.KneePain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "KneePain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.KneePain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "KneePain")}
                    /> Neither
                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Wrist pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.WristPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "WristPain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.WristPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "WristPain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.WristPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "WristPain")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Ankle pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.AnklePain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "AnklePain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.AnklePain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "AnklePain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.AnklePain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "AnklePain")}
                    /> Neither

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Hand pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.HandPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HandPain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.HandPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HandPain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.HandPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "HandPain")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Foot pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.FootPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FootPain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.FootPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FootPain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.FootPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FootPain")}
                    /> Neither

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Finger pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.FingerPain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FingerPain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.FingerPain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FingerPain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.FingerPain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "FingerPain")}
                    /> Neither
                        </div>
                    <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Toe pain</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>
                        <input
                      type="checkbox"
                      value="Right"
                      checked={this.props.vals.ToePain === "Right"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ToePain")}
                    /> Right 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Left"
                      checked={this.props.vals.ToePain === "Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ToePain")}
                    /> Left
                      &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="Both Right Left"
                      checked={this.props.vals.ToePain === "Both Right Left"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "ToePain")}
                    /> Neither

                        </div>
                </div>



                <div className="col-sm-12">
                  <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Injury</label>
                        </div>
                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>

                        <input
                      type="checkbox"
                      value="Yes"
                      checked={this.props.vals.Injury === "Yes"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "Injury")}
                    /> Yes 
                    &nbsp; &nbsp;
                    <input
                      type="checkbox"
                      value="No"
                      checked={this.props.vals.Injury === "No"}
                      onChange={e => this.props.handleOptionChange(e, "musculoskeletal", "Injury")}
                    /> No

                        </div>

                        <div className="col-sm-4" style={{float: 'left',marginTop: '15px'}}>


                        </div>
                </div>
                &nbsp;
                <hr style={{backgroundColor:'#ddd', height:'1px', border:'none'}}/>
               
               
               
                 <h4 style={{}}>Examination:</h4>
                &nbsp;

                <div className="col-sm-12">
                <div className="col-sm-2 " style={{textAlign:'left'}}>
                            <label style={{marginTop: '15px'}}>Joints:</label>
                        </div>
                        <div className="col-sm-6" style={{float: 'left',marginTop: '15px'}}>
                        <textarea rows="4" cols="50"  placeholder='Description'
                        value={this.props.vals_examination.Joint}
                        onChange={e => this.props.handleOptionChange(e, "musculoskeletal_examination", "Joint")}
                        >

                        
                        </textarea>

                        </div>

                     {/*}   <div className="col-sm-2"  style={{}}>
                      <button type="button" className="btn btn-primary" style={{padding:'6px 12px', fontSize:'14px',width:'',height:'',marginTop:'15px'}} data-dismiss="modal">Add</button>
                      <button type="button" className="btn btn-default" style={{padding:'6px 12px', fontSize:'14px',width:'',height:'',marginTop:'5px'}} data-dismiss="modal">Remove</button>
             </div>*/}
                    <div className="col-sm-2"  style={{}}>

                    </div>
                        </div> 


             </div>

                  <div className="modal-footer"   >
                      <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"
                         onClick={e => 
                          {this.props.handleSaveOptions(e, "musculoskeletal");
                          close();}
                          }
                          >Save</button>
                      <button type="button" className="btn btn-primary btn-xs" onClick={() => close()} data-dismiss="modal">Cancel</button>
                    </div>

        </div>
)}
</Popup>
         );
    }
}

export default musculoskeletal ;
