import React, { Component } from 'react';
import SearchDoctors from './../../Correspondence/searchDoctors';
import OtherPopup from "./OtherPopup";
import Popup from "reactjs-popup";
import Showhelp from './../../../showHelp';

class copies extends Component {
    state = { 
      searchDoc:false,
      selected_doc_id:'',
      from_guid: "",
      showOther: false,
      Firstname: "",
      surname: "",
      Address: "",
      City: "",
      Postcode: "",
     }
     handleFieldChange = (key, val)=> {
       this.setState({[key]: val});
     }
     showOther = () => {
       this.setState({showOther: true});
     }
     closeOther = () => {
      this.setState({showOther: false});
    }
     serachDoctor=()=>
     {
         this.setState({searchDoc:true})
     }
     addOtherCopies = () => {
       let from_guid = this.props.copies; 
       from_guid +=  "\n"+this.state.Firstname + " \n" + this.state.Surname+ ", \n"  + this.state.Address+ ", \n" + this.state.City + ".\n " +this.state.Postcode+ ",";
       this.setState({
        from_guid: from_guid,
        Firstname: "",
        surname: "",
        Address: "",
        City: "",
        Postcode: "",
       })
       this.props.copiestext(from_guid);
     }
     addSelectedDoc=(id)=>
     {
         this.setState({searchDoc:false})
         if(id!='')
         {
        //this.props.copiestext(id.email);
         this.setState({selected_doc_id:id.contact_id,from_guid:this.props.copies+ ", "+id.email+","});
         this.props.copiestext(this.props.copies+ "\n" + id.first_name + " " +id.surname + "\n"+ id.email+ ",");
         }
     }
    render() { 
        return ( 
            
      <div>
    <div className="modal-body" style={{textAlign:"left"}}>
  <div className="form-group row">
  
    <div className="col-sm-11">
      <div className="form-group row">
        <div className="col-sm-3" >
          
           
        </div>
        <div className="col-sm-6">
          {/* <input type="text" value={this.state.from_guid} style={{paddingLeft: '0px'}} name="search_condition" className="form-control form-control-sm rq-form-elemen" /> */}
        </div>
      </div>
      <div className="form-group row">	
        <div className="col-sm-1">
          {/* <label className="col-form-label text-left">Search:</label>  */}

          {(this.state.searchDoc) ?
                    <SearchDoctors searchDoc={this.state.searchDoc} changeKey={this.addSelectedDoc}/> : ''}
  
        </div>
        <div className="col-sm-2">
          {/* <input type="text"  value={this.state.from_guid}  style={{paddingLeft: '0px'}} name="search_condition" className="form-control form-control-sm rq-form-elemen" /> */}
        </div>
        <div className="col-sm-1">
          {/* <labssel className="col-form-label text-left">Category:</label> */}
        </div>
        <div className="col-sm-3">
          {/* <select style={{width: '100%'}}>
            <option>Select One</option>
          </select> */}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-9">
        <label className="col-form-label text-left" >Send copies to:</label>
          <textarea style={{float: 'left', padding: '10px', border: '1px solid #ddd', height: '150px', width: '100%'}} defaultValue={""} value={this.props.copies}  />
        </div>
      </div>
      &nbsp;
      <div className="form-group row">
        <div className="col-sm-2">

        <button className="btn btn-primary btn-xs" onClick={()=> this.serachDoctor()} > Search Contacts </button>
        </div><div className="col-sm-2">
        <button type="button" onClick= {e => {this.props.copytopatient()}}  className="btn btn-primary btn-xs" data-dismiss="modal" style={{width: '110px', marginLeft: '4px'}}>Copy to Patient</button>
        </div> <div className="col-sm-2">
          <button type="button" onClick={e => this.showOther()} className="btn btn-primary btn-xs" data-dismiss="modal" style={{width: '100px'}}> Other </button>
          </div>          <div className="col-sm-2">
          <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal"   onClick={e => this.props.Clear()}>Clear</button>
          </div>
          


          <Popup
style={{ minHeight: "500px" }}

open={this.state.showOther}
close={() => this.closeOther}  

position="right center"
modal
closeOnDocumentClick={false}
contentStyle={{}}
>
 
  <div>
    <div className="modal-header" style={{textAlign:"left"}}>
      <button
        type="button"
        className="close"
        onClick={() => this.closeOther()}
        data-dismiss="modal"
        aria-label="Close"
      >
        {" "}
        ×
      </button>
      <span>Copy to  - Other </span>
    </div>

    <div className="modal-body newpostpopup"
      style={{ fontSize: "14px", textAlign:"left" }}
      
    >
      <div
        className="col-md-12"
        style={{
          marginTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}
      >
       
       <div className="form-group row">
        <div className="col-sm-2"><label className="col-form-label text-left"> First Name:</label> </div>
        <div className="col-sm-6"><input type="text" class="form-control form-control-sm" value={this.state.firstname} onChange={e => this.handleFieldChange("Firstname", e.target.value)} /></div>
        </div>
      <div className="form-group row">     
        <div className="col-sm-2"><label className="col-form-label text-left"> Surname:</label></div>
        <div className="col-sm-6"><input type="text" class="form-control form-control-sm" value={this.state.Surname} onChange={e => this.handleFieldChange("Surname", e.target.value)} /></div>
        </div>

      <div className="form-group row">   
        <div className="col-sm-2"><label className="col-form-label text-left"> Address:</label></div>
        <div className="col-sm-6"><input type="text" class="form-control form-control-sm" value={this.state.Address} onChange={e => this.handleFieldChange("Address", e.target.value)} /></div>
        </div>
        
      <div className="form-group row">   
        <div className="col-sm-2"><label className="col-form-label text-left"> City:</label> </div>
        <div className="col-sm-6"><input type="text" class="form-control form-control-sm" value={this.state.City} onChange={e => this.handleFieldChange("City", e.target.value)} /></div>
        </div>

      <div className="form-group row">   
        <div className="col-sm-2"><label className="col-form-label text-left"> Postcode:</label> </div>
        <div className="col-sm-6"><input type="text" class="form-control form-control-sm" value={this.state.Postcode} onChange={e => this.handleFieldChange("Postcode", e.target.value)} /></div>
        </div>

   
   
      </div>
    </div>

    <div className="modal-footer">
      <button
        type="button" className="btn btn-primary btn-xs"
        onClick={e => {
          this.addOtherCopies(e);
          this.closeOther();
        }}
      >
        Add
      </button>
     
      <button
        type="button"  onClick={() => this.closeOther()}
        className="btn btn-primary btn-xs"
        data-dismiss="modal"
      >
        Cancel
      </button>
    </div>
  </div>
 
</Popup>


          </div>
       <div className="col-sm-3">
         
        </div>
      </div>
    </div>
  </div>
</div>
 

     );
    }
}
 
export default copies ;